import React from 'react';
import Typography, { TypographyVariant } from './Typography';

interface LinkProps {
  children: string;
  typographyVariant?: TypographyVariant;
  onClick?: () => any;
}

const Link: React.FC<LinkProps> = ({
  children,
  typographyVariant = 'body1',
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) onClick();
  };
  return (
    <div className="cursor-pointer" onClick={handleClick}>
      <Typography color="sky" variant={typographyVariant} allowHover>
        {children}
      </Typography>
    </div>
  );
};

export default Link;
