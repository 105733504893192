import React from 'react';
import { KeysMatchingType } from '../../types/utils';
import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { toSignificantFigures } from '../../utils/math';

interface CellProps {
  header?: boolean;
  children?: React.ReactNode;
  onClick?: () => any;
}

const Cell: React.FC<CellProps> = ({ header, children, onClick }) => {
  const CellElement: React.ElementType = header ? 'th' : 'td';

  let className = 'p-1 border border-slate-300';
  if (header) className += ' bg-slate-200';
  if (onClick) className += ' hover:bg-slate-300 transition-colors cursor-pointer';

  return (
    <CellElement className={className} onClick={onClick}>
      {children}
    </CellElement>
  );
};

interface RowProps {
  children: React.ReactNode;
}

const Row: React.FC<RowProps> = ({ children }) => {
  return (
    <tr className="odd:bg-white even:bg-slate-100">
      {children}
    </tr>
  );
};

type DisplayableKey<T> = KeysMatchingType<T, React.ReactNode>;
export type TableColumnProps<T> = { header: React.ReactNode; dataKey: DisplayableKey<T> };
type SortableKey<T> = KeysMatchingType<T, number | string | null | undefined>;
export type SortDirection = 'default' | 'ascending' | 'descending';
function iterateSortDirection(direction: SortDirection): SortDirection {
  switch (direction) {
    case 'default': return 'ascending';
    case 'ascending': return 'descending';
    case 'descending': return 'default';
  }
}
const sortDirectionIcons: Record<SortDirection, React.ElementType<{ className?: string }>> = {
  default: ChevronUpDownIcon,
  ascending: ChevronUpIcon,
  descending: ChevronDownIcon,
};
function compareProperties<T>(a: T, b: T, ascending: boolean): number {
  if (b === null || b === undefined) return -1;
  if (a === null || a === undefined) return 1;
  if (typeof a === 'string') {
    if (ascending) return a.localeCompare(b as string);
    return (b as string).localeCompare(a);
  }
  if (typeof a === 'number') {
    if (ascending) return a - (b as number);
    return (b as number) - a;
  }
  return 0;
}
interface TableProps<T> {
  data: T[];
  columns: TableColumnProps<T>[];
  // Default: Can sort on any column. Use an empty array to disable sorting
  sortableColumns?: SortableKey<T>[];
  numSignificantFigures?: number;
}

const Table = <T,>({
  columns,
  data,
  sortableColumns,
  numSignificantFigures,
}: TableProps<T>) => {
  const [sortKey, setSortKey] = React.useState<keyof T>();
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('default');

  const sortedData = React.useMemo<T[]>(() => {
    if (sortDirection === 'default' || !sortKey) return data;

    const ascending = sortDirection === 'ascending';
    return [...data].sort((a, b) => (
      compareProperties(a[sortKey], b[sortKey], ascending)
    ));
  }, [sortKey, sortDirection, data]);

  const handleSortClick = (key: keyof T) => {
    // If already sorting by this key, iterate the sort direction
    if (key === sortKey) setSortDirection(iterateSortDirection(sortDirection));
    // If not sorting by this key, sort on this key ascending
    else {
      setSortKey(key);
      setSortDirection('ascending');
    }
  };

  const headers = columns.map(({ header, dataKey }) => {
    let headerContent = header;
    let onClick: (() => any) | undefined;
    // If column is sortable, render a component to change the sort direction
    const sortable = (
      sortableColumns === undefined
      || sortableColumns.find((columnDataKey) => columnDataKey === dataKey as any)
    );
    if (sortable) {
      onClick = () => handleSortClick(dataKey);

      const keySortDirection = dataKey === sortKey ? sortDirection : 'default';
      const SortIcon = sortDirectionIcons[keySortDirection];
      let sortIconClass = 'size-4 ';
      sortIconClass += keySortDirection === 'default' ? 'text-slate-500' : 'text-black';
      headerContent = (
        <div className="flex gap-1 items-center justify-between">
          {header}
          <SortIcon className={sortIconClass} />
        </div>
      );
    }
    return <Cell header onClick={onClick}>{headerContent}</Cell>;
  });

  // Generate rows for each entry in the table
  const body = sortedData.map((entry, idx) => {
    // Generate cells for each specified column
    const row = columns.map<React.ReactNode>(({ dataKey }) => {
      let data = entry[dataKey] as React.ReactNode;
      if (typeof data === 'number' && numSignificantFigures !== undefined) {
        data = toSignificantFigures(data, numSignificantFigures);
      }
      return <Cell key={dataKey as React.Key}>{data}</Cell>
    });
    return <Row key={idx}>{row}</Row>
  });

  return (
    <table className="border-2 border-slate-400">
      <thead>
        {headers}
      </thead>
      <tbody>
        {body}
      </tbody>
    </table>
  );
};

export default Table;
