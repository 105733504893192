import React from 'react';

import HTRIEnumSelect from '../../../common/HTRIEnumSelect';
import VariableSelect from './VariableSelect';
import Select from '../../../common/Select';
import HTRIEnumSetSelector from '../../../common/HTRIEnumSetSelector';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';

interface EnumRuleProps {
  rule: EngineeringChecklist.EnumRule;
  onChange: (r: Partial<EngineeringChecklist.EnumRule>) => any;
}

export const equalityOperators = new Set([EngineeringChecklist.Operator.Equals, EngineeringChecklist.Operator.NotEqual])

const EnumRule: React.FC<EnumRuleProps> = ({ rule, onChange }) => {
  const variable = rule.lhs;

  const handleVariableChange = (newVariable: EngineeringChecklist.Variable) => {
    let rhs = rule.rhs;
    // If new variable is incompatible with the old one, clear RHS
    if (variable?.attributeName !== newVariable.attributeName) rhs = [];
    onChange({ lhs: newVariable, rhs });
  };

  const variableSelect = (
    <VariableSelect
      variable={variable}
      allowedTypes={[EngineeringChecklist.VariableType.Enum]}
      onChange={handleVariableChange}
    />
  );

  if (!rule.lhs) return (
    <>
      <div>
        {variableSelect}
      </div>
      <div />
      <div />
    </>
  );

  function handleOperatorChange(newOp: string) {
    const op = newOp as EngineeringChecklist.EnumRuleOperator;
    // Ensure rhs is updated appropriately when changing operators
    if (equalityOperators.has(op) && rule.rhs && rule.rhs.length > 1) onChange({ op, rhs: [rule.rhs[0]] });
    else onChange({ op });
  }

  const rhsSelectComponent = equalityOperators.has(rule.op) ? (
    <HTRIEnumSelect enum={rule.lhs.attributeName} value={rule.rhs?.[0]} onChange={(k) => onChange({ rhs: [k] })} />
  ) : (
    <HTRIEnumSetSelector enum={rule.lhs.attributeName} selected={rule.rhs} onChange={(ks) => onChange({ rhs: ks })} />
  );

  return (
    <>
      <div>
        {variableSelect}
      </div>
      <Select
        value={rule.op as string}
        options={EngineeringChecklist.EnumRuleOperators as readonly string[]}
        onChange={handleOperatorChange}
      />
      <div>
        {rhsSelectComponent}
      </div>
    </>
  );
};

export default EnumRule;
