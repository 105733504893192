import React from 'react';
import { useGetHTRIUnitConversionsQuery } from '../../redux/HTRICalcAPI';
import { skipToken } from '@reduxjs/toolkit/query';
import Select from './Select';

interface HTRISingleUnitSelectProps {
  unitSet: string;
  unit: string;
  onChange: (newUnit: string) => any;
}

/**
 * Unit select for HTRI single units (i.e. units that aren't multiunits)
 * to provide a more intuitive API when support for multiunits isn't needed.
 * This component is also able to recognize more unitSet names,
 * as the conversions endpoint supports the "pretty" names for unitSets
 * unlike unitSet queries.
 */
const HTRISingleUnitSelect: React.FC<HTRISingleUnitSelectProps> = ({ unitSet, unit, onChange }) => {
  const { isFetching, isError, currentData } = useGetHTRIUnitConversionsQuery(unitSet ?? skipToken);

  if (isFetching || unitSet === undefined) return <Select value="" options={[]} loading />;
  if (isError) return <div />;

  const options = Object.keys(currentData ?? {});

  // Ensure unit has a value if undefined, as consumer might not know what the options are
  if (unit === undefined && options.length) {
    onChange(unit[0]);
  }

  return (
    <Select value={unit} options={options} onChange={(newUnit) => onChange(newUnit)} />
  );
};

export default HTRISingleUnitSelect;
