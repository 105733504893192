import { Transition } from '@headlessui/react'
import React from 'react';
import { createPortal } from 'react-dom';
import { XMarkIcon } from '@heroicons/react/24/outline';

import Typography from './common/Typography';
import { useDispatch, useSelector } from '../redux/store';
import { resetNotifications } from '../redux/notifications';

const NotificationBar: React.FC = () => {
  const dispatch = useDispatch();
  const current = useSelector((state) => state.notifications.current);
  const [show, setShow] = React.useState(Boolean(current?.message));

  React.useEffect(() => {
    if (!current?.message) return;
    setShow(true);

    // Automatically clear notification after 5 seconds
    const timeout = setTimeout(() => setShow(false), 5000);
    return () => clearTimeout(timeout);
  }, [current]);

  // Prevent double renders, as they will flash an empty notification due to the transition element
  if (!current?.message && !show) return;

  const notificationElement = (
    <Transition
      show={show}
      as={React.Fragment}
      enter="transition duration-400"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={() => dispatch(resetNotifications())}
      unmount
    >
      <div className="absolute bottom-4 left-1/2 -translate-x-2/4 max-w-full px-4">
        <div className="flex flex-row justify-between items-center p-2 w-max max-w-full bg-slate-900 border-1 border-slate-900 rounded-md shadow-md">
          <Typography variant="body1" color="white">{current?.message}</Typography>
          <div
            className="flex items-center justify-center ml-2 bg-slate-900 hover:bg-slate-500 size-10 rounded-full transition-colors cursor-pointer"
            onClick={() => setShow(false)}
          >
            <XMarkIcon className="text-white size-8" />
          </div>
        </div>
      </div>
    </Transition>
  );

  return (
    <>
      {createPortal(notificationElement, document.body)}
    </>
  );
};

export default NotificationBar;
