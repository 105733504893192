import React from 'react';
import { ArrowPathIcon, PlusIcon } from '@heroicons/react/24/outline';

import { useDispatch, useSelector } from '../../../redux/store';
import Button from '../../common/Button';
import FetchErrorScreen from '../../common/FetchErrorScreen';
import Graph from '../../common/Graph';
import HTRIUnitRange from '../../common/HTRIUnitRange';
import InputField from '../../common/InputField';
import Typography from '../../common/Typography';
import { dataSourceForCorrelation } from './materialDataSources';
import materialsAPI from '../../../redux/materialsAPI';
import { materialsSlice } from '../../../redux/tools/materials';
import { DataSource } from '../../../utils/recharts/dataSources';
import { validateIsNumber } from '../../../utils/inputValidation';
import Material from '../../../types/materials';
import HTRISingleUnitSelect from '../../common/HTRISingleUnitSelect';
import { useHTRIUnitConversions } from '../../../hooks/useUnitConversions';
import useQueries from '../../../hooks/useQueries';
import LoadingScreen from '../../common/LoadingScreen';

const correlationPropertyUnitSets: Record<Material.Correlation.Property, string> = {
  'Allowable Stress': 'Mechanical Stress',
  'Coefficient of Thermal Expansion': 'Thermal Expansion',
  'Metal Density': 'Density',
  'Modulus of Elasticity': 'Mechanical Stress',
  'Thermal Conductivity': 'Thermal Conductivity',
  'Yield Stress': 'Mechanical Stress',
};

function toDataSources(material: Material.Details, property: string): DataSource[] {
  const { correlations } = material;

  const dataSources: DataSource[] = [];
  for (const correlation of correlations) {
    if (correlation.dependentVariable.name !== property) continue;
    dataSources.push(dataSourceForCorrelation(material, correlation));
  }
  return dataSources;
}

const useMaterialDetails = useQueries(materialsAPI.endpoints.getMaterialDetails);
function useCorrelationData(
  property: string,
  materialIDs: number[],
  yAxisUnitSet: string,
) {
  const conversions = useHTRIUnitConversions(['Temperature', yAxisUnitSet]);
  const materialDetails = useMaterialDetails(materialIDs);

  const isFetching = conversions.isFetching || materialDetails.isFetching;
  const isError = conversions.isError || materialDetails.isError;
  const refetch = () => {
    if (conversions.isError) conversions.refetch();
    if (materialDetails.isError) materialDetails.refetch();
  };

  const dataSources = React.useMemo<DataSource[][]>(() => {
    if (isFetching || isError) return [];
    return materialIDs.map((id) => {
      const material = materialDetails.currentData.get(id);
      return toDataSources(material, property);
    });
  }, [conversions, materialDetails, property]);

  return {
    convert: conversions.convert,
    dataSources,
    isFetching,
    isError,
    refetch,
  };
}

const CorrelationView = () => {
  const dispatch = useDispatch();
  const property = useSelector((state) => state.materials.selectedCorrelationProperty);
  const yUnit = useSelector((state) => state.materials.correlationYUnits[property]);
  const materials = useSelector((state) => state.materials.comparing);
  const xRange = useSelector((state) => state.materials.correlationXRange);
  const manualPointValues = useSelector((state) => state.materials.correlationManualPointValues);
  const [pointValueInput, setPointValueInput] = React.useState<string>('0');

  const propertyUnitSet = correlationPropertyUnitSets[property];
  const {
    dataSources: materialDataSources,
    isFetching,
    isError,
    convert,
    refetch,
  } = useCorrelationData(property, materials, propertyUnitSet);
  const dataSources = materialDataSources.flat();

  const rangeValid = (
    validateIsNumber(xRange.min) && validateIsNumber(xRange.max)
    && Number.parseFloat(xRange.min) < Number.parseFloat(xRange.max)
  );
  const propertySelected = Boolean(property);
  const materialsSelected = Boolean(materials.length);
  const hasDataSources = Boolean(dataSources.length);

  // Show loading indicator until units are loaded
  if (isFetching) return <LoadingScreen />;
  if (isError) return (
    <FetchErrorScreen
      title="Error Fetching Correlations"
      onRetry={refetch}
    >
      Something went wrong while loading correlation data.
    </FetchErrorScreen>
  );

  // Show error text unless a property and some number of materials are selected
  if (!propertySelected || !materialsSelected || !hasDataSources) {
    let errorText: string;
    if (!propertySelected && !materialsSelected) errorText = 'Select a physical property and one or more materials';
    else if (!propertySelected) errorText = 'Select a physical property';
    else if (!materialsSelected) errorText = 'Select one or more materials';
    else if (!hasDataSources) errorText = `Select a material with a correlation for ${property}`;
    return (
      <Typography className="p-2" color="slate">
        {`${errorText} to see a graph of the selected property for each material to compare.`}
      </Typography>
    );
  }

  const additionalXs = manualPointValues.map(({ value }) => Number.parseFloat(value));

  const graph = rangeValid
    ? (
      <div className="min-w-96 min-h-48 grow">
        <Graph
          xDomain={{ min: Number.parseFloat(xRange.min), max: Number.parseFloat(xRange.max) }}
          dataSources={dataSources}
          additionalPointValueCalculationXs={additionalXs}
          units={{
            x: { unit: xRange.unit, unitSet: 'Temperature' },
            y: { unit: yUnit, unitSet: propertyUnitSet },
            convert,
          }}
        />
      </div>
    )
    : (
      <Typography color="slate">
        {`Enter a valid range to see a graph of values for ${property.toLowerCase()}.`}
      </Typography>
    )
  ;

  const calculateDisabled = (
    !validateIsNumber(pointValueInput)
    || additionalXs.includes(Number.parseFloat(pointValueInput))
  );
  const handleCalculateClick = () => {
    if (calculateDisabled) return;
    dispatch(materialsSlice.actions.addCorrelationManualPointValue(Number.parseFloat(pointValueInput)))
    setPointValueInput('');
  };

  return (
    <div className="flex flex-col h-full w-full p-2 pb-0 gap-2">
      <div className="flex flex-row gap-1 items-center">
        <Typography className="whitespace-nowrap">X-Axis</Typography>
        <HTRIUnitRange
          min={xRange.min}
          max={xRange.max}
          onMinChange={(min) => dispatch(materialsSlice.actions.updateCorrelationXRange({ min }))}
          onMaxChange={(max) => dispatch(materialsSlice.actions.updateCorrelationXRange({ max }))}
          unitSet="Temperature"
          unit={xRange.unit}
          onUnitChange={(unit) => dispatch(materialsSlice.actions.updateCorrelationXRange({ unit }))}
        />
      </div>
      <div className="flex flex-row gap-1 items-center">
        <Typography className="whitespace-nowrap">Y-Axis</Typography>
        <HTRISingleUnitSelect
          unitSet={correlationPropertyUnitSets[property]}
          unit={yUnit}
          onChange={(unit) => dispatch(materialsSlice.actions.setCorrelationYUnit({ property, unit }))}
        />
      </div>
      <div className="flex flex-row gap-1 items-center">
        <InputField
          label="Point Value"
          type="decimal"
          allowNegative
          value={pointValueInput}
          onChange={setPointValueInput}
          onKeyDown={(e) => { if (e.key === 'Enter') handleCalculateClick(); }}
        />
        <Button
          label="Add to Graph"
          color="green"
          iconLeft={<PlusIcon className="size-6" />}
          disabled={calculateDisabled}
          onClick={handleCalculateClick}
        />
        <Button
          label="Clear"
          color="white"
          disabled={!additionalXs.length}
          iconLeft={<ArrowPathIcon className="size-6" />}
          onClick={() => dispatch(materialsSlice.actions.clearCorrelationManualPointValues())}
        />
      </div>
      {graph}
    </div>
  );
};

export default CorrelationView;
