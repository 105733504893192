import React from 'react';
import useCurrentUser from '../hooks/useCurrentUser';
import Typography from './common/Typography';
import { Role } from '../types/API';

const Footer: React.FC = () => {
  const user = useCurrentUser();

  let userText = '';
  if (user) {
    userText = `${user.lastName}, ${user.firstName}`;
    if (user.role !== Role.User) userText += ` (${user.role})`;
    userText += ` - ${user.company}`;
  }

  const copyrightText = `Copyright © HTRI ${new Date().getFullYear()}`;

  return (
    <footer className="h-8 w-screen bg-gradient-to-r from-sky-300 to-red-400 flex items-center justify-between gap-2 px-2">
      <Typography variant="body2" className="truncate">{userText}</Typography>
      <Typography variant="body2" className="truncate">{copyrightText}</Typography>
    </footer>
  );
};

export default Footer;
