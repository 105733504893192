import React from 'react';
import Select from './Select';
import { useGetHTRIEnumQuery } from '../../redux/HTRICalcAPI';

interface HTRIEnumSelectProps {
  enum: string;
  value: string | undefined;
  onChange: (s: string) => any;
}

const HTRIEnumSelect: React.FC<HTRIEnumSelectProps> = ({ enum: enumName, value, onChange }) => {
  const { isFetching, isError, currentData } = useGetHTRIEnumQuery(enumName);

  if (isError) return <div />;

  return (
    <Select
      options={new Map(Object.entries(currentData?.enum ?? {}))}
      value={value}
      onChange={(v) => onChange(v)}
      loading={isFetching}
    />
  );
};

export default HTRIEnumSelect;
