import React from 'react';
import Filters from './Filters';
import FavoriteMaterials from './FavoriteMaterials';
import MultiSidebar, { MultiSidebarItemProps } from '../../common/MultiSidebar';
import { AdjustmentsHorizontalIcon, ChartBarIcon, StarIcon } from '@heroicons/react/24/outline';
import DynamicFlexContainer from '../../common/DynamicFlexContainer';
import { TabProps } from '../../common/Tabs';
import MaterialsBrowserPage from './MaterialsBrowserPage';
import MaterialsContext, { MaterialsContextProvider } from './MaterialsContext';
import CorrelationPropertySelector from './CorrelationPropertySelector';
import CorrelationsPage from './CorrelationsPage';
import FetchErrorScreen from '../../common/FetchErrorScreen';
import RoutedTabs from '../../common/RoutedTabs';
import LoadingScreen from '../../common/LoadingScreen';

enum Tab {
  MaterialsBrowser = 'Browse Materials',
  Correlations = 'Correlations',
};
const availableTabs: Tab[] = [Tab.MaterialsBrowser, Tab.Correlations];
function tabContent(tab: Tab): React.ReactNode {
  switch (tab) {
    case Tab.MaterialsBrowser: return <MaterialsBrowserPage />;
    case Tab.Correlations: return <CorrelationsPage />;
  }
}

const filtersSidebar: MultiSidebarItemProps = {
  title: 'Filters',
  icon: AdjustmentsHorizontalIcon,
  content: <Filters />,
};
const favoritesSidebar: MultiSidebarItemProps = {
  title: 'Favorites',
  icon: StarIcon,
  content: <FavoriteMaterials />,
};
const correlationPropertySelectSidebar: MultiSidebarItemProps = {
  title: 'Physical Properties',
  icon: ChartBarIcon,
  content: <CorrelationPropertySelector />,
};
const pageSidebars: Record<Tab, MultiSidebarItemProps[]> = {
  [Tab.MaterialsBrowser]: [
    filtersSidebar,
    favoritesSidebar,
  ],
  [Tab.Correlations]: [
    correlationPropertySelectSidebar,
    filtersSidebar,
  ],
};

const MaterialsDatabase: React.FC = () => {
  const { getAllMaterialsQuery } = React.useContext(MaterialsContext);
  const { isFetching, isError, refetch } = getAllMaterialsQuery;

  if (isFetching) return <LoadingScreen />;

  if (isError) return (
    <FetchErrorScreen title="Error Fetching Materials" onRetry={refetch}>
      Something went wrong while fetching material data from our servers.
    </FetchErrorScreen>
  );

  const tabs: TabProps[] = availableTabs.map((tab) => ({
    label: tab,
    element: (
      <DynamicFlexContainer direction="row" className="bg-white" initialSizes={['20%', '80%']}>
        <MultiSidebar sidebars={pageSidebars[tab]} />
        {tabContent(tab)}
      </DynamicFlexContainer>
    ),
  }));

  return <RoutedTabs tabs={tabs} />;
};

// Only expose context-wrapped materials database component
const MaterialsDatabaseWrapper: React.FC = () => {
  return (
    <MaterialsContextProvider>
      <MaterialsDatabase />
    </MaterialsContextProvider>
  );
};

export default MaterialsDatabaseWrapper;
