import React from 'react';

import Rule, { ruleTypeIcons, ruleTypeNames } from './Rule';
import Button from '../../../common/Button';
import { useDispatch, useSelector } from '../../../../redux/store';
import { PlusIcon } from '@heroicons/react/24/outline';
import RulesContext, { RulesContextProps } from './RulesContext';
import { DirectedGraph } from '../../../../utils/graphs';
import ResizableGrid from '../../../common/ResizableGrid';
import rulesColumns from './rulesColumns';
import { engineeringChecklistSlice } from '../../../../redux/tools/engineeringChecklist';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';

const Rules: React.FC = () => {
  const dispatch = useDispatch();
  const rules = useSelector((state) => state.engineeringChecklist.rules);
  const rulesColumnWidths = useSelector((state) => state.engineeringChecklist.rulesColumnWidths);

  let rulesComponent: React.ReactNode;
  if (rules.length) {
    const handleColumnWidthsChange = (widths: string[]) => {
      dispatch(engineeringChecklistSlice.actions.setRulesColumnWidths(widths))
    };
    const ruleElements = rules.map((rule, idx) => (
      <Rule rule={rule} idx={idx} key={rule.id} />
    ));
    rulesComponent = (
      <div className="w-full h-full overflow-auto">
        <ResizableGrid
          columns={rulesColumns}
          columnWidths={rulesColumnWidths}
          onColumnWidthsChange={handleColumnWidthsChange}
        >
          {ruleElements}
        </ResizableGrid>
      </div>
    );
  }

  const newRuleButtons = [
    EngineeringChecklist.RuleType.Numeric,
    EngineeringChecklist.RuleType.Enum,
    EngineeringChecklist.RuleType.String
  ].map((ruleType) => (
    <Button
      color="green"
      iconLeft={ruleTypeIcons[ruleType]}
      iconRight={<PlusIcon className="size-6 text-white" />}
      label={ruleTypeNames[ruleType]}
      onClick={() => dispatch(engineeringChecklistSlice.actions.addRule(ruleType))}
      key={ruleType}
    />
  ));

  // Generate dependency graph for downstream use
  const dependencyGraph = new DirectedGraph<string>();
  rules.forEach((rule) => {
    rule.dependencyGroups.forEach((group) => {
      group.ruleIDs.forEach((dependencyID) => dependencyGraph.addEdge(rule.id, dependencyID));
    });
  });

  const rulesContextValue: RulesContextProps = { dependencyGraph };

  return (
    <RulesContext.Provider value={rulesContextValue}>
      <div className="self-start grid grid-cols-[repeat(3,_min-content)] p-1 gap-1">
        {newRuleButtons}
      </div>
      {rulesComponent}
    </RulesContext.Provider>
  );
};

export default Rules;
