import React from 'react';
import { useSelector } from '../../../../redux/store';
import Select from '../../../common/Select';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';

interface VariableSelectProps {
  variable: EngineeringChecklist.Variable | undefined;
  allowedTypes?: EngineeringChecklist.VariableType[];
  onChange: (v: EngineeringChecklist.Variable) => any;
}

const VariableSelect: React.FC<VariableSelectProps> = ({
  variable,
  allowedTypes: allowedTypesArray,
  onChange,
}) => {
  const allowedTypes = new Set(allowedTypesArray);
  const variables = useSelector((state) => state.engineeringChecklist.variables);

  const options: Map<EngineeringChecklist.Variable, string> = new Map(
    variables
      .filter((variable) => allowedTypes.has(variable.type))
      .map((variable) => [variable, variable.alias])
  );

  return (
    <Select
      options={options}
      value={variable}
      onChange={(v) => onChange(v)}
      placeholder="No variables for this rule type"
    />
  );
};

export default VariableSelect;
