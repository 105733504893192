import { ResizableGridColumns } from '../../../common/ResizableGrid';

export const variablesColumns: ResizableGridColumns = [
  {
    label: 'Status',
    minWidth: 40,
  },
  {
    label: 'Number',
    minWidth: 84,
  },
  {
    label: 'Property',
  },
  {
    label: 'Alias',
    minWidth: 96,
    defaultWidth: 96,
  },
  {
    label: 'IO Selections',
    minWidth: 128,
    defaultWidth: 128,
  },
  {
    label: 'Nav Options',
    minWidth: 166,
  },
  {
    label: 'Units',
  },
];
