import React from 'react';
import { useGetMaterialDetailsQuery } from '../../../redux/materialsAPI';
import LoadingIndicator from '../../common/LoadingIndicator';
import MaterialLabel from './MaterialLabel';
import Typography from '../../common/Typography';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch } from '../../../redux/store';
import { stopViewingMaterialDetailsFor } from '../../../redux/tools/materials';
import LoadingScreen from '../../common/LoadingScreen';

interface MaterialDetailViewProps {
  id: number;
  children: React.ReactNode
}

const MaterialDetailView: React.FC<MaterialDetailViewProps> = ({ id, children }) => {
  const dispatch = useDispatch();
  const { isFetching, currentData: material } = useGetMaterialDetailsQuery(id);

  if (isFetching) return <LoadingScreen />;

  return (
    <Typography className="h-full w-full flex flex-col">
      <div className="flex flex-row justify-between items-center gap-1 px-2 py-1 bg-slate-200">
        <MaterialLabel material={material!} />
        <button onClick={() => dispatch(stopViewingMaterialDetailsFor(id))}>
          <XMarkIcon
            className="size-10 text-slate-500 shrink-0 p-1 rounded-full bg-transparent hover:bg-slate-300 cursor-pointer"
            onClick={() => dispatch(stopViewingMaterialDetailsFor(id))}
          />
        </button>
      </div>
      {children}
    </Typography>
  );
};

export default MaterialDetailView;
