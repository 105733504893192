import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Claim, RawUser, Role, UsersAPI } from '../types/API';
import { getCurrentUser, signIn as sendSignInRequest, signOut as sendSignOutRequest } from '../utils/API';
import { startListening } from './listenerMiddleware';
import { createAsyncThunk } from './asyncThunk';

export enum AuthenticationStatus {
  Pending,
  Authorized,
  Unauthorized,
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  claims: Claim[];
  role: Role;
  iat: number;
  exp: number;
}

export interface AuthenticationState {
  status: AuthenticationStatus;
  user: User | null;
  signedOutEmail: string;
  saveEmail: boolean;
  userQueryParams: UsersAPI.GetUsersRequestParams;
}

const initialState: AuthenticationState = {
  status: AuthenticationStatus.Pending,
  user: null,
  // Use email from localStorage
  signedOutEmail: localStorage.getItem('email') ?? '',
  saveEmail: localStorage.getItem('email') !== null,
  userQueryParams: {},
};

// Updates state based on response body
function authenticate(state: AuthenticationState, rawUser?: RawUser | null) {
  if (rawUser) {
    state.status = AuthenticationStatus.Authorized;
    // Translate API format into more descriptive form
    state.user = {
      id: rawUser.id,
      email: rawUser.email,
      firstName: rawUser.firstName,
      lastName: rawUser.lastName,
      company: rawUser.companyName,
      claims: rawUser.claims,
      role: rawUser.role,
      iat: rawUser.iat,
      exp: rawUser.exp,
    };
  }
  else {
    state.status = AuthenticationStatus.Unauthorized;
    state.user = null;
  }
}

export const fetchAuthentication = createAsyncThunk<UsersAPI.GetCurrentUserAPIResponse>(
  'users/current',
  () => getCurrentUser(),
);

export const signIn = createAsyncThunk<UsersAPI.SignInAPIResponse, UsersAPI.SignInAPIRequestBody> (
  'users/signin',
  (credentials) => sendSignInRequest(credentials),
);

export const signOut = createAsyncThunk(
  'users/signout',
  () => sendSignOutRequest(),
);

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setSignedOutEmail: (state, action: PayloadAction<string>) => {
      state.signedOutEmail = action.payload;
    },
    setSaveEmail: (state, action: PayloadAction<boolean>) => {
      state.saveEmail = action.payload;
    },
    setUserQueryParams: (state, action: PayloadAction<UsersAPI.GetUsersRequestParams>) => {
      state.userQueryParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuthentication.fulfilled, (state, action) => {
      authenticate(state, action.payload.currentUser);
    })
    .addCase(fetchAuthentication.rejected, (state) => {
      // If there was an error fetching authentication, treat the user as logged out
      authenticate(state);
    })
    .addCase(signIn.fulfilled, (state, action) => {
      if (state.saveEmail) localStorage.setItem('email', state.signedOutEmail);
      authenticate(state, action.payload.currentUser)
    })
    .addCase(signIn.rejected, (state) => {
      authenticate(state);
    })
    .addCase(signOut.fulfilled, (state) => {
      authenticate(state);
    })
  },
});

// Update email in localStorage when save email is toggled
startListening({
  actionCreator: authenticationSlice.actions.setSaveEmail,
  effect: (action, { getState }) => {
    const shouldSave = action.payload;
    if (shouldSave) {
      localStorage.setItem('email', getState().authentication.signedOutEmail);
    }
    else localStorage.removeItem('email');
  },
});
