import { useDispatch, useSelector } from '../../../redux/store';

import React from 'react';
import MultiSidebar, { MultiSidebarItemProps } from '../../common/MultiSidebar';
import { ArrowDownIcon, ArrowsRightLeftIcon, ArrowUpIcon, CubeIcon, StarIcon } from '@heroicons/react/24/outline';
import { useGetMaterialDetailsQuery } from '../../../redux/materialsAPI';
import LoadingIndicator from '../../common/LoadingIndicator';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import Tooltip from '../../common/Tooltip';
import MaterialLabel from './MaterialLabel';
import Material from '../../../types/materials';
import { addToCorrelationComparison, removeFromCorrelationComparison } from '../../../redux/tools/materials';
import MaterialsContext from './MaterialsContext';
import DynamicFlexContainer from '../../common/DynamicFlexContainer';
import CorrelationView from './CorrelationView';

interface MaterialListItemProps {
  material: Material.Overview;
  inComparison: boolean;
  icon?: React.ReactNode;
}

const MaterialListItem: React.FC<MaterialListItemProps> = ({ material, inComparison, icon }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (inComparison) dispatch(removeFromCorrelationComparison(material.id));
    else dispatch(addToCorrelationComparison(material.id));
  };

  const ArrowIcon = inComparison ? ArrowDownIcon : ArrowUpIcon;

  return (
    <button
      className="group flex flex-row items-center justify-between w-full max-w-full px-1 bg-transparent hover:bg-sky-100 transition-colors"
      onClick={handleClick}
    >
      <MaterialLabel material={material} size="small" />
      <div className="flex flex-row items-center gap-1">
        <ArrowIcon className="hidden group-hover:block size-5 text-slate-700" />
        {icon}
      </div>
    </button>
  );
};

interface MaterialInComparisonProps {
  material: Material.Overview;
}

const MaterialInComparison: React.FC<MaterialInComparisonProps> = ({ material }) => {
  const property = useSelector((state) => state.materials.selectedCorrelationProperty);
  const { isFetching, currentData: materialDetails } = useGetMaterialDetailsQuery(material.id);

  if (isFetching) return (
    <MaterialListItem
      inComparison
      material={material}
      icon={<LoadingIndicator className="size-4 text-slate-500" />}
    />
  );

  const hasCorrelationForProperty = !property || materialDetails.correlations.some((correlation) => (
    correlation.dependentVariable.name === property
  ));

  return hasCorrelationForProperty
    ? (
      <MaterialListItem
        inComparison
        material={material}
      />
    )
    : (
      <MaterialListItem
        inComparison
        material={material}
        icon={(
          <Tooltip width="lg" label={`This material has no data available for ${property}.`}>
            <ExclamationCircleIcon className="size-5 text-red-500" />
          </Tooltip>
        )}
      />
    )
  ;
};

const CorrelationsPage = () => {
  const { materialsMatchingFilters, materialIDMap } = React.useContext(MaterialsContext);
  const comparing = useSelector((state) => state.materials.comparing);
  const favorites = useSelector((state) => state.materials.favorites);

  const comparingMaterialComponents = comparing.map((material) => (
    <MaterialInComparison material={materialIDMap.get(material)} key={material} />
  ));

  const favoritesSet = new Set(favorites);
  const favoritesNotInComparison: React.ReactNode[] = [];
  const nonFavoritesNotInComparison: React.ReactNode[] = [];
  for (const material of materialsMatchingFilters) {
    if (comparing.includes(material.id)) continue;

    if (favoritesSet.has(material.id)) favoritesNotInComparison.push(
      <MaterialListItem
        inComparison={false}
        material={material}
        icon={<StarIcon className="size-5 text-slate-700" />}
      />
    );
    else nonFavoritesNotInComparison.push(
      <MaterialListItem
        inComparison={false}
        material={material}
      />
    );

  }

  // Show favorites on top
  const otherMaterialComponents = [
    ...favoritesNotInComparison,
    ...nonFavoritesNotInComparison
  ];

  const sidebars: MultiSidebarItemProps[] = [
    {
      title: `Materials to Compare (${comparingMaterialComponents.length})`,
      icon: ArrowsRightLeftIcon,
      content: (
        <div className="flex flex-col w-full overflow-hidden">
          {comparingMaterialComponents}
        </div>
      ),
    },
    {
      title: `Other Materials (${otherMaterialComponents.length})`,
      icon: CubeIcon,
      content: (
        <div className="flex flex-col w-full overflow-hidden">
          {otherMaterialComponents}
        </div>
      ),
    },
  ];

  return (
    <DynamicFlexContainer direction="row" initialSizes={['25%', '75%']}>
      <MultiSidebar sidebars={sidebars} />
      <CorrelationView />
    </DynamicFlexContainer>
  );
};

export default CorrelationsPage;
