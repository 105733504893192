function floatingPtIn(value) {
    const f = parseFloat(value);
    return isNaN(f)? '' : f;
}

function floatingPtOut(value) {
    const f = parseFloat(value);
    return isNaN(f)? '' : f.toPrecision(8);
}

function percent(value) {
    const f = parseFloat(value);
    return isNaN(f)? '' : `${f.toPrecision(8)*100}%`;
}

export { floatingPtIn, floatingPtOut, percent }