import React from 'react';
import { useDispatch, useSelector } from '../../../redux/store';
import { SteamTable, steamTablePropertyValues, steamTablesSlice } from '../../../redux/tools/steamTables';
import Typography from '../../common/Typography';
import Select from '../../common/Select';
import DefaultMap from '../../../utils/DefaultMap';
import { PointArray } from '../../../utils/recharts/dataSources';
import Data from '../../../utils/recharts/types';
import Graph from '../../common/Graph';
import { mapIterable } from '../../../utils/iterables';
import { SynchronousUnitConversionFunction, useUnitsServiceConversions } from '../../../hooks/useUnitConversions';
import UnitSelect from '../../common/UnitSelect';
import FetchErrorScreen from '../../common/FetchErrorScreen';
import LoadingScreen from '../../common/LoadingScreen';

const yAxisProperties: SteamTable.YAxisProperty[] = [
  'density',
  'viscosity',
  'heatCapacity',
  'thermalConductivity',
  'enthalpy',
  'entropy',
];

class SteamTablePointArray extends PointArray {
  constructor(
    entries: SteamTable.Entry[],
    xAxisProperty: SteamTable.XAxisProperty,
    yAxisProperty: SteamTable.YAxisProperty,
    groupingPropertyUnit: string,
    idx: number,
    convert: SynchronousUnitConversionFunction,
  ) {
    const points: Data.Point[] = entries.map((entry) => ({
      x: entry[xAxisProperty],
      y: entry[yAxisProperty],
    }));

    const groupingProperty = xAxisProperty === 'temperature' ? 'pressure' : 'temperature';
    const groupingPropertySymbol = groupingProperty === 'temperature' ? 'T' : 'P';
    const groupingPropertyValue = convert(
      entries[0][groupingProperty],
      steamTablePropertyValues[groupingProperty].unitSet,
      steamTablePropertyValues[groupingProperty].dataUnit,
      groupingPropertyUnit,
    );

    const xPropertyValues = steamTablePropertyValues[xAxisProperty];
    const yPropertyValues = steamTablePropertyValues[yAxisProperty];

    super({
      label: idx.toString(),
      // TODO: use custom labelElement that's better?
      labelElement: `${groupingPropertySymbol} = ${groupingPropertyValue} ${groupingPropertyUnit}`,
      xUnits: { unit: xPropertyValues.dataUnit, unitSet: xPropertyValues.unitSet },
      yUnits: { unit: yPropertyValues.dataUnit, unitSet: yPropertyValues.unitSet },
    }, points);
  }
}

const formattedXAxisPropertyValues = ['Temperature', 'Pressure'] as const;
type FormattedXAxisPropertyValue = typeof formattedXAxisPropertyValues[number];
const requiredUnitSets = Object.values(steamTablePropertyValues).map(({ unitSet }) => unitSet);

const SteamTableGraphs: React.FC = () => {
  const dispatch = useDispatch();
  const conversions = useUnitsServiceConversions(requiredUnitSets);
  const table = useSelector((state) => state.steamTables.table);
  const units = useSelector((state) => state.steamTables.outputUnits);
  const [formattedXAxisProperty, setFormattedXAxisProperty] = React.useState<
    FormattedXAxisPropertyValue
  >('Temperature');
  const xAxisProperty: SteamTable.XAxisProperty = formattedXAxisProperty === 'Temperature' ? 'temperature' : 'pressure';

  if (!table.length) return (
    <Typography variant="body1">
      Click "Run" in the action bar above to generate a table of steam/water properties.
    </Typography>
  );

  if (conversions.isFetching) return <LoadingScreen />

  if (conversions.isError) return (
    <FetchErrorScreen
      title="Error Fetching Unit Conversion Data"
      onRetry={conversions.refetch}
    >
      Something went wrong while loading the unit conversion data necessary to
      show steam property graphs.
    </FetchErrorScreen>
  );

  // Group entries by the independent variable that's not being used as the x axis
  // so that each line is a range over the IV values
  const groupingProperty: SteamTable.XAxisProperty = xAxisProperty === 'temperature' ? 'pressure' : 'temperature';
  let minX = Number.POSITIVE_INFINITY;
  let maxX = Number.NEGATIVE_INFINITY;
  const aggregatedEntries = new DefaultMap<number, SteamTable.Entry[]>(Array);
  for (const entry of table) {
    const x = entry[xAxisProperty];
    if (x < minX) minX = x;
    if (x > maxX) maxX = x;
    aggregatedEntries.get(entry[groupingProperty]).push(entry);
  }

  const xPropertyValues = steamTablePropertyValues[xAxisProperty];
  const xUnits: Data.Unit = {
    name: xPropertyValues.name,
    unit: units[xAxisProperty],
    unitSet: xPropertyValues.unitSet,
  };
  const xDomain: Data.Domain = {
    min: conversions.convert(minX, xUnits.unitSet, xPropertyValues.dataUnit, units[xAxisProperty]),
    max: conversions.convert(maxX, xUnits.unitSet, xPropertyValues.dataUnit, units[xAxisProperty]),
  };

  // Create graphs
  const graphs = yAxisProperties.map((yAxisProperty) => {
    const yPropertyValues = steamTablePropertyValues[yAxisProperty];
    const yUnits: Data.Unit = {
      name: yPropertyValues.name,
      unit: units[yAxisProperty],
      unitSet: yPropertyValues.unitSet,
    };

    const dataSources = Array.from(
      mapIterable(aggregatedEntries.values(), (entries, idx) => (
        new SteamTablePointArray(
          entries,
          xAxisProperty,
          yAxisProperty,
          units[groupingProperty],
          idx,
          conversions.convert,
        )
      ))
    );

    return (
      <div className="flex flex-col w-full h-full items-center">
        <div className="flex flex-row w-full pl-20 pr-1 items-center justify-between">
          <Typography variant="h6" className="truncate">{yPropertyValues.name}</Typography>
          <UnitSelect
            value={units[yAxisProperty]}
            unitSet={yPropertyValues.unitSet}
            onChange={(unit) => dispatch(steamTablesSlice.actions.setOutputUnit({
              property: yAxisProperty,
              unit,
            }))}
          />
        </div>
        <Graph
          xDomain={xDomain}
          units={{
            x: xUnits,
            y: yUnits,
            convert: conversions.convert,
          }}
          dataSources={dataSources}
          syncId="steam-table-graph"
          key={`${xAxisProperty} ${yAxisProperty}`}
        />
      </div>
    );
  });

  return (
    <div className="flex flex-col h-full w-full gap-1">
      <div className="grid grid-cols-[repeat(2,_max-content)] gap-1 items-center">
        <Typography>X-Axis</Typography>
        <div className="flex flex-row gap-1 items-center">
          <Select
            value={formattedXAxisProperty}
            options={formattedXAxisPropertyValues as readonly any[]}
            onChange={(newProperty) => setFormattedXAxisProperty(newProperty)}
          />
          <UnitSelect
            value={units[xAxisProperty]}
            unitSet={xPropertyValues.unitSet}
            onChange={(unit) => dispatch(steamTablesSlice.actions.setOutputUnit({
              property: xAxisProperty,
              unit,
            }))}
          />
        </div>
        <Typography>
          {`${groupingProperty[0].toUpperCase()}${groupingProperty.slice(1)} Units`}
        </Typography>
        <UnitSelect
          value={units[groupingProperty]}
          unitSet={steamTablePropertyValues[groupingProperty].unitSet}
          onChange={(unit) => dispatch(steamTablesSlice.actions.setOutputUnit({
            property: groupingProperty,
            unit,
          }))}
        />
      </div>
      <div className="grid grid-cols-3 h-full w-full">
        {graphs}
      </div>
    </div>
  );
};

export default SteamTableGraphs;
