import React from 'react';

interface CollapsibleProps {
  open: boolean;
  initialHeight?: number;
  wrapperClassName?: string;
  contentClassName?: string;
  children: React.ReactNode;
  onCloseFinish?: () => any;
  onTargetHeightChange?: (h: number) => any;
}

const Collapsible: React.FC<CollapsibleProps> = ({
  open,
  initialHeight = 0,
  wrapperClassName = '',
  contentClassName = '',
  onCloseFinish,
  onTargetHeightChange,
  children,
}) => {
  const [displayChildren, setDisplayChildren] = React.useState(open);
  const container = React.useRef<HTMLDivElement>();
  const content = React.useRef<HTMLDivElement>();
  const [height, setHeight] = React.useState(initialHeight);

  React.useEffect(() => {
    if (open) {
      if (displayChildren) {
        const targetHeight = container.current.offsetHeight + content.current.offsetHeight;
        setHeight(targetHeight);
        if (onTargetHeightChange) onTargetHeightChange(targetHeight);
      }
      else setDisplayChildren(true);
    }
    else setHeight(0);
  }, [open, displayChildren]);

  // Neither Tailwind nor headlessui support transitions with arbitrary height values,
  // so we need to use style to animate a dynamic height
  const transitionWrapperStyle: React.CSSProperties = {
    maxHeight: `${height}px`,
  };

  if (!open && !displayChildren) return;

  const handleTransitionEnd = () => {
    if (height === 0) {
      setDisplayChildren(false);
      if (onCloseFinish) onCloseFinish();
      if (onTargetHeightChange) onTargetHeightChange(0);
    }
  };

  return (
    <div
      className={`${wrapperClassName} transition-all duration-200 w-full overflow-hidden ${displayChildren ? '' : 'hidden'}`}
      style={transitionWrapperStyle}
      onTransitionEnd={handleTransitionEnd}
      ref={container}
    >
      <div ref={content} className={`${contentClassName} overflow-hidden`}>
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
