import React from 'react';

import { useGetHTRIEnumQuery } from '../../redux/HTRICalcAPI';
import SetSelector from './SetSelector';

interface HTRIEnumSetSelectorProps {
  enum: string;
  selected: string[] | undefined;
  onChange: (newValue: string[]) => any;
}

const HTRIEnumSetSelector: React.FC<HTRIEnumSetSelectorProps> = ({ enum: enumName, selected, onChange }) => {
  const { isFetching, isError, currentData } = useGetHTRIEnumQuery(enumName);

  if (isError) return <div />;

  return (
    <SetSelector
      options={new Map(Object.entries(currentData?.enum ?? {}))}
      selected={selected}
      onChange={(v) => onChange(v)}
      loading={isFetching}
    />
  );
};

export default HTRIEnumSetSelector;
