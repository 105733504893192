type AsIterable<T> = { [K in keyof T]: Iterable<T[K]> }

export function* mapIterable<T, V>(
  iterable: IterableIterator<T>,
  callback: (t: T, idx: number) => V,
): IterableIterator<V> {
  let idx = 0;
  for (const t of iterable) yield callback(t, idx++);
}

export function* zip<T extends Array<any>>(...iterables: AsIterable<T>): Generator<T> {
  const iterators = iterables.map((iterable) => iterable[Symbol.iterator]());
  while (true) {
    const results = iterators.map((iterator) => iterator.next());
    if (results.some((result) => result.done)) break;
    yield results.map((result) => result.value) as T;
  }
}

export function* range(start: number, stop: number, step = 1): Generator<number> {
  if (step === 0) throw new RangeError('Range step cannot be 0.');
  let current = start;
  if (step > 0) {
    while (current < stop) {
      yield current;
      current += step;
    }
  }
  else {
    while (current > stop) {
      yield current;
      current += step;
    }
  }
}
