import React from 'react';
import { BlockProps, DataModelVariableBlock } from '../types';
import Block from './Block';

const DataModelVariable: React.FC<BlockProps> = ({ block }) => {
  const variable = block as DataModelVariableBlock;

  return (
    <Block block={variable.symbolGroup} />
  );
};

export default DataModelVariable;
