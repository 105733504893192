import React from 'react';
import Typography from '../Typography';

const ULContext = React.createContext<boolean>(false);

interface UnorderedListItemProps { children: React.ReactNode; }

const ULItem: React.FC<UnorderedListItemProps> = ({ children }) => {
  return (
    <li>{children}</li>
  );
};

interface UnorderedListContainerProps {
  children: React.ReactNode;
}

const ULContainer: React.FC<UnorderedListContainerProps> = ({ children }) => {
  const nested = React.useContext(ULContext);

  if (nested) return <ul>{children}</ul>;

  return (
    <ULContext.Provider value={true}>
      <Typography className="pl-7 pr-5 pt-1" variant="body1">
        <ul className="list-disc">
          {children}
        </ul>
      </Typography>
    </ULContext.Provider>
  );
};

const UnorderedList = {
  Item: ULItem,
  Container: ULContainer,
};

export default UnorderedList;
