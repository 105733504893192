import React from 'react';

import { useDispatch } from '../../../../redux/store';
import InputField from '../../../common/InputField';
import EnumRule from './EnumRule';
import { CalculatorIcon, ChatBubbleBottomCenterIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../../common/Tooltip';
import Chip from '../../../common/Chip';
import DependenciesSelector from './RuleDependenciesSelector';
import StringRule from './StringRule';
import NumericRule from './NumericRule';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';
import { engineeringChecklistSlice } from '../../../../redux/tools/engineeringChecklist';

interface RuleProps {
  rule: EngineeringChecklist.Rule;
  idx: number;
}

export const ruleTypeIcons: Record<EngineeringChecklist.RuleType, React.ReactNode> = {
  [EngineeringChecklist.RuleType.Numeric]: <CalculatorIcon className="size-6" />,
  [EngineeringChecklist.RuleType.Enum]: <ListBulletIcon className="size-6" />,
  [EngineeringChecklist.RuleType.String]: <ChatBubbleBottomCenterIcon className="size-6" />,
};

export const ruleTypeNames: Record<EngineeringChecklist.RuleType, string> = {
  [EngineeringChecklist.RuleType.Numeric]: 'Equation',
  [EngineeringChecklist.RuleType.Enum]: 'Value Selection',
  [EngineeringChecklist.RuleType.String]: 'Text-Based',
};

const Rule: React.FC<RuleProps> = ({ rule, idx }) => {
  const dispatch = useDispatch();

  function update<T extends EngineeringChecklist.Rule>(updates: Partial<T>): void {
    dispatch(engineeringChecklistSlice.actions.updateRule({ id: rule.id, updates }));
  };

  let ruleValueSelection: React.ReactNode;
  if (rule.type === EngineeringChecklist.RuleType.Enum) {
    ruleValueSelection = <EnumRule rule={rule} onChange={update} />;
  }
  else if (rule.type === EngineeringChecklist.RuleType.String) {
    ruleValueSelection = <StringRule rule={rule} onChange={update} />;
  }
  else ruleValueSelection = <NumericRule rule={rule} onChange={update} />;

  return (
    <>
      <div className="h-11 flex items-center justify-center">
        <Chip
          color="white"
          label={(idx + 1).toString()}
          onDelete={() => dispatch(engineeringChecklistSlice.actions.removeRule(rule.id))}
        />
      </div>
      <div className="h-11 flex items-center justify-center">
        <Tooltip label={ruleTypeNames[rule.type]} width="sm">
          <div className="text-slate-700">
            {ruleTypeIcons[rule.type]}
          </div>
        </Tooltip>
      </div>
      {ruleValueSelection}
      <DependenciesSelector rule={rule} />
      <div className="w-56">
        <InputField type="text" value={rule.note} onChange={(s) => update({ note: s})} />
      </div>
    </>
  );
};

export default Rule;
