/**
 * Calculates the intersection of two sets
 * @param a First set
 * @param b Second set
 * @returns All items contained in both sets
 */
export function intersection<T>(a: Set<T>, b: Set<T>): Set<T> {
  const smallerSet = a.size <= b.size ? a : b;
  const largerSet = smallerSet === a ? b : a;

  const intersection = new Set<T>();
  for (const item of smallerSet) if (largerSet.has(item)) intersection.add(item);
  return intersection;
}
