import React from 'react';
import { BlockProps, FunctionBlock } from '../types';
import Block from './Block';

const Function: React.FC<BlockProps> = ({ block }) => {
  const functionBlock = block as FunctionBlock;

  return (
    <div className="flex flex-row items-center">
      <Block block={functionBlock.function} />
      <Block block={functionBlock.operand} />
    </div>
  );
};

export default Function;
