import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';

import Chip from './Chip';
import Dropdown from './Dropdown';
import Typography from './Typography';

interface SetSelectorProps<K> {
  selected: K[] | undefined;
  options: string[] | Map<K, string>;
  onChange: (ks: K[]) => any;
  loading?: boolean;
  placeholder?: string;
}

function SetSelector<K>({
  selected = [],
  options,
  onChange,
  loading,
  placeholder = 'No items to display.',
}: SetSelectorProps<K>): JSX.Element {
  const isMapped = !Array.isArray(options);
  const dropdownReferenceElement = React.useRef<HTMLDivElement>(null);

  // If not loading and there are no options, show placeholder
  const numOptions = Array.isArray(options) ? options.length : options.size;
  if (!loading && numOptions === 0) {
    return (
      <div className="max-w-128 p-1 border-2 bg-white border-slate-300 rounded-md flex items-center select-none">
        <Typography color="lightSlate" variant="body2">{placeholder}</Typography>
      </div>
    )
  }

  const getLabel = (key: K): string => isMapped ? options.get(key)! : key as string;

  function unselect(key: K) {
    onChange(selected.filter((k) => k !== key));
  }

  // Sort selected items to ensure ordering is consistent and easier to read
  const sortedSelections = [...selected]
    // Don't display items that aren't defined in options
    .filter((value) => getLabel(value) !== undefined)
    .sort((a, b) => getLabel(a).localeCompare(getLabel(b)));

  const chips = [];
  const unselected = new Map<K, string>(
    isMapped ? options : options.map((option) => [option as K, option])
  );
  sortedSelections.forEach((key) => {
    unselected.delete(key);
    const label = getLabel(key);
    chips.push(
      <Chip label={label} onDelete={() => unselect(key)} key={label} />
    );
  });
  chips.push(
    <div className={unselected.size ? '' : 'hidden'} ref={dropdownReferenceElement} key="addNewItem">
      <Chip label="Add" color="green" icon={<PlusIcon />} onClick={() => {}} />
    </div>
  );

  return (
    <div className="max-w-128 p-1 bg-white border-2 border-slate-300 rounded-md">
      <div className="flex flex-row flex-wrap gap-1">
        {chips}
      </div>
      <Dropdown
        options={unselected}
        referenceElement={dropdownReferenceElement}
        widthClassName="w-64 max-w-64"
        onChange={(k) => onChange([...selected, k])}
        skipCloseAnimation
        loading={loading}
      />
    </div>
  );
};

export default SetSelector;
