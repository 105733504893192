/**
 * This file serves as a template to configure a redux slice for a new tool.
 * Whenever adding a new tool, use this template and fill in the blanks
 * with specific config for that tool where necessary.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SolveStatus } from '../../types/tools';
import { reset as resetError } from '../errors';
import { startListening } from '../listenerMiddleware';
import { solvePTRange } from '../../utils/API';
import { convertUnits } from '../../utils/units';
import { UnitSet, RangeValues, ConfigurableRangeValues } from '../../types/units';
import { validateIsNumber } from '../../utils/inputValidation';
import { generateSteamTableCSV, serializeSteamTablesInputs } from '../../utils/steamTablesSerialization';
import { MIMEType, savePlaintextFile } from '../../utils/save';
import { LoggedError } from '../errorMiddleware';
import { createAsyncThunk } from '../asyncThunk';

export namespace SteamTable {
  export interface Entry {
    temperature: number;
    pressure: number;
    phase: string,
    density: number,
    viscosity: number,
    heatCapacity: number,
    thermalConductivity: number,
    enthalpy: number,
    entropy: number,
  }

  export type XAxisProperty = Extract<keyof Entry, 'temperature' | 'pressure'>;
  export type YAxisProperty = Exclude<keyof Entry, 'temperature' | 'pressure' | 'phase'>;

  export interface PropertyValues {
    name: string;
    dataUnit: string;
    defaultUnit?: string;
    unitSet: UnitSet;
  }
}

export const steamTablePropertyValues: Record<
  SteamTable.XAxisProperty | SteamTable.YAxisProperty,
  SteamTable.PropertyValues
> = {
  pressure: {
    name: 'Pressure',
    dataUnit: 'Pa',
    defaultUnit: 'bar',
    unitSet: UnitSet.Pressure,
  },
  temperature: {
    name: 'Temperature',
    dataUnit: 'K',
    defaultUnit: '°C',
    unitSet: UnitSet.Temperature,
  },
  density: {
    name: 'Density',
    dataUnit: 'kg/m^3',
    unitSet: UnitSet.MassDensity,
  },
  viscosity: {
    name: 'Dynamic Viscosity',
    dataUnit: 'P',
    unitSet: UnitSet.DynamicViscosity,
  },
  heatCapacity: {
    name: 'Heat Capacity',
    dataUnit: 'J/(mol*K)',
    unitSet: UnitSet.MolarEntropy,
  },
  thermalConductivity: {
    name: 'Thermal Conductivity',
    dataUnit: 'W/(m*K)',
    unitSet: UnitSet.ThermalConductivity,
  },
  enthalpy: {
    name: 'Enthalpy',
    dataUnit: 'J/mol',
    unitSet: UnitSet.MolarEnergy,
  },
  entropy: {
    name: 'Entropy',
    dataUnit: 'J/(mol*K)',
    unitSet: UnitSet.MolarEntropy,
  },
};

export interface SteamTablesState {
  solveStatus: SolveStatus;
  pressure: RangeValues<UnitSet.Pressure>;
  temperature: RangeValues<UnitSet.Temperature>;
  table: SteamTable.Entry[];
  outputUnits: Record<SteamTable.XAxisProperty | SteamTable.YAxisProperty, string>,
}

export interface SerializedSteamTablesInputs {
  pressureMin: string;
  pressureMax: string;
  pressureUnits: string;
  temperatureMin: string;
  temperatureMax: string;
  temperatureUnits: string;
}
const serializedInputRequiredFields: (keyof SerializedSteamTablesInputs)[] = [
  'pressureMin',
  'pressureMax',
  'pressureUnits',
  'temperatureMin',
  'temperatureMax',
  'temperatureUnits',
];

export function validateSteamTableInputs({ pressure, temperature}: SteamTablesState): boolean {
  return [pressure.min, pressure.max, temperature.min, temperature.max].every(validateIsNumber);
}

export const calculatePropertyTable = createAsyncThunk<SteamTable.Entry[], void>(
  `steamTables/run`,
  async (_, { getState }) => {
    const { pressure, temperature } = getState().steamTables;

    // Don't send request unless all units are defined
    if (!validateSteamTableInputs(getState().steamTables)) throw new LoggedError('Invalid pressure/temperature values detected. Please make sure each value is defined and try again.');
    // Convert to SI units
    const [minPressure, maxPressure, minTemp, maxTemp] = await Promise.all([
      convertUnits(Number.parseFloat(pressure.min), pressure.unitSet, pressure.unit, 'Pa'),
      convertUnits(Number.parseFloat(pressure.max), pressure.unitSet, pressure.unit, 'Pa'),
      convertUnits(Number.parseFloat(temperature.min), temperature.unitSet, temperature.unit, 'K'),
      convertUnits(Number.parseFloat(temperature.max), temperature.unitSet, temperature.unit, 'K'),
    ]);

    const response = await solvePTRange(minPressure, maxPressure, minTemp, maxTemp);
    if (response.length === 0) {
      throw new LoggedError('Unable to generate properties with the given values.');
    }

    // Valid response, calculate new table values
    const values: SteamTable.Entry[] = [];
    response.forEach((result) => {
      const { Temperature: temperature, Pressure: pressure } = result;
      result.Phases.forEach((phaseResult) => {
        const {
          Phase: phase,
          Density: density,
          Viscosity: viscosity,
          HeatCapacity: heatCapacity,
          ThermalConductivity: thermalConductivity,
          Enthalpy: enthalpy,
          Entropy: entropy,
        } = phaseResult;
        values.push({
          temperature, pressure, phase, density, viscosity, heatCapacity, thermalConductivity, enthalpy, entropy,
        });
      });
    });
    return values;
  },
);

function getInitialState(): SteamTablesState {
  const defaultUnit = (prop: SteamTable.XAxisProperty | SteamTable.YAxisProperty): string => (
    steamTablePropertyValues[prop].defaultUnit ?? steamTablePropertyValues[prop].dataUnit
  );
  const outputUnits: Record<SteamTable.XAxisProperty | SteamTable.YAxisProperty, string> = {
    temperature: defaultUnit('temperature'),
    pressure: defaultUnit('pressure'),
    density: defaultUnit('density'),
    viscosity: defaultUnit('viscosity'),
    heatCapacity: defaultUnit('heatCapacity'),
    thermalConductivity: defaultUnit('thermalConductivity'),
    entropy: defaultUnit('entropy'),
    enthalpy: defaultUnit('enthalpy'),
  };

  return {
    solveStatus: SolveStatus.Unsolved,
    pressure: {
      min: '0',
      max: '0',
      unit: 'Pa',
      unitSet: UnitSet.Pressure,
    },
    temperature: {
      min: '0',
      max: '0',
      unit: '°C',
      unitSet: UnitSet.Temperature,
    },
    table: [],
    outputUnits,
  };
}

export const steamTablesSlice = createSlice({
  name: 'steamTables',
  initialState: getInitialState,
  reducers: {
    // Actions without side-effects
    updatePressure: (state, action: PayloadAction<Partial<ConfigurableRangeValues>>) => {
      Object.assign(state.pressure, action.payload);
    },
    updateTemperature: (state, action: PayloadAction<Partial<ConfigurableRangeValues>>) => {
      Object.assign(state.temperature, action.payload);
    },
    setOutputUnit: (
      state,
      action: PayloadAction<{
        property: SteamTable.XAxisProperty | SteamTable.YAxisProperty,
        unit: string,
      }>,
    ) => {
      state.outputUnits[action.payload.property] = action.payload.unit;
    },
    load: (state, action: PayloadAction<string>) => {
      const { pressure, temperature } = state;
      const pRangeOriginalState = { ...pressure };
      const tRangeOriginalState = { ...temperature };
      const invalidFileMessage = 'The file you selected is not a valid steam tables input file.';

      try {
        const serialized = JSON.parse(action.payload) as SerializedSteamTablesInputs;
        if (!serializedInputRequiredFields.every((field) => (
          typeof serialized[field] === 'string'
        ))) throw new LoggedError(invalidFileMessage);
        pressure.min = serialized.pressureMin;
        pressure.max = serialized.pressureMax;
        pressure.unit = serialized.pressureUnits;
        temperature.min = serialized.temperatureMin;
        temperature.max = serialized.temperatureMax;
        temperature.unit = serialized.temperatureUnits;
      }
      catch {
        pressure.min = pRangeOriginalState.min;
        pressure.max = pRangeOriginalState.max;
        pressure.unit = pRangeOriginalState.unit;
        temperature.min = tRangeOriginalState.min;
        temperature.max = tRangeOriginalState.max;
        temperature.unit = tRangeOriginalState.unit;
        throw new LoggedError(invalidFileMessage);
      }
    },
    save: (state) => {
      const serialized = serializeSteamTablesInputs(state);
      savePlaintextFile(JSON.stringify(serialized), 'SteamTableOperatingConditions', 'json', MIMEType.JSON);
    },

    // Actions with side-effects - see run definition above and startListening call below
    export: () => {},
    reset: (state) => {
      state.solveStatus = SolveStatus.Unsolved;
      state.table = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(calculatePropertyTable.pending, (state) => {
      state.solveStatus = SolveStatus.Solving;
      state.table = [];
    })
    .addCase(calculatePropertyTable.fulfilled, (state, action) => {
      state.solveStatus = SolveStatus.Solved;
      // Match output units with inputs
      state.outputUnits.temperature = state.temperature.unit;
      state.outputUnits.pressure = state.pressure.unit;
      state.table = action.payload;
    })
    .addCase(calculatePropertyTable.rejected, (state) => {
      state.solveStatus = SolveStatus.Failed;
    })
  }
});

// Action side-effects
startListening({
  actionCreator: steamTablesSlice.actions.reset,
  effect: (_, { dispatch }) => { dispatch(resetError()); },
});

startListening({
  actionCreator: steamTablesSlice.actions.export,
  effect: async (_, { getState }) => {
    const csv = await generateSteamTableCSV(getState().steamTables);
    savePlaintextFile(csv, 'SteamTableProperties', 'csv', MIMEType.CSV);
  },
})
