import React from 'react'
import { useSelector } from 'react-redux'

// TODO: remove Tool import, rename ReduxTool to Tool once dew point calculator has been converted
import Error from './components/Error'
import Auth from './components/Account/Auth'
import Header from './components/Header'
import Footer from './components/Footer'

import NotificationBar from './components/NotificationBar'
import { RootState } from './redux/store'
import { AuthenticationStatus } from './redux/authentication'
import { useOutlet } from 'react-router-dom'
import PageNotFound from './components/PageNotFound'

const App: React.FC = () => {
  const outlet = useOutlet();
  const authStatus = useSelector<RootState, AuthenticationStatus>((state) => state.authentication.status);

  // Don't display anything while authorization is pending
  if (authStatus === AuthenticationStatus.Pending) return;
  else if (authStatus === AuthenticationStatus.Unauthorized) {
    return (
      <div className="flex flex-col h-screen">
        <Error />
        <div className="flex-grow h-full max-h-full">
          <Auth />
        </div>
        <NotificationBar />
      </div>
    );
  }
  else {
    // Authorized
    return (
      <div className="flex flex-col h-screen bg-sky-50">
        <Error />
        <Header />
        <div className="flex-grow h-full max-h-full min-h-32 overflow-auto">
          {outlet ?? <PageNotFound />}
        </div>
        <Footer />
        <NotificationBar />
      </div>
    );
  }
}

export default App;
