import HTRICalcAPI from '../redux/HTRICalcAPI';
import store from '../redux/store';
import unitsAPI from '../redux/unitsAPI';
import { UnitSet } from '../types/units';

export async function convertHTRIUnits(
  value: number | undefined,
  unitSet: string,
  unit: string,
  newUnit: string,
  throwOnError = true,
): Promise<number> {
  if (value === undefined) return undefined;

  const promise = store.dispatch(HTRICalcAPI.endpoints.getHTRIUnitConversions.initiate(unitSet));
  try {
    const unitConversions = await promise.unwrap();
    const from = unitConversions[unit];
    if (!from) throw new Error(`Unit ${unit} does not exist.`);
    const to = unitConversions[newUnit];
    if (!to) throw new Error(`Unit ${newUnit} does not exist.`);
    return (from.valueSI * value + from.offset - to.offset) / to.valueSI;
  }
  catch (e) {
    if (throwOnError) throw e;
    return value;
  }
  finally { promise.unsubscribe(); }
}

export async function convertUnits(value: number | undefined, unitSet: UnitSet, unit: string, newUnit: string): Promise<number> {
  if (value === undefined) return undefined;

  const promise = store.dispatch(unitsAPI.endpoints.getUnitSet.initiate(unitSet));
  try {
    const unitOptions = await promise.unwrap();
    const from = unitOptions[unit];
    const to = unitOptions[newUnit];
    return (from.valueSI * value + from.offset - to.offset) / to.valueSI;
  }
  // In the event of an error, fallback to the old value
  catch { return value; }
  finally { promise.unsubscribe(); }
};

// Pre-redux signature, remove once all tools have been ported to redux
export const convert = (value, unitOptions, unit, targetUnit) => {
    const targetIndex = unitOptions.findIndex(
        (item) => item.symbol === targetUnit
    );
    const selectedIndex = unitOptions.findIndex(
        (item) => item.symbol === unit
    );
    if(targetIndex < 0 || selectedIndex < 0) {
        return Number.NaN;
    }
    const { valueSI, offset } = unitOptions[selectedIndex];
    const { valueSI: targetValueSI, offset: targetOffset } = unitOptions[targetIndex];
    return ((valueSI * value + offset - targetOffset) / targetValueSI);
};
