import Material from '../../../types/materials';

export default function useCorrelationProperties(materials: Material.Overview[]) {
  return [
    'Allowable Stress',
    'Coefficient of Thermal Expansion',
    'Metal Density',
    'Modulus of Elasticity',
    'Thermal Conductivity',
    'Yield Stress',
  ];
}
