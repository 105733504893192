import React from 'react';
import Typography from './Typography';
import useDrag from '../../hooks/useDrag';

interface HeaderProps {
  label: string;
  minWidth: number;
  isLast: boolean;
  onResize: (width: string) => any;
}
const Header: React.FC<HeaderProps> = ({ label, minWidth = 32, isLast, onResize }) => {
  const headerRef = React.useRef<HTMLDivElement>();
  const { onDraggableElementMouseDown } = useDrag({
    cursor: 'col-resize',
    onDrag: (e) => {
      let newWidth = e.clientX - headerRef.current.getBoundingClientRect().left;
      newWidth += 4.5; // Add half width of resize component to center resize component on mouse position
      newWidth = Math.max(minWidth, newWidth);
      onResize(`${newWidth}px`);
    },
  });

  const resizeComponent = !isLast && (
    <div className="cursor-col-resize px-1" onMouseDown={onDraggableElementMouseDown}>
      <div className="bg-slate-400 h-full w-px" />
    </div>
  );

  return (
    <div className="sticky top-0 z-10 h-max flex flex-row self-center justify-between bg-slate-200" key={label} ref={headerRef}>
      <Typography className="p-1 truncate select-none">{label}</Typography>
      {resizeComponent}
    </div>
  );
};

interface ResizableGridColumn {
  label: string;
  minWidth?: number;
  defaultWidth?: number
}
export type ResizableGridColumns = [...ResizableGridColumn[], { label: string }];

export function generateInitialColumnWidths(columns: ResizableGridColumns) {
  return columns.map((column, idx) => {
    if (idx === columns.length - 1) return `minmax(max-content, 1fr)`;
    return 'defaultWidth' in column ? `${column.defaultWidth}px` : 'min-content'
  });
}

interface ControlledResizableGridProps {
  alignItems?: 'start' | 'center';
  rowDividers?: boolean;
  className?: string;
  columns: ResizableGridColumns;
  columnWidths: string[];
  children: React.ReactNode[];
  onColumnWidthsChange: (newWidths: string[]) => any;
}

const ControlledResizableGrid: React.FC<ControlledResizableGridProps> = ({
  alignItems = 'center',
  rowDividers = true,
  className = '',
  columns,
  columnWidths,
  onColumnWidthsChange,
  children,
}) => {
  const gridStyle: React.CSSProperties = {
    gridTemplateColumns: columnWidths.join(' '),
  };

  const headers = columns.map((column, idx) => {
    const handleResize = (width: string) => {
      const newColumnWidths = [...columnWidths];
      newColumnWidths[idx] = width;
      onColumnWidthsChange(newColumnWidths);
    };

    return (
      <Header
        label={column.label}
        minWidth={'minWidth' in column ? column.minWidth : undefined}
        isLast={idx === columns.length - 1}
        onResize={handleResize}
      />
    );
  });

  // Highlight active row and add borders if subgrid is supported
  const browserSupportsSubgrid = CSS.supports('grid-template-columns', 'subgrid');

  const rows = React.Children.map(children, (child, idx) => {
    if (browserSupportsSubgrid) {
      let dynamicClass = alignItems === 'center' ? 'items-center ' : '';
      if (rowDividers && idx !== 0) dynamicClass += 'border-t border-slate-300 ';

      return (
        <div
          className={`${dynamicClass}grid grid-cols-subgrid hover:bg-slate-100 *:p-1 *:pr-[9px]`}
          style={{ gridColumn: `1 / span ${columns.length}`}}
        >
          {child}
        </div>
      );
    }
    return <div className="contents *:p-1 *:pr-[9px]">{child}</div>
  });

  const alignmentClass = (!browserSupportsSubgrid && alignItems === 'center') ? ' items-center ' : '';

  return (
    <div className={`${className} ${alignmentClass} grid auto-rows-max h-full w-full`} style={gridStyle}>
      {headers}
      {rows}
    </div>
  );
};

type UncontrolledResizableGridProps = Omit<
  ControlledResizableGridProps,
  'columnWidths' | 'onColumnWidthsChange'
>;

const UncontrolledResizableGrid: React.FC<UncontrolledResizableGridProps> = ({ columns, children }) => {
  const [columnWidths, setColumnWidths] = React.useState(() => generateInitialColumnWidths(columns));

  return (
    <ControlledResizableGrid columnWidths={columnWidths} columns={columns} onColumnWidthsChange={setColumnWidths}>
      {children}
    </ControlledResizableGrid>
  );
};


type ResizableGridProps = ControlledResizableGridProps | UncontrolledResizableGridProps;
const ResizableGrid: React.FC<ResizableGridProps> = (props) => {
  return 'columnWidths' in props
    ? <ControlledResizableGrid {...props} />
    : <UncontrolledResizableGrid {...props} />
  ;
};

export default ResizableGrid;
