import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { savePlaintextFile } from '../../utils/save';
import { startListening } from '../listenerMiddleware';
import { reset as resetError, set as setError } from '../errors';
import { validateVariables } from '../../utils/inputValidation';
import { deserializeEngineeringChecklist, serializeEngineeringChecklist } from '../../utils/engineeringChecklistSerialization';
import { generateInitialColumnWidths } from '../../components/common/ResizableGrid';
import rulesColumns from '../../components/Tools/EngineeringChecklist/Rules/rulesColumns';
import { EngineeringChecklist } from '../../types/engineeringChecklist';
import { createEmptyRule, parseVariable, queryDataModel, setDataModelNodeOpenStatus, updateRulesReferencingVariable, updateRulesWithRuleDeletion, updateRulesWithVariableDeletion } from '../../utils/engineeringChecklist';
import { LoggedError } from '../errorMiddleware';
import { createAsyncThunk } from '../asyncThunk';
import { variablesColumns } from '../../components/Tools/EngineeringChecklist/Variables/variablesColumns';

export interface EngineeringChecklistState {
  dataModel: {
    initialized: boolean;
    error: boolean;
    data: EngineeringChecklist.DataModelNode[];
    searchQuery: string;
    searchTree: EngineeringChecklist.DataModelNode[];
  },

  info: EngineeringChecklist.Info;

  variables: EngineeringChecklist.Variable[];
  variablesColumnWidths: string[];

  rulesColumnWidths: string[];
  rules: EngineeringChecklist.Rule[];
}

const initialState: EngineeringChecklistState = {
  dataModel: {
    initialized: false,
    error: false,
    data: [],
    searchQuery: '',
    searchTree: [],
  },

  info: {
    name: '',
    version: '',
    author: '',
    company: '',
  },

  variables: [],
  variablesColumnWidths: generateInitialColumnWidths(variablesColumns),

  rules: [],
  rulesColumnWidths: generateInitialColumnWidths(rulesColumns),
};

export const initializeDataModel = createAsyncThunk<EngineeringChecklist.DataModelNode>(
  'engineeringChecklist/initializeDataModel',
  async () => (await import('../../utils/dataModel')).default,
);

export const engineeringChecklistSlice = createSlice({
  initialState: initialState,
  name: 'engineeringChecklist',
  reducers: {

    /* Info */
    updateInfo: (state, action: PayloadAction<Partial<EngineeringChecklist.Info>>) => {
      Object.assign(state.info, action.payload);
    },

    /* Data model manipulation */
    toggleDataModelNodeOpen: (state, action: PayloadAction<string[]>) => {
      if (!state.dataModel.initialized) return;

      let newOpenStatus: boolean | undefined;
        // If searching, toggle in searchTree
      if (state.dataModel.searchQuery) {
        newOpenStatus = setDataModelNodeOpenStatus(state.dataModel.searchTree, action.payload).open;
      }
      // Regardless of whether searching, update the open status in the base tree.
      // If searching, sync the two open statuses. Otherwise, toggle it.
      setDataModelNodeOpenStatus(state.dataModel.data, action.payload, newOpenStatus);
      // If not searching, set searchTree to the new tree as this is what actually displays to the user
      if (!state.dataModel.searchQuery) state.dataModel.searchTree = state.dataModel.data;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.dataModel.searchQuery = action.payload;
      if (action.payload) state.dataModel.searchTree = queryDataModel(state.dataModel.data, action.payload);
      else state.dataModel.searchTree = state.dataModel.data;
    },

    /* Variables */
    setVariables: (state, action: PayloadAction<EngineeringChecklist.Variable[]>) => {
      state.variables = action.payload;
    },
    addVariable: (state, action: PayloadAction<EngineeringChecklist.DataModelNode>) => {
      state.variables.push(parseVariable(action.payload, state));
    },
    removeVariable: (state, action: PayloadAction<string>) => {
      const variableIdx = state.variables.findIndex((variable) => variable.id === action.payload);
      state.variables.splice(variableIdx, 1);
      updateRulesWithVariableDeletion(state, action.payload);
    },
    updateVariable: (
      state,
      action: PayloadAction<{
        id: string;
        updates: Omit<Partial<EngineeringChecklist.Variable>, 'id' | 'type' | 'unitSet'>;
      }>,
    ) => {
      const variableIdx = state.variables.findIndex((variable) => variable.id === action.payload.id);
      const variable = Object.assign(state.variables[variableIdx], action.payload.updates);
      updateRulesReferencingVariable(state, variable, action.payload.id);
    },
    updateVariableNavSelection: (
      state,
      action: PayloadAction<{
        id: string;
        navIdx: number;
        value: string | EngineeringChecklist.IndexNavSelection;
      }>) => {
      const variable = state.variables.find((variable) => variable.id === action.payload.id);
      if (!variable) return;
      variable.navSelections[action.payload.navIdx] = action.payload.value;
      updateRulesReferencingVariable(state, variable, action.payload.id);
    },
    setVariablesColumnWidths: (state, action: PayloadAction<string[]>) => {
      state.variablesColumnWidths = action.payload;
    },

    /* Rules */
    setRules: (state, action: PayloadAction<EngineeringChecklist.Rule[]>) => {
      state.rules = action.payload;
    },
    addRule: (state, action: PayloadAction<EngineeringChecklist.RuleType>) => {
      state.rules.push(createEmptyRule(action.payload));
    },
    removeRule: (state, action: PayloadAction<string>) => {
      updateRulesWithRuleDeletion(state, action.payload);
    },
    updateRule: (
      state,
      action: PayloadAction<{
        id: string,
        updates: Omit<Partial<EngineeringChecklist.Rule>, 'id'>,
      }>
    ) => {
      const rule = state.rules.find((rule) => rule.id === action.payload.id);
      if (!rule) return;
      Object.assign(rule, action.payload.updates);
    },
    addRuleDependencyGroup: (state, action: PayloadAction<string>) => {
      const rule = state.rules.find((rule) => rule.id === action.payload);
      if (!rule) return;
      rule.dependencyGroups.push({
        type: EngineeringChecklist.DependencyGroupType.Any,
        ruleIDs: [],
      });
    },
    removeRuleDependencyGroup: (
      state,
      action: PayloadAction<{
        ruleID: string;
        dependencyGroupIdx: number;
      }>,
    ) => {
      const rule = state.rules.find((rule) => rule.id === action.payload.ruleID);
      if (!rule) return;
      rule.dependencyGroups.splice(action.payload.dependencyGroupIdx, 1);
    },
    updateRuleDependencyGroup: (
      state,
      action: PayloadAction<{
        ruleID: string;
        dependencyGroupIdx: number;
        updates: Partial<EngineeringChecklist.DependencyGroup>;
      }>,
    ) => {
      const rule = state.rules.find((rule) => rule.id === action.payload.ruleID);
      if (!rule) return;
      const dependencyGroup = rule.dependencyGroups[action.payload.dependencyGroupIdx];
      Object.assign(dependencyGroup, action.payload.updates);
    },
    setRulesColumnWidths: (state, action: PayloadAction<string[]>) => {
      state.rulesColumnWidths = action.payload;
    },

    /* Control actions */
    load: (state, action: PayloadAction<string>) => {
      // Update state
      const { info, variables, rules } = deserializeEngineeringChecklist(action.payload);
      state.info = info;
      state.variables = variables;
      state.rules = rules;
    },

    // Actions with side-effects - see startListening calls below
    save: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(initializeDataModel.fulfilled, (state, action) => {
      state.dataModel.initialized = true;
      state.dataModel.error = false;
      state.dataModel.data = [action.payload];
      state.dataModel.searchTree = [action.payload];
    })
    .addCase(initializeDataModel.rejected, (state) => {
      if (!state.dataModel.initialized) {
        state.dataModel.error = true;
      }
    })
  }
});

// Action side-effects
startListening({
  // TODO: need to make sure export format matches what Xchanger Suite expects
  actionCreator: engineeringChecklistSlice.actions.save,
  effect: async (_action, listenerAPI) => {
    listenerAPI.dispatch(resetError());

    const state = listenerAPI.getState();
    const checklist = state.engineeringChecklist;

    // Validate inputs before attempting to save
    if (!validateVariables(checklist.variables)) {
      throw new LoggedError('Not all variable aliases are valid. Please ensure there are no issues in the Variables tab.');
    }
    // TODO: could validate there are no cyclical rule requirements here,
    // but the only way this can happen is if the raw .json was edited manually before importing

    // Serialize display item states
    const serializedState = await serializeEngineeringChecklist(
      checklist,
      state.authentication.user,
    );
    const filename = checklist.info.name || 'engineering-checklist';
    savePlaintextFile(JSON.stringify(serializedState), filename, 'list');
  },
});

// Ensure info fields stay updated with current user
startListening({
  predicate: (_action, currentState, previousState) => {
    const previousUser = previousState.authentication.user;
    const currentUser = currentState.authentication.user;
    return currentUser?.id !== previousUser?.id;
  },
  effect: (_action, { dispatch, getState }) => {
    const state = getState();
    const user = state.authentication.user;
    const author = user ? `${user.firstName} ${user.lastName}` : '';
    const company = user?.company ?? '';

    dispatch(engineeringChecklistSlice.actions.updateInfo({ author, company }));
  },
})
