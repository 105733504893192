import React from 'react';

/**
 * Creates a ref that tracks ref's dimensions.
 * Note that this doesn't trigger rerenders.
 * @param ref Item to track dimensions of
 * @returns A ref containing dimensions of the target element
 */
export default function useDimensions(ref: React.MutableRefObject<HTMLElement>) {
  const size = React.useRef<DOMRect>();

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(([{ target }]) => {
      size.current = target.getBoundingClientRect();
    });

    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, []);

  return size;
}
