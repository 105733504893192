import React from 'react';
import { Block, PlaceholderBlock, SymbolGroupBlock, equationFunctions } from '../types';
import { useEquationDispatch } from '../redux/store';
import { EquationEditorContext } from '../EquationEditor';
import { CogIcon, VariableIcon } from '@heroicons/react/24/outline';
import Typography from '../../Typography';
import { Focus, replaceBlock } from '../redux/reducer';
import { createBlock, createBlockFocus, createDataModelVariableBlock, createParenthesesBlock, createPlaceholderBlock, createSymbolFocus, createSymbolGroupBlock } from '../utils';
import { BlockType } from '../types';
import { autoPlacement, autoUpdate, size, useFloating } from '@floating-ui/react';
import useDimensions from '../../../../hooks/useDimensions';

enum AutocompleteOptionType { Variable, Function };
interface OptionProps {
  value: string,
  helpText?: string;
  type: AutocompleteOptionType,
  createBlock: () => { block: Block, focus: Focus },
}

const collator = new Intl.Collator();

function generateID(value: string, type: AutocompleteOptionType) {
  return `${value} ${type}`;
}

interface AutocompleteProps {
  block: Block<SymbolGroupBlock> | Block<PlaceholderBlock>;
  children: React.ReactNode;
}

const Autocomplete: React.FC<AutocompleteProps> = ({ block, children }) => {
  const contentRef = React.useRef<HTMLDivElement>();
  const contentDimensions = useDimensions(contentRef);
  const { refs, floatingStyles } = useFloating({
    middleware: [
      autoPlacement({ allowedPlacements: ['top-start', 'bottom-start'] }),
      size({
        apply({ availableHeight, availableWidth, elements }) {
          let height = availableHeight;
          if (contentDimensions.current) height = Math.min(contentDimensions.current.height + 2, availableHeight);
          Object.assign(elements.floating.style, {
            height: `${height}px`,
            maxWidth: `${availableWidth}px`,
          });
        }
      }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const dispatch = useEquationDispatch();
  const { variables } = React.useContext(EquationEditorContext);
  const [selectedIdx, setSelectedIdx] = React.useState(0);

  const replaceBlockWith = (newBlock: Block, focus: Focus) => {
    dispatch(replaceBlock({ old: block, new: newBlock, focus, variables }));
  };

  const symbols = block.type === BlockType.SymbolGroup ? block.symbols : [];
  const blockText = symbols.reduce((acc, symbol) => acc + symbol.character, '');
  const lowercaseBlockText = blockText.toLocaleLowerCase();
  const options: OptionProps[] = React.useMemo(() => {
    const options: OptionProps[] = [];
    for (const [alias, variable] of variables.entries()) {
      if (
        alias.toLocaleLowerCase().startsWith(lowercaseBlockText)
        || variable.label.toLocaleLowerCase().startsWith(lowercaseBlockText)
      ) {
        const block = createDataModelVariableBlock(variable);
        options.push({
          value: alias,
          helpText: variable.label,
          type: AutocompleteOptionType.Variable,
          createBlock: () => ({
            block,
            focus: createSymbolFocus(block.symbolGroup.symbols[block.symbolGroup.symbols.length - 1]),
          }),
        });
      }
    }
    for (const fn of equationFunctions) {
      if (fn.toLocaleLowerCase().startsWith(lowercaseBlockText)) {
        const placeholder = createPlaceholderBlock();
        const block = createBlock({
          type: BlockType.Function,
          function: createSymbolGroupBlock(fn, true),
          operand: createParenthesesBlock(placeholder),
        });
        options.push({
          value: fn,
          type: AutocompleteOptionType.Function,
          createBlock: () => ({
            block,
            focus: createBlockFocus(placeholder),
          }),
        });
      }
    }
    return options.sort((a, b) => collator.compare(a.value, b.value));
  }, [variables, blockText]);

  React.useEffect(() => {
    const handleKeyDownEvent = (e: KeyboardEvent) => {
      let handled = false;
      let newSelectedIdx: number;
      if (e.key === 'ArrowUp') {
        newSelectedIdx = selectedIdx - 1;
        if (newSelectedIdx < 0) newSelectedIdx = options.length - 1
        handled = true;
      }
      else if (e.key === 'ArrowDown') {
        newSelectedIdx = selectedIdx + 1;
        if (newSelectedIdx >= options.length) newSelectedIdx = 0;
        handled = true;
      }
      else if (e.key === 'Enter') {
        const { block, focus } = options[selectedIdx].createBlock();
        replaceBlockWith(block, focus);
        handled = true;
      }

      if (newSelectedIdx !== undefined) {
        setSelectedIdx(newSelectedIdx);
        const newSelectedItem = options[newSelectedIdx];
        // Ensure selected item is visible
        document.getElementById(
          generateID(newSelectedItem?.value, newSelectedItem?.type)
        )?.scrollIntoView({ block: 'nearest' });
      }
      if (handled) { e.preventDefault(); e.stopPropagation(); }
    }

    const eventListenerOptions = { capture: true };
    document.addEventListener('keydown', handleKeyDownEvent, eventListenerOptions);
    return () => {
      document.removeEventListener('keydown', handleKeyDownEvent, eventListenerOptions)
    };
  }, [options, selectedIdx]);

  const optionComponents = options.map(({ value, helpText, type, createBlock }, idx) => {
    const Icon = type === AutocompleteOptionType.Function ? CogIcon : VariableIcon;
    const bgColor = idx === selectedIdx ? 'bg-blue-200' : 'bg-white hover:bg-slate-200';
    const helpTextComponent = helpText && <Typography className="whitespace-nowrap" variant="body2" color="lightSlate">{helpText}</Typography>
    const id = generateID(value, type);

    return (
      <div
        id={id}
        className={`${bgColor} pl-1 pr-6 py-0.5 flex items-center gap-0.5 cursor-pointer`}
        onClick={() => {
          const { block, focus } = createBlock();
          replaceBlockWith(block, focus);
        }}
        key={id}
      >
        <Icon className="size-5 text-slate-500 shrink-0" />
        {value}
        {helpTextComponent}
      </div>
    );
  });

  return (
    <div ref={refs.setReference}>
      {children}
      <div className="z-20" ref={refs.setFloating} style={floatingStyles}>
        <Typography variant="body2" className="h-min max-h-full overflow-auto bg-white border-[1px] border-slate-300 shadow-md rounded-sm font-sans">
          <div className="h-min" ref={contentRef}>
            {optionComponents}
          </div>
        </Typography>
      </div>
    </div>
  );
};

export default Autocomplete;
