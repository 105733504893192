import React from 'react';
import { useDispatch, useSelector } from '../../../redux/store';
import { engineeringChecklistSlice, initializeDataModel } from '../../../redux/tools/engineeringChecklist';
import FetchErrorScreen from '../../common/FetchErrorScreen';
import Actionbar, { ActionbarActions } from '../Actionbar';
import EngineeringChecklistContent from './EngineeringChecklistContent';
import LoadingScreen from '../../common/LoadingScreen';

const EngineeringChecklist: React.FC = () => {
  const dispatch = useDispatch();
  const dataModel = useSelector((state) => state.engineeringChecklist.dataModel);

  React.useEffect(() => {
    if (!dataModel.initialized) dispatch(initializeDataModel());
  }, [dataModel.initialized]);

  if (!dataModel.initialized) return <LoadingScreen />;

  if (dataModel.error) return (
    <FetchErrorScreen
      title="Failed to Initialize Engineering Checklist"
      onRetry={() => dispatch(initializeDataModel())}
    >
      There was an error loading data for the engineering checklist.
      Click the button below to retry, or contact an HTRI staff member if the problem continues.
    </FetchErrorScreen>
  );

  // Data model successfully loaded, render content
  const actions: ActionbarActions = {
    save: () => dispatch(engineeringChecklistSlice.actions.save()),
    load: {
      accept: '.list',
      onLoad: (content) => dispatch(engineeringChecklistSlice.actions.load(content)),
    },
  };

  return (
    <div className="h-full w-full flex flex-col bg-white">
      <Actionbar actions={actions} />
      <EngineeringChecklistContent />
    </div>
  );
};

export default EngineeringChecklist;
