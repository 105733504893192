import React from 'react';
import Typography, { TypographyColor, TypographyVariant } from '../Typography';
import Paper from '../Paper';
import { ArticleContent, Section } from './types';
import { LinkIcon } from '@heroicons/react/24/outline';
import getNearestScrollingAncestor from '../../../utils/getNearestScrollingAncestor';
import { hasSubsections, iterateSections } from './utils';

function getSectionID(article: ArticleContent, anchor: string) {
  return `${article.title ?? 'untitledarticle'}-${anchor}`;
}

function scrollToAnchor(article: ArticleContent, anchor: string) {
  const options: ScrollIntoViewOptions = { behavior: 'smooth' };

  window.location.hash = `#${anchor}`;
  const anchorElementID = getSectionID(article, anchor);
  const anchorElement = document.getElementById(anchorElementID);
  if (anchorElement) anchorElement.scrollIntoView(options);
}

interface SectionProps {
  article: ArticleContent;
  section: Section;
  subsection?: boolean;
  onSectionChange: (anchor: string | undefined) => any;
}

const Section: React.FC<SectionProps> = ({ article, section, subsection = false, onSectionChange }) => {
  const sectionID = getSectionID(article, section.anchor);
  const sectionRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    let lastBottom: number;

    const observerCallback: IntersectionObserverCallback = (entries) => {
      const { boundingClientRect, isIntersecting, rootBounds } = entries[0];

      // Don't update state on initial render
      if (lastBottom === undefined) {
        lastBottom = boundingClientRect.bottom;
        return;
      }

      if (isIntersecting) {
        const enteringFromTop = lastBottom < rootBounds.bottom;
        // If entering from the top, change to the previous section
        // as part of it is still visible
        if (enteringFromTop) {
          let previousSection: Section;
          for (const { section: currentSection } of iterateSections(article.sections)) {
            if (currentSection === section) {
              onSectionChange(previousSection?.anchor ?? section.anchor);
              break;
            }
            previousSection = currentSection;
          }
        }
      }
      else {
        lastBottom = boundingClientRect.bottom;

        const leavingFromTop = boundingClientRect.top <= rootBounds.top;
        if (leavingFromTop) onSectionChange(section.anchor);
      }
    }

    const scrollContainer = getNearestScrollingAncestor(sectionRef.current, 'y');
    const options: IntersectionObserverInit = {
      root: scrollContainer,
      threshold: 1,
    };

    const observer = new IntersectionObserver(observerCallback, options);
    observer.observe(sectionRef.current);

    return () => observer.disconnect();
  }, []);

  const handleAnchorClick = () => {
    scrollToAnchor(article, section.anchor);
  };

  const headerMargin = subsection ? 'mt-2' : 'mt-3';
  const headerVariant: TypographyVariant = subsection ? 'h5' : 'h4';
  const linkSize = subsection ? 'size-5' : 'size-6';
  const content = hasSubsections(section)
    ? (
      <>
        {section.beforeSubsections}
        {section.subsections.map((subsection) => (
          <Section
            subsection
            article={article}
            section={subsection}
            onSectionChange={onSectionChange}
            key={subsection.anchor}
          />
        ))}
      </>
    )
    : section.content
  ;

  return (
    <>
      <div
        className={`${headerMargin} group flex flex-row items-center -scroll-mt-1`}
        id={sectionID}
        ref={sectionRef}
      >
        <Typography variant={headerVariant}>{section.header}</Typography>
        <LinkIcon
          className={`${linkSize} ml-2 text-slate-500 hover:text-blue-500 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity`}
          onClick={handleAnchorClick}
        />
      </div>
      {content}
    </>
  );
};

interface SectionNavigationProps {
  article: ArticleContent;
  selectedSectionAnchor: string;
}

const SectionNavigation: React.FC<SectionNavigationProps> = ({ article, selectedSectionAnchor }) => {
  const sectionComponents: React.ReactNode[] = [];
  for (const { section, isSubsection } of iterateSections(article.sections)) {
    const isSelected = section.anchor === selectedSectionAnchor;
    let dynamicClass = isSelected ? 'bg-sky-100' : 'hover:bg-slate-100';
    if (isSubsection) dynamicClass += ' pl-5';
    const textColor: TypographyColor = isSelected ? 'sky' : 'black';
    const textVariant: TypographyVariant = isSubsection ? 'caption' : 'body2';

    const handleClick = () => {
      scrollToAnchor(article, section.anchor);
    };

    sectionComponents.push(
      <li
        className={`${dynamicClass} rounded-full px-3 py-1 cursor-pointer transition-colors`}
        onClick={handleClick}
      >
        <Typography color={textColor} variant={textVariant} className="truncate">
          {section.header}
        </Typography>
      </li>
    );
  }

  return (
    <div className="absolute top-0 left-0 h-full -translate-x-full w-min max-w-64 hidden xl:block">
      <ul className="sticky top-2 p-1 mr-2 rounded-md shadow-md bg-white flex flex-col gap-1">
        {sectionComponents}
      </ul>
    </div>
  );
};


interface ContainerProps {
  article: ArticleContent;
}

const Container: React.FC<ContainerProps> = ({ article }) => {
  const [selectedSectionAnchor, setSelectedSectionAnchor] = React.useState(article.sections[0]?.anchor);

  // Scroll to anchor after initial render if it is set
  React.useEffect(() => {
    const anchor = window.location.hash.slice(1);
    // Set a short timeout before scrolling to (hopefully) allow the page to stabilize
    // when there's dynamic content such as images
    if (anchor) window.setTimeout(() => scrollToAnchor(article, anchor), 100);
  }, []);

  const titleComponent = article.title
    ? <Typography variant="h3">{article.title}</Typography>
    : undefined
  ;

  const sectionComponents = article.sections.map((section) => (
    <Section
      article={article}
      section={section}
      key={section.header}
      onSectionChange={setSelectedSectionAnchor}
    />
  ));

  return (
    <div className="bg-sky-50 h-full w-full flex justify-center overflow-auto">
      <Paper shadowSize="lg" className="relative w-128 xl:w-1/2 max-w-[800px] h-min py-2">
        <SectionNavigation article={article} selectedSectionAnchor={selectedSectionAnchor} />
        {titleComponent}
        {sectionComponents}
      </Paper>
    </div>
  );
};

export default Container;
