import Article from '../../common/Article';
import { ArticleContent } from '../../common/Article/types';
import baffleTypeExampleRules from '../../../images/baffleTypeExampleRules.png';
import baffleTypeExampleVariable from '../../../images/baffleTypeExampleVariable.png';
import equationRuleAutocompleteExample from '../../../images/equationRuleAutocompleteExample.png';
import exampleInfo from '../../../images/exampleInfo.png';
import multiVariableEquationRuleExample from '../../../images/multiVariableEquationRuleExample.png';
import outletTempExampleRule from '../../../images/outletTempExampleRule.png';
import outletTempExampleVariable from '../../../images/outletTempExampleVariable.png';
import ruleRequirementExampleRules from '../../../images/ruleRequirementExampleRules.png';
import ruleRequirementExampleVariables from '../../../images/ruleRequirementExampleVariables.png';
import temaTypeExampleRule from '../../../images/temaTypeExampleRule.png';
import variableConfiguration from '../../../images/variableConfiguration.png';
import variableTree from '../../../images/variableTree.png';

const engineeringChecklistHelp: ArticleContent = {
  sections: [
    {
      header: 'Overview',
      anchor: 'overview',
      content: (
        <>
          <Article.Paragraph>
            The engineering checklist guides the creation of engineering specifications
            for various types of heat exchangers.
            You configure the checklist via the tabs at the top of the screen:
          </Article.Paragraph>
          <Article.UnorderedListContainer>
            <Article.UnorderedListItem>
              <strong>Information</strong> helps you specify metadata for the checklist.
            </Article.UnorderedListItem>
            <Article.UnorderedListItem>
              <strong>Variables</strong> let you reference specific properties you want to make rules for.
            </Article.UnorderedListItem>
            <Article.UnorderedListItem>
              <strong>Rules</strong> allow you to add constraints to the values of variables.
            </Article.UnorderedListItem>
          </Article.UnorderedListContainer>
        </>
      ),
    },
    {
      header: 'Information',
      anchor: 'info',
      content: (
        <>
          <Article.Paragraph>
            With fields on this tab, you configure the checklist’s name and version.
            The author and company are automatically filled based on your identity.
          </Article.Paragraph>
          <Article.Image
            src={exampleInfo}
            size="md"
            caption="Example of the information tab when filled out"
          />
        </>
      ),
    },
    {
      header: 'Variables',
      anchor: 'variables',
      subsections: [
        {
          header: 'Creating Variables',
          anchor: 'creatingVariables',
          content: (
            <>
              <Article.Paragraph>
                To create variables, you select properties from the tree menu
                on the left side of the screen.
                You can create multiple variables for the same property
                and configure them independently.
              </Article.Paragraph>
              <Article.Image
                src={variableTree}
                size="md"
                caption="The variable tree showing expandable items (indicated by arrow icons) and variables (document icons)"
              />
            </>
          ),
        },
        {
          header: 'Searching for Variables',
          anchor: 'searchingForVariables',
          content: (
            <Article.Paragraph>
              Use the search bar to filter results to properties matching your search terms.
              You can search properties by either the label shown in the tree
              or by the attribute name as used in <Article.XchangerSuite trademark />.
              To view the attribute name, simply hover over a variable's label.
            </Article.Paragraph>
          )
        },
        {
          header: 'Configuring Variables',
          anchor: 'configuringVariables',
          content: (
            <>
              <Article.Paragraph>
                After you create a variable,
                it is displayed in a list on the right side of the screen.
              </Article.Paragraph>
              <Article.Image
                src={variableConfiguration}
                caption="Example list of variables with a variety of configuration options"
              />
              <Article.Paragraph>
                Select a variable to show its configurable options,
                indicated by text fields or drop-down lists.
                Each column in the variable list shows a different piece of information:
              </Article.Paragraph>
              <Article.UnorderedListContainer>
                <Article.UnorderedListItem>
                  <strong>Status</strong> shows an error symbol
                  to indicate a problem with the configuration of the variable.
                  Hovering over this symbol displays a summary of the issue.
                </Article.UnorderedListItem>
                <Article.UnorderedListItem>
                  <strong>Number</strong> displays the variable’s position in the list.
                  Click the <strong>X</strong> button to delete the variable.
                </Article.UnorderedListItem>
                <Article.UnorderedListItem>
                  <strong>Property</strong> shows the user-friendly name of the variable.
                  Hover over the name to view the property’s
                  attribute name and navigation strings.
                </Article.UnorderedListItem>
                <Article.UnorderedListItem>
                  <strong>Alias</strong> sets the variable’s name in the rules tab.
                </Article.UnorderedListItem>
                <Article.UnorderedListItem>
                  <strong>IO Selections</strong> let you select whether each supported
                  variable is derived from a property input to <Article.XchangerSuite />
                  or output to a report.
                </Article.UnorderedListItem>
                <Article.UnorderedListItem>
                  <strong>Nav Options</strong> set the specific configuration options for a variable.
                  These can be either drop-down lists or index selections (described below).
                </Article.UnorderedListItem>
                <Article.UnorderedListItem>
                  <strong>Units</strong> indicate the units for the variable.
                  When configuring equation rules,
                  remember that the numbers you type are converted into this unit set.
                </Article.UnorderedListItem>
              </Article.UnorderedListContainer>
            </>
          ),
        },
        {
          header: 'Indexed Variables',
          anchor: 'indexedVariables',
          content: (
            <>
              <Article.Paragraph>
                An <Article.XchangerSuite /> case can use some properties to refer to multiple values.
                For example, different Heat Release Curves can each contain multiple points
                defined by their temperature, pressure, and other thermodynamic properties.
              </Article.Paragraph>
              <Article.Paragraph>
                With the engineering checklist, you can
              </Article.Paragraph>
              <Article.UnorderedListContainer>
                <Article.UnorderedListItem>
                  manually specify the numeric index of a property to select a specific instance of it
                </Article.UnorderedListItem>
                <Article.UnorderedListItem>
                  select all instances of a property to check the values of all the copies
                </Article.UnorderedListItem>
                <Article.UnorderedListItem>
                  let <Article.XchangerSuite /> scan all the instances and use the minimum or maximum value
                </Article.UnorderedListItem>
              </Article.UnorderedListContainer>
            </>
          ),
        },
      ],
    },
    {
      header: 'Rules',
      anchor: 'rules',
      beforeSubsections: (
        <>
          <Article.Paragraph>
            After creating variables, you can create rules to validate that those variables
            match the criteria you can define.
            Rules can be one of three types: equation, value selection, and text-based.
            At the top of the rules tab, buttons let you create rules for each type.
            Once added, rules are displayed in a list with several columns:
          </Article.Paragraph>
          <Article.UnorderedListContainer>
            <Article.UnorderedListItem>
              <strong>Number</strong> shows the rule’s position in the list.
              Click the <strong>X</strong> button to delete the rule.
            </Article.UnorderedListItem>
            <Article.UnorderedListItem>
              <strong>Type</strong> shows the rule’s type,
              indicating that a rule works with variables matching that type.
              Each rule type is configured in a different way.
            </Article.UnorderedListItem>
            <Article.UnorderedListItem>
              <strong>LHS</strong> is the left-hand side of the rule.
            </Article.UnorderedListItem>
              <strong>Operator</strong> is the comparison to use,
              and the operators available depend on the rule type
              (text-based and value selection rules don't allow inequalities).
              The operator “in” indicates that the value of the variable on the left
              matches one of the options specified on the right.
              Similarly, the operator “!in” means that the value of the variable on the left
              is not one of the options specified on the right.
            <Article.UnorderedListItem>
              <strong>RHS</strong> is the right-hand side of the rule.
            </Article.UnorderedListItem>
            <Article.UnorderedListItem>
              <strong>Requirements</strong> are the rules that must be satisfied
              before a rule is checked, giving you more granular control
              over what rules are applied in various conditions.
            </Article.UnorderedListItem>
          </Article.UnorderedListContainer>
        </>
      ),
      subsections: [
        {
          header: 'Equation Rules',
          anchor:'equationRules',
          content: (
            <>
              <Article.Paragraph>
                Equation rules let you constrain the values of numeric variables.
                For example, to specify that the tube side outlet temperature is under 80 °C,
                you simply add the corresponding variable and configure it appropriately:
              </Article.Paragraph>
              <Article.Image src={outletTempExampleVariable} />
              <Article.Paragraph>
                Then create an equation rule and set the value as below:
              </Article.Paragraph>
              <Article.Image src={outletTempExampleRule} />
              <Article.Paragraph>
                Note that units need to be specified only for each variable, not for rules.
                You can also make more complex rules involving multiple variables,
                such as in the example below.
              </Article.Paragraph>
              <Article.Image src={multiVariableEquationRuleExample} />
              <Article.Paragraph>
                Equation rules also support a variety of useful functions
                and intelligent autocompletion for functions, variable aliases, and property names.
                When typing into an empty block, all of these will be shown,
                and as you type the results will be limited to the ones matching your input.
                Using the arrow keys allows you to move between suggestions, and clicking
                a result or pressing enter will fill in the option you selected.
              </Article.Paragraph>
              <Article.Image
                src={equationRuleAutocompleteExample}
                caption="Autocompletion after typing &quot;T&quot; showing all functions, variable aliases, and property names that match the user's current input"
              />
            </>
          ),
        },
        {
          header: 'Value Selection Rules',
          anchor: 'valueSelectionRules',
          content: (
            <>
              <Article.Paragraph>
                Value selection rules let you choose allowable values for variables
                that have a pre-defined list of possible values.
                For example, to configure the allowable baffle types,
                you would first add the appropriate variable:
              </Article.Paragraph>
              <Article.Image src={baffleTypeExampleVariable} />
              <Article.Paragraph>
                Then you could configure the value in one of the ways shown below:
              </Article.Paragraph>
              <Article.Image src={baffleTypeExampleRules} />
            </>
          ),
        },
        {
          header: 'Text-Based Rules',
          anchor: 'textBasedRules',
          content: (
            <>
              <Article.Paragraph>
                Text-based rules let you use text strings to specify allowable values for variables.
                These rules are configured in the same way as value selection rules.
                For example, you could specify the allowable TEMA types for a heat exchanger.
              </Article.Paragraph>
              <Article.Image
                src={temaTypeExampleRule}
                caption="Configuring text-based rules that allow you to specify values (indicated by T icon) or to reference the value of other variables (indicated by V icon)"
              />
            </>
          ),
        },
        {
          header: 'Rule Requirements',
          anchor: 'ruleRequirements',
          content: (
            <>
              <Article.Paragraph>
                Requirements stipulate that rules are checked only if a specified
                combination of other rules is satisfied.
                For example, TEMA requirements state that an impingement device
                is needed for impinging fluids with high entrance line values
                for <i>ρV<sup>2</sup></i>.
                To represent this requirement, you could create the following variables and rules:
              </Article.Paragraph>
              <Article.Image src={ruleRequirementExampleVariables} />
              <Article.Image src={ruleRequirementExampleRules} />
              <Article.Paragraph>
                You can also specify more complex rule requirements,
                adding multiple requirement groups and choosing whether all rules
                or only a single rule in a group must be satisfied before checking.
              </Article.Paragraph>
            </>
          ),
        },
        {
          header: 'Automatic Expansion of Rules',
          anchor: 'automaticExpansionOfRules',
          content: (
            <Article.Paragraph>
              When a rule references a variable that uses the Select All option,
              multiple copies of the rule are created internally,
              involving all the copies of the selected variable.
              If the rule involves multiple variables with the Select All option,
              the expansion of the rules happens only if all the variables
              are of the same size (i.e., they have the same number of copies).
            </Article.Paragraph>
          ),
        },
      ],
    },
    {
      header: 'Saving and Loading Checklists',
      anchor: 'savingAndLoadingChecklists',
      content: (
        <>
          <Article.Paragraph>
            Clicking the <strong>Save</strong> button (available at the top of the screen)
            generates a .list file of the checklist configuration.
          </Article.Paragraph>
          <Article.Paragraph>
            You can load a saved .list file to use it or modify it later.
            Loading a .list file replaces the content of all the tabs
            with the information contained in the file.
            You can then modify the checklist and save it to update the checklist.
          </Article.Paragraph>
        </>
      ),
    },
  ],
};

const EngineeringChecklistHelp: React.FC = () => (
  <Article.Container article={engineeringChecklistHelp} />
)

export default EngineeringChecklistHelp;
