import React from 'react';
import Typography from '../Typography';

interface ParagraphProps {
  children: React.ReactNode;
}

const Paragraph: React.FC<ParagraphProps> = ({ children }) => {
  return (
    <Typography className="mt-1" variant="body1">{children}</Typography>
  );
};

export default Paragraph;
