import React from "react";
import { useSelector } from "react-redux";

import { RootState, useDispatch } from '../redux/store';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { Error } from '../types/API';
import Typography from './common/Typography';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { reset as resetErrors } from '../redux/errors';

const Error: React.FC = () => {
  const dispatch = useDispatch();
  const errors = useSelector<RootState, Error[]>((state) => state.errors.errors);

  if (errors.length === 0) return undefined;

  const multipleErrors = errors.length > 1;

  const header = multipleErrors ? `${errors.length} errors occurred:` : 'Error:';

  const errorList = errors.map((error) => (
    <Typography color="darkRed" variant="body2" key={error.message}>
      <li>{error.message}</li>
    </Typography>
  ))

  return (
    <div className="z-50 w-full bg-red-50 p-4 shadow-md flex items-center gap-4">
      <XCircleIcon className="size-10 text-red-500" />
      <div className="w-full">
        <Typography color="darkRed" variant="h6">{header}</Typography>
        <ul role="list" className="list-disc space-y-1 pl-5 max-h-32 overflow-auto">
          {errorList}
        </ul>
      </div>
      <div
        className="ml-auto rounded-full bg-transparent hover:bg-red-200 transition-colors cursor-pointer"
        onClick={() => dispatch(resetErrors())}
      >
        <XMarkIcon className="size-12 text-slate-400" />
      </div>
    </div>
  );
};

export default Error;
