import React from 'react';
import { GET_USERS_RESULTS_PER_PAGE, useGetUsersQuery } from '../../../redux/userQueriesAPI';
import { useDispatch, useSelector } from '../../../redux/store';
import PageSelector from '../../common/PageSelector';
import { authenticationSlice } from '../../../redux/authentication';

const UserQueryPageSelector: React.FC = () => {
  const dispatch = useDispatch();
  const queryParams = useSelector((state) => state.authentication.userQueryParams);
  const { currentData } = useGetUsersQuery(queryParams);
  if (!currentData) return;

  const page = Math.round((queryParams.offset ?? 0) / GET_USERS_RESULTS_PER_PAGE) + 1;
  const numPages = Math.ceil(currentData.total / GET_USERS_RESULTS_PER_PAGE);

  if (numPages <= 1) return;

  const handlePageChange = (newPage: number) => {
    dispatch(authenticationSlice.actions.setUserQueryParams({
      ...queryParams,
      offset: (newPage - 1) * GET_USERS_RESULTS_PER_PAGE,
    }));
  };

  return (
    <div className="flex justify-center w-full p-2">
      <PageSelector page={page} numPages={numPages} onPageChange={handlePageChange} />
    </div>
  );
};

export default UserQueryPageSelector;
