import React from 'react';

import Typography from '../Typography';
import Button from '../Button';
import Paper from '../Paper';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

interface FormContainerProps {
  title?: string;
  submitButtonLabel?: string;
  error?: string;
  loading?: boolean;
  canSubmit?: boolean;
  onSubmit?: (e: React.MouseEvent<HTMLButtonElement>) => any;
  onBack?: () => any;
  children: React.ReactNode;
}

const FormContainer: React.FC<FormContainerProps> = ({
  title,
  submitButtonLabel = title,
  error = '',
  loading,
  canSubmit = true,
  onSubmit,
  onBack,
  children,
}) => {
  const titleComponent = title && (
    <Typography variant="h4">{title}</Typography>
  )

  const backButtonComponent = onBack && (
    <Button
      label="Back"
      iconLeft={<ChevronLeftIcon className="size-5" />}
      color="white"
      onClick={onBack}
    />
  );

  const errorMessageComponent = error && (
    <Typography variant="caption" color="red">{error}</Typography>
  );

  const submitButtonComponent = onSubmit && (
    <Button
      type="submit"
      label={submitButtonLabel ?? 'Submit'}
      color="primary"
      onClick={onSubmit}
      disabled={!canSubmit}
      loading={loading}
    />
  );

  const actionItems = [backButtonComponent, errorMessageComponent, submitButtonComponent];
  const actionItemContainer = actionItems.some((item) => Boolean(item)) && (
    <div className="flex flex-row items-center justify-between gap-2">
      {backButtonComponent}
      {errorMessageComponent}
      {submitButtonComponent}
    </div>
  )

  return (
    <div className="flex flex-col items-center">
      <form className="max-w-full">
        <Paper shadowSize="lg" className="p-4 w-128 max-w-full" innerClassName="flex flex-col w-full items-stretch gap-2">
          {titleComponent}
          {children}
          {actionItemContainer}
        </Paper>
      </form>
    </div>
  );
};

export default FormContainer;
