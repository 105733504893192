import { createApi } from '@reduxjs/toolkit/query/react';
import makeRequestBaseQuery from './makeRequestBaseQuery';
import { HTRICalcAPI } from '../types/API';

export enum UnitType { SingleUnit, MultiUnit };

export interface HTRISingleUnitSet {
  type: UnitType.SingleUnit;
  numerators: string[];
}

export interface HTRIMultiUnitSet {
  type: UnitType.MultiUnit;
  numerators: string[];
  denominators: string[][];
}

export type HTRIUnitSet = HTRISingleUnitSet | HTRIMultiUnitSet;

export type HTRIUnitSetConversions = Record<string, { valueSI: number, offset: number }>;

export interface HTRIEnum {
  name: string;
  enum: Record<string, string>;
}

const HTRICalcAPI = createApi({
  reducerPath: 'HTRICalcAPI',
  baseQuery: makeRequestBaseQuery({ baseUrl: `/api/v1/htricalc/`}),
  endpoints: (builder) => ({
    getHTRIUnitSet: builder.query<HTRIUnitSet, string>({
      query: (name) => `units/?name=${name}`,
      transformResponse: (response: HTRICalcAPI.GetHTRIUnitSetResponse) => {
        return {
          type: response.type === 'single_unit' ? UnitType.SingleUnit : UnitType.MultiUnit,
          numerators: response.numerator,
          denominators: response.denominator,
        };
      },
    }),
    getHTRIUnitConversions: builder.query<HTRIUnitSetConversions, string>({
      query: (name) => `units/conversions?name=${name}`,
      transformResponse: (response: HTRICalcAPI.GetHTRIUnitConversionsResponse) => {
        return Object.fromEntries(
          response.units.map(({ name, offset, valueSI }) => (
            [
              name,
              {
                valueSI,
                // Multiply offset by valueSI so that they are consistent with units API -
                // HTRI units are calculated with (x + offset) * valueSI
                // rather than (x * valueSI) + offset
                offset: offset * valueSI,
              }
            ]
          ))
        );
      },
    }),
    getHTRIEnum: builder.query<HTRIEnum, string>({
      query: (name) => `enums/?name=${name}`,
      transformResponse: (response: HTRICalcAPI.GetHTRIEnumResponse) => {
        return {
          name: response.name,
          enum: response.data,
        };
      },
    }),
  }),
  // Never remove data from cache
  keepUnusedDataFor: Infinity,
});

export default HTRICalcAPI;
export const { useGetHTRIUnitSetQuery, useGetHTRIUnitConversionsQuery, useGetHTRIEnumQuery } = HTRICalcAPI;
