import React from 'react';
import useCurrentUser from '../../hooks/useCurrentUser';
import HomeRedirectPage from './HomeRedirectPage';
import { userHasClaims } from '../../utils/permissions';
import { Claim } from '../../types/API';
import { useOutlet } from 'react-router-dom';
import PageNotFound from '../PageNotFound';

interface ClaimValidatorProps {
  claims: Claim | Claim[];
  children?: React.ReactNode;
}

const ClaimValidator: React.FC<ClaimValidatorProps> = ({ claims, children }) => {
  const user = useCurrentUser();
  const outlet = useOutlet();

  if (!userHasClaims(user, claims)) {
    return (
      <HomeRedirectPage title="Page Not Accessible">
        You do not have sufficient permissions to access this page.
      </HomeRedirectPage>
    );
  }

  // Return children if provided, otherwise use as an outlet
  // so that this can be used as a router element when convenient
  if (children) return children;
  return outlet ?? <PageNotFound />;
};

export default ClaimValidator;
