import { useState } from 'react'

import { convert } from '../utils/units'
import { floatingPtIn, floatingPtOut } from '../utils/ValueDisplay'

const ProcessValue = ({ status, propertyMutators, controlActions, property }) => {

  const { id, label, userSpecified, value, unitOptions, unit, displayUnit } = property;

  const [selectedUnit, setSelectedUnit] = useState(displayUnit);
  const [displayValue, setDisplayValue] = useState(convert(value, unitOptions, unit, displayUnit));

  const onValueChange = (e) => {
    if (displayValue === e.target.value) return;
    setDisplayValue(e.target.value);
    propertyMutators.updateProcessValue(id, convert(e.target.value, unitOptions, selectedUnit, unit));
    controlActions.reset();
  };

  const onSelectionChange = (e) => {
    if (selectedUnit === e.target.value) return;
    setDisplayValue(convert(displayValue, unitOptions, selectedUnit, e.target.value));
    propertyMutators.updateProcessValueDisplayUnit(id, e.target.value);
    return setSelectedUnit(e.target.value);
  };

  return (
    <div className="flex items-center justify-start h-10 w-full m-1 bg-white">
      <span className='m-1 p-1 w-60 text-left'>
        {label}
      </span>
      <input
        {...(userSpecified
          ? { className: 'm-1 p-1 w-60 text-right text-blue-500 rounded-md border-2 border-blue-400 hover:border-4 hover:border-blue-700' }
          : {
              className: 'm-1 p-1 w-60 text-right rounded-md border-2 border-slate-100',
              readOnly: 'readonly',
            })}
        type="number"
        step="0.01"
        value={userSpecified? floatingPtIn(displayValue) : floatingPtOut(displayValue)}
        onChange={onValueChange}
      />
      {unitOptions.length > 0 && (
        <select
          className="m-1 p-1 w-60 rounded-md border-2 border-slate-100 hover:border-4"
          value={selectedUnit}
          onChange={onSelectionChange}
        >
          {unitOptions.map((item) => (
            <option key={item.symbol}>{item.symbol}</option>
          ))}
        </select>
      )}
    </div>
  );
}

export default ProcessValue;
