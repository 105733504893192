import React from 'react';

export type TypographyColor = (
  'black'
  | 'white'
  | 'red'
  | 'darkRed'
  | 'sky'
  | 'slate'
  | 'lightSlate'
  | 'extraLightSlate'
  | 'inherit'
);
interface TypographyColorClasses {
  default: string;
  hover: string;
}
const colorClasses: Record<TypographyColor, TypographyColorClasses> = {
  black: {
    default: 'text-black',
    hover: 'hover:text-slate-700',
  },
  white: {
    default: 'text-white',
    hover: 'hover:text-slate-200',
  },
  red: {
    default: 'text-red-500',
    hover: 'hover:text-red-300',
  },
  darkRed: {
    default: 'text-red-700',
    hover: 'hover:text-red-500',
  },
  sky: {
    default: 'text-sky-600',
    hover: 'hover:text-sky-400',
  },
  slate: {
    default: 'text-slate-700',
    hover: 'hover:text-slate-500',
  },
  lightSlate: {
    default: 'text-slate-500',
    hover: 'hover:text-slate-400',
  },
  extraLightSlate: {
    default: 'text-slate-300',
    hover: 'hover:text-slate-500',
  },
  inherit: {
    default: 'text-inherit',
    hover: '',
  },
};

// Typography variants are taken from Google's Material Design guidelines:
// https://m2.material.io/design/typography/the-type-system.html#type-scale
export type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2' | 'button' | 'caption';
interface VariantProps {
  weight: string;
  size: string;
  tracking: string;
}
const variantClasses: Record<TypographyVariant, VariantProps> = {
  h1: { weight: 'font-light', size: 'text-8xl', tracking: 'tracking-tight' },
  h2: { weight: 'font-light', size: 'text-6xl', tracking: 'tracking-tight' },
  h3: { weight: 'font-normal', size: 'text-5xl', tracking: 'tracking-normal' },
  h4: { weight: 'font-normal', size: 'text-4xl', tracking: 'tracking-wide' },
  h5: { weight: 'font-normal', size: 'text-2xl', tracking: 'tracking-normal' },
  h6: { weight: 'font-medium', size: 'text-xl', tracking: 'tracking-wide' },
  body1: { weight: 'font-normal', size: 'text-base', tracking: 'tracking-wider' },
  body2: { weight: 'font-normal', size: 'text-sm', tracking: 'tracking-wide' },
  button: { weight: 'font-normal', size: 'text-sm', tracking: 'tracking-wider' },
  caption: { weight: 'font-normal', size: 'text-xs', tracking: 'tracking-wider' },
};

interface TypographyProps {
  variant?: TypographyVariant;
  bold?: boolean;
  color?: TypographyColor;
  allowHover?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const Typography: React.FC<TypographyProps> = ({
  variant = 'body1',
  bold,
  color = 'black',
  allowHover,
  className = '',
  children,
  ...propsToPass
}) => {
  const baseClasses = variantClasses[variant];
  let calculatedClassName = `${className} ${colorClasses[color].default} ${baseClasses.size} ${baseClasses.tracking}`;
  calculatedClassName += bold ? ' font-semibold' : ` ${baseClasses.weight}`;
  if (allowHover) calculatedClassName += ` ${colorClasses[color].hover}`;

  return (
    <div className={calculatedClassName} {...propsToPass}>
      {children}
    </div>
  );
};

export default Typography;
