import Material from '../../../types/materials';
import { DataSource, EllipticalCurve, HyperbolaParameters, PointArray, Polynomial } from '../../../utils/recharts/dataSources';
import Data from '../../../utils/recharts/types';
import MaterialLabel from './MaterialLabel';

function validXRange(correlation: Material.Correlation.Details): Data.Range {
  return {
    min: correlation.independentVariable.lowValue,
    max: correlation.independentVariable.highValue,
  };
}

function minValidY(correlation: Material.Correlation.Details): number | undefined {
  switch (correlation.dependentVariable.name) {
    case 'Allowable Stress': return 0;
    case 'Metal Density': return 0;
    case 'Modulus of Elasticity': return 0;
    case 'Thermal Conductivity': return 0;
    case 'Yield Stress': return 0;
    default: return undefined;
  }
}

function label(material: Material.Details, correlation: Material.Correlation.Details) {
  return `${material.id}x${correlation.id}`;
}

function units(correlation: Material.Correlation.Details): { xUnits: Data.Unit, yUnits: Data.Unit } {
  return {
    xUnits: {
      unit: correlation.independentVariable.units,
      unitSet: correlation.independentVariable.unitOfMeasure,
    },
    yUnits: {
      unit: correlation.dependentVariable.units,
      unitSet: correlation.dependentVariable.unitOfMeasure,
    },
  };
}

interface CorrelationDataSourceProps {
  material: Material.Details;
  correlation: Material.Correlation.Details;
}

class MaterialPolynomialCorrelation extends Polynomial {
  constructor({ material, correlation }: CorrelationDataSourceProps) {
    const terms = correlation.constants.map((constant) => constant.value);
    super({
      label: label(material, correlation),
      labelElement: <MaterialLabel material={material} size="small" />,
      ...units(correlation),
    }, terms);
    const xRange = validXRange(correlation);
    this.minValidX = xRange.min;
    this.maxValidX = xRange.max;
    this.minValidY = minValidY(correlation);
  }
}

class MaterialHyperbolicCorrelation extends EllipticalCurve {
  constructor({ material, correlation }: CorrelationDataSourceProps) {
    const params: HyperbolaParameters = {
      c0: correlation.constants[0]?.value ?? 0,
      c1: correlation.constants[1]?.value ?? 0,
      c2: correlation.constants[2]?.value ?? 0,
      c3: correlation.constants[3]?.value ?? 0,
    };
    super({
      label: label(material, correlation),
      labelElement: <MaterialLabel material={material} size="small" />,
      ...units(correlation),
    }, params);
    const xRange = validXRange(correlation);
    this.minValidX = xRange.min;
    this.maxValidX = xRange.max;
    this.minValidY = minValidY(correlation);
  }
}

class MaterialTableInterpolationCorrelation extends PointArray {
  constructor({ material, correlation }: CorrelationDataSourceProps) {
    const points: Data.Point[] = correlation.tableRecords.map((tableRecord) => ({
      x: tableRecord.independentVariableValue,
      y: tableRecord.dependentVariableValue,
    }));
    super({
      label: label(material, correlation),
      labelElement: <MaterialLabel material={material} size="small" />,
      ...units(correlation),
    }, points);
    const xRange = validXRange(correlation);
    this.minValidX = xRange.min;
    this.maxValidX = xRange.max;
    this.minValidY = minValidY(correlation);
  }
}

export function dataSourceForCorrelation(
  material: Material.Details,
  correlation: Material.Correlation.Details,
): DataSource {
  const props: CorrelationDataSourceProps = { material, correlation };
  // Determine data source to use based on correlation form
  switch (correlation.functionalForm) {
    // TODO: metal density correlations are nominally polynomials
    //but need to be dericed from their single constant instead of use the values directly
    case 'polynomial': return new MaterialPolynomialCorrelation(props);
    case 'hyperbolic': return new MaterialHyperbolicCorrelation(props);
    case 'tableinterpolation': return new MaterialTableInterpolationCorrelation(props);
    default: throw new Error(`Unsupported correlation type ${correlation.functionalForm}. Please contact an administrator to have this correlation type implemented.`);
  }
}
