import React from 'react';
import { Block, BlockProps, PlaceholderBlock } from '../types';
import Symbol from '../Symbol';
import { useEquationSelector } from '../redux/store';
import Autocomplete from './Autocomplete';

const Placeholder: React.FC<BlockProps> = ({ block }) => {
  const placeholder = block as Block<PlaceholderBlock>;
  const currentFocus = useEquationSelector((state) => state.currentFocus);

  const hasFocus = (
    currentFocus.target?.id === placeholder.id
    || currentFocus.target?.id === placeholder.placeholder.id
  );

  const symbol = <Symbol symbol={placeholder.placeholder} />;
  if (hasFocus) return (
    <Autocomplete block={placeholder}>
      {symbol}
    </Autocomplete>
  );

  return symbol;
};
export default Placeholder;
