import React from 'react';
import { createPortal } from 'react-dom';
import Paper from './Paper';
import Button, { ButtonProps } from './Button';
import Typography from './Typography';

interface DialogProps {
  onClose: () => any;
  title?: string;
  children: React.ReactNode;
  buttons?: (ButtonProps & { shouldDismiss?: boolean })[];
}

const Dialog = ({ onClose, title, buttons, children }: DialogProps): JSX.Element => {
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    // Update opacity to fade in
    setVisible(true);

    // Allow closing by pressing escape
    const handleEscapePress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') setVisible(false);
    };
    document.addEventListener('keyup', handleEscapePress);
    return () => document.removeEventListener('keyup', handleEscapePress);
  }, []);

  const titleComponent = title ? (
    <Typography className="mb-4" variant="h5">{title}</Typography>
  ) : undefined;

  let controlButtons: React.ReactNode;
  if (buttons?.length) {
    const buttonElements = buttons.map((button, idx) => {
      const buttonProps: ButtonProps = {
        ...button,
        onClick: (e) => {
          if (button.shouldDismiss) setVisible(false);
          if (button.onClick) button.onClick(e);
        }
      };
      return <Button {...buttonProps} key={buttonProps.label ?? idx} />;
    });

    controlButtons = (
      <div className="flex justify-end gap-2 mt-4">
        {buttonElements}
      </div>
    );
  }

  const handleBackgroundClick = (e: React.MouseEvent) => {
    // Only dismiss if background was clicked directly
    if (e.currentTarget === e.target) setVisible(false);
  }

  const dialog = (
    <div
      className={`fixed top-0 left-0 z-40 transition-opacity duration-200 ${visible ? 'opacity-100' : 'opacity-0'} w-full h-full`}
      onTransitionEnd={() => { if (!visible) onClose(); }}
    >
      <div
        className="bg-black bg-opacity-25 w-full h-full"
        onClick={handleBackgroundClick}>
        <Paper className="absolute w-128 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {titleComponent}
          {children}
          {controlButtons}
        </Paper>
      </div>
    </div>
  );

  return createPortal(dialog, document.body);
};

export default Dialog;
