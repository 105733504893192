import React from 'react';
import HomeRedirectPage from './common/HomeRedirectPage';

const PageNotFound: React.FC = () => (
  <HomeRedirectPage title="404: Page Not Found">
    The page you requested does not exist.
  </HomeRedirectPage>
);

export default PageNotFound;
