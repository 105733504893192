import SidebarItem from './SidebarItem';

const Sidebar = ({sidebarTree}) => {
  return (
    <div className="flex flex-col max-h-full h-full min-w-fit p-3 bg-sky-100 border-2 border-sky-200">
      <div className="flex-grow h-0 overflow-y-auto">
        {sidebarTree.map((node, index) => (
          <SidebarItem key={index} node={node} />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
