import React from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from '../../../../redux/store';
import { engineeringChecklistSlice } from '../../../../redux/tools/engineeringChecklist';
import Typography, { TypographyColor } from '../../../common/Typography';

const DataModelTreeSearch: React.FC = () => {
  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.engineeringChecklist.dataModel.searchQuery);
  const input = React.useRef<HTMLInputElement>(null);

  const handleChange = (newQuery: string) => {
    dispatch(engineeringChecklistSlice.actions.setSearchQuery(newQuery));
  };

  const searchTextColor: TypographyColor = searchQuery ? 'black' : 'lightSlate';

  return (
    <div className="w-full flex flex-row items-center bg-white p-1">
      <MagnifyingGlassIcon className="size-5 text-slate-700 shrink-0" />
      <Typography className="w-full" color={searchTextColor}>
        <input
          className="w-full border-0 p-0 px-1 focus:outline-none"
          value={searchQuery}
          onChange={(e) => handleChange(e.target.value)}
          placeholder="Search Variables"
          ref={input}
        />
      </Typography>
      <XMarkIcon
        className="size-7 text-slate-700 shrink-0 justify-self-end rounded-full hover:bg-slate-200 transition-colors cursor-pointer"
        onClick={() => { handleChange(''); input.current?.focus(); }}
      />
    </div>
  );
};

export default DataModelTreeSearch;
