import { signHash } from './API';
import { SHA256 } from './hashing';
import { User } from '../redux/authentication';
import parseEquation from '../components/common/EquationEditor/parseEquation';
import { createController } from '../components/common/EquationEditor/redux/BlockController';
import { EngineeringChecklist } from '../types/engineeringChecklist';
import { EngineeringChecklistState } from '../redux/tools/engineeringChecklist';

// Use semantic versioning to ensure that potentially breaking changes can be handled correctly
/*
 * Version history:
 *
 *   1.0.0: Initial revision (5/20/2024)
*/
type EngineeringChecklistVersion = `${number}.${number}.${number}`;
const CURRENT_CHECKLIST_VERSION: EngineeringChecklistVersion = '1.0.0';

/* Type definitions */
type SerializedInfo = {
  name: string,
  version: string,
  author: string,
  company: string,
};
type SerializedIOSelection = 'Input' | 'Output';
type SerializedVariable = {
  label: string;
  alias: string;
  ioSelection?: SerializedIOSelection;
  attributeName: string;
  navSelections: string[];
  units?: string;

  // These properties aren't used by Xchanger Suite, but are needed for deserialization
  type: EngineeringChecklist.VariableType;
  navigationStrings: string[];
  unitSet: string;
}
type SerializedOperator = (
  '='
  | '!='
  | '>'
  | '>='
  | '<'
  | '<='
  | 'in'
  | '!in'
);
type SerializedRule = {
  id: string;
  lhs: string | undefined;
  op: SerializedOperator;
  rhs: string | undefined;
  // Requirements are either single rule ids or rule ids joined by ' or '
  requirements?: string[];
  note: string;

  // This property isn't used by Xchanger Suite, but are needed for deserialization
  type: EngineeringChecklist.RuleType;
}
interface SerializedEngineeringChecklistData {
  info: SerializedInfo;
  variables: SerializedVariable[];
  rules: SerializedRule[];
}
interface SerializedEngineeringChecklist {
  hash: string;
  version: `${number}.${number}.${number}`;
  data: SerializedEngineeringChecklistData;
}


/* Serialization functions */
// Info
function serializeInfo(info: EngineeringChecklist.Info, user: User | null): SerializedInfo {
  const author = user ? `${user.firstName} ${user.lastName}` : '';
  const company = user?.company ?? '';
  return {
    name: info.name,
    version: info.version,
    author,
    company,
  };
}

// Variables
function serializeVariable(variable: EngineeringChecklist.Variable): SerializedVariable {
  const navSelections: string[] = variable.navSelections.map((selection) => (
    typeof selection === 'string' ? selection : selection.toString()
  ));

  return {
    label: variable.label,
    alias: variable.alias,
    ioSelection: variable.ioSelection,
    attributeName: variable.attributeName,
    navSelections,
    units: variable.unit?.join('/'),

    navigationStrings: variable.navigationStrings,
    type: variable.type,
    unitSet: variable.unitSet,
  };
}
function serializeVariables(variables: EngineeringChecklist.Variable[]): SerializedVariable[] {
  return variables.map(serializeVariable);
}

// Rules
const serializedOperators: Record<EngineeringChecklist.Operator, SerializedOperator> = {
  [EngineeringChecklist.Operator.Equals]: '=',
  [EngineeringChecklist.Operator.NotEqual]: '!=',
  [EngineeringChecklist.Operator.LessThan]: '<',
  [EngineeringChecklist.Operator.GreaterThan]: '>',
  [EngineeringChecklist.Operator.LessThanOrEquals]: '<=',
  [EngineeringChecklist.Operator.GreaterThanOrEquals]: '>=',
  [EngineeringChecklist.Operator.In]: 'in',
  [EngineeringChecklist.Operator.NotIn]: '!in',
}
type RuleIDMap = Map<string, string>;
function serializeRuleDependencies(rule: EngineeringChecklist.Rule, serializedIDs: RuleIDMap): string[] {
  const requirements: string[] = [];
  rule.dependencyGroups.forEach((group) => {
    // Only process dependencies that point to valid rules
    const groupRuleIDs = group.ruleIDs
      .map((id) => serializedIDs.get(id))
      .filter((id) => id !== undefined);
    if (!groupRuleIDs.length) return;

    if (group.type === EngineeringChecklist.DependencyGroupType.All) {
      requirements.push(...groupRuleIDs);
    }
    else requirements.push(groupRuleIDs.join(' or '));
  });

  return requirements;
}
function serializeEnumRule(rule: EngineeringChecklist.EnumRule, serializedIDs: RuleIDMap): SerializedRule {
  return {
    id: serializedIDs.get(rule.id)!,
    type: rule.type,
    lhs: rule.lhs?.alias,
    op: serializedOperators[rule.op],
    rhs: rule.lhs ? rule.rhs?.map((value) => `${rule.lhs!.attributeName}::${value}`).join(', ') : undefined,
    requirements: serializeRuleDependencies(rule, serializedIDs),
    note: rule.note,
  };
}
function serializeStringRule(rule: EngineeringChecklist.StringRule, serializedIDs: RuleIDMap): SerializedRule {
  const rhs = (rule.rhs ?? [])
    .filter((value) => value !== undefined)
    .map((value) => (
      typeof value === 'string' ? `'${value}'` : value.alias
    ))
    .join(', ')
  ;

  return {
    id: serializedIDs.get(rule.id),
    type: rule.type,
    lhs: rule.lhs?.alias,
    op: serializedOperators[rule.op],
    rhs,
    requirements: serializeRuleDependencies(rule, serializedIDs),
    note: rule.note,
  };
}
function serializeNumericRule(rule: EngineeringChecklist.NumericRule, serializedIDs: RuleIDMap): SerializedRule {
  return {
    id: serializedIDs.get(rule.id),
    type: rule.type,
    lhs: createController(rule.lhs, undefined).toString(),
    op: serializedOperators[rule.op],
    rhs: createController(rule.rhs, undefined).toString(),
    requirements: serializeRuleDependencies(rule, serializedIDs),
    note: rule.note,
  };
}
function serializeRules(rules: EngineeringChecklist.Rule[]): SerializedRule[] {
  // Ensure IDs match display labels
  const serializedIDs: RuleIDMap = new Map(
    rules.map((rule, idx) => [rule.id, (idx + 1).toString()])
  );

  return rules.map((rule) => {
    switch(rule.type) {
      case EngineeringChecklist.RuleType.Enum: return serializeEnumRule(rule, serializedIDs);
      case EngineeringChecklist.RuleType.String: return serializeStringRule(rule, serializedIDs);
      case EngineeringChecklist.RuleType.Numeric: return serializeNumericRule(rule, serializedIDs);
    }
  });
}

export async function serializeEngineeringChecklist(
  state: EngineeringChecklistState,
  user: User | null,
): Promise<SerializedEngineeringChecklist> {
  const data: SerializedEngineeringChecklistData = {
    info: serializeInfo(state.info, user),
    variables: serializeVariables(state.variables),
    rules: serializeRules(state.rules),
  };
  const digest = await SHA256(JSON.stringify(data));
  const response = await signHash(digest);
  const hash = response.token;

  return {
    hash,
    version: CURRENT_CHECKLIST_VERSION,
    data,
  };
}


/* Deserialization functions */
const deserializedIOSelections: Record<SerializedIOSelection, EngineeringChecklist.IOSelection> = {
  'Input': EngineeringChecklist.IOSelection.Input,
  'Output': EngineeringChecklist.IOSelection.Output,
};
function deserializeVariable(variable: SerializedVariable): EngineeringChecklist.Variable {
  return {
    id: crypto.randomUUID(),
    type: variable.type,
    label: variable.label,
    alias: variable.alias,
    ioSelection: deserializedIOSelections[variable.ioSelection],
    attributeName: variable.attributeName,
    navSelections: variable.navSelections,
    navigationStrings: variable.navigationStrings,
    unitSet: variable.unitSet,
    unit: variable.units?.split('/'),
  };
}

function deserializeDependencyGroups(
  rule: SerializedRule,
  ruleIDMap: Map<number, string>,
): EngineeringChecklist.DependencyGroup[] {
  const anyDependencyGroups: EngineeringChecklist.DependencyGroup[] = [];
  // Use a single dependency group for "all" group type since they can be merged
  const allDependencyGroupIDs: string[] = [];
  const requirements = rule.requirements ?? [];
  requirements.forEach((requirement) => {
    const splitRequirements = requirement.split(' or ');
    // If there's more than one dependency here, the group type is "any"
    if (splitRequirements.length > 1) {
      anyDependencyGroups.push({
        type: EngineeringChecklist.DependencyGroupType.Any,
        // Decrement id before get since indices start at 1 for serialized rules
        ruleIDs: splitRequirements.map((x) => ruleIDMap.get(Number(x) - 1)!),
      });
    }
    else allDependencyGroupIDs.push(ruleIDMap.get(Number(splitRequirements) - 1)!);
  });

  const allDependencyGroups: EngineeringChecklist.DependencyGroup[] = allDependencyGroupIDs.length ? [{
    type: EngineeringChecklist.DependencyGroupType.All,
    ruleIDs: allDependencyGroupIDs,
  }] : [];

  return [
    ...allDependencyGroups,
    ...anyDependencyGroups,
  ];
}
const deserializedOperators: Record<SerializedOperator, EngineeringChecklist.Operator> = {
  '=': EngineeringChecklist.Operator.Equals,
  '!=': EngineeringChecklist.Operator.NotEqual,
  '>': EngineeringChecklist.Operator.GreaterThan,
  '<': EngineeringChecklist.Operator.LessThan,
  '>=': EngineeringChecklist.Operator.GreaterThanOrEquals,
  '<=': EngineeringChecklist.Operator.LessThanOrEquals,
  'in': EngineeringChecklist.Operator.In,
  '!in': EngineeringChecklist.Operator.NotIn,
};
function deserializeEnumRule(
  rule: SerializedRule,
  aliasMap: Map<string, EngineeringChecklist.Variable>,
  ruleIDMap: Map<number, string>,
  id: string,
): EngineeringChecklist.EnumRule {
  return {
    id,
    type: EngineeringChecklist.RuleType.Enum,
    lhs: aliasMap.get(rule.lhs),
    op: deserializedOperators[rule.op as EngineeringChecklist.EnumRuleOperator],
    rhs: rule.rhs?.split(', ').map((value) => value.split('::')[1]),
    dependencyGroups: deserializeDependencyGroups(rule, ruleIDMap),
    note: rule.note,
  };
}
function deserializeStringRule(
  rule: SerializedRule,
  aliasMap: Map<string, EngineeringChecklist.Variable>,
  ruleIDMap: Map<number, string>,
  id: string,
): EngineeringChecklist.StringRule {
  const rhs: EngineeringChecklist.StringRuleValue[] = rule.rhs
    ?.split(', ')
    .map((value) => {
      if (value[0] === "'" && value[value.length - 1] === "'") {
        return value.slice(1, -1);
      }
      return aliasMap.get(value);
    })
  ;

  return {
    id,
    type: EngineeringChecklist.RuleType.String,
    lhs: aliasMap.get(rule.lhs),
    op: deserializedOperators[rule.op as EngineeringChecklist.StringRuleOperator],
    rhs,
    dependencyGroups: deserializeDependencyGroups(rule, ruleIDMap),
    note: rule.note,
  }
}
function deserializeNumericRule(
  rule: SerializedRule,
  aliasMap: Map<string, EngineeringChecklist.Variable>,
  ruleIDMap: Map<number, string>,
  id: string,
): EngineeringChecklist.NumericRule {
  return {
    id,
    type: EngineeringChecklist.RuleType.Numeric,
    lhs: parseEquation(rule.lhs, aliasMap),
    op: deserializedOperators[rule.op as EngineeringChecklist.NumericRuleOperator],
    rhs: parseEquation(rule.rhs, aliasMap),
    dependencyGroups: deserializeDependencyGroups(rule, ruleIDMap),
    note: rule.note,
  };
}
function deserializeRule(
  rule: SerializedRule,
  variableAliasMap: Map<string, EngineeringChecklist.Variable>,
  ruleIDMap: Map<number, string>,
  idx: number,
): EngineeringChecklist.Rule {
  const id = ruleIDMap.get(idx);
  switch(rule.type) {
    case EngineeringChecklist.RuleType.Enum: return deserializeEnumRule(rule, variableAliasMap, ruleIDMap, id);
    case EngineeringChecklist.RuleType.String: return deserializeStringRule(rule, variableAliasMap, ruleIDMap, id);
    case EngineeringChecklist.RuleType.Numeric: return deserializeNumericRule(rule, variableAliasMap, ruleIDMap, id);
    default: throw new Error(`Unsupported rule type for id ${rule.id}: ${rule.type}`);
  }
}

type DeserializedEngineeringChecklist = {
  info: EngineeringChecklist.Info,
  variables: EngineeringChecklist.Variable[],
  rules: EngineeringChecklist.Rule[],
}
export function deserializeEngineeringChecklist(source: string): DeserializedEngineeringChecklist {
  const checklist = JSON.parse(source) as SerializedEngineeringChecklist;
  const { info, variables, rules } = checklist.data;

  const deserializedVariables = variables.map(deserializeVariable);
  const variableAliasMap = new Map(
    deserializedVariables.map((variable) => [variable.alias, variable])
  );
  // Generate rule IDs
  const ruleIDMap = new Map<number, string>(
    rules.map((_rule, idx) => [idx, crypto.randomUUID()])
  );
  const deserializedRules = rules.map((rule, idx) => (
    deserializeRule(rule, variableAliasMap, ruleIDMap, idx)
  ));

  return {
    info: {
      name: info.name,
      version: info.version,
      author: info.author,
      company: info.company,
    },
    variables: deserializedVariables,
    rules: deserializedRules,
  }
}
