import React from 'react';
import { Role } from '../../types/API';
import useCurrentUser from '../../hooks/useCurrentUser';
import HomeRedirectPage from './HomeRedirectPage';

interface RoleValidatorProps {
  roles: Role[] | Set<Role>;
  children: React.ReactNode;
}

const RoleValidator: React.FC<RoleValidatorProps> = ({ roles, children }) => {
  const user = useCurrentUser();
  const validRoles = Array.isArray(roles) ? new Set(roles) : roles;

  if (!user?.role || !validRoles.has(user.role)) {
    return (
      <HomeRedirectPage title="Page Not Accessible">
        You do not have sufficient permissions to access this page.
      </HomeRedirectPage>
    );
  }

  return children;
};

export default RoleValidator;
