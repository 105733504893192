import React from 'react';
import Typography, { TypographyColor } from './Typography';

interface LazyTabProps {
  render: () => React.ReactNode;
}
interface StaticTabProps {
  element: React.ReactNode;
}
export type TabProps = {
  label: string;
  icon?: React.ElementType<{ className?: string }>;
} & (LazyTabProps | StaticTabProps);

interface TabsProps {
  tabs: TabProps[];
  activeTabIdx: number;
  onTabChange: (idx: number) => any;
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTabIdx, onTabChange }) => {
  const activeTab = tabs[activeTabIdx];

  const content = 'render' in activeTab ? activeTab.render() : activeTab.element;

  const tabComponents = tabs.map((tab, idx) => {
    const active = idx === activeTabIdx;
    let className = 'flex gap-0.5 items-center min-w-12 max-w-max px-2 py-1 select-none transition-colors ';
    if (active) className += 'bg-white';
    else className += 'cursor-pointer bg-slate-200 hover:bg-slate-100';

    const color: TypographyColor = idx === activeTabIdx ? 'black' : 'lightSlate';

    const icon = tab.icon ? <tab.icon className="size-5 text-slate-700" /> : undefined;

    return (
      <div className={className} onClick={() => { if (idx !== activeTabIdx) onTabChange(idx) }}>
        {icon}
        <Typography
          bold={active}
          color={color}
          variant="body2"
          className="truncate"
        >
          {tab.label}
        </Typography>
      </div>
    );
  });

  return (
    <div className="w-full h-full flex flex-col overflow-hidden">
      <div className="w-full flex flex-row bg-slate-300 px-1 pt-1">
        {tabComponents}
      </div>
      <div className="w-full h-full overflow-auto bg-white">
        {content}
      </div>
    </div>
  );
};

export default Tabs;
