import React from 'react';
import { Block, BlockProps, BlockType } from '../types';
import BinaryOperator from './BinaryOperator';
import Division from './Division';
import Exponentiation from './Exponentiation';
import Function from './Function';
import Invalid from './Invalid';
import Parentheses from './Parentheses';
import Placeholder from './Placeholder';
import SymbolGroup from './SymbolGroup';
import DataModelVariable from './DataModelVariable';
import { useEquationSelector } from '../redux/store';
import { BlockPlacement, FocusType } from '../redux/reducer';
import Cursor from '../Cursor';
import Negative from './Negative';

const blockTypeComponents: Record<BlockType, React.ElementType<BlockProps>> = {
  [BlockType.BinaryOperator]: BinaryOperator,
  [BlockType.DataModelVariable]: DataModelVariable,
  [BlockType.Division]: Division,
  [BlockType.Exponentiation]: Exponentiation,
  [BlockType.Function]: Function,
  [BlockType.InvalidFunctionOrVariable]: Invalid,
  [BlockType.MismatchedParenthesis]: Invalid,
  [BlockType.Negative]: Negative,
  [BlockType.Parentheses]: Parentheses,
  [BlockType.Placeholder]: Placeholder,
  [BlockType.SymbolGroup]: SymbolGroup,
  [BlockType.Unparsable]: Invalid,
};

const blocksOverridingCursorPlacement = new Set<BlockType>([
  BlockType.Division,
  BlockType.Exponentiation,
]);

const Block: React.FC<Omit<BlockProps, 'cursorBefore'>> = ({ block }) => {
  const focus = useEquationSelector((state) => state.currentFocus);
  const isFocusedBefore = (
    focus.type === FocusType.Block
    && focus.target?.id === block.id
    && focus.placement === BlockPlacement.Before
    && !blocksOverridingCursorPlacement.has(block.type)
  );

  const BlockComponent = blockTypeComponents[block.type];

  return (
    <div className="flex flex-row items-stretch">
      {isFocusedBefore && <Cursor />}
      <BlockComponent block={block} />
    </div>
  );
};

export default Block;
