import { useState } from 'react'
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SearchBox = ({ options, currentOption, onOptionChange }) => {
    const [query, setQuery] = useState('');
    const [option, setOption] = useState(currentOption);

    const onChangeHandler = (newOption) => {
      setOption(newOption);
      onOptionChange(newOption);
    };  
  
    const filteredOptions = query === '' ? options : options.filter((op) => {
      return op.toUpperCase().includes(query.toUpperCase())
    })
  
    return (
      <Combobox value={option} onChange={onChangeHandler}>
        <div className="relative">
          <Combobox.Input onChange={(e) => setQuery(e.target.value)}
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-black shadow-sm ring-2 ring-inset ring-blue-400 focus:ring-4 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"/>
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="h-5 w-5 text-slate-600" aria-hidden="true" />
          </Combobox.Button>
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map((value, idx) => (
              <Combobox.Option key={idx} value={value} 
                className={({ active }) => classNames('relative cursor-default select-none py-2 pl-3 pr-9',
                  active ? 'bg-slate-600 text-white' : 'text-black')}>
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{value}</span>
                    {selected && (
                      <span
                        className={classNames('absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-slate-600')}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </div>
      </Combobox>
    )
  }

  export default SearchBox;