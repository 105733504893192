import { AsyncThunkPayloadCreator, createAsyncThunk as createAsyncThunkBase } from '@reduxjs/toolkit';
import { Dispatch, RootState } from "./store"

export interface AsyncThunkConfig {
  state: RootState;
  dispatch: Dispatch;

  // Unused for now, if we need them then fill in the types here
  extra: unknown
  rejectValue: unknown
  serializedErrorType: unknown
  pendingMeta: unknown
  fulfilledMeta: unknown
  rejectedMeta: unknown
}

/**
 * Wrapper for createAsyncThunk that automatically handles errors by rejecting -
 * this is different from the default behavior which serializes them and deletes
 * all properties not in the default error class
 */
export function createAsyncThunk<Returned, ThunkArg = void>(
  type: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, AsyncThunkConfig>,
) {
  return createAsyncThunkBase<Returned, ThunkArg, AsyncThunkConfig>(
    type,
    async (arg, thunkAPI) => {
      try {
        return await payloadCreator(arg, thunkAPI) as Returned;
      }
      catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
  );
}
