import { createApi } from '@reduxjs/toolkit/query/react';
import { GetUnitSetValuesAPIResponse } from '../types/API';
import { UnitConversionFactors, UnitSet } from '../types/units';
import makeRequestBaseQuery from './makeRequestBaseQuery';

export type UnitSetValues = Record<string, UnitConversionFactors>;

const unitsAPI = createApi({
  reducerPath: 'unitsAPI',
  baseQuery: makeRequestBaseQuery({ baseUrl: `/api/v1/units/`}),
  endpoints: (builder) => ({
    getUnitSet: builder.query<UnitSetValues, UnitSet>({
      query: (unitSet) => `unit-sets/${unitSet}/details`,
      transformResponse: (response: GetUnitSetValuesAPIResponse) => {
        const values: UnitSetValues = {};
        response.forEach(({ symbol, valueSI, offset }) => {
          values[symbol] = { valueSI: valueSI, offset: offset };
        });
        return values;
      },
    })
  }),
  // Never remove data from cache
  keepUnusedDataFor: Infinity,
});

export default unitsAPI;
export const { useGetUnitSetQuery } = unitsAPI;
