import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FolderIcon, FolderOpenIcon, DocumentIcon } from '@heroicons/react/24/outline'

const SidebarItem = ({ node }) => {
  const [open, setOpen] = useState(node.children && node.children.length < 5);
  const location = useLocation();
  const path = location.pathname;
  const endpoint = path.substring(path.lastIndexOf('/') + 1);
  const { children } = node;
  if (children && children.length > 0) {
    return (
      <div className="mt-1 text-sm font-light hover:cursor-pointer">
        <div className="flex items-center hover:bg-slate-600 hover:text-white hover:font-medium rounded-md" onClick={() => setOpen(!open)}>
          {open? <FolderOpenIcon className="w-6 h-6" /> : <FolderIcon className="w-6 h-6" />}
          <span className={open? 'font-bold':'font-light'}>{node.label}</span>
        </div>
        <div className={open? 'mx-5' : 'hidden'}>
          {children.map((child, index) => (
              <SidebarItem key={index} node={child} />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <Link to={node.endpoint}>
        <div className="flex hover:bg-slate-600 hover:text-white hover:font-medium rounded-md">
          <DocumentIcon className="w-4 h-4" />
          <span className={endpoint === node.endpoint? 'font-bold':'font-light'}>{node.label}</span>
        </div>
      </Link>
    );
  }
};

export default SidebarItem;
