import React from 'react';
import Material from '../../../types/materials';
import Chip from '../../common/Chip';
import Typography, { TypographyVariant } from '../../common/Typography';

interface MaterialLabelProps {
  material: Material.Overview | Material.Details;
  size?: 'medium' | 'small';
}

const MaterialLabel: React.FC<MaterialLabelProps> = ({ material, size = 'medium' }) => {
  const codeChip = material.code && <Chip label={material.code} size={size} />;
  const names = material.names.join(', ');

  const heightClass = size === 'medium' ? 'h-9' : 'h-7';
  const typographyVariant: TypographyVariant = size === 'medium' ? 'body1' : 'body2';

  const properties: string[] = [];
  if (material.form) properties.push(material.form);
  if (material.grade) properties.push(`(${material.grade})`);

  const labelText = properties.length ? `${names} - ${properties.join(' ')}` : names;

  return (
    <div className={`${heightClass} flex flex-row items-center gap-1 overflow-hidden`}>
      {codeChip}
      <Typography color="inherit" variant={typographyVariant} className="truncate">{labelText}</Typography>
    </div>
  );
};

export default MaterialLabel;
