import { ResizableGridColumns } from '../../../common/ResizableGrid';

const rulesColumns: ResizableGridColumns = [
  {
    label: 'Number',
    minWidth: 84,
  },
  {
    label: 'Type',
    minWidth: 58,
  },
  {
    label: 'LHS',
    minWidth: 100,
  },
  {
    label: 'Operator',
    minWidth: 90,
    defaultWidth: 90,
  },
  {
    label: 'RHS',
    minWidth: 120,
  },
  {
    label: 'Requirements',
    minWidth: 300,
    defaultWidth: 480,
  },
  {
    label: 'Note',
  },
];

export default rulesColumns;
