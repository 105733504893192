import React from 'react';
import { ChevronDownIcon, ChevronRightIcon, ClipboardDocumentListIcon, PlusIcon } from '@heroicons/react/24/outline'

import { EngineeringChecklist } from '../../../../types/engineeringChecklist';
import { useDispatch } from '../../../../redux/store';
import { engineeringChecklistSlice } from '../../../../redux/tools/engineeringChecklist';
import Typography from '../../../common/Typography';
import Tooltip from '../../../common/Tooltip';
import { getVariableTooltipLabel } from '../../../../utils/strings';

interface DataModelTreeItemProps {
  node: EngineeringChecklist.DataModelNode;
  path: string[];
  depth?: number;
}

const DataModelTreeItem: React.FC<DataModelTreeItemProps> = ({
  node,
  path,
  depth = 0,
}) => {
  const dispatch = useDispatch();
  let { label, attributeName, open, children } = node;

  const [hovering, setHovering] = React.useState(false);

  const isAttributes = label === 'attributes';
  const isVariable = !children.length;

  const handleClick = () => {
    if (isVariable) dispatch(engineeringChecklistSlice.actions.addVariable(node));
    else dispatch(engineeringChecklistSlice.actions.toggleDataModelNodeOpen(path));
  };

  let Icon: React.ElementType<{ className?: string }>;
  if (isVariable) {
    if (hovering) Icon = PlusIcon;
    else Icon = ClipboardDocumentListIcon;
  }
  else {
    if (open) Icon = ChevronDownIcon;
    else Icon = ChevronRightIcon;
  }

  // Display attributes directly instead of their parent, so don't increase depth
  const childrenDepth = isAttributes ? depth : depth + 1;
  const childElements = (open || isAttributes)
    ? children
      .filter((child) => child.type !== EngineeringChecklist.VariableType.Empty)
      .map((child) => (
        <DataModelTreeItem
          node={child}
          depth={childrenDepth}
          path={[...path, child.label]}
          key={child.label}
        />
      ))
    : undefined;

  const nodeElement = isAttributes ? undefined : (
    <div
      className="flex items-center gap-1 py-[2px] pr-2 hover:cursor-pointer hover:bg-sky-200"
      style={{ paddingLeft: `${12 * depth + 4}px`}}
      onClick={handleClick}
      onMouseEnter={isVariable ? () => setHovering(true) : undefined}
      onMouseLeave={isVariable ? () => setHovering(false) : undefined}
    >
      <Icon className="size-5 shrink-0 text-slate-700" />
      <Typography variant="body2" bold={open} className="select-none truncate">{label}</Typography>
    </div>
  );
  const wrappedNodeElement = attributeName ? (
    <Tooltip label={getVariableTooltipLabel(node)} width="full">
      {nodeElement}
    </Tooltip>
  ) : nodeElement;

  return (
    <>
      {wrappedNodeElement}
      {childElements}
    </>
  );
};

export default DataModelTreeItem;
