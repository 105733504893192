import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import store from './redux/store';

import { UnitsProvider } from './context/UnitsContext';
import { RefPropProvider } from './context/RefPropContext';
import { DewPointCalculatorProvider } from './context/DewPointCalculatorContext';
import router from './components/router';

const container = document.getElementById("root");

const root = createRoot(container);
root.render(
  <Provider store={store}>
    <UnitsProvider>
      <RefPropProvider>
        <DewPointCalculatorProvider>
          <RouterProvider router={router} />
        </DewPointCalculatorProvider>
      </RefPropProvider>
    </UnitsProvider>
  </Provider>
);
