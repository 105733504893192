import React from 'react';
import { useGetAllMaterialsQuery } from '../../../redux/materialsAPI';
import Material from '../../../types/materials';

// Takes care of data fetching and manages available filter options
export default function useMaterials() {
  const getAllMaterialsQuery = useGetAllMaterialsQuery();
  const { currentData } = getAllMaterialsQuery;

  // Memoize filter options by getting all possible values of each property
  const filterOptions = React.useMemo<Material.FilterOptions | undefined>(() => {
    const groups = new Set<string>();
    const compositionElements = new Set<string>();
    const forms = new Set<string>();
    const designationTypes = new Set<string>();
    const types = new Set<string>();
    const tempers = new Set<string>();

    // Add empty placeholders where needed to allow deselecting
    compositionElements.add('');
    forms.add('');
    designationTypes.add('');
    types.add('');
    tempers.add('');

    // Populate filter options by enumerating possible values from all materials
    if (currentData) {
      for (const material of currentData) {
        material.groups.forEach((group) => groups.add(group));
        if (material.composition) {
          // prob have to change this ig
          Object.keys(material.composition).forEach((element) => (
            compositionElements.add(element)
          ));
        }
        if (material.form) forms.add(material.form);
        material.designations.forEach((designation) => designationTypes.add(designation.type));
        if (material.type) types.add(material.type);
        if (material.temper) tempers.add(material.temper);
      }
    }

    const filterOptions: Material.FilterOptions = {
      groups: Array.from(groups).sort(),
      compositionElements: Array.from(compositionElements).sort(),
      forms: Array.from(forms).sort(),
      types: Array.from(types).sort(),
      tempers: Array.from(tempers).sort(),
      designationTypes: Array.from(designationTypes).sort(),
    }
    return filterOptions;
  }, [currentData]);

  const materialIDMap = React.useMemo(() => {
    const materialsIDMap = new Map<number, Material.Overview>();
    for (const material of currentData ?? []) materialsIDMap.set(material.id, material);
    return materialsIDMap;
  }, [currentData]);

  return {
    filterOptions,
    getAllMaterialsQuery,
    materials: currentData ?? [],
    materialIDMap,
  };
}
