import { createApi } from '@reduxjs/toolkit/query/react';
import { Claim, UsersAPI } from '../types/API';
import makeRequestBaseQuery from './makeRequestBaseQuery';

export const GET_USERS_RESULTS_PER_PAGE = 50;

const userQueriesAPI = createApi({
  reducerPath: 'userQueriesAPI',
  baseQuery: makeRequestBaseQuery({ baseUrl: '/api/v1/users/' }),
  endpoints: (builder) => ({
    getUsers: builder.query<UsersAPI.GetUsersResponse, UsersAPI.GetUsersRequestParams>({
      query: (params) => ({
        url: '',
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    getClaims: builder.query<Claim[], void>({
      query: () => 'claims',
      transformResponse: (response: Claim[]) => (
        response.sort((a, b) => a.localeCompare(b))
      ),
      // Never remove data from cache
      keepUnusedDataFor: Infinity,
    }),
  }),
});

export default userQueriesAPI;
export const { useGetUsersQuery, useGetClaimsQuery } = userQueriesAPI;
