import React from 'react';
import { DataSource } from './dataSources';
import Data from './types';
import DefaultMap from '../DefaultMap';
import { CartesianGrid, Label, ReferenceLine, XAxis, YAxis } from 'recharts';
import { toSignificantFigures } from '../math';
import { SynchronousUnitConversionFunction } from '../../hooks/useUnitConversions';

interface DataAggregatorResult {
  components: React.ReactNode[];
  data: Data.Properties[];
}

export default class DataAggregator {
  #xDomain: Data.Domain;
  #sources: DataSource[];
  #xUnits?: Data.Unit;
  #yUnits?: Data.Unit;
  #convert?: SynchronousUnitConversionFunction;
  #additionalPointValueCalculationXs: number[];

  constructor(props: {
    xDomain: Data.Domain;
    dataSources?: DataSource[];
    xUnits?: Data.Unit;
    yUnits?: Data.Unit;
    convert?: SynchronousUnitConversionFunction;
    additionalPointValueCalculationXs?: number[];
  }) {
    this.#xDomain = props.xDomain;

    this.#xUnits = props.xUnits;
    this.#yUnits = props.yUnits;
    this.#convert = props.convert;
    this.#additionalPointValueCalculationXs = props.additionalPointValueCalculationXs ?? [];

    this.#sources = [];
    if (props.dataSources) {
      for (const source of props.dataSources) this.addSource(source);
    }
  }

  get sources() { return this.#sources; }
  addSource(source: DataSource): this {
    this.#sources.push(source);
    source.index = this.#sources.length - 1;
    source.additionalXs = this.#additionalPointValueCalculationXs;
    source.targetXUnits = this.#xUnits;
    source.targetYUnits = this.#yUnits;
    source.convert = this.#convert;

    return this;
  }

  protected static getGridTickFrequency(range: Data.Range): number {
    // Artificially make range smaller than it is to avoid situations where
    // there are only one or two ticks
    let rangeValue = Math.abs(range.max - range.min) * 0.5;
    const nearestLowerPowerOf10 = Math.floor(Math.log10(rangeValue));
    return Math.pow(10, nearestLowerPowerOf10);
  }

  protected static calculateAxisTicks(range: Data.Range): number[] {
    const tickFrequency = DataAggregator.getGridTickFrequency(range);
    // Calculate minimum ticks by rounding up to the nearest multiple of frequency
    // TODO: maybe rounding down is better?
    const minTickValue = Math.ceil(range.min / tickFrequency) * tickFrequency;
    let currentTick = minTickValue;
    const ticks: number[] = [];

    // Add small tolerance to ensure max shows up if it should be a grid tick
    const maxTickValue = range.max + 0.01 * Math.abs(range.max);
    while (currentTick <= maxTickValue) {
      ticks.push(currentTick);
      currentTick += tickFrequency;
    }
    return ticks;
  }

  protected static formatTick(value: number): string {
    value = toSignificantFigures(value, 5);
    const abs = Math.abs(value);
    // If value is sufficiently large or small, use exponential notation
    if (abs >= 1e5 || (abs !== 0 && abs < 1e-4)) return value.toExponential(1);
    return value.toString();
  }

  generate(): DataAggregatorResult {
    const dataSourceComponents: React.ReactNode[] = [];
    // values - maps x values to values from each data source
    const values = new DefaultMap<number, Omit<Data.Properties, 'x'>>(Object);

    // Iterate over sources, getting the components and values for each
    let minY = Number.POSITIVE_INFINITY;
    let maxY = Number.NEGATIVE_INFINITY;
    for (const source of this.#sources) {
      const { dataPoints, components: sourceComponents } = source.outputs(this.#xDomain);
      dataSourceComponents.push(...sourceComponents);

      for (const { x, y, value } of dataPoints) {
        if (x < this.#xDomain.min || x > this.#xDomain.max) continue;

        if (y < minY) minY = y;
        if (y > maxY) maxY = y;

        const valuesAtX = values.get(x);
        const currentValueForLabel = valuesAtX[source.label];
        if (currentValueForLabel) Object.assign(currentValueForLabel, value);
        else valuesAtX[source.label] = value;
      }
    }

    // Manually add values for additionalPointValueCalculationXs and domain min/max
    const additionalXsToInclude = [
      ...this.#additionalPointValueCalculationXs,
      this.#xDomain.min,
      this.#xDomain.max,
    ];
    for (const x of additionalXsToInclude) {
      if (!values.has(x) && x >= this.#xDomain.min && x <= this.#xDomain.max) {
        values.set(x, {});
      }
    }

    // Iterate over each x value and calculate missing point values for missing sources
    const sourceLabels = new Map(this.#sources.map((source) => [source, source.label]));
    for (const [x, dataSourceValues] of values.entries()) {
      for (const [source, label] of sourceLabels.entries()) {
        if (!(label in dataSourceValues)) {
          const { y, value } = source.calculatePointValue(x);
          if (y < minY) minY = y;
          if (y > maxY) maxY = y;
          dataSourceValues[label] = value;
          const currentValueForLabel = dataSourceValues[source.label];
          if (currentValueForLabel) Object.assign(currentValueForLabel, value);
          else dataSourceValues[source.label] = value;
        }
      }
    }

    const sortedValues = Array.from(values.entries())
      .sort(([valueA], [valueB]) => valueA - valueB);
    const data: Data.Properties[] = sortedValues.map(([value, properties]) => ({
      x: value,
      ...properties,
    }));

    // Adjust minY and maxY to always show y axis
    if (minY > 0) minY = 0;
    if (maxY < 0) maxY = 0;

    // Determine axis and grid ticks to use
    const xTicks = DataAggregator.calculateAxisTicks(this.#xDomain);
    const yTicks = DataAggregator.calculateAxisTicks({ min: minY, max: maxY });

    // Create components for the graph overall - axes, a grid, and origin reference lines
    const components: React.ReactNode[] = [];

    const xAxisLabel = this.#xUnits && (
      <Label
        value={`${this.#xUnits.name ?? this.#xUnits.unitSet} (${this.#xUnits.unit})`}
        position="insideBottom"
      />
    );
    const xAxisHeight = xAxisLabel ? 48 : 30;
    const yAxisLabel = this.#yUnits && (
      <Label
        angle={270}
        value={`${this.#yUnits.name ?? this.#yUnits.unitSet} (${this.#yUnits.unit})`}
        position="insideLeft"
        style={{ textAnchor: 'middle' }}
      />
    );
    const yAxisWidth = yAxisLabel ? 78 : 60;

    // Add components to illustrate scale
    components.push(
      <XAxis
        type="number"
        dataKey="x"
        height={xAxisHeight}
        axisLine={false}
        ticks={xTicks}
        domain={[this.#xDomain.min, this.#xDomain.max]}
        tickFormatter={DataAggregator.formatTick}
        allowDataOverflow
        key="x-axis"
      >
        {xAxisLabel}
      </XAxis>
    );
    components.push(
      <YAxis
        type="number"
        width={yAxisWidth}
        axisLine={false}
        ticks={yTicks}
        domain={[minY, maxY]}
        tickFormatter={DataAggregator.formatTick}
        key="y-axis"
      >
        {yAxisLabel}
      </YAxis>
    );
    components.push(
      <CartesianGrid
        verticalValues={xTicks}
        horizontalValues={yTicks}
        key="cartesian-grid"
      />
    );

    // Add components emphasizing the x and y axis origins
    components.push(<ReferenceLine y={0} stroke="#000000" key="x-axis-origin" />);
    components.push(<ReferenceLine x={0} stroke="#000000" key="y-axis-origin" />);

    // Add components for data sources last so that they render on top
    components.push(...dataSourceComponents);

    return { data, components };
  }
}
