import React from 'react';
import Typography from '../Typography';

type ImageSize = 'sm' | 'md' | 'lg';
interface ImageProps {
  src: any;
  size?: ImageSize;
  caption?: string;
}

const sizeClasses: Record<ImageSize, string> = {
  sm: 'w-48',
  md: 'w-96',
  lg: 'w-full max-w-128',
};

const Image: React.FC<ImageProps> = ({ size = 'lg', src, caption }) => {
  const sizeClass = sizeClasses[size];
  const captionComponent = caption && (
    <Typography
      className="mt-1 text-center"
      variant="caption"
      color="slate"
    >
      {caption}
    </Typography>
  );

  return (
    <div className="w-full px-4 mt-1 flex justify-center ">
      <div className={`${sizeClass} flex flex-col items-center`}>
        <img className="w-full h-auto mx-2" src={src} />
        {captionComponent}
      </div>
    </div>
  );
};

export default Image;
