import { ArrowPathIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import Typography from './Typography';
import Button from './Button';

interface FetchErrorScreenProps {
  title: string;
  children: string;
  onRetry: () => any;
}

const FetchErrorScreen: React.FC<FetchErrorScreenProps> = ({ title, children, onRetry}) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <div className="w-full h-full bg-white flex flex-col items-center justify-center">
      <ExclamationTriangleIcon className="size-9 text-red-500" />
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1" color="slate">
        {children}
      </Typography>
      <div className="mt-2">
        <Button
          label="Retry"
          color="primary"
          iconLeft={<ArrowPathIcon className="size-6" />}
          loading={loading}
          onClick={() => { onRetry(); setLoading(true); }}
        />
      </div>
    </div>

  );
};

export default FetchErrorScreen;
