import { addListener, createListenerMiddleware } from "@reduxjs/toolkit";

import store, { Dispatch, RootState } from "./store";
import { set as setError } from './errors';
import { isDispatchableLoggedError } from './errorMiddleware';

const listenerMiddleware = createListenerMiddleware({
  onError: (e) => {
    if (isDispatchableLoggedError(e)) store.dispatch(setError(e));
  },
});
const startAppListening = listenerMiddleware.startListening.withTypes<RootState, Dispatch>();
const addAppListener = addListener.withTypes<RootState, Dispatch>();

export {
  listenerMiddleware,
  startAppListening as startListening,
  addAppListener as addListener,
};
