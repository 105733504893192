import React from 'react';
import { useEquationDispatch, useEquationSelector } from './redux/store';
import { FocusType, deleteSymbolAtCursor, insertAtCursor, move, unfocus } from './redux/reducer';
import { EquationEditorContext } from './EquationEditor';
import { useDetectClickOutside } from '../../../hooks/useDetectClickOutside';

interface EquationInputHandlerProps {
  children: React.ReactNode;
}

const EquationInputHandler: React.FC<EquationInputHandlerProps> = ({ children }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { variables } = React.useContext(EquationEditorContext);
  const dispatch = useEquationDispatch();
  const hasFocus = useEquationSelector((state) => state.currentFocus.type !== FocusType.None);

  useDetectClickOutside(() => {
    dispatch(unfocus());
  }, [containerRef], { active: hasFocus });

  React.useEffect(() => {
    // Only add listener while focused
    if (!hasFocus) return;

    // Handle keyboard inputs
    const handleKeyDownEvent = (e: KeyboardEvent) => {
      e.preventDefault();

      // Movement
      if (e.key === 'ArrowUp') dispatch(move({ direction: 'up', variables }));
      else if (e.key === 'ArrowLeft') dispatch(move({ direction: 'left', variables }));
      else if (e.key === 'ArrowDown') dispatch(move({ direction: 'down', variables }));
      else if (e.key === 'ArrowRight') dispatch(move({ direction: 'right', variables }));

      // Change focus
      else if (e.key === 'Escape') dispatch(unfocus());

      // Handle deletions
      else if (e.key === 'Backspace' || e.key === 'Delete') {
        dispatch(deleteSymbolAtCursor({ key: e.key, variables }));
      }

      // Handle insertions
      else dispatch(insertAtCursor({ key: e.key, variables }));

      // TODO: propagate inputs that aren't supported by the equation editor
    };
    document.addEventListener('keydown', handleKeyDownEvent);

    return () => document.removeEventListener('keydown', handleKeyDownEvent);
  }, [hasFocus]);

  const borderColorClass = hasFocus ? 'border-sky' : 'border-sky-200 hover:border-sky-400';

  return (
    <div
      className={`${borderColorClass} flex items-center h-min w-64 w-min font-math bg-white p-1 text-xl leading-tight border-2 rounded-md transition-colors`}
      ref={containerRef}
    >
      {children}
    </div>
  );
};

export default EquationInputHandler;
