import { Section, SectionWithSubsections } from './types';

export function hasSubsections(section: Section): section is SectionWithSubsections {
  return 'subsections' in section;
}

interface IterateSectionsResult {
  section: Section;
  isSubsection: boolean;
}
export function* iterateSections(sections: Section[], isSubsection = false): Generator<IterateSectionsResult> {
  for (const section of sections) {
    yield {
      section,
      isSubsection,
    };

    if (hasSubsections(section)) {
      yield* iterateSections(section.subsections, true);
    }
  }
}
