import React from 'react';

import { OperatorSymbolCharacter, Symbol, SymbolType } from './types';
import { useEquationDispatch, useEquationSelector } from './redux/store';
import Cursor from './Cursor';
import { FocusType, focus } from './redux/reducer';
import { createSymbolFocus } from './utils';

function getOperatorDisplayString(operator: OperatorSymbolCharacter): string {
  return operator === '*' ? '×' : operator;
}

interface SymbolProps {
  symbol: Symbol;
  style?: React.CSSProperties;
}

const Symbol: React.FC<SymbolProps> = ({ symbol, style }) => {
  const hitboxRef = React.useRef<HTMLDivElement>();
  const dispatch = useEquationDispatch();
  const currentFocus = useEquationSelector((state) => state.currentFocus);
  const hasFocus = (
    currentFocus.type === FocusType.Symbol
    && currentFocus.target.id === symbol.id
  );

  let symbolElement: React.ReactElement;
  switch (symbol.type) {
    case SymbolType.Placeholder:
      symbolElement = <div className="w-[0.6em] h-[1em] m-px border-[1px] border-dashed border-sky-300" style={style} />
      break;
    case SymbolType.Digit:
      symbolElement = <div style={style}>{symbol.character}</div>;
      break;
    case SymbolType.Character:
      symbolElement = (
        <div className={symbol.nonItalic ? '' : 'italic'} style={style}>
          {symbol.character}
        </div>
      );
      break;
    case SymbolType.Operator:
      symbolElement = <div className="px-1" style={style}>{getOperatorDisplayString(symbol.character)}</div>
      break;
    case SymbolType.Parenthesis:
      // Use sans-serif font for parens as they're super chunky in times new roman
      symbolElement = <div className="font-thin font-sans" style={style}>{symbol.character}</div>
      break;
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.button === 0) dispatch(focus(createSymbolFocus(symbol)));
  };

  // Special handling for placeholder with cursor, as the cursor should be in the middle of the placeholder box
  if (hasFocus && symbol.type === SymbolType.Placeholder) {
    return (
      <div className="relative h-full cursor-text" onMouseDown={handleMouseDown} ref={hitboxRef}>
        {symbolElement}
        <div className="absolute h-full w-full inset-0 py-[3px] flex items-stretch justify-center">
          <Cursor />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row items-stretch cursor-text" onMouseDown={handleMouseDown} ref={hitboxRef}>
      {symbolElement}
      {hasFocus && <Cursor />}
    </div>
  );
};

export default Symbol;
