import React from 'react';
import { Dot, DotProps } from 'recharts';
import { HTRIBlue } from '../../../tailwind.config';

type AdditionalXDotProperties = {
  additionalXs?: Set<number>;
  payload?: { x: number };
  fallback?: (props: AdditionalXDotProperties) => React.ReactNode;
  active?: boolean;
} & DotProps;

const AdditionalXDot: React.FC<AdditionalXDotProperties> = (props) => {
  if (props.additionalXs?.has(props.payload?.x)) {
    if (props.active) {
      return <Dot {...props} r={5} stroke="#ffb008" fill={HTRIBlue} strokeDasharray={undefined} />;
    }
    return <Dot {...props} r={5} stroke={HTRIBlue} fill="#ffb008" strokeDasharray={undefined} />;
  }
  if (props.fallback) return props.fallback(props);
  if (props.active) return <Dot {...props} />;
  return undefined;
};

export default AdditionalXDot;
