import Material from './materials';

// intermediate types
export interface RawUnitSetValue {
  symbol: string;
  valueSI: number;
  offset: number;
}

export enum Role {
  User = 'user',
  Admin = 'admin',
  Staff = 'staff',
  Rep = 'rep',
  Officer = 'officer',
}

export type Claim = (
  'checklists'
  | 'licensing'
  | 'dew-points'
  | 'steam-tables'
  | 'materials'
);

export type UserApprovalStatus = (
  'pending'
  | 'approved'
  | 'denied'
  | 'disabled'
);

export interface RawUser {
  id: string;
  email: string;
  role: Role;
  firstName: string;
  lastName: string;
  companyName: string;
  claims: Claim[];
  iat: number;
  exp: number;
}

export interface RawDetailedUser {
  id: string;
  email: string;
  role: Role;
  firstName: string;
  lastName: string;
  companyName: string;
  country: string;
  resetPassword: boolean;
  claims: Claim[];
  approvedBy?: string;
  approvedDate?: string;
  status: UserApprovalStatus;
  companyCardCode?: string;
  personCardCode?: string;
  city?: string;
  courtesyTitle?: string;
  gender?: string;
  jobTitle?: string;
  phone?: string;
  regionState?: string;
  zipCode?: string;
}

enum Phase {
  Liquid = 'Liquid',
  Gas = 'Gas',
};

export interface PhaseResult {
  Phase: Phase;
  Fraction: number;
  Density: number;
  Viscosity: number;
  HeatCapacity: number;
  ThermalConductivity: number;
  Enthalpy: number;
  Entropy: number;
  Composition: Record<string, number>;
}

export interface PTRangeResult {
  Basis: 'mass' | 'molar';
  Temperature: number;
  Pressure: number;
  SurfaceTension: null // TODO: can this take other values?
  Phases: PhaseResult[];
}

export interface Error {
  message: string;
}

/* Expected format of bad request responses */
export interface BadRequestResponse {
  errors: Error[];
}

// units endpoint
export type GetUnitSetValuesAPIResponse = RawUnitSetValue[];

// users endpoint
export namespace UsersAPI {
  export interface CreateAccountAPIRequestBody {
    email: string;
    password: string;
    role: string;
    firstName: string;
    lastName: string;
    country: string;
    companyName: string;
  }

  export interface SignInAPIRequestBody {
    email: string;
    password: string;
  }
  export interface SignInAPIResponse {
    currentUser: RawUser | null;
  }

  export interface GetCurrentUserAPIResponse {
    currentUser: RawUser | null;
  }

  export interface PasswordUpdateAPIRequestBody {
    email: string;
    password: string;
    newPassword: string;
  }

  export interface SignHashAPIRequestBody {
    digest: string;
  }
  export interface SignHashAPIResponse {
    decoded: {
      email: string;
      timeStamp: number;
      hash: string;
    };
    token: string;
  }

  export interface UpdateUserStatusRequestBody {
    status: UserApprovalStatus;
  }

  export type SetUserClaimsRequestBody = Claim[];

  export interface GetUsersRequestParams {
    count?: number;
    offset?: number;
    email?: string;
    status?: UserApprovalStatus;
    role?: Role;
    companyName?: string;
    city?: string;
    regionState?: string;
    country?: string;
    personCardCode?: string;
    companyCardCode?: string;
    resetPassword?: string;
  }
  export interface GetUsersResponse {
    total: number;
    users: RawDetailedUser[];
  }

  export interface RequestPasswordResetBody {
    email: string;
  }
  export interface ResetPasswordBody {
    email: string;
    code: string;
    newPassword: string;
  }
}

// iapws-97 endpoint
export interface SolvePTRangeRequestBody {
  MinPressure: number;
  MaxPressure: number;
  MinTemperature: number;
  MaxTemperature: number;
  PressurePoints: number;
  TemperaturePoints: number;
}

export type SolvePTRangeResponse = PTRangeResult[];

// htricalc endpoint
export namespace HTRICalcAPI {
  // Intermediate types
  type RawHTRIUnitType = 'single_unit' | 'multiunit';

  interface RawHTRIUnitConversion {
    name: string;
    valueSI: number;
    offset: number;
  }

  // Requests and responses
  interface GetHTRIUnitSetResponseBase {
    type: RawHTRIUnitType;
    name: string;
    numerator: string[];
  }
  interface GetHTRISingleUnitSetResponse extends GetHTRIUnitSetResponseBase {
    type: 'single_unit';
    denominator: null;
  }
  interface GetHTRIMultiUnitSetResponse extends GetHTRIUnitSetResponseBase {
    type: 'multiunit';
    denominator: string[][];
  }
  export type GetHTRIUnitSetResponse = (
    GetHTRISingleUnitSetResponse
    | GetHTRIMultiUnitSetResponse
  );

  export interface GetHTRIUnitConversionsResponse {
    unitSet: string[];
    units: RawHTRIUnitConversion[];
  }
  export interface GetHTRIEnumResponse {
    type: string;
    name: string;
    data: Record<string, string>;
  }
}

// materials endpoint
export namespace MaterialsAPI {
  export interface GetAllMaterialsResponseItem {
    ID: number;
    Names: string[];
    NominalComposition: string;
    Code: string | null;
    Form: string | null;
    Grade: string | null;
    Type: string | null;
    Temper: string | null;
    Groups: string[];
    DesignationTypes: (string | null)[];
    DesignationNumbers: (string | null)[];
  }

  export interface Group {
    Name: string;
  }

  export interface Designation {
    DesignationType: string | null;
    Country: string | null;
    Number: string | null;
    NominalComposition: string | null;
  }

  export interface Variable {
    Name: string;
    UnitOfMeasure: string | null;
    Units: string | null;
    LowValue: number;
    HighValue: number;
  }

  export interface TableRecord {
    DependentVariableValue: number;
    IndependentVariableValues: number;
  }

  export interface Constant {
    SubscriptLabel: number;
    Value: number;
    Units: string | null;
  }
  export interface Correlation {
    ID: number;
    FunctionalForm: Material.Correlation.FunctionalForm;
    DependentVariable: MaterialsAPI.Variable;
    IndependentVariable: MaterialsAPI.Variable;
    TableRecords: MaterialsAPI.TableRecord[];
    TableInterpolationMethod: Material.Correlation.InterpolationMethod;
    Constants: MaterialsAPI.Constant[] | null;
    Sources: string[];
    Notes: string[];
  }

  export type GetAllMaterialsResponse = GetAllMaterialsResponseItem[];

  export interface GetMaterialDetailsResponse {
    ID: number;
    Names: string[];
    NominalComposition: string;
    Code: string | null;
    Form: string | null;
    Class: string | null;
    Grade: string | null;
    Type: string | null;
    Temper: string | null;
    ExternalPressureChart: Material.ExternalPressureChart;
    MaterialGroups: MaterialsAPI.Group[];
    Designations: MaterialsAPI.Designation[];
    Correlations: MaterialsAPI.Correlation[];
  }
}
