import type { Config } from 'tailwindcss';
import defaultTheme from 'tailwindcss/defaultTheme';

export const HTRIBlue = '#3e6991';

export default {
  content: ["./src/**/*.{html,js,ts,jsx,tsx}"],
  theme: {
    extend: {
      fontFamily: {
        sans: ['Inter var', ...defaultTheme.fontFamily.sans],
        math: ['Times New Roman', ...defaultTheme.fontFamily.serif],
      },
      colors: {
        sky: {
          50: '#f4f7fb',
          100: '#e9eef5',
          200: '#cedce9',
          300: '#a3bfd6',
          400: '#719cbf',
          500: '#4f7fa8',
          DEFAULT: HTRIBlue,
          600: HTRIBlue,
          700: '#325272',
          800: '#2c4760',
          900: '#293c51',
          950: '#1b2736',
        },
      },
      spacing: {
        '128': '32rem',
      },
    },
  },
  plugins: [],
} satisfies Config
