import React from 'react';

interface XchangerSuiteProps {
  // Trademark is needed for the first occurrence in text, so optionally allow adding it
  trademark?: boolean;
}

const XchangerSuite: React.FC<XchangerSuiteProps> = ({ trademark }) => {
  const plainText = trademark ? 'changer Suite®' : 'changer Suite';
  return (
    <i>
      <strong>X</strong>{plainText}
    </i>
  );
};

export default XchangerSuite;
