import React from 'react';
import ResizableGrid, { ResizableGridColumns } from '../../common/ResizableGrid';
import { Claim, RawDetailedUser, UserApprovalStatus, UsersAPI } from '../../../types/API';
import User from './User';
import { useGetUsersQuery } from '../../../redux/userQueriesAPI';
import Typography from '../../common/Typography';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { useSelector } from '../../../redux/store';
import FetchErrorScreen from '../../common/FetchErrorScreen';
import LoadingScreen from '../../common/LoadingScreen';

export const usersQueryTableColumns: ResizableGridColumns = [
  {
    label: 'First Name',
  },
  {
    label: 'Last Name',
  },
  {
    label: 'Email',
  },
  {
    label: 'Company',
    defaultWidth: 250,
  },
  {
    label: 'Role',
  },
  {
    label: 'Status',
  },
  {
    label: 'Modify Claims',
    defaultWidth: 360,
    minWidth: 300,
  },
  {
    label: 'Set Approval Status',
  },
];

function useUsers(params: UsersAPI.GetUsersRequestParams) {
  const currentUser = useCurrentUser();
  const { error, currentData, refetch } = useGetUsersQuery(params);
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState<RawDetailedUser[]>([]);

  React.useEffect(() => {
    if (error) {
      setUsers([]);
      setLoading(false);
    }
    else if (!currentData) setLoading(true);
    else {
      setLoading(false);
      setUsers(currentData.users);
    }
  }, [error, currentData]);

  const updateStatus = (idx: number, newStatus: UserApprovalStatus) => {
    const newUsers = [...users];
    let approvedBy = newUsers[idx].approvedBy;
    let approvedDate = newUsers[idx].approvedDate;
    if (newStatus === 'approved') {
      approvedBy = currentUser!.email;
      approvedDate = new Date().toUTCString();
    }
    newUsers[idx] = {
      ...newUsers[idx],
      status: newStatus,
      approvedBy,
      approvedDate,
    };
    setUsers(newUsers);
  }

  const setClaims = (idx: number, claims: Claim[]) => {
    const newUsers = [...users];
    let modifiedUser = newUsers[idx];
    modifiedUser = {
      ...newUsers[idx],
      claims,
    };
    newUsers[idx] = modifiedUser;
    setUsers(newUsers);
  }

  return {
    users,
    error,
    loading,
    updateStatus,
    setClaims,
    refetch,
  };
}

export interface UsersQueryTableProps {
  columnWidths: string[];
  onColumnWidthsChange: (newWidths: string[]) => any;
  params?: UsersAPI.GetUsersRequestParams;
}

const UsersQueryTable: React.FC<UsersQueryTableProps> = ({ columnWidths, onColumnWidthsChange }) => {
  const params = useSelector((state) => state.authentication.userQueryParams);
  const { users, loading, error, updateStatus, setClaims, refetch } = useUsers(params);

  if (error) {
    return (
      <FetchErrorScreen title="Error Fetching Users" onRetry={refetch}>
        An error occurred while trying to fetch users.
        Please try again later or contact a maintainer if this issue persists.
      </FetchErrorScreen>
    );
  }

  if (loading) return <LoadingScreen />;

  // Inform user if no users match the query
  if (users.length === 0) {
    return <Typography className="p-2 bg-white">No users match your query.</Typography>;
  }

  const userComponents = users.map((user, idx) => (
    <User
      user={user}
      onStatusChange={(status) => updateStatus(idx, status)}
      onSetClaims={(claims) => setClaims(idx, claims)}
      key={user.id}
    />
  ));

  return (
    <div className="w-full h-full overflow-auto">
      <ResizableGrid
        columns={usersQueryTableColumns}
        columnWidths={columnWidths}
        onColumnWidthsChange={onColumnWidthsChange}
        rowDividers={false}
      >
        {userComponents}
      </ResizableGrid>
    </div>
  );
};

export default UsersQueryTable;
