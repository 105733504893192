import { useDispatch } from 'react-redux';
import { PlayIcon, ExclamationTriangleIcon, ArrowUpTrayIcon, ArrowDownTrayIcon, CheckIcon, ClockIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'

import { SolveStatus } from '../../types/tools';
import React from 'react';
import Button, { ButtonColor } from '../common/Button';
import Typography, { TypographyColor } from '../common/Typography';
import useFilePicker from '../../utils/useFilePicker';
import { reset as resetError, set as setError } from '../../redux/errors';
import LoadingIndicator from '../common/LoadingIndicator';

export function isAction(action: string): action is Action {
  return (orderedActions as readonly string[]).includes(action);
}
export function isStandardAction(action: Action): action is StandardAction {
  return !(loadActions as readonly Action[]).includes(action);
}

/* Types for creating action handlers */
const orderedActions = ['load', 'save', 'export', 'run'] as const;
type Action = typeof orderedActions[number];
const loadActions = ['load'] as const;
type LoadAction = typeof loadActions[number];
type StandardAction = Exclude<Action, LoadAction>;
type StandardActionHandler = () => any;
type LoadActionProps = {
  accept: string;
  multiple?: boolean;
  onLoad: (content: string) => any;
};
type AvailableActionbarActions = (
  Record<StandardAction, StandardActionHandler>
  & Record<LoadAction, LoadActionProps>
);
export type ActionbarActions = Partial<AvailableActionbarActions>;

/* Internal structuring */
interface InternalActionProps {
  label: string;
  icon: React.ElementType;
  color?: ButtonColor;
}
const actionStyles: Record<Action, InternalActionProps> = {
  load: { label: 'Load', icon: ArrowUpTrayIcon },
  save: { label: 'Save', icon: ArrowDownTrayIcon },
  export: { label: 'Export', icon: ArrowTopRightOnSquareIcon },
  run: { label: 'Run', color: 'primary', icon: PlayIcon, },
};
interface SolveStatusProps {
  icon: React.ElementType;
  color?: TypographyColor;
  iconColorClass?: string;
}
const solveStatusStyles: Record<SolveStatus, SolveStatusProps> = {
  [SolveStatus.Unsolved]: { icon: ClockIcon },
  [SolveStatus.Solving]: { icon: LoadingIndicator },
  [SolveStatus.Solved]: { icon: CheckIcon, iconColorClass: 'text-green-500' },
  [SolveStatus.Failed]: { icon: ExclamationTriangleIcon, color: 'red', iconColorClass: 'text-red-500' },
};

/* Component */
interface ActionbarProps {
  actions: ActionbarActions;
  solveStatus?: SolveStatus;
}
const Actionbar: React.FC<ActionbarProps> = ({ actions, solveStatus }) => {
  const dispatch = useDispatch();
  const openLoadFilePicker = useFilePicker({
    accept: actions.load?.accept,
    multiple: actions.load?.multiple,
  });

  const actionButtons: React.ReactNode[] = [];
  for (const action of orderedActions) {
    // Render nothing for undefined actions
    if (!(action in actions)) continue;

    // Determine click handler to use
    const handleClick = () => {
      dispatch(resetError());
      if (isStandardAction(action)) actions[action]();
      else {
        openLoadFilePicker({
          onSuccess: (content) => actions[action].onLoad(content),
          onError: (error) => dispatch(setError({ message: error.message })),
        });
      }
    };

    const styles = actionStyles[action];
    actionButtons.push(
      <Button
        label={styles.label}
        color={styles.color ?? 'white'}
        iconLeft={<styles.icon className="size-5" />}
        onClick={handleClick}
        key={styles.label}
      />
    );
  }

  let solveStatusComponent: React.ReactNode;
  if (solveStatus !== undefined) {
    const styles = solveStatusStyles[solveStatus];
    solveStatusComponent = (
      <div className="ml-1 inline-flex items-center gap-1">
        <styles.icon className={`size-5 ${styles.iconColorClass ?? 'text-slate-500'}`} />
        <Typography color={styles.color ?? 'black'} variant="body2" className="select-none">
          {solveStatus}
        </Typography>
      </div>
    );
  }

  return (
      <div className="flex items-center justify-start w-full bg-sky-100 border-b-2 border-slate-300">
          <span className="m-1 isolate inline-flex gap-x-1">
            {actionButtons}
            {solveStatusComponent}
          </span>
      </div>
  );
};

export default Actionbar;
