import React from 'react';

import { useSelector } from '../../../../redux/store';
import DataModelTreeItem from './DataModelTreeItem';
import Typography from '../../../common/Typography';
import DataModelTreeSearch from './DataModelTreeSearch';


const DataModelTree: React.FC = () => {
  const searchTree = useSelector((state) => state.engineeringChecklist.dataModel.searchTree);
  const items = searchTree.map((node) => (
    <DataModelTreeItem node={node} path={[node.label]} key={node.label} />
  ));
  // Display placeholder message if there are no items matching the search
  if (!items.length) items.push(
    <Typography className="p-2 select-none" variant="body2" color="slate">No items match your search terms.</Typography>
  );

  return (
    <div className="flex flex-col max-h-full h-full w-full bg-slate-200">
      <div className="w-full border-b-2 border-slate-300">
        <DataModelTreeSearch />
      </div>
      <div className="flex flex-col w-full h-full overflow-y-auto">
        {items}
      </div>
    </div>
  );
};

export default DataModelTree;
