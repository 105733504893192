import React from 'react';
import { useSelector } from '../../../redux/store';
import Material from '../../../types/materials';
import { validateIsNonNegativeNumber } from '../../../utils/inputValidation';
import { intersection } from '../../../utils/sets';
import { MATERIAL_SEARCH_RESULTS_CONTAINER_ID } from './MaterialSearchResults';

function trim(s: string | null | undefined): string {
  if (!s) return '';
  return s.replace(' ', '');
}

function fuzzyMatch(toCheck: string, search: string): boolean {
  // Split string to check and search into the words they're composed of
  const wordsToCheck = toCheck.toLowerCase().split(' ').filter((word) => word.length);
  const searchTerms = search.toLowerCase().split(' ').filter((term) => term.length);

  // Returns whether every word in searchTerms matches a word in wordsToCheck
  for (const term of searchTerms) {
    if (wordsToCheck.every((word) => !word.startsWith(term))) return false;
  }
  return true;
}

function applyFuzzyFilter(
  materials: Material.Overview[],
  filterValues: Material.FilterValues,
  filter: 'code' | 'grade',
) {
  const trimmedFilterValue = trim(filterValues[filter]);
  if (trimmedFilterValue === '') return undefined;

  const matches = new Set<Material.Overview>();
  for (const material of materials) {
    if (!material[filter]) continue;
    if (fuzzyMatch(material[filter], trimmedFilterValue)) matches.add(material);
  }
  return matches;
}

function applyExactFilter(
  materials: Material.Overview[],
  filterValues: Material.FilterValues,
  filter: 'form' | 'type' | 'temper',
) {
  const filterValue = filterValues[filter];
  if (!filterValue) return undefined;

  const matches = new Set<Material.Overview>();
  for (const material of materials) {
    if (material[filter] === filterValue) matches.add(material);
  }
  return matches;
}

export default function useFilters(materials: Material.Overview[]) {
  // Keep filters updated
  const filterValues = useSelector((state) => state.materials.filters);

  // Scroll to top of results when results are updated
  const searchResultsContainer = document.getElementById(
    MATERIAL_SEARCH_RESULTS_CONTAINER_ID
  );
  if (searchResultsContainer) searchResultsContainer.scrollTop = 0;

  const materialsMatchingName = React.useMemo(() => {
    const trimmedFilterValue = trim(filterValues.name);
    if (trimmedFilterValue === '') return undefined;

    const matchesName = new Set<Material.Overview>();
    for (const material of materials) {
      for (const name of material.names) {
        if (fuzzyMatch(name, trimmedFilterValue)) {
          matchesName.add(material);
          break;
        }
      }
    }
    return matchesName;
  }, [materials, filterValues.name])

  const materialsMatchingGroup = React.useMemo(() => {
    if (!filterValues.group) return undefined;

    const matchesGroup = new Set<Material.Overview>();
    for (const material of materials) {
      for (const group of material.groups) {
        if (group === filterValues.group) {
          matchesGroup.add(material);
          break;
        }
      }
    }
    return matchesGroup;
  }, [materials, filterValues.group]);

  const materialsMatchingComposition = React.useMemo(() => {
    const validCompositionFilters = filterValues.composition.filter((filter) => (
      filter.substance && validateIsNonNegativeNumber(filter.percentage)
    ));
    if (!validCompositionFilters.length) return undefined;

    const matchesComposition = new Set<Material.Overview>();
    for (const material of materials) {
      let matches = true;
      for (const { substance, operator, percentage } of validCompositionFilters) {
        if (!(substance in material.composition)) { matches = false; break; }
        if (
          operator === Material.CompositionOperator.GreaterOrEqual
          && material.composition[substance] < Number.parseFloat(percentage)
        ) { matches = false; break; }
        if (
          operator === Material.CompositionOperator.LessOrEqual
          && material.composition[substance] > Number.parseFloat(percentage)
        ) { matches = false; break; }
      }
      if (matches) matchesComposition.add(material);
    }
    return matchesComposition;
  }, [materials, filterValues.composition]);

  const materialsMatchingForm = React.useMemo(() => {
    return applyExactFilter(materials, filterValues, 'form');
  }, [materials, filterValues.form]);

  const materialsMatchingCode = React.useMemo(() => {
    return applyFuzzyFilter(materials, filterValues, 'code');
  }, [materials, filterValues.code]);

  const materialsMatchingDesignationType = React.useMemo(() => {
    if (!filterValues.designationType) return undefined;

    const matchesDesignationType = new Set<Material.Overview>();
    for (const material of materials) {
      for (const designation of material.designations) {
        // Ignore designations with no number,
        // as this means the designation isn't defined
        if (designation.number === null) continue;
        if (designation.type === filterValues.designationType) {
          matchesDesignationType.add(material);
          break;
        }
      }
    }
    return matchesDesignationType;
  }, [materials, filterValues.designationType]);

  const materialsMatchingDesignationNumber = React.useMemo(() => {
    const trimmedFilterValue = trim(filterValues.designationNumber);
    if (!trimmedFilterValue) return undefined;

    const matchesDesignationNumber = new Set<Material.Overview>();
    for (const material of materials) {
      for (const designation of material.designations) {
        if (designation.number === filterValues.designationNumber) {
          matchesDesignationNumber.add(material);
          break;
        }
      }
    }
    return matchesDesignationNumber;
  }, [materials, filterValues.designationNumber]);

  const materialsMatchingType = React.useMemo(() => {
    return applyExactFilter(materials, filterValues, 'type');
  }, [materials, filterValues.type]);

  const materialsMatchingTemper = React.useMemo(() => {
    return applyExactFilter(materials, filterValues, 'temper');
  }, [materials, filterValues.temper])

  const materialsMatchingGrade = React.useMemo(() => {
    return applyFuzzyFilter(materials, filterValues, 'grade');
  }, [materials, filterValues.grade]);

  // Determine materials matching filters
  let materialsMatchingFilters = new Set(materials);
  const filtersToApply: (Set<Material.Overview> | undefined)[] = [
    materialsMatchingName,
    materialsMatchingGroup,
    materialsMatchingComposition,
    materialsMatchingForm,
    materialsMatchingCode,
    materialsMatchingDesignationType,
    materialsMatchingDesignationNumber,
    materialsMatchingType,
    materialsMatchingTemper,
    materialsMatchingGrade,
  ].filter((matching) => matching !== undefined);
  for (const materialsMatchingFilter of filtersToApply) {
    materialsMatchingFilters = intersection(materialsMatchingFilter, materialsMatchingFilters);
  }
  return Array.from(materialsMatchingFilters);
}
