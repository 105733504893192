import Container from './Container';
import Image from './Image';
import Paragraph from './Paragraph';
import TodoNote from './TodoNote';
import UnorderedList from './UnorderedList';
import XchangerSuite from './XchangerSuite';

const Article = {
  Container,
  Image,
  Paragraph,
  TodoNote,
  UnorderedListContainer: UnorderedList.Container,
  UnorderedListItem: UnorderedList.Item,
  XchangerSuite,
};

export default Article;
