import InputField from '../InputField';
import Checkbox from '../Checkbox';
import Typography from '../Typography';
import Select from '../Select';

interface InputFieldFormItemProps {
  label: string;
  value: string | undefined;
  type: 'text' | 'email' | 'password';
  onChange: (newValue: string) => any;
  autoFocus?: boolean;
}
interface CheckboxFormItemProps {
  label: string;
  value: boolean | undefined;
  type: 'checkbox';
  onChange: (newValue: boolean) => any;
}
interface SelectFormItemProps<V> {
  label: string;
  value: V | undefined;
  type: 'select';
  options: Map<V, string> | (V extends string ? readonly V[] : never);
  onChange: (newValue: V) => any;
}
type FormItemProps<V> = (
  InputFieldFormItemProps
  | CheckboxFormItemProps
  | SelectFormItemProps<V>
);

const FormItem = <V,>(props: FormItemProps<V>) => {
  switch (props.type) {
    case 'checkbox':
      return (
        <div
          className="flex flex-row gap-2 items-center select-none"
          onClick={() => props.onChange(!props.value)}
        >
          <Checkbox checked={props.value ?? false} onChange={() => {}} />
          <Typography variant="body2" className="whitespace-nowrap">
            {props.label}
          </Typography>
        </div>
      );
    case 'select':
      return (
        <div className="flex items-center gap-2">
          <Typography className="whitespace-nowrap">
            {props.label}
          </Typography>
          <Select value={props.value} options={props.options} onChange={props.onChange} />
        </div>
      );
    default:
      return (
        <div>
          <InputField label={props.label} value={props.value} type={props.type} onChange={props.onChange} autoFocus={props.autoFocus} />
        </div>
      );
  }
};

export default FormItem;
