import React from 'react';
import { Role, UserApprovalStatus, UsersAPI } from '../../../types/API';
import useFormData from '../../../hooks/useFormData';
import { useDispatch, useSelector } from '../../../redux/store';
import { authenticationSlice } from '../../../redux/authentication';
import Form from '../../common/Form/Form';

const userStatusOptions: Map<UserApprovalStatus | undefined, string> = new Map([
  [undefined, 'All'],
  ['pending', 'Pending'],
  ['approved', 'Approved'],
  ['denied', 'Denied'],
  ['disabled', 'Disabled'],
]);
const roleOptions: Map<Role | undefined, string> = new Map([
  [undefined, 'All'],
  [Role.User, 'User'],
  [Role.Staff, 'Staff'],
  [Role.Admin, 'Admin'],
  [Role.Officer, 'Officer'],
  [Role.Rep, 'Rep'],
]);

const UserFilters: React.FC = () => {
  const dispatch = useDispatch();
  const queryParams = useSelector((state) => state.authentication.userQueryParams);

  const updateParam = <K extends keyof UsersAPI.GetUsersRequestParams>(
    param: K,
    value: UsersAPI.GetUsersRequestParams[K],
  ) => {
    const newQueryParams = { ...queryParams };
    if (value === '' || value === undefined) delete newQueryParams[param];
    else newQueryParams[param] = value;
    dispatch(authenticationSlice.actions.setUserQueryParams(newQueryParams));
  };

  const connect = <K extends keyof UsersAPI.GetUsersRequestParams>(param: K) => ({
    value: queryParams[param],
    onChange: (x: UsersAPI.GetUsersRequestParams[K]) => updateParam(param, x),
  });

  const { fields } = useFormData({
    email: { label: 'Email', type: 'text', ...connect('email') },
    status: { label: 'Status', type: 'select', options: userStatusOptions, ...connect('status') },
    role: { label: 'Role', type: 'select', options: roleOptions, ...connect('role') },
    companyName: { label: 'Company', type: 'text', ...connect('companyName') },
    country: { label: 'Country', type: 'text', ...connect('country') },
  });

  return (
    <div className="flex flex-col p-2 gap-2 bg-white">
      <Form.AutoField field={fields.email} />
      <Form.AutoField field={fields.status} />
      <Form.AutoField field={fields.role} />
      <Form.AutoField field={fields.companyName} />
      <Form.AutoField field={fields.country} />
    </div>
  );
};

export default UserFilters;
