import React from 'react';
import { useDispatch, useSelector } from '../../../redux/store';
import { EngineeringChecklist } from '../../../types/engineeringChecklist';
import { engineeringChecklistSlice } from '../../../redux/tools/engineeringChecklist';
import InputField from '../../common/InputField';

const EngineeringChecklistInfo: React.FC = () => {
  const dispatch = useDispatch();
  const info = useSelector((state) => state.engineeringChecklist.info);

  const updateInfo = (field: keyof EngineeringChecklist.Info, value: string) => {
    dispatch(engineeringChecklistSlice.actions.updateInfo({ [field]: value }));
  };

  return (
    <div className="w-64 flex flex-col items-center gap-2 p-2">
      <InputField label="Name" value={info.name} onChange={(name) => updateInfo('name', name)} />
      <InputField label="Version" value={info.version} onChange={(version) => updateInfo('version', version)} />
      <InputField label="Author" value={info.author} readOnly />
      <InputField label="Company" value={info.company} readOnly />
    </div>
  );
};

export default EngineeringChecklistInfo;
