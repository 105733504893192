import { useState, useRef } from 'react'

import AddRemoveButton from './AddRemoveButton'
import Component from './Component'

import { floatingPtOut, percent } from '../utils/ValueDisplay'

const Composition = ({ status, propertyMutators, controlActions, property }) => {
  
  const { id, label, userSpecified, basis, components } = property;

  const componentSet = useRef(components);

  const [base, setBase] = useState(basis);
  const [count, setCount] = useState(components.length);
  const [fractions, setFractions] = useState(propertyMutators.getCompositionFractions(id));
  const [sum, setSum] = useState(propertyMutators.getCompositionSum(id));

  const updateSum = () => {
    setSum(propertyMutators.getCompositionSum(id));
    setFractions(propertyMutators.getCompositionFractions(id));
    controlActions.reset();
  }

  const onRemoveChange = () => {
    if (count > 0) {
      componentSet.current.pop();
      propertyMutators.updateComponents(id, base, componentSet.current);
      setCount((prev) => prev - 1);
      controlActions.reset();
    }
  };

  const onAddChange = () => {
    componentSet.current.push({ name: '', value: 0 });
    propertyMutators.updateComponents(id, base, componentSet.current);
    setCount((prev) => prev + 1);
    controlActions.reset();
  };

  const onBasisChange = (e) => {
    e.persist();
    propertyMutators.updateCompositionBasis(id, e.target.value);
    setBase(e.target.value);
    controlActions.reset();
  };

  const unitarySum = fractions.reduce((s, v) => s + v, 0);

  return (
    <div className="flex flex-col items-center justify-center w-full m-1 bg-white">
      <div className="flex items-center justify-start h-10 w-full gap-3"> 
        <div className="m-1 p-1 w-60 font-medium">{`${label}`}</div>
        { !userSpecified && <div className="m-1 p-1 w-60 font-medium">{base === 'molar'? 'Basis: Molar' : 'Basis: Mass' }</div>}
        { userSpecified && <div className="m-1 p-1 w-60 justify-center">
          <span className="m-1 p-1">Basis:</span>
          <input
            className="m-1 p-1"
            type="radio"
            name={id}
            id={`molar-${id}`}
            autoComplete="off"
            value="molar"
            onChange={onBasisChange}
            checked={base === 'molar'}
          />
          <span htmlFor={`molar-${id}`}>Molar</span>
          <input
            className="m-1 p-1"
            type="radio"
            name={id}
            id={`mass-${id}`}
            autoComplete="off"
            value="mass"
            onChange={onBasisChange}
            checked={base === 'mass'}
          />
          <span htmlFor={`mass-${id}`}>Mass</span>
        </div>}
        { userSpecified && <AddRemoveButton onAddChange={onAddChange} onRemoveChange={onRemoveChange} />}
      </div>
      {componentSet.current.map((component, index) => (
        <Component key={index}
          status={status}
          propertyMutators={propertyMutators}
          controlActions={controlActions}
          index={index}
          id={id}
          userSpecified={userSpecified}
          component={component}
          updateSum={updateSum}
          fraction={fractions[index]}
        />
      ))}
      <div className="flex items-center justify-start h-10 w-full">
        <span className="m-1 p-1 w-60 text-left">Totals</span>
        <input
          type="number"
          className="m-1 p-1 w-60 text-right rounded-md border-2 border-slate-100"
          value={floatingPtOut(sum)}
          disabled
        />
        <input
          type="text"
          className="m-1 p-1 w-60 text-right rounded-md border-2 border-slate-100"
          value={percent(unitarySum)}
          disabled
        />
      </div>
    </div>
  );
}

export default Composition;
