import { createSlice, current, isDraft, PayloadAction } from '@reduxjs/toolkit';
import { Block, Symbol } from '../types';
import { BlockReplacementEvent, createController, DeletionEvent, Direction, InsertionEvent } from './BlockController';
import { createPlaceholderBlock } from '../utils';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';

export enum FocusType { Symbol, Block, None };
export enum BlockPlacement { Before, After };
export interface BlockFocus {
  type: FocusType.Block;
  target: Block;
  placement: BlockPlacement;
}
export interface SymbolFocus { type: FocusType.Symbol; target: Symbol; }
export interface NoFocus { type: FocusType.None; target: null; };
export type Focus = BlockFocus | SymbolFocus | NoFocus;
export interface EquationState {
  block: Block;
  currentFocus: Focus;
}

export type DeletionKey = 'Backspace' | 'Delete';

export function initializeEquationStateFrom(block?: Block): EquationState {
  const state: EquationState = {
    block: block ?? createPlaceholderBlock(),
    currentFocus: {
      type: FocusType.None,
      target: null,
    },
  };
  return state;
}

export const equationSlice = createSlice({
  name: 'equation',
  initialState: initializeEquationStateFrom(),
  reducers: {
    setBlock: (state, action: PayloadAction<Block>) => {
      state.block = action.payload;
    },
    focus: (state, action: PayloadAction<Focus>) => {
      state.currentFocus = action.payload;
    },
    unfocus: (state) => {
      state.currentFocus = {
        type: FocusType.None,
        target: null,
      };
    },
    move: (state, action: PayloadAction<{ direction: Direction, variables: Map<string, EngineeringChecklist.Variable>}>) => {
      // Disable immer, as this use case is incompatible with it
      const controller = createController(state.block, action.payload.variables);
      let newFocus = state.currentFocus;
      switch (action.payload.direction) {
        case 'left':
          newFocus = controller.moveLeft(state.currentFocus);
          break;
        case 'right':
          newFocus = controller.moveRight(state.currentFocus);
          break;
        case 'up':
          newFocus = controller.moveUp(state.currentFocus);
          break;
        case 'down':
          newFocus = controller.moveDown(state.currentFocus);
          break;
      }

      return {
        ...state,
        currentFocus: newFocus,
      };
    },
    deleteSymbolAtCursor: (state, action: PayloadAction<{ key: DeletionKey, variables: Map<string, EngineeringChecklist.Variable> }>) => {
      // const originalState =  cloneDeep(original(state));
      const deletionEvent = new DeletionEvent({
        state,
        key: action.payload.key,
        variables: action.payload.variables,
      });
      deletionEvent.dispatch();
    },
    insertAtCursor: (state, action: PayloadAction<{ key: string, variables: Map<string, EngineeringChecklist.Variable> }>) => {
      const insertionEvent = new InsertionEvent({
        state,
        key: action.payload.key,
        variables: action.payload.variables,
      });
      insertionEvent.dispatch();
    },
    replaceBlock: (state, action: PayloadAction<{ old: Block, new: Block, focus: Focus, variables: Map<string, EngineeringChecklist.Variable> }>) => {
      const blockReplacementEvent = new BlockReplacementEvent({
        state,
        newValue: action.payload.new,
        newFocus: action.payload.focus,
        variables: action.payload.variables,
      });
      blockReplacementEvent.dispatch();
    },
  },
});

export const {
  setBlock, focus, unfocus, move, deleteSymbolAtCursor, insertAtCursor, replaceBlock
} = equationSlice.actions;
