import { LoggedError } from '../redux/errorMiddleware';
import { SerializedSteamTablesInputs, SteamTablesState, validateSteamTableInputs } from '../redux/tools/steamTables';
import { UnitSet } from '../types/units';
import { convertUnits } from './units';

export function serializeSteamTablesInputs(state: SteamTablesState): SerializedSteamTablesInputs {
  const pRange = state.pressure;
  const tRange = state.temperature;
  if (!validateSteamTableInputs(state)) throw new LoggedError('All operating conditions must be valid before saving.');

  return {
    pressureMin: pRange.min,
    pressureMax: pRange.max,
    pressureUnits: pRange.unit,
    temperatureMin: tRange.min,
    temperatureMax: tRange.max,
    temperatureUnits: tRange.unit,
  };
}

export async function generateSteamTableCSV(state: SteamTablesState): Promise<string> {
  const { pressure, temperature, table } = state;
  if (table.length === 0) throw new LoggedError('No property tables to export. Click "Run" to generate a table for the specified operating conditions.');

  const headers = [
    `Temperature (${temperature.unit})`,
    `Pressure (${pressure.unit})`,
    'Phase',
    'Density (kg/m³)',
    'Viscosity (Pa·s)',
    'Heat Capacity (J/K)',
    'Thermal Conductivity (W/m·K)',
    'Enthalpy (J)',
    'Entropy (J/K)',
  ];
  let csv = headers.join(',');
  csv += '\n';
  const dataRows = await Promise.all(
    table.map(async (value) => [
      await convertUnits(value.temperature, UnitSet.Temperature, 'K', temperature.unit),
      await convertUnits(value.pressure, UnitSet.Pressure, 'Pa', pressure.unit),
      value.phase,
      value.density,
      value.viscosity,
      value.heatCapacity,
      value.thermalConductivity,
      value.enthalpy,
      value.entropy,
    ])
  );
  for (const row of dataRows) {
    csv += row.join(',');
    csv += '\n';
  }

  return csv;
}
