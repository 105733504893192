import React from 'react';

interface CheckboxProps {
  checked: boolean;
  onChange: (newChecked: boolean) => any;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange }) => {
  return (
    <input
      type="checkbox"
      className="h-4 w-4 rounded border-gray-300 text-sky-600 accent-sky-600 focus:ring-sky-600 transition-colors"
      checked={checked}
      onChange={() => onChange(!checked)}
    />
  );
};

export default Checkbox;
