import { useSelector } from '../../../redux/store';
import MaterialSearchResults from './MaterialSearchResults';
import DynamicFlexContainer from '../../common/DynamicFlexContainer';
import MaterialProperties from './MaterialProperties';

const MaterialsBrowserPage = () => {
  const viewingDetailsFor = useSelector((state) => state.materials.viewingDetailsFor);

  if (!viewingDetailsFor.length) return (
    <MaterialSearchResults />
  );

  return (
    <DynamicFlexContainer direction="column" initialSizes={['75%', '25%']}>
      <DynamicFlexContainer direction="row">
        {viewingDetailsFor.map((id) => <MaterialProperties id={id} key={id} />)}
      </DynamicFlexContainer>
      <MaterialSearchResults />
    </DynamicFlexContainer>
  );
};

export default MaterialsBrowserPage;
