import React from 'react';
import Typography from './Typography';
import { validateIsInteger, validateIsNonNegativeNumber, validateIsNumber, validateIsPositiveInteger } from '../../utils/inputValidation';

export type InputFieldType = 'text' | 'number' | 'decimal' | 'email' | 'password';

type TextAlign = 'left' | 'right' | 'center' | 'justify' | 'start' | 'end';
const textAlignClass: Record<TextAlign, string> = {
  left: 'text-left',
  right: 'text-right',
  center: 'text-center',
  justify: 'text-justify',
  start: 'text-start',
  end: 'text-end',
};

interface InputFieldBaseProps {
  iconRight?: React.ElementType;
  label?: string;
  readOnly?: boolean;
  textAlign?: TextAlign;
  onFocus?: () => any;
  onBlur?: () => any;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => any;
  autoFocus?: boolean;
}
type ValidatingInputFieldBaseProps = InputFieldBaseProps & {
  validator: (s: string) => boolean;
  onValidationFail?: (s: string) => any;
}
interface StringInputFieldProps {
  type?: Exclude<InputFieldType, 'decimal' | 'number'>;
  value?: string;
  onChange?: (newValue: string) => any;
}
type DecimalInputFieldProps = Omit<StringInputFieldProps, 'type'> & {
  type: 'decimal';
  allowNegative?: boolean;
};
interface NumericInputFieldProps {
  type: 'number';
  value?: number;
  onChange?: (newValue: number) => any;
  allowNegative?: boolean;
}

type InputFieldProps = (InputFieldBaseProps | ValidatingInputFieldBaseProps)
  & (StringInputFieldProps | DecimalInputFieldProps | NumericInputFieldProps);

function isValidating(props: InputFieldProps): props is ValidatingInputFieldBaseProps {
  return 'validator' in props;
}

const InputField: React.FC<InputFieldProps> = (props) => {
  const {
    type = 'text',
    value,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    iconRight: IconRight,
    label,
    readOnly = false,
    textAlign = 'left',
  } = props;
  const inputComponentType = type === 'decimal' ? 'text' : type;
  const hasError = props.type === 'decimal' && (
    (!props.allowNegative && !validateIsNonNegativeNumber(props.value ?? ''))
    || !validateIsNumber(props.value ?? '')
  );

  let className = 'p-1 w-full rounded-md border-2 transition-colors focus:ring-transparent focus:outline-none peer ';

  // Determine colors to use from props
  if (hasError) className += 'border-red-300 hover:border-red-400 focus:border-red-500 ';
  else if (readOnly) className += 'bg-slate-50 border-slate-300 focus:border-slate-300 ';
  else className += 'border-sky-200 hover:border-sky-400 focus:border-sky ';

  if (inputComponentType === 'number') className += 'number-spinner-none ';
  if (IconRight) className += 'pr-6 '; // Number here is normal padding (1) + icon size (5)
  className += textAlignClass[textAlign];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;

    if (isValidating(props)) {
      // Handle failed validation
      if (!props.validator(e.target.value)) {
        if (props.onValidationFail) props.onValidationFail(e.target.value);
        return;
      }
    }

    // Determine appropriate input handler from input type
    if (props.type === 'number') {
      const numberValidationFunction = props.allowNegative ? validateIsInteger : validateIsPositiveInteger;
      if (numberValidationFunction(e.target.value)) props.onChange?.(Number.parseInt(e.target.value));
    }
    else props.onChange?.(e.target.value);
  };

  const iconRightComponent = IconRight && (
    <IconRight className="absolute size-5 text-slate-500 right-0 top-0 bottom-0 my-auto p-[inherit] pr-0 mr-1" />
  );

  const labelComponent = label && (
    <Typography
      color="lightSlate"
      className={`absolute top-0 h-full flex items-center inset-x-0 py-1 pl-[6px] ${IconRight ? 'pr-6' : 'pr-1'} truncate pointer-events-none transition-all data-[filled=true]:py-0 peer-focus:py-0 data-[filled=true]:-translate-y-1/2 peer-focus:-translate-y-1/2 data-[filled=true]:text-xs peer-focus:text-xs peer-focus:data-[error=false]:text-sky data-[error=true]:text-red-500`}
      data-filled={Boolean(value)}
      data-error={hasError}
    >
      <div className="grid px-0.5 max-w-full relative">
        <div className="[grid-area:1/1] z-10 px-[3px] truncate">
          {label}
        </div>
        <div
          data-readonly={readOnly}
          className="[grid-area:1/1] clip-bottom-half bg-white data-[readonly=true]:bg-slate-50 w-full h-full"
        />
      </div>
    </Typography>
  );

  return (
    <Typography className="relative" variant="body1">
      <input
        className={className}
        value={value ?? ''}
        type={inputComponentType}
        readOnly={readOnly}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        autoFocus={props.autoFocus}
      />
      {iconRightComponent}
      {labelComponent}
    </Typography>
  );
};

export default InputField;
