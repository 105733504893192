import FileSaver from 'file-saver';

export enum MIMEType {
  CSV = 'text/csv',
  JSON = 'application/json',
  Text = 'text/plain;charset=utf-8',
};

export function savePlaintextFile(
  contents: string,
  filenameWithoutExtension: string,
  extension: string,
  mimeType = MIMEType.Text,
): void {
  const blob = new Blob([contents], { type: mimeType });
  FileSaver.saveAs(blob, `${filenameWithoutExtension}.${extension}`);
}
