import { React, createContext, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux';

import { set as setError } from '../redux/errors';

const UnitsContext = createContext();

export const UnitsProvider: React.FC = ({ children }) => {
  const unitOptionsMap = useRef(new Map());
  const dispatch = useDispatch();

  const addUnitOptions = useCallback((propertyMap) => {
    propertyMap.forEach(p => {
      const { unitSet } = p;
      if (!unitSet) return;
      if (unitOptionsMap.current.has(unitSet)) return;
      fetch(
        `/api/v1/units/unit-sets/${unitSet}/details`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
          },
        }
      )
        .then((res) => res.json())
          .then((unitOptions) => {
            if (Array.isArray(unitOptions)) {
              unitOptionsMap.current.set(unitSet, unitOptions);
              p.unitOptions = unitOptions;
            }
          })
          .catch((err) => {
            return dispatch(setError(err));
          });
      })
  }, []);

  const getUnitOptions = useCallback((unitSet) => {
    return unitOptionsMap.current.has(unitSet)? unitOptionsMap.current.get(unitSet) : [];
  }, []);

  return (
    <UnitsContext.Provider value={{ addUnitOptions, getUnitOptions }}>
      {children}
    </UnitsContext.Provider>
  );
}

export default UnitsContext;
