import React from 'react';
import Typography, { TypographyColor } from './Typography';
import LoadingIndicator from './LoadingIndicator';

export type ButtonColor = 'primary' | 'white' | 'green' | 'red';
const backgroundColorClass: Record<ButtonColor, string> = {
  primary: 'bg-sky hover:enabled:bg-sky-500 border-[1px] border-slate-300 enabled:border-sky hover:enabled:border-sky-500 enabled:text-white',
  white: 'bg-white hover:enabled:bg-slate-200 border-[1px] border-slate-300 enabled:text-black',
  green: 'bg-green-500 hover:enabled:bg-green-700 border-[1px] border-slate-300 enabled:border-green-500 hover:enabled:border-green-700 enabled:text-white',
  red: 'bg-red-500 hover:enabled:bg-red-700 border-[1px] border-slate-300 enabled:border-red-500 hover:enabled:border-red-700 enabled:text-white',
};
const textColorClass: Record<ButtonColor, TypographyColor> = {
  primary: 'white',
  white: 'black',
  green: 'white',
  red: 'white',
};

export interface ButtonProps {
  label?: string;
  color?: ButtonColor;
  disabled?: boolean;
  loading?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => any;
  type?: 'button' | 'submit';
}

const Button: React.FC<ButtonProps> = ({
  color = 'white',
  label,
  disabled,
  loading,
  iconLeft,
  iconRight,
  onClick,
  type = 'button',
}) => {
  disabled = loading || disabled;
  const textColor = disabled ? 'white' : textColorClass[color];
  const buttonClass = `inline-flex items-center rounded-md gap-1 ${backgroundColorClass[color]} px-2 py-1 transition-colors focus:z-10 disabled:bg-slate-300`;
  const labelComponent = label && (
    <Typography variant="button" color="inherit" className="text-nowrap">
      {label}
    </Typography>
  );

  const iconLeftComponent = loading ? <LoadingIndicator className="size-5" /> : iconLeft;

  return (
    <Typography className="transition-colors" variant="button" color={textColor}>
      <button
        className={buttonClass}
        type={type}
        onClick={(e) => { if (onClick) onClick(e); }}
        disabled={disabled}
      >
        {iconLeftComponent}
        {labelComponent}
        {iconRight}
      </button>
    </Typography>
  );
};

export default Button;
