import { createApi } from '@reduxjs/toolkit/query/react';
import { MaterialsAPI } from '../types/API';
import Material from '../types/materials';
import makeRequestBaseQuery from './makeRequestBaseQuery';

const letterRegex = /[a-zA-Z]/;
function parseComposition(
  material: MaterialsAPI.GetAllMaterialsResponseItem | MaterialsAPI.GetMaterialDetailsResponse
): Material.Composition {
  if (!material.NominalComposition) return {};

  const composition: Material.Composition = {};
  const components = material.NominalComposition.split('-');
  for (const component of components) {
    const firstLetterIdx = letterRegex.exec(component).index;
    const quantity = Number.parseFloat(component.slice(0, firstLetterIdx));
    let substance = component.slice(firstLetterIdx);
    substance = substance.split(' to ')[0]; // Take the lower value for components with ranges
    composition[substance] = quantity;
  }
  return composition;
}

function parseDesignations(material: MaterialsAPI.GetAllMaterialsResponseItem): Material.DesignationOverview[] {
  const designations: Material.DesignationOverview[] = [];
  for (const [idx, type] of material.DesignationTypes.entries()) {
    designations.push({
      type,
      number: material.DesignationNumbers[idx],
    });
  }
  return designations;
}

function parseVariable(variable: MaterialsAPI.Variable): Material.Correlation.Variable {
  return {
    name: variable.Name,
    unitOfMeasure: variable.UnitOfMeasure,
    units: variable.Units,
    lowValue: variable.LowValue,
    highValue: variable.HighValue,
  };
}

function parseCorrelations(
  material: MaterialsAPI.GetMaterialDetailsResponse
): Material.Correlation.Details[] {
  return material.Correlations.map((correlation) => ({
    id: correlation.ID,
    functionalForm: correlation.FunctionalForm,
    dependentVariable: parseVariable(correlation.DependentVariable),
    independentVariable: parseVariable(correlation.IndependentVariable),
    tableRecords: correlation.TableRecords.map((tableRecord) => ({
      dependentVariableValue: tableRecord.DependentVariableValue,
      independentVariableValue: tableRecord.IndependentVariableValues,
    })),
    tableInterpolationMethod: correlation.TableInterpolationMethod,
    constants: correlation.Constants?.map((constant) => ({
      subscriptLabel: constant.SubscriptLabel,
      value: constant.Value,
      units: constant.Units,
    })) ?? [],
    sources: correlation.Sources,
    notes: correlation.Notes,
  }));
}

const materialsAPI = createApi({
  reducerPath: 'materialsAPI',
  baseQuery: makeRequestBaseQuery({ baseUrl: `/api/v1/materials/`}),
  endpoints: (builder) => ({
    getAllMaterials: builder.query<Material.Overview[], void>({
      query: () => `materials`,
      transformResponse: (response: MaterialsAPI.GetAllMaterialsResponse) => (
        response
          .map((material) => ({
            id: material.ID,
            names: material.Names,
            composition: parseComposition(material),
            code: material.Code,
            form: material.Form,
            grade: material.Grade,
            type: material.Type,
            temper: material.Temper,
            groups: material.Groups,
            designations: parseDesignations(material),
          }))
          .sort((a, b) => {
            // Sort materials with a code to the top, then sort by their first name
            if (a.code && !b.code) return -1;
            if (b.code && !a.code) return 1;
            return a.names[0].localeCompare(b.names[0]);
          })
      ),
    }),
    getMaterialDetails: builder.query<Material.Details, number>({
      query: (id) => `materials/${id}`,
      transformResponse: (response: MaterialsAPI.GetMaterialDetailsResponse) => ({
        id: response.ID,
        names: response.Names,
        composition: parseComposition(response),
        code: response.Code,
        form: response.Form,
        class: response.Class,
        grade: response.Grade,
        type: response.Type,
        temper: response.Temper,
        externalPressureChart: response.ExternalPressureChart,
        groups: response.MaterialGroups.map((group) => ({ name: group.Name })),
        designations: response.Designations
          .map((designation) => ({
            type: designation.DesignationType,
            country: designation.Country,
            number: designation.Number,
            nominalComposition: designation.NominalComposition,
          }))
          // Sort designations by type to ensure consistent ordering between materials
          .sort((a, b) => a.type.localeCompare(b.type))
        ,
        correlations: parseCorrelations(response),
      }),
    })
  }),
  // Never remove data from cache
  keepUnusedDataFor: Infinity,
});

export default materialsAPI;
export const { useGetAllMaterialsQuery, useGetMaterialDetailsQuery } = materialsAPI;
