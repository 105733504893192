import { ApiEndpointQuery, QueryDefinition, skipToken, SkipToken } from '@reduxjs/toolkit/query';
import { useDispatch } from '../redux/store';
import React from 'react';
import { zip } from '../utils/iterables';

export type QueryResults<QueryArg, ReturnValue> = Map<QueryArg, ReturnValue>;

const useQueries = <QueryArg, ReturnValue>(
  endpoint: ApiEndpointQuery<QueryDefinition<QueryArg, any, any, ReturnValue, string>, any>,
) => (args: (QueryArg | SkipToken)[]) => {
  const dispatch = useDispatch();
  const [error, setError] = React.useState(false);
  const [results, setResults] = React.useState<
    QueryResults<QueryArg, ReturnValue>
  >();
  const refetch = React.useRef<() => void>(() => {});

  const definedArgs = args.filter((arg) => arg !== undefined && arg !== skipToken) as QueryArg[];

  const fetchData = () => {
    let current = true;
    // Reset state
    setResults(undefined);
    setError(false);

    // Ready all unit set conversions and update state
    const queryPromises = definedArgs.map((arg) => (dispatch(endpoint.initiate(arg))));
    refetch.current = async () => {
      for (const promise of queryPromises) promise.unsubscribe();
      fetchData();
    };

    Promise.all(queryPromises)
      .then((resultArray) => {
        const newResults: QueryResults<QueryArg, ReturnValue> = new Map();
        let hasError = false;
        for (const [arg, result] of zip(definedArgs, resultArray)) {
          if (result.isSuccess) newResults.set(arg, result.data);
          else { hasError = true; break; }
        }
        if (current) {
          if (hasError) setError(true);
          else setResults(newResults);
        }
      })

    return () => {
      current = false;
      for (const promise of queryPromises) promise.unsubscribe();
    }
  };

  React.useEffect(fetchData, [JSON.stringify(args)]);

  const isFetching = !error && !definedArgs.every((arg) => results?.has(arg));

  return {
    isFetching,
    isError: error,
    currentData: results,
    refetch: refetch.current,
  };
};

export default useQueries;
