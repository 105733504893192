import React from 'react';
import { BlockProps, ParenthesesBlock } from '../types';
import Block from './Block';
import Symbol from '../Symbol';

const Parentheses: React.FC<BlockProps> = ({ block }) => {
  const parentheses = block as ParenthesesBlock;
  const contentRef = React.useRef<HTMLDivElement>();
  const [parenStyle, setParenStyle] = React.useState<React.CSSProperties>();

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setParenStyle({
        fontSize: entries[0]?.contentRect?.height,
        height: '1.25em',
      });
    });
    resizeObserver.observe(contentRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <div className="flex flex-row items-center">
      <Symbol symbol={parentheses.leftParen} style={parenStyle} />
      <div ref={contentRef}>
        <Block block={parentheses.content} />
      </div>
      <Symbol symbol={parentheses.rightParen} style={parenStyle} />
    </div>
  );
};

export default Parentheses;
