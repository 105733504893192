import { EngineeringChecklist } from '../../../types/engineeringChecklist';

export type WithID<T> = T & { id: string };

/*
  Symbol types
*/
export enum SymbolType {
  Digit,
  Character,
  Operator,
  Parenthesis,
  Placeholder,
};

export interface DigitSymbol {
  type: SymbolType.Digit;
  character: string;
}

export interface CharacterSymbol {
  type: SymbolType.Character;
  nonItalic?: boolean;
  character: string;
}

export type OperatorSymbolCharacter = '+' | '-' | '*';
export interface OperatorSymbol {
  type: SymbolType.Operator;
  character: OperatorSymbolCharacter;
}

export interface ParenthesisSymbol {
  type: SymbolType.Parenthesis;
  character: '(' | ')';
}

export interface PlaceholderSymbol {
  type: SymbolType.Placeholder;
}

export type SymbolWithoutID = (
  DigitSymbol
  | CharacterSymbol
  | OperatorSymbol
  | ParenthesisSymbol
  | PlaceholderSymbol
);

export type Symbol<ST extends SymbolWithoutID = SymbolWithoutID> = WithID<ST>;

/*
  Block types
*/
export enum BlockType {
  SymbolGroup,
  Parentheses,
  Function,
  BinaryOperator,
  DataModelVariable,
  Division,
  Exponentiation,
  Negative,
  Placeholder,
  MismatchedParenthesis,
  InvalidFunctionOrVariable,
  Unparsable,
};

export type DigitOrCharacterSymbol = Symbol<DigitSymbol> | Symbol<CharacterSymbol>;
export interface SymbolGroupBlock {
  type: BlockType.SymbolGroup;
  symbols: DigitOrCharacterSymbol[];

}

export interface ParenthesesBlock {
  type: BlockType.Parentheses;
  leftParen: Symbol<ParenthesisSymbol>;
  content: Block;
  rightParen: Symbol<ParenthesisSymbol>;
}

export const equationFunctions = [
  'exp', 'log', 'ln', 'log10',
  'abs', 'sqrt',
  'sin', 'cos', 'tan', 'csc', 'sec', 'cot',
  'asin', 'acos', 'atan', 'acsc', 'asec', 'acot',
  'sinh', 'cosh', 'tanh', 'csch', 'sech', 'coth',
  'asinh', 'acosh', 'atanh','acsch', 'asech', 'acoth'
] as const;
export type EquationFunction = typeof equationFunctions[number];
export const equationFunctionSet = new Set<string>(equationFunctions);
export interface FunctionBlock {
  type: BlockType.Function;
  function: Block<SymbolGroupBlock>;
  operand: Block;
}

export enum BinaryOperator { Addition, Subtraction, Multiplication };
export interface BinaryOperatorBlock {
  type: BlockType.BinaryOperator;
  lhs: Block;
  operator: Symbol<OperatorSymbol>;
  rhs: Block;
}

export interface DataModelVariableBlock {
  type: BlockType.DataModelVariable;
  symbolGroup: Block<SymbolGroupBlock>;
  variable: EngineeringChecklist.Variable;
}

export interface DivisionBlock {
  type: BlockType.Division;
  numerator: Block;
  denominator: Block;
}

export interface ExponentiationBlock {
  type: BlockType.Exponentiation;
  base: Block;
  exponent: Block;
}

export interface NegativeBlock {
  type: BlockType.Negative;
  negativeSign: Symbol<DigitSymbol>;
  content: Block;
}

export interface PlaceholderBlock {
  type: BlockType.Placeholder;
  placeholder: Symbol<PlaceholderSymbol>;
}

export interface MismatchedParenthesisBlock {
  type: BlockType.MismatchedParenthesis;
  symbol: Symbol<CharacterSymbol>;
}

export interface InvalidFunctionOrVariableBlock {
  type: BlockType.InvalidFunctionOrVariable;
  symbolGroup: Block<SymbolGroupBlock>,
}

export interface UnparsableBlock {
  type: BlockType.Unparsable;
  symbolGroup: Block<SymbolGroupBlock>;
}

export type BlockWithoutID = (
  SymbolGroupBlock
  | ParenthesesBlock
  | FunctionBlock
  | BinaryOperatorBlock
  | DataModelVariableBlock
  | DivisionBlock
  | ExponentiationBlock
  | NegativeBlock
  | PlaceholderBlock
  | MismatchedParenthesisBlock
  | InvalidFunctionOrVariableBlock
  | UnparsableBlock
);

export type Block<BT extends BlockWithoutID = BlockWithoutID> = WithID<BT>;

export type InvalidBlock = (
  InvalidFunctionOrVariableBlock
  | UnparsableBlock
);

export interface BlockProps {
  block: Block;
}
