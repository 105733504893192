import { Block } from '../components/common/EquationEditor/types';

export namespace EngineeringChecklist {
  export interface Info {
    name: string;
    version: string;
    author: string;
    company: string;
  }

  /* Variables */
  export enum VariableType {
    Double = 'Double',
    Int16 = 'Int16',
    Int32 = 'Int32',
    Enum = 'Enum',
    String = 'String',
    Empty = 'Empty',
  }
  export type IndexNavSelection = number | '*Min' | '*Max' | '*';
  export type NavSelections = (IndexNavSelection | string)[];
  export enum IOSelection {
    Input = 'Input',
    Output = 'Output',
  }
  export interface Variable {
    id: string;
    attributeName: string;
    alias: string;
    label: string;
    type: VariableType;
    navigationStrings: string[];
    navSelections: NavSelections;
    ioSelection: IOSelection | null;
    unitSet?: string;
    // unit: [numerator, denominator1?, denominator2?]
    unit?: string[];
  }

  export interface DataModelNode {
    label: string;
    attributeName: string;
    io: boolean;
    navigationStrings: string[];
    children: DataModelNode[];
    type?: VariableType;
    units?: string;
    open?: boolean;
  }

  /* Rules */
  export enum Operator {
    GreaterThan = '>',
    GreaterThanOrEquals = '≥',
    LessThan = '<',
    LessThanOrEquals = '≤',
    Equals = '=',
    NotEqual = '≠',
    In = 'in',
    NotIn = '!in',
  }
  export enum DependencyGroupType {
    Any = 'Any',
    All = 'All',
  };
  export interface DependencyGroup {
    type: DependencyGroupType;
    ruleIDs: string[];
  }
  interface RuleBase {
    id: string;
    lhs: any;
    op: Operator;
    rhs: any;
    note: string;
    type: RuleType;
    dependencyGroups: DependencyGroup[];
  }
  export enum RuleType {
    Numeric,
    String,
    Enum,
  }
  export const EnumRuleOperators = [Operator.Equals, Operator.NotEqual, Operator.In, Operator.NotIn] as const;
  export type EnumRuleOperator = typeof EnumRuleOperators[number];
  export type StringRuleOperator = EnumRuleOperator;
  export const NumericRuleOperators = [
    Operator.GreaterThanOrEquals, Operator.GreaterThan,
    Operator.LessThanOrEquals, Operator.LessThan,
    Operator.Equals, Operator.NotEqual,
  ] as const;
  export type NumericRuleOperator = typeof NumericRuleOperators[number];
  export interface EnumRule extends RuleBase {
    type: RuleType.Enum;
    lhs: Variable | undefined;
    op: EnumRuleOperator;
    rhs: string[] | undefined;
  }
  export type StringRuleValue = string | Variable | undefined;
  export interface StringRule extends RuleBase {
    type: RuleType.String;
    lhs: Variable | undefined;
    op: StringRuleOperator;
    rhs: StringRuleValue[] | undefined,
  }
  export interface NumericRule extends RuleBase {
    type: RuleType.Numeric;
    lhs: Block | undefined;
    op: NumericRuleOperator;
    rhs: Block | undefined;
  }
  export type Rule = (
    EnumRule
    | StringRule
    | NumericRule
  );
}
