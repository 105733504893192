import { Draft, current, isDraft } from '@reduxjs/toolkit';
import { BinaryOperatorBlock, Block, BlockType, BlockWithoutID, DataModelVariableBlock, DigitOrCharacterSymbol, DivisionBlock, ExponentiationBlock, FunctionBlock, InvalidBlock, MismatchedParenthesisBlock, NegativeBlock, ParenthesesBlock, PlaceholderBlock, Symbol, SymbolGroupBlock, SymbolType } from '../types';
import { SplitBlock, createBinaryOperatorBlock, createBlock, createBlockFocus, createBlockFromSymbols, createNegativeBlock, createParenthesesBlock, createPlaceholderBlock, createSymbolFocus, createSymbolFromCharacter, deleteFromBlock, getBlockChildren, getBlockDirectSymbols, getContent, isEmpty, mergeBlocks, splitBlock } from '../utils';
import { BlockPlacement, FocusType, Focus, DeletionKey, EquationState } from './reducer';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';

export type Direction = 'up' | 'down' | 'left' | 'right';

interface ChangeEventConstructorProps {
  state: Draft<EquationState>;
  key: string;
  variables: Map<string, EngineeringChecklist.Variable>;
}
abstract class ChangeEvent {
  protected state: Draft<EquationState>;
  protected rootController: BlockController;
  protected target: BlockController;
  readonly key: string;

  // To be initialized by subclasses
  protected _originalFocus: Focus;
  previousTarget: BlockController
  newFocus: Focus;

  // To be updated by BlockControllers
  modifiedBlock: Block | undefined;

  constructor(props: ChangeEventConstructorProps) {
    this.state = props.state;
    this.rootController = createController(this.state.block, props.variables);
    this.key = props.key;
    this.target = this.rootController.findFocusController(this.state.currentFocus);
  }

  get originalFocus(): Focus { return this._originalFocus; }

  abstract dispatch(): void;
}

interface DeletionEventConstructorProps {
  key: DeletionKey;
  state: Draft<EquationState>;
  variables: Map<string, EngineeringChecklist.Variable>;
}
export class DeletionEvent extends ChangeEvent {
  override readonly key: DeletionKey;
   _symbol: Symbol;

  constructor(props: DeletionEventConstructorProps) {
    super(props);

    // Determine correct target for this event
    let focus = this.state.currentFocus;
    const iterateFocus = (): Focus => {
      const newFocus = props.key === 'Backspace'
        ? this.target.moveLeft(focus)
        : this.target.moveRight(focus)
      ;
      // If the focus hasn't changed, no need to recalculate controller
      if (newFocus.target?.id === focus.target?.id) return newFocus;

      // Only need to update controller if focusing a symbol
      if (newFocus.type === FocusType.Symbol) {
        this.target = this.rootController.findFocusController(newFocus);
      }
      return newFocus;
    }
    // If pressing delete, delete the symbol after the cursor rather than under it
    if (props.key === 'Delete' && focus.type === FocusType.Symbol) {
      const newFocus = iterateFocus();
      // If there's no other symbol after the current focus, do nothing
      if (newFocus.target?.id === focus.target?.id) return;
      // Always select next character if the symbol isn't a placeholder,
      // for placeholder blocks behavior is more intuitive if the symbol
      // immediately following the placeholder is deleted instead
      if (focus.target.type !== SymbolType.Placeholder || newFocus.type === FocusType.Symbol) {
        focus = newFocus;
      }
    }
    while (focus.type !== FocusType.Symbol) {
      const newFocus = iterateFocus();
      if (newFocus.target?.id === focus.target?.id) return;
      focus = newFocus;
    }

    this._symbol = focus.target;
    this._originalFocus = focus;
    this.newFocus = focus;
    this.previousTarget = this.target;
  }

  get symbol(): Symbol { return this._symbol; }

  dispatch() {
    if (this.originalFocus?.type !== FocusType.Symbol) return;
    if (this._symbol && this.target) {
      this.target.deleteSymbol(this);
      this.state.block = this.rootController.getBlock();
      this.state.currentFocus = this.newFocus;
    }
  }
}

const symbolRegex = /^[_a-zA-Z\d\.]$/;
const newBlockKeys = ['+', '-', '/', '*', '^', '(', ')'] as const;
export type BlockInsertionKey = typeof newBlockKeys[number];
const newBlockKeySet = new Set<string>(newBlockKeys);

export class InsertionEvent extends ChangeEvent {
  constructor(props: ChangeEventConstructorProps) {
    super(props);

    this._originalFocus = this.state.currentFocus;
    this.previousTarget = this.target;
    this.newFocus = this._originalFocus;
  }

  dispatch() {
    if (this.target) {
      if (newBlockKeySet.has(this.key)) this.target.splitBlock(this);
      else if (symbolRegex.test(this.key)) this.target.insertSymbol(this);
      else return;

      this.state.block = this.rootController.getBlock();
      this.state.currentFocus = this.newFocus;
    }
  }
}

type BlockReplacementEventConstructorProps = (
  Omit<ChangeEventConstructorProps, 'key'>
  & { newValue: Block, newFocus: Focus }
);
export class BlockReplacementEvent extends ChangeEvent {
  constructor(props: BlockReplacementEventConstructorProps) {
    super({ ...props, key: '' });
    this._originalFocus = this.state.currentFocus;
    this.previousTarget = this.target;
    this.newFocus = props.newFocus;
    this.modifiedBlock = props.newValue;
  }

  dispatch() {
    if (this.target) {
      this.target.replaceBlock(this);
      this.state.block = this.rootController.getBlock();
      this.state.currentFocus = this.newFocus;
    }
  }
}

interface BlockControllerConstructorProps<BT extends BlockWithoutID = BlockWithoutID> {
  block: Block<BT>;
  variables: Map<string, EngineeringChecklist.Variable>;
  parent?: BlockController;
  createsNewContext?: boolean;
}
interface BlockController<BT extends BlockWithoutID = BlockWithoutID> {
  // Should be implemented on all controllers with direct symbols
  onDeleteSymbol?: (e: DeletionEvent) => any;
  handleChildChange?: (e: ChangeEvent) => void;
}
abstract class BlockController<BT extends BlockWithoutID = BlockWithoutID> {
  #block: Block<BT>;
  readonly parent?: BlockController;
  protected readonly variables: Map<string, EngineeringChecklist.Variable>;
  protected children: BlockController[];
  createsNewContext: boolean = false;

  constructor(props: BlockControllerConstructorProps<BT>) {
    this.#block = props.block;
    this.variables = props.variables;
    this.parent = props.parent;
    if (props.createsNewContext || !this.parent) this.createsNewContext = true;

    this.children = getBlockChildren(props.block).map((child) => {
      const ChildControllerType = blockControllerTypes[child.block.type];
      return new ChildControllerType({
        variables: this.variables,
        block: child.block,
        parent: this,
        createsNewContext: child.createsNewContext,
      });
    });
  }

  /*      Helper functions      */
  get block() {
    return this.#block;
  }

  protected findSymbol(symbol: Symbol): BlockController | undefined {
    // Try to find in direct symbols
    if (getBlockDirectSymbols(this.block).find((s) => s.id === symbol.id)) return this;

    // Recursively call in children
    for (const child of this.children) {
      const controller = child.findSymbol(symbol);
      if (controller) return controller;
    }
  }

  findBlock(block: Block): BlockController | undefined{
    if (block.id === this.block.id) return this;
    for (const child of this.children) {
      const controller = child.findBlock(block);
      if (controller) return controller;
    }
  }

  findFocusController(focus: Focus): BlockController | undefined {
    if (focus.type === FocusType.None) return;
    if (focus.type === FocusType.Block) return this.findBlock(focus.target);
    return this.findSymbol(focus.target);
  }

  abstract toString(): string;

  abstract getSymbols(): Generator<Symbol>;
  *getAllSymbols(): Generator<Symbol> {
    yield* this.getSymbols();
  }

  getBlock(): Block {
    return this.block;
  }

  /*      Movement      */
  // Override if:
  // - block has multiple focus positions
  // - block has both children and direct symbols
  protected getFocusTargetBefore(focus: Focus): Focus {
    if (this.createsNewContext && focus.target.id !== this.block.id) {
      return createBlockFocus(this.block);
    }
    return this.propagateGetFocusTargetBefore(focus);
  }
  protected propagateGetFocusTargetBefore(focus: Focus): Focus {
    if (this.parent) return this.parent.receiveFocusFromChild(this, focus, 'left');
    return focus;
  }
  moveLeft(focus: Focus): Focus {
    const controller = this.findFocusController(focus);
    if (!controller) return focus;
    return controller.getFocusTargetBefore(focus);
  }

  // Override if:
  // - block has multiple focus positions
  // - block has children
  protected getFocusTargetAfter(focus: Focus): Focus {
    return this.propagateGetFocusTargetAfter(focus);
  }
  protected propagateGetFocusTargetAfter(focus: Focus): Focus {
    if (this.parent) return this.parent.receiveFocusFromChild(this, focus, 'right');
    return focus;
  }
  moveRight(focus: Focus): Focus {
    const controller = this.findFocusController(focus);
    if (!controller) return focus;
    return controller.getFocusTargetAfter(focus);
  }

  // Override if block can directly handle vertical movement
  protected getFocusTargetAbove(focus: Focus): Focus {
    return this.propagateGetFocusTargetAbove(focus);
  }
  protected propagateGetFocusTargetAbove(focus: Focus): Focus {
    if (this.parent) return this.parent.receiveFocusFromChild(this, focus, 'up');
    return focus;
  }
  moveUp(focus: Focus): Focus {
    const controller = this.findFocusController(focus);
    if (!controller) return focus;
    return controller.getFocusTargetAbove(focus);
  }

  // Override if block can directly handle vertical movement
  protected getFocusTargetBelow(focus: Focus): Focus {
    return this.propagateGetFocusTargetBelow(focus);
  }
  protected propagateGetFocusTargetBelow(focus: Focus): Focus {
    if (this.parent) return this.parent.receiveFocusFromChild(this, focus, 'down');
    return focus;
  }
  moveDown(focus: Focus): Focus {
    const controller = this.findFocusController(focus);
    if (!controller) return focus;
    return controller.getFocusTargetBelow(focus);
  }

  /*      Focus     */
  // Override if:
  // - block has both children and direct symbols, and starts with a direct symbol
  receiveFocusFromLeft(): Focus {
    if (this.createsNewContext) return createBlockFocus(this.block);
    if (this.children.length) return this.children[0].receiveFocusFromLeft();
    return createSymbolFocus(getBlockDirectSymbols(this.block)[0]);
  }
  // Override if block has direct symbols and they aren't the last item in the block
  receiveFocusFromRight(): Focus {
    const directSymbols = getBlockDirectSymbols(this.block);
    if (directSymbols.length) {
      const lastDirectSymbol = directSymbols[directSymbols.length - 1];
      return createSymbolFocus(lastDirectSymbol);
    }
    return this.children[this.children.length - 1].receiveFocusFromRight();
  }
  // Override if:
  // - block has multiple children
  // - block has child(ren) and direct symbols
  receiveFocusFromChild(child: BlockController, focus: Focus, direction: Direction): Focus {
    if (direction === 'left') return this.propagateGetFocusTargetBefore(focus);
    if (direction === 'right') return this.propagateGetFocusTargetAfter(focus);
    if (direction === 'up') return this.propagateGetFocusTargetAbove(focus);
    return this.propagateGetFocusTargetBelow(focus);
  }

  /*      Event handling      */
  protected bubbleChangeEvent(e: ChangeEvent): void {
    e.previousTarget = this;
    if (this.parent) {
      this.parent.handleChildChange(e);
    }
    else {
      if (e.modifiedBlock === undefined) {
        e.modifiedBlock = createPlaceholderBlock();
        e.newFocus = createSymbolFocus(e.modifiedBlock.placeholder);
      }
      this.#block = e.modifiedBlock as unknown as Block<BT>;
    }
  }

  /*      Deletion      */
  deleteSymbol(e: DeletionEvent) {
    this.onDeleteSymbol(e);
    this.bubbleChangeEvent(e);
  }

  /*      Insertion     */
  // Override if:
  // - block has direct symbols
  // - block has irregular focus positions
  splitAtCursor(e: InsertionEvent): SplitBlock {
    if (e.originalFocus.type !== FocusType.Block) {
      throw new Error(`
        Default implementation of splitAtCursor cannot handle blocks
        with direct symbols.
      `);
    };
    if (e.originalFocus.placement === BlockPlacement.Before) {
      return [undefined, this.block, undefined];
    }
    return [this.block, undefined, undefined];
  }
  splitBlock(e: InsertionEvent): void {
    const split = this.splitAtCursor(e);
    const modified = splitBlock(e.key as BlockInsertionKey, split);
    e.modifiedBlock = modified.block;
    e.newFocus = modified.focus;
    this.bubbleChangeEvent(e);
  }

  // Override if:
  // - block has direct symbols
  // - block has irregular focus positions
  onInsertSymbol(e: InsertionEvent, newSymbol: DigitOrCharacterSymbol): void {
    let { block: newBlock, focus } = createBlockFromSymbols([newSymbol], this.variables, 0);

    if (
      e.originalFocus.type === FocusType.Block
      && e.originalFocus.placement === BlockPlacement.After
    ) newBlock = createBinaryOperatorBlock(this.block, '*', newBlock);
    else newBlock = createBinaryOperatorBlock(newBlock, '*', this.block);

    e.modifiedBlock = newBlock;
    e.newFocus = focus;
  }
  insertSymbol(e: InsertionEvent): void {
    const newSymbol = createSymbolFromCharacter(e.key);
    this.onInsertSymbol(e, newSymbol);
    this.bubbleChangeEvent(e);
  }

  /*      Block replacement     */
  replaceBlock(e: BlockReplacementEvent) {
    this.bubbleChangeEvent(e);
  }
}

class BinaryOperatorBlockController extends BlockController<BinaryOperatorBlock> {
  toString() {
    return this.children[0].toString() + this.block.operator.character + this.children[1].toString();
  }

  *getSymbols() {
    yield* this.children[0].getSymbols();
    yield this.block.operator;
    yield* this.children[1].getSymbols();
  }

  override getFocusTargetBefore(focus: Focus) {
    if (focus.type === FocusType.Block) return this.propagateGetFocusTargetBefore(focus);
    if (focus.target.id === this.block.operator.id) return this.children[0].receiveFocusFromRight();
    if (this.createsNewContext) return createBlockFocus(this.block);
    return this.propagateGetFocusTargetBefore(focus);
  }
  override getFocusTargetAfter(focus: Focus) {
    if (focus.type === FocusType.Block) {
      return this.children[0].receiveFocusFromLeft();
    }
    return this.children[1].receiveFocusFromLeft();
  }

  override receiveFocusFromChild(child: BlockController, focus: Focus, direction: Direction): Focus {
    if (direction === 'up') return this.propagateGetFocusTargetAbove(focus);
    if (direction === 'down') return this.propagateGetFocusTargetBelow(focus);

    // Coming from lhs
    if (child === this.children[0]) {
      if (direction === 'right') return createSymbolFocus(this.block.operator);
      if (this.createsNewContext) return createBlockFocus(this.block);
      return this.propagateGetFocusTargetBefore(focus);
    }
    // Coming from rhs
    if (direction === 'left') return createSymbolFocus(this.block.operator);
    return this.propagateGetFocusTargetAfter(focus);
  }

  override receiveFocusFromLeft(): Focus {
    if (this.createsNewContext) return createBlockFocus(this.block);
    return this.children[0].receiveFocusFromLeft();
  }
  override receiveFocusFromRight(): Focus {
    return this.children[1].receiveFocusFromRight();
  }

  override onDeleteSymbol(e: DeletionEvent) {
    // Only valid symbol is operator, so delete it
    if (this.block.lhs.type === BlockType.Placeholder) {
      e.modifiedBlock = this.block.rhs;
      e.newFocus = createBlockFocus(this.block.rhs);
    }
    else {
      e.modifiedBlock = mergeBlocks(this.block.lhs, this.block.rhs);
      e.newFocus = this.children[0].receiveFocusFromRight();
    }
  }

  override handleChildChange(e: ChangeEvent) {
    // Handle deletion
    if (e.modifiedBlock === undefined) {
      // Handle lhs deletion
      if (e.previousTarget === this.children[0]) {
        if (this.block.operator.character === '-') {
          e.modifiedBlock = createNegativeBlock(this.block.rhs);
          e.newFocus = createBlockFocus(e.modifiedBlock);
        }
        else {
          e.modifiedBlock = this.block.rhs;
          // Ensure cursor is placed at beginning of RHS
          this.children[1].createsNewContext = true;
          e.newFocus = this.children[1].receiveFocusFromLeft();
        }
        this.bubbleChangeEvent(e);
      }
      // Handle rhs deletion
      else {
        e.modifiedBlock = this.block.lhs;
        e.newFocus = this.children[0].receiveFocusFromRight();
        this.bubbleChangeEvent(e);
      }
    }
    // Handle lhs change
    else if (e.previousTarget === this.children[0]) {
      // If lhs is a binary operator, make it a simpler block type instead
      // to ensure binary operators are ordered consistently
      if (e.modifiedBlock.type === BlockType.BinaryOperator) {
        const newLHS = e.modifiedBlock.lhs;
        const newRHS = createBinaryOperatorBlock(e.modifiedBlock.rhs, this.block.operator.character, this.block.rhs);
        e.modifiedBlock = createBinaryOperatorBlock(newLHS, e.modifiedBlock.operator.character, newRHS);
        this.bubbleChangeEvent(e);
      }
      else this.block.lhs = e.modifiedBlock;
    }
    // Handle rhs change
    else this.block.rhs = e.modifiedBlock;
  }

  override splitAtCursor(e: InsertionEvent): SplitBlock {
    // If operator is selected, split LHS and RHS
    if (e.originalFocus.target.id === this.block.operator.id) {
      if (e.key === '(') return [this.block.lhs, this.block.rhs, undefined];
      return [
        createBinaryOperatorBlock(
          this.block.lhs,
          this.block.operator.character,
          createPlaceholderBlock(),
        ),
        this.block.rhs,
        undefined,
      ];
    }
    return super.splitAtCursor(e);
  }

  override onInsertSymbol(e: InsertionEvent, newSymbol: DigitOrCharacterSymbol) {
    if (e.originalFocus.target.id === this.block.operator.id) {
      this.children[1].onInsertSymbol(e, newSymbol);
      this.block.rhs = e.modifiedBlock;
      e.modifiedBlock = this.block;
    }
    else super.onInsertSymbol(e, newSymbol);
  }
}

class DataModelVariableBlockController extends BlockController<DataModelVariableBlock> {
  toString() {
    return this.block.variable.alias;
  }

  *getSymbols() {
    yield* this.children[0].getSymbols();
  }

  override handleChildChange(e: DeletionEvent) {
    this.bubbleChangeEvent(e);
  }
}

class DivisionBlockController extends BlockController<DivisionBlock> {
  constructor(props) { super({ ...props, createsNewContext: true }); }

  toString() {
    return `(${this.children[0].toString()})/(${this.children[1].toString()})`;
  }

  *getSymbols() {
    yield* this.children[0].getSymbols();
    yield* this.children[1].getSymbols();
  }

  override getFocusTargetBefore(focus: Focus) {
    if (focus.type !== FocusType.Block) return focus;
    if (focus.placement === BlockPlacement.Before) {
      return this.propagateGetFocusTargetBefore(focus);
    }
    return this.children[1].receiveFocusFromRight();
  }
  override getFocusTargetAfter(focus: Focus) {
    if (focus.type !== FocusType.Block) return focus;
    if (focus.placement === BlockPlacement.After) {
      return this.propagateGetFocusTargetAfter(focus);
    }
    return this.children[0].receiveFocusFromLeft();
  }

  // Handle movement between numerator and denominator, trying to keep cursor at a similar relative position
  #switchFractionComponent(focus: Focus, from: BlockController, to: BlockController): Focus {
    if (focus.type === FocusType.Block) return to.receiveFocusFromLeft();

    const fromDirectSymbols = getBlockDirectSymbols(from.block);
    const toDirectSymbols = getBlockDirectSymbols(to.block);

    // When there's only one symbol in the original block, move to the beginning
    if (fromDirectSymbols.length === 1) return to.receiveFocusFromLeft();

    if (fromDirectSymbols.length && toDirectSymbols.length) {
      // Both blocks have direct children, try to move to similar position
      const currentSymbolIdx = fromDirectSymbols.findIndex((symbol) => (symbol.id === focus.target.id));
      const lengthRatio = toDirectSymbols.length / fromDirectSymbols.length;
      const newSymbolIdx = Math.floor(((currentSymbolIdx + 1) / fromDirectSymbols.length) * lengthRatio);
      return createSymbolFocus(toDirectSymbols[newSymbolIdx]);
    }
    return to.receiveFocusFromLeft();
  }

  override receiveFocusFromLeft() {
    return createBlockFocus(this.block);
  }
  override receiveFocusFromRight() {
    return createBlockFocus(this.block, BlockPlacement.After);
  }

  override receiveFocusFromChild(child: BlockController, focus: Focus, direction: Direction) {
    if (direction === 'left') return createBlockFocus(this.block);
    if (direction === 'right') return createBlockFocus(this.block, BlockPlacement.After);
    if (direction === 'up') {
      if (child === this.children[0]) return this.propagateGetFocusTargetAbove(focus);
      return this.#switchFractionComponent(focus, child, this.children[0]);
    }
    if (direction === 'down') {
      if (child === this.children[1]) return this.propagateGetFocusTargetBelow(focus);
      return this.#switchFractionComponent(focus, child, this.children[1]);
    }
  }

  override handleChildChange(e: ChangeEvent) {
    // Handle numerator change
    if (e.previousTarget === this.children[0]) {
      if (e.modifiedBlock === undefined) {
        e.modifiedBlock = this.block.denominator;
        e.newFocus = this.children[1].receiveFocusFromLeft();
        this.bubbleChangeEvent(e);
      }
      else this.block.numerator = e.modifiedBlock;
    }
    // Handle denominator change
    else {
      if (e.modifiedBlock === undefined) {
        e.modifiedBlock = this.block.numerator;
        e.newFocus = this.children[0].receiveFocusFromRight();
        this.bubbleChangeEvent(e);
      }
      else this.block.denominator = e.modifiedBlock;
    }
  }
}

class ExponentiationBlockController extends BlockController<ExponentiationBlock> {
  toString() {
    return `(${this.children[0].toString()})^(${this.children[1].toString()})`;
  }

  *getSymbols() {
    yield* this.children[0].getSymbols();
    yield* this.children[1].getSymbols();
  }

  override getFocusTargetBefore(focus: Focus): Focus {
    if (focus.target.id === this.block.exponent.id) return this.children[0].receiveFocusFromRight();
    if (focus.type === FocusType.Block && focus.placement === BlockPlacement.After) {
      return this.children[1].receiveFocusFromRight();
    }
    if (this.createsNewContext && focus.target.id !== this.block.base.id) {
      return createBlockFocus(this.block.base);
    }
    return this.propagateGetFocusTargetBefore(focus);
  }
  override getFocusTargetAfter(focus: Focus): Focus {
    if (focus.target.id === this.block.base.id) return this.children[1].receiveFocusFromLeft();
    if (focus.type === FocusType.Block && focus.placement === BlockPlacement.Before) {
      return this.children[0].receiveFocusFromLeft();
    }
    return this.propagateGetFocusTargetAfter(focus);
  }

  override receiveFocusFromChild(child: BlockController, focus: Focus, direction: Direction) {
    if (direction === 'left') {
      if (child === this.children[1]) return this.children[0].receiveFocusFromRight();
      if (this.createsNewContext && focus.target.id !== this.block.base.id) {
        return createBlockFocus(this.block.base);
      }
      return this.propagateGetFocusTargetBefore(focus);
    }
    if (direction == 'right') {
      if (child === this.children[0]) return this.children[1].receiveFocusFromLeft();
      return createBlockFocus(this.block, BlockPlacement.After);
    }
    if (direction === 'up') return this.propagateGetFocusTargetAbove(focus);
    if (direction === 'down') {
      if (child === this.children[1]) return this.children[0].receiveFocusFromRight();
      return this.propagateGetFocusTargetBelow(focus);
    }
  }
  override receiveFocusFromRight(): Focus {
    return createBlockFocus(this.block, BlockPlacement.After);
  }

  override handleChildChange(e: ChangeEvent) {
    if (e.previousTarget.block.id === this.block.base.id) {
      // Handle base deletion
      if (e.modifiedBlock === undefined) {
        e.modifiedBlock = this.block.exponent;
        e.newFocus = this.children[1].receiveFocusFromLeft();
        this.bubbleChangeEvent(e);
      }
      // Handle base change - split this block if needed
      else if (e.modifiedBlock.type === BlockType.BinaryOperator) {
        this.block.base = e.modifiedBlock.rhs;
        e.modifiedBlock.rhs = this.block;
        this.bubbleChangeEvent(e);
      }
      else if (e.modifiedBlock.type === BlockType.Division) {
        this.block.base = createParenthesesBlock(e.modifiedBlock);
        e.modifiedBlock = this.block;
        this.bubbleChangeEvent(e);
      }
      else this.block.base = e.modifiedBlock;
    }
    // Handle exponent change
    else {
      if (e.modifiedBlock === undefined) {
        e.modifiedBlock = this.block.base;
        e.newFocus = this.children[0].receiveFocusFromRight();
        if (e.key === 'Delete') e.newFocus = this.propagateGetFocusTargetAfter(e.newFocus);
        this.bubbleChangeEvent(e);
      }
      else this.block.exponent = e.modifiedBlock;
    }
  }
}

class FunctionBlockController extends BlockController<FunctionBlock> {
  toString() {
    return this.children[0].toString() + this.children[1].toString();
  }
  *getSymbols() {
    yield* this.children[0].getSymbols();
    yield* this.children[1].getSymbols();
  }

  override getFocusTargetBefore(focus: Focus): Focus {
    if (focus.target.id === this.block.function.id) return this.children[0].receiveFocusFromRight();
    if (this.createsNewContext) return createBlockFocus(this.block);
    return this.propagateGetFocusTargetBefore(focus);
  }
  override getFocusTargetAfter(focus: Focus): Focus {
    if (focus.target.id === this.block.id) return this.children[0].receiveFocusFromLeft();
    return this.propagateGetFocusTargetAfter(focus);
  }

  override receiveFocusFromChild(child: BlockController, focus: Focus, direction: Direction) {
    if (direction === 'left') {
      if (child === this.children[1]) return this.children[0].receiveFocusFromRight();
      if (this.createsNewContext) return createBlockFocus(this.block);
      return this.propagateGetFocusTargetBefore(focus);
    }
    if (direction == 'right') {
      if (child === this.children[0]) return this.children[1].receiveFocusFromLeft();
      return this.propagateGetFocusTargetAfter(focus);
    }
    if (direction === 'up') return this.propagateGetFocusTargetAbove(focus);
    if (direction === 'down') return this.propagateGetFocusTargetBelow(focus);
  }

  #handleFunctionChange(e: ChangeEvent) {
    if (e.modifiedBlock.type === BlockType.Function) {
      if (!isEmpty(e.modifiedBlock.operand)) {
        // If modifiedBlock has content, merge with this block's operand
        this.block.function = e.modifiedBlock.function;
        if (isEmpty(this.block.operand)) {
          this.block.operand = e.modifiedBlock.operand;
        }
        else this.block.operand = createParenthesesBlock(
          createBinaryOperatorBlock(
            getContent(e.modifiedBlock.operand),
            '*',
            getContent(this.block.operand),
          )
        );
        // Ensure focus is set appropriately considering e.modifiedBlock won't be in the new equation
        if (e.newFocus?.target.id === e.modifiedBlock.id) e.newFocus = createBlockFocus(this.block);
        e.modifiedBlock = this.block;
      }
      // If this block was empty, it will be replaced by e.modifiedBlock (the new function block)
    }
    // No longer a function after the change, change to multiplication with operand
    else {
      if (!isEmpty(this.block.operand)) {
        e.modifiedBlock = createBinaryOperatorBlock(e.modifiedBlock, '*', this.block.operand);
      }
    }
    this.bubbleChangeEvent(e);
  }
  override handleChildChange(e: ChangeEvent) {
    // Handle function change
    if (e.previousTarget === this.children[0]) {
      this.#handleFunctionChange(e);
    }
    else {
      // Handle operand change
      if (e.modifiedBlock === undefined) {
        // If placeholder was deleted, delete last character of function
        const newSymbols = this.block.function.symbols.slice(0, -1);
        const { block, focus } = createBlockFromSymbols(
          newSymbols,
          this.variables,
          newSymbols.length - 1,
          true,
        );
        e.modifiedBlock = block;
        e.newFocus = focus;
        this.#handleFunctionChange(e);
      }
      else if (e.modifiedBlock.type === BlockType.BinaryOperator) {
        // Ensure binary operators are separated rather than forced into the operand
        this.block.operand = e.modifiedBlock.lhs;
        e.modifiedBlock = createBinaryOperatorBlock(
          this.block,
          e.modifiedBlock.operator.character,
          e.modifiedBlock.rhs,
        );
        this.bubbleChangeEvent(e);
      }
      else if (e.modifiedBlock.type === BlockType.Division) {
        this.block.operand = e.modifiedBlock.numerator;
        e.modifiedBlock = createBlock({
          type: BlockType.Division,
          numerator: this.block,
          denominator: e.modifiedBlock.denominator,
        });
        this.bubbleChangeEvent(e);
      }
      else {
        // Operand is still present, wrap in parens if needed and update operand
        this.block.operand = (e.modifiedBlock.type === BlockType.Parentheses)
          ? e.modifiedBlock
          : createParenthesesBlock(e.modifiedBlock)
        ;
        e.modifiedBlock = this.block;
        this.bubbleChangeEvent(e);
      }
    }
  }

  override onInsertSymbol(e: InsertionEvent, newSymbol: DigitOrCharacterSymbol): void {
    // Only possible position is at the beginning of this block
    const newSymbols = [newSymbol, ...this.block.function.symbols];
    const { block, focus } = createBlockFromSymbols(newSymbols, this.variables, 0);
    e.newFocus = focus;
    if (block.type === BlockType.Function) {
      // Ensure content is retained if still a function
      this.block.function = block.function;
      e.modifiedBlock = this.block;
    }
    else {
      // No longer a function, change to multiplication with operand
      e.modifiedBlock = createBinaryOperatorBlock(block, '*', this.block.operand);
    }
  }
}

class NegativeBlockController extends BlockController<NegativeBlock> {
  toString() {
    return `-${this.children[0].toString()}`;
  }

  *getSymbols() {
    yield this.block.negativeSign;
    yield* this.children[0].getSymbols();
  }

  override getFocusTargetAfter(focus: Focus) {
    if (focus.type === FocusType.Block) return createSymbolFocus(this.block.negativeSign);
    return this.children[0].receiveFocusFromLeft();
  }

  override receiveFocusFromLeft() {
    if (this.createsNewContext) return createBlockFocus(this.block);
    return createSymbolFocus(this.block.negativeSign);
  }
  override receiveFocusFromRight() {
    return this.children[0].receiveFocusFromRight();
  }
  override receiveFocusFromChild(child: BlockController<BlockWithoutID>, focus: Focus, direction: Direction) {
    if (direction === 'left') return createSymbolFocus(this.block.negativeSign);
    if (direction === 'right') return this.propagateGetFocusTargetAfter(focus);
    if (direction === 'up') return this.propagateGetFocusTargetAbove(focus);
    if (direction === 'down') return this.propagateGetFocusTargetBelow(focus);
  }

  override handleChildChange(e: ChangeEvent) {
    if (e.modifiedBlock === undefined) {
      e.modifiedBlock = createPlaceholderBlock();
      e.newFocus = createSymbolFocus(e.modifiedBlock.placeholder);
      this.bubbleChangeEvent(e);
    }
    else this.block.content = e.modifiedBlock;
  }

  override onDeleteSymbol(e: DeletionEvent) {
    e.modifiedBlock = this.block.content;
    e.newFocus = createBlockFocus(this.block.content);
  }

  override onInsertSymbol(e: InsertionEvent, newSymbol: DigitOrCharacterSymbol) {
    if (e.originalFocus.target.id === this.block.id) {
      const { block, focus } = createBlockFromSymbols([newSymbol], this.variables, 0);
      e.modifiedBlock = createBinaryOperatorBlock(block, '-', this.block.content);
      e.newFocus = focus;
    }
    else {
      this.children[0].onInsertSymbol(e, newSymbol);
      if (e.modifiedBlock.type === BlockType.BinaryOperator) {
        this.block.content = e.modifiedBlock.lhs;
        e.modifiedBlock = createBinaryOperatorBlock(this.block, '*', e.modifiedBlock.rhs);
      }
      else {
        this.block.content = e.modifiedBlock;
        e.modifiedBlock = this.block;
      }
    }
  }

  override splitAtCursor(e: InsertionEvent): SplitBlock {
    if (e.originalFocus.target.id === this.block.id) return [undefined, this.block, undefined];
    // If inserting after negative symbol, wrap output block in negative
    return [undefined, this.block.content, (block) => {
      if (block.type === BlockType.BinaryOperator) {
        this.block.content = block.lhs;
        return createBinaryOperatorBlock(this.block, '*', block.rhs);
      }
      this.block.content = block;
      return this.block;
    }];
  }
}

class ParenthesesBlockController extends BlockController<ParenthesesBlock> {
  toString() {
    return `(${this.children[0].toString()})`;
  }

  *getSymbols() {
    yield this.block.leftParen;
    yield* this.children[0].getSymbols();
    yield this.block.rightParen;
  }

  override getFocusTargetBefore(focus: Focus) {
    if (focus.target.id === this.block.rightParen.id) return this.children[0].receiveFocusFromRight();
    if (this.createsNewContext) return createBlockFocus(this.block);
    return this.propagateGetFocusTargetBefore(focus);
  }
  override getFocusTargetAfter(focus: Focus) {
    if (focus.target.id === this.block.rightParen.id) {
      return this.propagateGetFocusTargetAfter(focus);
    }
    if (focus.target.id === this.block.id) return createSymbolFocus(this.block.leftParen);
    return this.children[0].receiveFocusFromLeft();
  }

  override receiveFocusFromLeft() {
    return createSymbolFocus(this.block.leftParen);
  }
  override receiveFocusFromRight() {
    return createSymbolFocus(this.block.rightParen);
  }

  override receiveFocusFromChild(child: BlockController, focus: Focus, direction: Direction) {
    if (direction === 'left') return createSymbolFocus(this.block.leftParen);
    if (direction === 'right') return createSymbolFocus(this.block.rightParen);
    if (direction === 'up') return this.propagateGetFocusTargetAbove(focus);
    if (direction === 'down') return this.propagateGetFocusTargetBelow(focus);
  }

  override onDeleteSymbol(e: DeletionEvent) {
    e.modifiedBlock = this.block.content;
    if (e.symbol.id === this.block.leftParen.id) e.newFocus = createBlockFocus(this.block.content);
    else e.newFocus = this.children[0].receiveFocusFromRight();
  }

  override splitAtCursor(e: InsertionEvent): SplitBlock {
    if (e.originalFocus.type === FocusType.Block) return [undefined, this.block, undefined];
    if (e.originalFocus.target.id === this.block.leftParen.id) {
      return [undefined, this.block.content, (block) => {
        this.block.content = block;
        return this.block;
      }];
    }
    return [this.block, undefined, undefined];
  }

  override handleChildChange(e: ChangeEvent): void {
    if (e.modifiedBlock === undefined) this.bubbleChangeEvent(e);
    else this.block.content = e.modifiedBlock;
  }

  override onInsertSymbol(e: InsertionEvent, newSymbol: DigitOrCharacterSymbol) {
    let { block: newBlock, focus } = createBlockFromSymbols([newSymbol], this.variables, 0);
    if (e.originalFocus.target.id === this.block.leftParen.id) {
      this.block.content = mergeBlocks(newBlock, this.block.content);
      newBlock = this.block;
    }
    else if (e.originalFocus.target.id === this.block.rightParen.id) {
      newBlock = createBinaryOperatorBlock(this.block, '*', newBlock);
    }
    else newBlock = createBinaryOperatorBlock(newBlock, '*', this.block);
    e.modifiedBlock = newBlock;
    e.newFocus = focus;
  }
}

class SymbolGroupBlockController extends BlockController<SymbolGroupBlock> {
  toString() {
    return this.block.symbols.map((symbol) => symbol.character).join('');
  }

  *getSymbols() {
    yield* this.block.symbols;
  }

  override getFocusTargetBefore(focus: Focus) {
    if (focus.type === FocusType.Block) return this.propagateGetFocusTargetBefore(focus);

    const selectedSymbolIdx = this.block.symbols.findIndex((symbol) => symbol.id === focus.target.id);
    if (selectedSymbolIdx === 0) {
      if (this.createsNewContext) return createBlockFocus(this.block);
      else return this.propagateGetFocusTargetBefore(focus);
    }

    const newIdx = selectedSymbolIdx - 1;
    return createSymbolFocus(this.block.symbols[newIdx]);
  }
  override getFocusTargetAfter(focus: Focus) {
    const selectedSymbolIdx = this.block.symbols.findIndex((symbol) => symbol.id === focus.target.id);

    const newIdx = selectedSymbolIdx + 1;

    if (newIdx === this.block.symbols.length) return this.propagateGetFocusTargetAfter(focus);
    return createSymbolFocus(this.block.symbols[newIdx]);
  }

  override receiveFocusFromLeft() {
    if (this.createsNewContext) return createBlockFocus(this.block);
    return createSymbolFocus(this.block.symbols[0]);
  }
  override receiveFocusFromRight() {
    return createSymbolFocus(this.block.symbols[this.block.symbols.length - 1]);
  }

  override onDeleteSymbol(e: DeletionEvent) {
    const modified = deleteFromBlock(this.block.symbols, e.symbol, this.variables);
    e.modifiedBlock = modified.block;
    e.newFocus = modified.focus;
  }

  override splitAtCursor(e: InsertionEvent): SplitBlock {
    const selectedSymbolIdx = this.block.symbols.findIndex((symbol) => (
      symbol.id === e.originalFocus.target.id
    ));
    // Add one since cursor comes after the symbol
    const splitIdx = selectedSymbolIdx + 1;

    const lhsSymbols = this.block.symbols.slice(0, splitIdx);
    const lhs = lhsSymbols.length ? createBlockFromSymbols(lhsSymbols, this.variables).block : undefined;
    const rhsSymbols = this.block.symbols.slice(splitIdx);
    const rhs = rhsSymbols.length ? createBlockFromSymbols(rhsSymbols, this.variables).block : undefined;
    return [lhs, rhs, undefined];
  }

  override onInsertSymbol(e: InsertionEvent, newSymbol: DigitOrCharacterSymbol): void {
    let selectedSymbolIdx: number;
    if (e.originalFocus.type === FocusType.Block) selectedSymbolIdx = -1;
    else {
      selectedSymbolIdx = this.block.symbols.findIndex((symbol) => (
        symbol.id === e.originalFocus.target.id
      ));
    }

    const newSymbols = [...this.block.symbols];
    const insertedSymbolIdx = selectedSymbolIdx + 1;
    newSymbols.splice(insertedSymbolIdx, 0, newSymbol);
    const prioritizeMovingIntoFunction = this.parent?.block.type !== BlockType.Function;
    const { block, focus } = createBlockFromSymbols(
      newSymbols,
      this.variables,
      insertedSymbolIdx,
      prioritizeMovingIntoFunction
    );
    e.modifiedBlock = block;
    e.newFocus = focus;
  }
}

class MismatchedParenthesisBlockController extends BlockController<MismatchedParenthesisBlock> {
  toString() {
    return this.block.symbol.character;
  }

  *getSymbols() {
    yield this.block.symbol;
  }

  override onDeleteSymbol(e: DeletionEvent) {
    e.modifiedBlock = undefined;
    e.newFocus = this.propagateGetFocusTargetAfter(e.originalFocus);
  }
}

class InvalidBlockController extends BlockController<InvalidBlock> {
  toString() {
    return this.children[0].toString();
  }

  *getSymbols() {
    yield* this.children[0].getSymbols();
  }

  override handleChildChange(e: DeletionEvent): void {
    this.bubbleChangeEvent(e);
  }
}

class PlaceholderBlockController extends BlockController<PlaceholderBlock> {
  toString() {
    // TODO: might want to throw instead
    return '';
  }

  *getSymbols() {
    yield this.block.placeholder;
  }

  override getFocusTargetBefore(focus: Focus): Focus {
    return this.propagateGetFocusTargetBefore(focus);
  }

  override receiveFocusFromLeft(): Focus {
    return createSymbolFocus(this.block.placeholder);
  }
  override receiveFocusFromRight(): Focus {
    return createSymbolFocus(this.block.placeholder);
  }

  override onDeleteSymbol(e: DeletionEvent) {
    e.modifiedBlock = undefined;
    e.newFocus = this.propagateGetFocusTargetBefore(e.originalFocus);
  }

  override splitAtCursor(e: InsertionEvent): SplitBlock {
    return [undefined, undefined, undefined];
  }

  override onInsertSymbol(e: InsertionEvent, newSymbol: DigitOrCharacterSymbol): void {
    const { block, focus } = createBlockFromSymbols([newSymbol], this.variables, 0);
    e.modifiedBlock = block;
    e.newFocus = focus;
  }
}

type BlockControllerConstructor = {
  new (props: BlockControllerConstructorProps): BlockController
};
export const blockControllerTypes: Record<BlockType, BlockControllerConstructor> = {
  [BlockType.BinaryOperator]: BinaryOperatorBlockController,
  [BlockType.DataModelVariable]: DataModelVariableBlockController,
  [BlockType.Division]: DivisionBlockController,
  [BlockType.Exponentiation]: ExponentiationBlockController,
  [BlockType.Function]: FunctionBlockController,
  [BlockType.Negative]: NegativeBlockController,
  [BlockType.Parentheses]: ParenthesesBlockController,
  [BlockType.SymbolGroup]: SymbolGroupBlockController,
  [BlockType.InvalidFunctionOrVariable]: InvalidBlockController,
  [BlockType.MismatchedParenthesis]: MismatchedParenthesisBlockController,
  [BlockType.Unparsable]: InvalidBlockController,
  [BlockType.Placeholder]: PlaceholderBlockController,
};

export function createController(block: Block, variables: Map<string, EngineeringChecklist.Variable>): BlockController {
  const Controller = blockControllerTypes[block.type];
  return new Controller({ block, createsNewContext: true, variables });
}
