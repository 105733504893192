import React from 'react';
import Typography from './Typography';
import InputField from './InputField';
import { convertUnits } from '../../utils/units';
import UnitSelect from './UnitSelect';
import { validateIsNumber } from '../../utils/inputValidation';
import { RangeValues, UnitSet } from '../../types/units';

type RangeProps<US extends UnitSet> = RangeValues<US> & {
  onMinChange: (min: string) => any;
  onMaxChange: (max: string) => any;
  onUnitChange: (unit: string) => any;
}

const Range = <US extends UnitSet>(
  { min, onMinChange, max, onMaxChange, unit, onUnitChange, unitSet }: RangeProps<US>
) => {
  const handleUnitChange = async (newUnit: string) => {
    onUnitChange(newUnit);
    let newMin = min;
    if (validateIsNumber(min)) {
      newMin = (await convertUnits(Number.parseFloat(min), unitSet, unit, newUnit)).toString();
      onMinChange(newMin);
    }
    let newMax = max;
    if (validateIsNumber(max)) {
      newMax = (await convertUnits(Number.parseFloat(max), unitSet, unit, newUnit)).toString();
      onMaxChange(newMax);
    }
  };

  return (
    <div className="flex gap-2 items-center">
      <div className="w-60">
        <InputField
          label="Min"
          type="decimal"
          allowNegative
          value={min}
          onChange={onMinChange}
        />
      </div>
      <Typography variant="body1" className="text-nowrap">-</Typography>
      <div className="w-60">
        <InputField
          label="Max"
          type="decimal"
          allowNegative
          value={max}
          onChange={onMaxChange}
        />
      </div>
      <div className="w-32">
        <UnitSelect value={unit} unitSet={unitSet} onChange={handleUnitChange} />
      </div>
    </div>
  );
};

export default Range;
