import React from 'react';
import Typography from './Typography';
import DynamicFlexContainer from './DynamicFlexContainer';

export interface MultiSidebarItemProps {
  icon?: React.ElementType<{ className?: string }>;
  title: string;
  content: React.ReactNode;
}

interface MultiSidebarProps {
  sidebars: MultiSidebarItemProps[];
}

const MultiSidebar: React.FC<MultiSidebarProps> = ({ sidebars }) => {
  const sidebarComponents = sidebars.map((sidebar) => (
    <div className="flex flex-col h-full w-full" key={sidebar.title}>
      <div className="sticky top-0 left-0 z-10 flex items-center gap-x-1 pl-1 py-1 bg-slate-100 border-b border-slate-300">
        {sidebar.icon && <sidebar.icon className="size-7 text-slate-500 fill-white" />}
        <Typography className="truncate" variant="h6">{sidebar.title}</Typography>
      </div>
      <div className="bg-white h-full w-full overflow-auto">
        {sidebar.content}
      </div>
    </div>
  ));

  return (
    <DynamicFlexContainer direction="column" className="w-full h-full">
      {sidebarComponents}
    </DynamicFlexContainer>
  );
};

export default MultiSidebar;
