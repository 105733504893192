import React from 'react';
import { useNavigate } from 'react-router-dom';

import Form from '../common/Form/Form';
import { reset as resetError } from '../../redux/errors';
import { useDispatch, useSelector } from '../../redux/store';
import { updatePassword } from '../../utils/API';
import { validatePassword } from '../../utils/inputValidation';
import { setNotification } from '../../redux/notifications';
import useCurrentUser from '../../hooks/useCurrentUser';
import { authenticationSlice } from '../../redux/authentication';

const ChangePasswordPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const email = useSelector((state) => state.authentication.user?.email ?? state.authentication.signedOutEmail);
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = React.useState('');
  const [requestProcessing, setRequestProcessing] = React.useState(false);


  const displayEmailInput = user === null;
  const passwordIssues = validatePassword(newPassword, newPasswordConfirmation);
  const canSubmit = !passwordIssues.length && !requestProcessing;

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(resetError());
    if (!canSubmit) return;

    setRequestProcessing(true);
    updatePassword(email, currentPassword, newPassword)
      .then(() => {
        setRequestProcessing(false);
        dispatch(setNotification({ message: 'Password successfully changed.'}));
        navigate('/account');
      })
      .catch(() => {
        setCurrentPassword('');
        setRequestProcessing(false);
      });
  };

  const handleEmailChange = (newEmail: string) => {
    dispatch(authenticationSlice.actions.setSignedOutEmail(newEmail));
    dispatch(resetError());
  };
  const handleCurrentPasswordChange = (value: string) => {
    setCurrentPassword(value);
    dispatch(resetError());
  };
  const handleNewPasswordChange = (value: string) => {
    setNewPassword(value);
    dispatch(resetError());
  };
  const handleNewPasswordConfirmationChange = (value: string) => {
    setNewPasswordConfirmation(value);
    dispatch(resetError());
  };

  const handleBack = () => {
    const previousPage = user === null ? '/' : '/account';
    navigate(previousPage);
  };

  const emailInput = displayEmailInput && (
    <Form.Item label="Email" type="email" value={email} onChange={handleEmailChange} />
  );

  return (
    <Form.Container title="Change Password" canSubmit={canSubmit} onSubmit={handleSubmit} loading={requestProcessing} onBack={handleBack}>
      {emailInput}
      <Form.Item label="Current Password" type="password" value={currentPassword} onChange={handleCurrentPasswordChange} />
      <Form.Item label="New Password" type="password" value={newPassword} onChange={handleNewPasswordChange} />
      <Form.Item label="Confirm New Password" type="password" value={newPasswordConfirmation} onChange={handleNewPasswordConfirmationChange} />
    </Form.Container>
  );
};

export default ChangePasswordPage;
