import React from 'react';
import { Line } from 'recharts';
import Data from './types';
import { inverseLerp, lerp, linspace } from '../math';
import { CurveType } from 'recharts/types/shape/Curve';
import PointArrayDot from './PointArrayDot';
import AdditionalXDot from './AdditionalXDot';
import { mapIterable } from '../iterables';
import { SynchronousUnitConversionFunction } from '../../hooks/useUnitConversions';

// Number of points to generate inside domain for continuous functions
// like polynomials and hyperbolic curves
const NUM_CONTINUOUS_FUNCTION_VALUES = 200;

// Array of stroke colors to use, these are repeated if too many are used
const strokeColors = [
  '#3e6991',
  '#fcb441',
  '#3bd8a9',
  '#d50026',
  '#418cf0',
  '#f58f31',
  '#00713c',
  '#ff5958',
  '#961eea',
  '#d80080',
  '#808080',
];

interface GenericDataSourceProps {
  label: string;
  interpolationType: CurveType;
  xUnits?: Data.Unit;
  yUnits?: Data.Unit;
  convert?: SynchronousUnitConversionFunction;
  labelElement?: React.ReactNode;
}
// Props for data sources extending a concrete DataSource implementation
type GenericDataSourcePropOverrides = (
  Partial<GenericDataSourceProps>
  & Pick<GenericDataSourceProps, 'label'>
);

interface DataSourceOutput {
  components: React.ReactNode[];
  dataPoints: IterableIterator<Data.Source.Point>;
}

export abstract class DataSource {
  #label: string;
  #name?: string | React.ReactNode;
  #interpolationType: CurveType;
  convert: SynchronousUnitConversionFunction = () => { throw new Error('convert must be defined if specifying x or y units.'); };
  // target[X/Y]Units: desired output units
  targetXUnits?: Data.Unit;
  targetYUnits?: Data.Unit;
  index: number;

  // Optional instance variables to customize appearance of components
  protected internalAdditionalXs?: Set<number>;
  protected linePropOverrides?: React.ComponentPropsWithoutRef<typeof Line>;
  // [x/y]Units: native units of this data source
  protected xUnits?: Data.Unit;
  protected yUnits?: Data.Unit;
  protected minValidX?: number;
  protected maxValidX?: number;
  protected minValidY?: number;

  constructor(props: GenericDataSourceProps) {
    this.#label = props.label;
    this.#interpolationType = props.interpolationType;
    this.xUnits = props.xUnits;
    this.yUnits = props.yUnits;
    this.#name = props.labelElement;
  }

  get label(): string { return this.#label; }

  static strokeColorForIdx(idx: number | undefined): string {
    return strokeColors[(idx ?? 0) % strokeColors.length]
  }

  protected get stroke(): string {
    return DataSource.strokeColorForIdx(this.index);
  }

  set additionalXs(xs: number[] | Set<number>) {
    this.internalAdditionalXs = Array.isArray(xs) ? new Set(xs) : xs;
  }

  // Abstract functions to generate data for this data source
  protected abstract getValueAt(x: number): number;
  protected abstract data(xDomain: Data.Domain): IterableIterator<Data.Point>;

  #toDataSourcePoint({ x, y }: Data.Point): Data.Source.Point {
    // Determine whether the point is interpolated or extrapolated
    let withinValidRange = true;
    if (this.minValidX !== undefined && x < this.minValidX) {
      withinValidRange = false;
    }
    else if (this.maxValidX !== undefined && x > this.maxValidX) {
      withinValidRange = false;
    }

    // Convert x unit if necessary
    if (this.xUnits && this.targetXUnits) x = this.convert(
      x,
      this.xUnits.unitSet,
      this.xUnits.unit,
      this.targetXUnits.unit
    );

    // Constrain y appropriately
    if (this.yUnits && this.targetYUnits) y = this.convert(
      y,
      this.yUnits.unitSet,
      this.yUnits.unit,
      this.targetYUnits.unit
    );
    y = this.#constrainY(y);

    // Wrap the value so that the appopriate line can use the value
    let value: Data.Source.Value;
    if (withinValidRange) value = { interpolated: y };
    else value = { extrapolated: y };


    return { x, y, value };
  }

  // Constrains y to the valid specified range
  #constrainY(y: number): number {
    if (this.minValidY === undefined || y >= this.minValidY) return y;
    return this.minValidY;
  }

  calculatePointValue(x: number, preconverted = true): Data.Source.Point {
    if (preconverted && this.xUnits && this.targetXUnits) {
      x = this.convert(x, this.xUnits.unitSet, this.targetXUnits.unit, this.xUnits.unit);
    }
    let y = this.getValueAt(x);
    return this.#toDataSourcePoint({ x, y });
  }

  protected components(injectedOverrides?: React.ComponentPropsWithoutRef<typeof Line>): React.ReactNode[] {
    // Note: name must be coerced to string type as custom labels and legends
    // support other types for the name prop, but the official type annotation doesn't

    // Create line to show interpolated data (data within the valid range)
    const components: React.ReactNode[] = [
      <Line
        type={this.#interpolationType}
        connectNulls
        name={(this.#name ?? this.label) as string}
        dataKey={`${this.label}.interpolated`}
        stroke={this.stroke}
        strokeWidth={2}
        dot={<AdditionalXDot additionalXs={this.internalAdditionalXs} />}
        activeDot={<AdditionalXDot additionalXs={this.internalAdditionalXs} active />}
        isAnimationActive={false}
        key={`${this.label}-interpolation`}
        {...this.linePropOverrides}
        {...injectedOverrides}
      />,
    ];

    // Create dashed line to show extrapolated data (data outside the valid range)
    if (this.minValidX ?? this.maxValidX !== undefined) {
      components.push(
        <Line
          type={this.#interpolationType}
          name={(this.#name ?? this.label) as string}
          dataKey={`${this.label}.extrapolated`}
          stroke={this.stroke}
          strokeDasharray="3 3"
          dot={<AdditionalXDot additionalXs={this.internalAdditionalXs} />}
          activeDot={<AdditionalXDot additionalXs={this.internalAdditionalXs} active />}
          isAnimationActive={false}
          key={`${this.label}-extrapolation`}
        />
      );
    }

    return components;
  }

  *#getDataPoints(xDomain: Data.Domain): Generator<Data.Source.Point> {
    for (const point of this.data(xDomain)) yield this.#toDataSourcePoint(point);

    // If this data source has a min or max valid value,
    // add points at them to make extrapolation lines flush with interpolation
    if (this.minValidX !== undefined) {
      const y = this.getValueAt(this.minValidX);
      const point = this.#toDataSourcePoint({ x: this.minValidX, y});
      point.value = { interpolated: point.y, extrapolated: point.y };
      yield point;
    }
    if (this.maxValidX !== undefined) {
      const y = this.getValueAt(this.maxValidX);
      const point = this.#toDataSourcePoint({ x: this.maxValidX, y});
      point.value = { interpolated: point.y, extrapolated: point.y };
      yield point;
    }
  }

  outputs(xDomain: Data.Domain): DataSourceOutput {
    return {
      components: this.components(),
      dataPoints: this.#getDataPoints(xDomain),
    };
  }
}

// PolynomialTerms: form is t[0]*x^0 + t[1]*x^1 + t[2]*x^2 + ... + t[n]*x^n
export type PolynomialTerms = number[];

export class Polynomial extends DataSource {
  #terms: PolynomialTerms;

  constructor(props: GenericDataSourcePropOverrides, terms: PolynomialTerms) {
    super({
      interpolationType: 'monotone',
      ...props,
    });
    this.#terms = terms;
  }

  getValueAt(x: number): number {
    let y = 0;
    for (const [i, a_i] of this.#terms.entries()) { y += a_i * Math.pow(x, i); }
    return y;
  }

  protected *data(xDomain: Data.Domain): Generator<Data.Point> {
    const { min, max } = xDomain;

    for (const x of linspace(min, max, NUM_CONTINUOUS_FUNCTION_VALUES)) {
      yield { x, y: this.getValueAt(x) };
    }
  }
}

export interface HyperbolaParameters {
  c0: number;
  c1: number;
  c2: number;
  c3: number;
}

export class EllipticalCurve extends DataSource {
  #params: HyperbolaParameters;

  constructor(props: GenericDataSourcePropOverrides, parameters: HyperbolaParameters) {
    super({
      interpolationType: 'monotone',
      ...props,
    });
    this.#params = parameters;
  }

  getValueAt(x: number): number {
    const c1mod = x - this.#params.c1;
    return Math.sqrt(this.#params.c0 + c1mod * c1mod / this.#params.c2) + this.#params.c3;
  }

  protected *data(xDomain: Data.Domain): Generator<Data.Point> {
    const { min, max } = xDomain;
    for (const x of linspace(min, max, NUM_CONTINUOUS_FUNCTION_VALUES)) {
      yield { x, y: this.getValueAt(x) };
    }
  }
}

export class PointArray extends DataSource {
  #points: Data.Point[];
  #dataPointXs: Set<number>;

  constructor(props: GenericDataSourcePropOverrides, points: Data.Point[]) {
    super({
      interpolationType: 'linear',
      ...props,
    });

    this.#points = [...points];
    if (this.#points.length === 0) throw new TypeError('Cannot initialize PointArray with no points.');
    this.#points.sort((a, b) => a.x - b.x);

    // Use custom dot to only display dots for explicitly specified data points
    this.#dataPointXs = new Set(this.#points.map(({ x }) => x));

    // Mark values outside of the point array's range as invalid
    this.minValidX = this.#points[0].x;
    this.maxValidX = this.#points[this.#points.length - 1].x;
  }

  protected override components(): React.ReactNode[] {
    let scaledDataPointXs = this.#dataPointXs;
    if (this.xUnits && this.targetXUnits) {
      scaledDataPointXs = new Set(
        mapIterable(this.#dataPointXs.values(), (x) => (
          this.convert(x, this.xUnits.unitSet, this.xUnits.unit, this.targetXUnits.unit)
        ))
      );
    }

    return super.components({
      dot: (
        <AdditionalXDot
          additionalXs={this.internalAdditionalXs}
          fallback={(props) => <PointArrayDot {...props} xCoordinates={scaledDataPointXs} />}
        />
      ),
      activeDot: (
        <AdditionalXDot
          additionalXs={this.internalAdditionalXs}
          active
          fallback={(props) => <PointArrayDot {...props} xCoordinates={scaledDataPointXs} />}
        />
      ),
    });
  }

  getValueAt(x: number): number {
    // If correlation only has a single value, return it
    if (this.#points.length === 1) return this.#points[0].y;

    // Find lowest point index lower than x
    let idx = 0;
    while (this.#points[idx].x < x && idx < this.#points.length - 1) { idx++; }
    idx = Math.max(idx - 1, 0);

    // Interpolate between the appropriate points
    const pointBeforeOrAtX = this.#points[idx];
    const pointAfterX = this.#points[idx + 1];

    const t = inverseLerp(pointBeforeOrAtX.x, pointAfterX.x, x);
    const y = lerp(pointBeforeOrAtX.y, pointAfterX.y, t);
    return y;
  }

  protected *data(xDomain: Data.Domain): Generator<Data.Point> {
    // Generate a point for each point in the data source
    for (const point of this.#points) {
      yield point;
    }

    // Generate an additional point on the min and max x values
    // to ensure extrapolation goes to the edges of the graph
    yield { x: xDomain.min, y: this.getValueAt(xDomain.min) };
    yield { x: xDomain.max, y: this.getValueAt(xDomain.max) };
  }
}
