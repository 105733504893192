const AddRemoveButton = ({ onAddChange, onRemoveChange }) => {
    return (
        <div className="m-1 p-1 justify-center">
            <button
                className="w-20 h-8 bg-green-600 border-2 border-green-600 rounded-l-xl text-xl hover:ring-1 hover:ring-green-600 hover:text-white  hover:font-bold"
                type="button"
                onClick={onAddChange}>+
            </button>
            <button
                className="w-20 h-8 bg-red-400 border-2 border-red-400 rounded-r-xl text-xl hover:ring-1 hover:ring-red-400 hover:text-white hover:font-bold"
                type="button"
                onClick={onRemoveChange}>-
            </button>
        </div>
    );
}

export default AddRemoveButton;