import React from 'react';
import { BlockProps, BlockType, InvalidBlock } from '../types';
import Tooltip from '../../Tooltip';
import Symbol from '../Symbol';
import Block from './Block';

function createErrorMessage(block: InvalidBlock): string {
  if (block.type === BlockType.MismatchedParenthesis) return 'Mismatched parenthesis';
  const text = block.symbolGroup.symbols.map((symbol) => symbol.character).join('');
  if (block.type === BlockType.InvalidFunctionOrVariable) return `Invalid function or variable: ${text}`;
  return `Failed to parse "${text}"`;
}

const Invalid: React.FC<BlockProps> = ({ block }) => {
  const invalidBlock = block as InvalidBlock;
  const label = createErrorMessage(invalidBlock);

  let symbolComponents: React.ReactNode;
  if (invalidBlock.type === BlockType.MismatchedParenthesis) {
    symbolComponents = <Symbol symbol={invalidBlock.symbol} />
  }
  else {
    symbolComponents = <Block block={invalidBlock.symbolGroup} />
  }

  return (
    <Tooltip label={label} width="md">
      <div className="flex flex-row items-center px-1 bg-red-200 border-[1px] border-red-300 rounded-sm">
        {symbolComponents}
      </div>
    </Tooltip>
  );
};

export default Invalid;
