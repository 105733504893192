import React from 'react';

export default function useFilePicker<AsArrayBuffer extends boolean = false>(options: {
  accept: string;
  multiple?: boolean;
  readAsArrayBuffer?: AsArrayBuffer;
}) {
  const fileInput = React.useMemo(() => {
    const element = document.createElement('input');
    element.style.setProperty('display', 'hidden');
    element.setAttribute('type', 'file');
    element.setAttribute('multiple', options.multiple ? 'true' : undefined);
    element.setAttribute('accept', options.accept);

    return element;
  }, []);

  type OnSuccessContentType = AsArrayBuffer extends true ? ArrayBuffer : string;
  const openFilePicker = (handlers: {
    onSuccess: (content: OnSuccessContentType) => any,
    onError?: (error: DOMException) => any,
  }) => {
    // Clear input value
    fileInput.value = null;

    // Set change event handler to invidually call handlers with each selected file
    fileInput.onchange = () => {
      for (const file of fileInput.files) {
        const reader = new FileReader();
        reader.onload = () => {
          handlers.onSuccess(reader.result as OnSuccessContentType);
        };
        reader.onerror = () => {
          if (handlers.onError) handlers.onError(reader.error)
        };

        if (options.readAsArrayBuffer) reader.readAsArrayBuffer(file);
        else reader.readAsText(file);
      }
    }

    fileInput.click();
  }

  return openFilePicker;
}
