import React from 'react';
import Paper from './Paper';
import Typography from './Typography';
import { useNavigate } from 'react-router-dom';
import Link from './Link';

interface HomeRedirectPageProps {
  title: string;
  children: string;
}

const HomeRedirectPage: React.FC<HomeRedirectPageProps> = ({ title, children }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex justify-center">
      <Paper className="w-128 max-w-full m-4">
        <Typography variant="h5">{title}</Typography>
        <Typography className="mt-3 mb-1" variant="body1">
          {children}
        </Typography>
        <Link onClick={() => navigate('/')}>Click here to return to the homepage.</Link>
      </Paper>
    </div>
  );
};

export default HomeRedirectPage;
