import React from 'react';

interface CursorProps {
  style?: React.CSSProperties;
}

const Cursor: React.FC<CursorProps> = ({ style }) => {
  const [visible, setVisible] = React.useState(true);

  React.useEffect(() => {
    const blinkTimeout = setTimeout(() => setVisible(!visible), 800);
    return () => clearTimeout(blinkTimeout);
  }, [visible]);

  const visibilityClass = visible ? ' opacity-100' : ' opacity-0';

  return (
    <span className={`${visibilityClass} -ml-px w-px bg-black`} style={style} />
  );
};

export default Cursor;
