import { React, createContext, useContext, useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { set as setError, reset as resetError } from '../redux/errors';
import UnitsContext from './UnitsContext'

import { createPropertySets } from '../utils/PropertySetsFactory'
import { AuthenticationStatus } from '../redux/authentication';
import { RootState } from '../redux/store';

const DewPointCalculatorContext = createContext();

export const DewPointCalculatorProvider: React.FC = ({ children }) => {

  const dispatch = useDispatch();
  const authStatus = useSelector<RootState, AuthenticationStatus>((state) => state.authentication.status);
  const { addUnitOptions } = useContext(UnitsContext);

  const [status, setStatus] = useState('Unsolved');

  const errState = useRef(false);
  const id = useRef(null);
  const result = useRef({});
  const sidebarTree = useRef([
    {
      label: 'Dew-Point Calculator',
      endpoint: 'dew-point-calculator',
      children: [
        {
          label: 'Inputs',
          endpoint: 'inputs',
          children: [],
        },
        {
          label: 'Results',
          endpoint: 'results',
          children: [],
        },
      ],
    },
  ]);
  const propertyMap = useRef(new Map([
    [
      'dew-point-calculator/inputs/P',
      {
        id: 'dew-point-calculator/inputs/P',
        type: 'scalar',
        label: 'Pressure',
        unitSet: 'pressure',
        unitOptions: [],
        userSpecified: true,
        value: Number.NaN,
        unit: 'Pa',
        displayUnit: 'Pa',
      },
    ],
    [
      'dew-point-calculator/inputs/Z',
      {
        id: 'dew-point-calculator/inputs/Z',
        type: 'composition',
        label: 'Vapor Composition',
        userSpecified: true,
        basis: 'molar',
        components: [],
      },
    ],
    [
      'dew-point-calculator/results/Tdew',
      {
        id: 'dew-point-calculator/results/Tdew',
        type: 'scalar',
        label: 'Dew-Point Temperature',
        unitSet: 'temperature',
        unitOptions: [],
        userSpecified: false,
        value: Number.NaN,
        unit: 'K',
        displayUnit: 'K',
      },
    ],
    [
      'dew-point-calculator/results/X',
      {
        id: 'dew-point-calculator/results/X',
        type: 'composition',
        label: 'Liquid Composition',
        userSpecified: false,
        basis: 'molar',
        components: [],
      },
    ],
    [
      'dew-point-calculator/results/RhoL',
      {
        id: 'dew-point-calculator/results/RhoL',
        type: 'scalar',
        label: 'Density',
        unitSet: 'molar-density',
        unitOptions: [],
        userSpecified: false,
        value: Number.NaN,
        unit: 'mol/m^3',
        displayUnit: 'mol/m^3',
      },
    ],
    [
      'dew-point-calculator/results/MuL',
      {
        id: 'dew-point-calculator/results/MuL',
        type: 'scalar',
        label: 'Viscosity',
        unitSet: 'dynamic-viscosity',
        unitOptions: [],
        userSpecified: false,
        value: Number.NaN,
        unit: 'Pa*s',
        displayUnit: 'Pa*s',
      },
    ],
    [
      'dew-point-calculator/results/CpL',
      {
        id: 'dew-point-calculator/results/CpL',
        type: 'scalar',
        label: 'Heat Capacity',
        unitSet: 'molar-entropy',
        unitOptions: [],
        userSpecified: false,
        value: Number.NaN,
        unit: 'J/(mol*K)',
        displayUnit: 'J/(mol*K)',
      },
    ],
    [
      'dew-point-calculator/results/HL',
      {
        id: 'dew-point-calculator/results/HL',
        type: 'scalar',
        label: 'Enthalpy',
        unitSet: 'molar-energy',
        unitOptions: [],
        userSpecified: false,
        value: Number.NaN,
        unit: 'J/mol',
        displayUnit: 'J/mol',
      },
    ],
    [
      'dew-point-calculator/results/SL',
      {
        id: 'dew-point-calculator/results/SL',
        type: 'scalar',
        label: 'Entropy',
        unitSet: 'molar-entropy',
        unitOptions: [],
        userSpecified: false,
        value: Number.NaN,
        unit: 'J/(mol*K)',
        displayUnit: 'J/(mol*K)',
      },
    ],
  ]));
  const propertyMutators = useRef({
    updateProcessValue: (id, value) => {
      if (propertyMap.current.has(id)) {
        const p = propertyMap.current.get(id);
        p.value = value;
        // const x = propertyMap.current.get(id);
        // console.log(`${x.label} = ${x.value} (${x.unit}) [${id}]`);
      }
    },
    updateProcessValueDisplayUnit: (id, displayUnit) => {
      if (propertyMap.current.has(id)) {
        const p = propertyMap.current.get(id);
        p.displayUnit = displayUnit;
      }
    },
    updateComponents: (id, basis, components) => {
      if (propertyMap.current.has(id)) {
        const p = propertyMap.current.get(id);
        p.basis = basis;
        p.components = components;
      }
    },
    updateCompositionBasis: (id, basis) => {
      if (propertyMap.current.has(id)) {
        const p = propertyMap.current.get(id);
        p.basis = basis;
      }
    },
    updateComponentName: (id, index, name) => {
      if (propertyMap.current.has(id)) {
        const p = propertyMap.current.get(id);
        if (p.components.length > index) {
          p.components[index].name = name;
        }
      }
    },
    updateComponentValue: (id, index, value) => {
      if (propertyMap.current.has(id)) {
        const p = propertyMap.current.get(id);
        if (p.components.length > index) {
          p.components[index].value = Number(value);
        }
      }
    },
    getCompositionSum: (id) => {
      let sum = 0.0;
      if (propertyMap.current.has(id)) {
        const p = propertyMap.current.get(id);
        for (let index = 0; index < p.components.length; ++index) {
          sum += Number(p.components[index].value);
        }
      }
      return sum;
    },
    getCompositionFractions: (id) => {
      const fractions = [];
      if (propertyMap.current.has(id)) {
        const p = propertyMap.current.get(id);
        let sum = 0.0;
        for (let index = 0; index < p.components.length; ++index) {
          sum += Number(p.components[index].value);
        }
        for (let index = 0; index < p.components.length; ++index) {
          fractions.push(p.components[index].value / sum);
        }
      }
      return fractions;  
    }
  });
  const propertySets = useRef(createPropertySets(sidebarTree.current, propertyMap.current));

  const {updateProcessValue, updateComponents} = propertyMutators.current;

  const controlActions = useRef({
    run: () => {
      const initialize = async () => {
        errState.current = false;
        return setStatus('Solving');
      };
      const postConfiguration = async () => {
        const Z = propertyMap.current.get('dew-point-calculator/inputs/Z');
        const payload = {
          Basis: Z.basis,
        };
        const endpoint = `/api/v1/refprop/configuration`;
        return fetch(endpoint, {
          method: 'POST',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            if (res.status !== 200) {
              errState.current = true;
            }
            return res.json();
          })
          .then((resBody) => {
            if (errState.current) {
              if (resBody) {
                const { Error:msg } = resBody;
                if (msg) {
                  throw Error(`${endpoint} returned error: ${msg}`);
                } 
              }
              throw Error(`${endpoint} returned an error`);
            }
          }).catch((err) => dispatch(setError(err)));
      };
      const postMixture = async () => {
        const Z = propertyMap.current.get('dew-point-calculator/inputs/Z');
        let Components = {};
        Z.components.forEach((p) => {
          Components[p.name] = p.value;
        });
        const payload = {
          Basis: Z.basis,
          Components,
        };
        const endpoint = `/api/v1/refprop/mixtures`;
        return fetch(endpoint, {
          method: 'POST',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            if (res.status !== 200) {
              errState.current = true;
            }
            return res.json();
          })
          .then((resBody) => {
            if (errState.current) {
              if (resBody) {
                const { Error:msg } = resBody;
                if (msg) {
                  throw Error(`${endpoint} returned error: ${msg}`);
                } 
              }
              throw Error(`${endpoint} returned an error`);
            }
            id.current = resBody.Id;
          }).catch((err) => dispatch(setError(err)));
      };
      const postDewPointAtP = async () => {
        if (errState.current) return;
        const { value } = propertyMap.current.get('dew-point-calculator/inputs/P');
        const P = [];
        P.push(Number(value));
        const payload = { Pressures: P };
        const endpoint = `/api/v1/refprop/mixtures/${id.current}/dew-point/pressures`;
        return fetch(endpoint, {
          method: 'POST',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            if (res.status !== 200) {
              errState.current = true;
            }
            return res.json();
          })
          .then((resBody) => {
            if (errState.current) {
              if (resBody) {
                const { Error:msg } = resBody;
                if (msg) {
                  throw Error(`${endpoint} returned error: ${msg}`);
                } 
              }
              throw Error(`${endpoint} returned an error`);
            }
            result.current = resBody;
          })
          .catch((err) => dispatch(setError(err)));
      };
      const updateResults = () => {
        if (errState.current) return;
        const { Temperature, Phases } = result.current.at(0);
        updateProcessValue('dew-point-calculator/results/Tdew', Temperature);
        const liquidPhase = Phases.filter((p) => p.Phase === 'Liquid');
        const { Density, Viscosity, HeatCapacity, Enthalpy, Entropy, Composition } =
          liquidPhase.at(0);
        updateProcessValue('dew-point-calculator/results/RhoL', Density);
        updateProcessValue('dew-point-calculator/results/MuL', Viscosity);
        updateProcessValue('dew-point-calculator/results/CpL', HeatCapacity);
        updateProcessValue('dew-point-calculator/results/HL', Enthalpy);
        updateProcessValue('dew-point-calculator/results/SL', Entropy);
        const components = [];
        for (let [key, value] of Object.entries(Composition)) {
          components.push({ name: key, value });
        }
        updateComponents('dew-point-calculator/results/X', Composition.basis, components);
      };
      initialize().then(() => {
        postConfiguration().then(() => {
          postMixture().then(() => {
            postDewPointAtP().then(() => {
              updateResults();
              return setStatus(errState.current? 'Failed' : 'Solved');
            });
          });
        });
      });
    },
    load: (file) => {
      console.log(file.content);
    },
    save: () => {
    },
    reset: () => {
      const resetResults = () => {
        updateProcessValue('dew-point-calculator/results/Tdew', Number.NaN);
        updateProcessValue('dew-point-calculator/results/RhoL', Number.NaN);
        updateProcessValue('dew-point-calculator/results/MuL', Number.NaN);
        updateProcessValue('dew-point-calculator/results/CpL', Number.NaN);
        updateProcessValue('dew-point-calculator/results/HL', Number.NaN);
        updateProcessValue('dew-point-calculator/results/SL', Number.NaN);
        updateComponents('dew-point-calculator/results/X', 'molar', []);
      };
      dispatch(resetError());
      resetResults();
      setStatus('Unsolved');
    }
  });

  useEffect(() => {
    if (authStatus === AuthenticationStatus.Authorized) {
      addUnitOptions(propertyMap.current);
    }
  }, [authStatus]);

  return (
    <DewPointCalculatorContext.Provider
      value={{
        status,
        sidebarTree: sidebarTree.current,
        propertySets: propertySets.current,
        propertyMutators: propertyMutators.current,
        controlActions: controlActions.current
      }}
    >
      {children}
    </DewPointCalculatorContext.Provider>
  );
};

export default DewPointCalculatorContext;
