import { React, createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { set as setError } from '../redux/errors';

const RefPropContext = createContext();

export const RefPropProvider: React.FC = ({ children }) => {

  const [species, setSpecies] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    fetch(
      `/api/v1/refprop/configuration/components`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((resBody) => {
        if (Array.isArray(resBody)) {
          return setSpecies(resBody);
        }
        return setSpecies([]);
      })
      .catch((err) => {
        return dispatch(setError(err));
      });
  }, []);

  return (
    <RefPropContext.Provider value={{ species }}>
      {children}
    </RefPropContext.Provider>
  );
};

export default RefPropContext;
