import React from 'react';
import Typography from '../../common/Typography';
import { useDispatch, useSelector } from '../../../redux/store';
import Table, { TableColumnProps } from '../../common/Table';
import { SteamTable, steamTablePropertyValues, steamTablesSlice } from '../../../redux/tools/steamTables';
import { SolveStatus } from '../../../types/tools';
import UnitSelect from '../../common/UnitSelect';
import { useUnitsServiceConversions } from '../../../hooks/useUnitConversions';
import FetchErrorScreen from '../../common/FetchErrorScreen';
import LoadingScreen from '../../common/LoadingScreen';

const requiredUnitSets = Object.values(steamTablePropertyValues).map(({ unitSet }) => unitSet);

const SteamTableProperties: React.FC = () => {
  const dispatch = useDispatch();
  const table = useSelector((state) => state.steamTables.table);
  const solveStatus = useSelector((state) => state.steamTables.solveStatus);
  const units = useSelector((state) => state.steamTables.outputUnits);
  const conversions = useUnitsServiceConversions(requiredUnitSets);

  if (solveStatus === SolveStatus.Solving) return <LoadingScreen />;

  if (!table.length) {
    return (
      <Typography variant="body1">
        Click "Run" in the action bar above to generate a table of steam/water properties.
      </Typography>
    );
  }

  if (conversions.isFetching) return <LoadingScreen />;

  if (conversions.isError) return (
    <FetchErrorScreen
      title="Error Fetching Unit Conversion Data"
      onRetry={conversions.refetch}
    >
      Something went wrong while loading the unit conversion data necessary to
      show steam property graphs.
    </FetchErrorScreen>
  );

  // Add columns for each x- and y-axis property
  const columns = Object.entries(steamTablePropertyValues).map<
    TableColumnProps<SteamTable.Entry>
  >(([dataKey, properties]) => {
    let header: React.ReactNode = properties.name;
    if (properties.unitSet) {
      header = (
        <div className="flex flex-col items-center gap-1">
          {header}
          <UnitSelect
            value={units[dataKey as SteamTable.XAxisProperty | SteamTable.YAxisProperty]}
            unitSet={properties.unitSet}
            onChange={(unit) => {
              dispatch(steamTablesSlice.actions.setOutputUnit({
                property: dataKey as SteamTable.XAxisProperty | SteamTable.YAxisProperty,
                unit,
              }))
            }}
          />
        </div>
      );
    }
    return { header, dataKey: dataKey as any };
  });
  // Add column for phase after x-axis properties
  columns.splice(2, 0, { header: 'Phase', dataKey: 'phase' });

  // Convert table data into the intended units
  const tableData = table.map((entry) => {
    const converted = { ...entry };
    for (const [key, { dataUnit, unitSet }] of Object.entries(steamTablePropertyValues)) {
      const property = key as SteamTable.XAxisProperty | SteamTable.YAxisProperty;
      converted[property] = conversions.convert(
        entry[property],
        unitSet,
        dataUnit,
        units[property]
      );
    }
    return converted;
  });

  return (
    <Typography className="whitespace-nowrap">
      <Table
        data={tableData}
        columns={columns}
        numSignificantFigures={6}
      />
    </Typography>
  );
};

export default SteamTableProperties;
