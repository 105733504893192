import { Fragment } from 'react'

import ProcessValue from './ProcessValue'
import Composition from './Composition'

const PropertySet = ({ status, propertyMutators, controlActions, properties }) => {
  return (
    <Fragment>
      {properties.map((property) => {
        const { id, type } = property;
        if (type === 'scalar') {
          return (
            <ProcessValue key={id} status={status} propertyMutators={propertyMutators} controlActions={controlActions} property={property} />
          );
        } else if (type === 'composition') {
          return (
            <Composition key={id} status={status} propertyMutators={propertyMutators} controlActions={controlActions} property={property} />
          );
        } else {
          return <Fragment key={id} />;
        }
      })}
    </Fragment>
  );
};

export default PropertySet;
