function doesScroll(style: string): boolean {
  return style === 'auto' || style === 'scroll';
}

export default function getNearestScrollingAncestor(
  element: Element,
  direction: 'x' | 'y' | 'both'
): Element {
  while (true) {
    if (!element) return document.documentElement;
    const style = window.getComputedStyle(element);

    switch (direction) {
      case 'x': if (doesScroll(style.overflowX)) return element; break;
      case 'y': if (doesScroll(style.overflowY)) return element; break;
      case 'both': if (style.overflow.split(' ').every(doesScroll)) return element;
    }

    element = element.parentElement;
  }
}
