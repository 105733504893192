import React from 'react';
import Table from '../../common/Table';
import { useGetMaterialDetailsQuery } from '../../../redux/materialsAPI';
import Typography from '../../common/Typography';
import MaterialDetailView from './MaterialDetailView';

interface PropertyProps {
  label: string;
  children: React.ReactNode;
}

const Property: React.FC<PropertyProps> = ({ label, children }) => {
  return (
    <>
      <strong>{`${label}:`}</strong>
      <div>
        {children}
      </div>
    </>
  );
};

interface MaterialPropertiesProps {
  id: number;
}

const MaterialProperties: React.FC<MaterialPropertiesProps> = ({ id }) => {
  const { currentData: material } = useGetMaterialDetailsQuery(id);

  if (!material) return;

  const listOfNames = (
    <Property label="Common Names">
      <ul className="list-disc list-inside">
        {material.names.map((name) => <li key={name}>{name}</li>)}
      </ul>
    </Property>
  );
  const groups = (
    <Property label="Groups">
      <ul className="list-disc list-inside">
        {material.groups.map((group) => <li key={group.name}>{group.name}</li>)}
      </ul>
    </Property>
  );

  const codeSpecification = material.code && (
    <Property label="Code Specification">
      {material.code}
    </Property>
  );

  const components = Object.entries(material.composition).map(([substance, percentage]) => (
    <li>
      {percentage ? `${percentage}% ${substance}` : substance}
    </li>
  ));

  const composition = components && (
    <Property label="Nominal Composition">
      <ul className="list-disc list-inside">
        {components}
      </ul>
    </Property>
  );
  const productForm = material.form && (
    <Property label="Product Form">
      {material.form}
    </Property>
  );
  const type = material.type && (
    <Property label="Type">
      {material.type}
    </Property>
  );
  const temper = material.temper && (
    <Property label="Temper">
      {material.temper}
    </Property>
  );
  const grade = material.grade && (
    <Property label="Grade">
      {material.grade}
    </Property>
  );
  const materialClass = material.class && (
    <Property label="Class">
      {material.class}
    </Property>
  );
  // TODO: add min/max dimension once it's added to the API endpoint
  const designationTable = material.designations.length
    ? (
      <Table
        data={material.designations}
        columns={[
          { dataKey: 'country', header: 'Country' },
          { dataKey: 'type', header: 'Designation Type' },
          { dataKey: 'number', header: 'Number' },
          { dataKey: 'nominalComposition', header: 'Nominal Composition' },
        ]}
      />
    )
    : (
      <Typography color="slate">This material has no designations.</Typography>
    )
  ;

  return (
    <MaterialDetailView id={id}>
      <div className="w-full h-full p-2 overflow-auto">
        <div className="grid grid-cols-[max-content_minmax(0,_1fr)] gap-x-1 gap-y-2">
          {listOfNames}
          {groups}
          {codeSpecification}
          {composition}
          {productForm}
          {type}
          {temper}
          {grade}
          {materialClass}
          <Typography variant="h6" className="col-span-2 mt-2">
            Designations
          </Typography>
          <div className="col-span-2">
            {designationTable}
          </div>
        </div>
      </div>
    </MaterialDetailView>
  );
};

export default MaterialProperties;
