import React from 'react';
import { Dot, DotProps } from 'recharts';

type PointArrayDotProps = {
  xCoordinates: Set<number>;
  payload?: { x: number };
  active?: boolean;
} & DotProps;

const PointArrayDot: React.FC<PointArrayDotProps> = (props) => {
  // Only render dot for points explicitly specified by the point array
  if (props.xCoordinates.has(props.payload?.x)) return <Dot {...props} />;
  if (props.active) return <Dot {...props} />;
  return undefined;
};

export default PointArrayDot;
