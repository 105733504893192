import React from 'react';
import useDrag from '../../hooks/useDrag';
import useDimensions from '../../hooks/useDimensions';


interface ResizableItemProps {
  containerDimensions: React.MutableRefObject<DOMRect>;
  containerRef: React.MutableRefObject<HTMLElement>;
  direction: 'row' | 'column';
  isLast: boolean;
  setItemSizePercentage: (percentage: number) => any;
  children: React.ReactNode;
}

const ResizableItem: React.FC<ResizableItemProps> = ({
  containerDimensions,
  direction,
  isLast,
  setItemSizePercentage,
  children,
}) => {
  const isRow = direction === 'row';
  const itemRef = React.useRef<HTMLDivElement>();
  const itemDimensions = useDimensions(itemRef);
  const { dragging, onDraggableElementMouseDown } = useDrag({
    cursor: isRow ? 'col-resize' : 'row-resize',
    onDrag: (e) => {
      const targetSize = isRow
        ? e.clientX - itemDimensions.current.left
        : e.clientY - itemDimensions.current.top
      ;
      const containerSize = isRow
        ? containerDimensions.current.width
        : containerDimensions.current.height
      ;
      const targetPercentage = targetSize / containerSize * 100;
      setItemSizePercentage(targetPercentage);
    },
  });

  let resizeHandle: React.ReactNode;
  if (!isLast) {
    const borderOpacityClass = dragging
      ? 'border-opacity-80'
      : 'border-opacity-0 hover:border-opacity-80'
    ;
    const positioningClass = isRow
      ? 'top-0 right-0 h-full w-[10px] border-x-4 translate-x-1/2 cursor-col-resize'
      : 'bottom-0 left-0 w-full h-[10px] border-y-4 translate-y-1/2 cursor-row-resize'
    ;
    resizeHandle = (
      <div
        className={`${borderOpacityClass} ${positioningClass} absolute z-[15] bg-clip-padding bg-slate-400 border-slate-300 transition-colors`}
        onMouseDown={onDraggableElementMouseDown}
      />
    );
  }

  return (
    <div className="relative w-full h-full" ref={itemRef}>
      <div className="w-full h-full overflow-auto">
        {children}
      </div>
      {resizeHandle}
    </div>
  );
};

function generateDefaultSizes(numItems: number): string[] {
  return Array(numItems).fill(`${100 / numItems}%`);
}

function parsePercentage(percentage: string): number {
  return Number.parseFloat(percentage.split('%')[0]);
}

interface DynamicFlexContainerProps {
  className?: string;
  initialSizes?: `${number}%`[];
  direction: 'row' | 'column';
  children: React.ReactNode[];
}

const MIN_SIZE_PERCENTAGE = 10;

const DynamicFlexContainer: React.FC<DynamicFlexContainerProps> = ({
  className,
  initialSizes,
  direction,
  children,
}) => {
  const [itemSizes, setItemSizes] = React.useState(() => (
    initialSizes ?? generateDefaultSizes(children.length)
  ));
  const containerRef = React.useRef<HTMLDivElement>();
  const containerDimensions = useDimensions(containerRef);

  // Keep sizes update if number of children is changed
  React.useLayoutEffect(() => {
    setItemSizes(initialSizes ?? generateDefaultSizes(children.length));
  }, [children.length]);

  const resizableAxisTemplateProp = direction === 'row' ? 'gridTemplateColumns' : 'gridTemplateRows';
  const gridStyle: React.CSSProperties = {
    [resizableAxisTemplateProp]: itemSizes.join(' '),
  };
  const inlineAxisSizeClass = direction === 'row' ? 'grid-rows-[100%]' : 'grid-cols-[100%]';

  const setItemSizePercentage = (idx: number, newPercentage: number) => {
    const newSizes = [...itemSizes];
    // Ensure new height is valid
    newPercentage = Math.max(newPercentage, MIN_SIZE_PERCENTAGE);
    newPercentage = Math.min(
      newPercentage,
      100 - ((children.length - 1)* MIN_SIZE_PERCENTAGE)
    );
    const originalPercentage = parsePercentage(itemSizes[idx]);

    const percentageDelta = newPercentage - originalPercentage;

    // If item wasn't made larger, simply resize the next element
    if (percentageDelta <= 0) {
      newSizes[idx] = `${newPercentage}%`;
      newSizes[idx + 1] = `${parsePercentage(itemSizes[idx + 1]) - percentageDelta}%`;
    }

    else {
      // Item is now larger, and needs to remove space from other items -
      // resize successive items until the correct amount of space is removed
      let remainingPercentageDelta = percentageDelta;
      for (let currentIdx = idx + 1; currentIdx < children.length; currentIdx++) {
        // Resize current item
        const currentItemPercentage = parsePercentage(itemSizes[currentIdx]);
        if (currentItemPercentage <= MIN_SIZE_PERCENTAGE) continue;

        const currentItemNewPercentage = Math.max(
          currentItemPercentage - remainingPercentageDelta,
          MIN_SIZE_PERCENTAGE,
        );
        newSizes[currentIdx] = `${currentItemNewPercentage}%`;

        // Calculate new percentageDelta to know whether more items must be resized
        const spaceTakenFromCurrentItem = currentItemPercentage - currentItemNewPercentage;
        remainingPercentageDelta -= spaceTakenFromCurrentItem;
        if (remainingPercentageDelta <= 0) break;
      }
      // Item might still not be target size after this loop -
      // that's okay as shrinking items above it would lead to unintuitive behavior
      newPercentage = newPercentage - Math.max(remainingPercentageDelta, 0);
      newSizes[idx] = `${newPercentage}%`;
    }

    setItemSizes(newSizes);
  };

  const items = children.map((child, idx) => (
    <ResizableItem
      containerDimensions={containerDimensions}
      containerRef={containerRef}
      direction={direction}
      isLast={idx === children.length - 1}
      setItemSizePercentage={(percentage) => setItemSizePercentage(idx, percentage)}
      key={idx}
    >
      {child}
    </ResizableItem>
  ));

  return (
    <div
      className={`grid w-full h-full ${inlineAxisSizeClass} ${className ?? ''}`}
      style={gridStyle}
      ref={containerRef}
    >
      {items}
    </div>
  );
};

export default DynamicFlexContainer;
