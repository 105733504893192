import React from 'react';
import { FloatingArrow, FloatingPortal, arrow, flip, offset, shift, size, useFloating } from '@floating-ui/react';
import Typography from './Typography';

type TooltipWidth = 'sm' | 'md' | 'lg' | 'full';
const widthClasses: Record<TooltipWidth, string> = {
  sm: 'max-w-32',
  md: 'max-w-64',
  lg: 'max-w-lg',
  full: 'max-w-full',
};

interface TooltipProps {
  width: TooltipWidth;
  label: string;
  className?: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ width, label, className, children }) => {
  const [open, setOpen] = React.useState(false);
  const arrowRef = React.useRef();
  const { refs, floatingStyles, context } = useFloating({
    placement: 'top',
    middleware: [
      flip(),
      shift(),
      offset(4),
      // TODO: probably need middleware to shift on main axis
      arrow({ element: arrowRef }),
    ],
  });

  const tooltip = open ? (
    <FloatingPortal>
      <div
        className={`flex items-center justify-center p-2 rounded-md bg-opacity-90 bg-sky-950 ${widthClasses[width]} z-50`}
        ref={refs.setFloating}
        style={floatingStyles}
      >
        <Typography
          variant="body2"
          color="white"
          className="select-none whitespace-pre text-center"
        >
          {label}
        </Typography>
        <FloatingArrow className="opacity-90 fill-sky-950" ref={arrowRef} context={context} />
      </div>
    </FloatingPortal>
  ) : undefined;

  return (
    <>
      <div className={className} ref={refs.setReference} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        {children}
      </div>
      {tooltip}
    </>
  );
};

export default Tooltip;
