import React from 'react';
import Material from '../../../types/materials';
import { useDispatch, useSelector } from '../../../redux/store';
import Select from '../../common/Select';
import { addCompositionFilter, removeCompositionFilter, updateCompositionFilter } from '../../../redux/tools/materials';
import Typography from '../../common/Typography';
import InputField from '../../common/InputField';
import Button from '../../common/Button';
import { PlusIcon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/outline';

interface NominalCompositionFilterProps {
  filterOptions: Material.FilterOptions;
}

const availableOperators: string[] = [
  Material.CompositionOperator.GreaterOrEqual,
  Material.CompositionOperator.LessOrEqual,
];

const NominalCompositionFilter: React.FC<NominalCompositionFilterProps> = ({ filterOptions }) => {
  const { compositionElements } = filterOptions;
  const dispatch = useDispatch();
  const composition = useSelector((state) => state.materials.filters.composition);

  const updateComponent = (id: string, updates: Partial<Material.CompositionFilter>) => {
    dispatch(updateCompositionFilter({ id, updates }));
  };

  const components = composition.map((component) => (
    <div className="flex flex-row items-center gap-1" key={component.id}>
      <TrashIcon
        className="size-7 shrink-0 ml-auto p-1 rounded-full text-slate-700 bg-transparent hover:bg-slate-400 transition-colors cursor-pointer"
        onClick={() => dispatch(removeCompositionFilter(component.id))}
      />
      <Select
        value={component.substance}
        options={compositionElements}
        onChange={(substance) => updateComponent(component.id, { substance })}
      />
      <div className="shrink-0">
        <Select
          value={component.operator}
          options={availableOperators}
          onChange={(operator) => updateComponent(component.id, { operator: operator as Material.CompositionOperator })}
        />
      </div>
      <div className="w-12 shrink-0">
        <InputField
          type="decimal"
          value={component.percentage}
          onChange={(percentage) => updateComponent(component.id, { percentage })}
        />
      </div>
      <Typography>%</Typography>
    </div>
  ));

  return (
    <div className="flex flex-col gap-1">
      {components}
      <Button
        color="green"
        iconLeft={<PlusIcon className="size-6" />}
        label="Add Substance"
        onClick={() => dispatch(addCompositionFilter())}
      />
    </div>
  );
};

export default NominalCompositionFilter;
