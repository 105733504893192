import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Role } from '../../types/API';
import { validateEmail, validatePassword } from '../../utils/inputValidation';
import { useDispatch, useSelector } from '../../redux/store';
import { errorsSlice, reset as resetError } from '../../redux/errors';
import { createAccount } from '../../utils/API';
import { setNotification } from '../../redux/notifications';
import useCurrentUser from '../../hooks/useCurrentUser';
import Form from '../common/Form/Form';
import { authenticationSlice } from '../../redux/authentication';
import useFormData from '../../hooks/useFormData';

const CreateAccountPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const { values, fields } = useFormData({
    email: {
      label: 'Email',
      type: 'email',
      value: useSelector((state) => state.authentication.signedOutEmail),
      onChange: (newEmail) => dispatch(authenticationSlice.actions.setSignedOutEmail(newEmail)),
    },
    role: {
      label: 'Role',
      type: 'select',
      initialValue: Role.User,
      options: [Role.User],
    },
    password: { label: 'Password', type: 'password' },
    passwordConfirmation: { label: 'Confirm Password', type: 'password' },
    firstName: { label: 'First Name', type: 'text' },
    lastName: { label: 'Last Name', type: 'text' },
    companyName: { label: 'Company', type: 'text' },
    country: { label: 'Country', type: 'text' },
  });
  const [requestProcessing, setRequestProcessing] = React.useState(false);

  const isAdmin = user?.role === Role.Admin;
  const goToPreviousPage = () => navigate(isAdmin ? '/account' : '/');

  const emailValid = validateEmail(values.email);
  const passwordIssues = validatePassword(values.password, values.passwordConfirmation);
  const canSubmit = Boolean(
    emailValid
    && !passwordIssues.length
    && values.firstName.length
    && values.lastName.length
    && values.companyName.length
    && !requestProcessing
  );

  let errorMessage: string | undefined;
  if (values.email.length > 2 && !emailValid) errorMessage = 'Please ensure the email you provided is valid.';
  else if (values.password.length) errorMessage = passwordIssues.join('\n');

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(resetError());
    if (!canSubmit) return;

    setRequestProcessing(true);
    createAccount(values)
      .then(() => {
        setRequestProcessing(false);
        const message = isAdmin
          ? 'Account successfully created.'
          : 'Account successfully created, and is pending authentication.'
        ;
        dispatch(setNotification({ message }));
        goToPreviousPage();
      })
      .catch((e) => {
        dispatch(errorsSlice.actions.set(e));
        setRequestProcessing(false);
      });
  };

  return (
    <Form.Container title="Create Account" submitButtonLabel="Create" error={errorMessage} canSubmit={canSubmit} onSubmit={handleSubmit} onBack={goToPreviousPage}>
      <Form.AutoField field={fields.firstName} autoFocus />
      <Form.AutoField field={fields.lastName} />
      <Form.AutoField field={fields.companyName} />
      <Form.AutoField field={fields.country} />
      <Form.AutoField field={fields.email} />
      <Form.AutoField field={fields.password} />
      <Form.AutoField field={fields.passwordConfirmation} />
    </Form.Container>
  );
};

export default CreateAccountPage;
