import { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { makeRequest } from '../utils/API';

const makeRequestBaseQuery = (
  { baseUrl }: { baseUrl: string } = { baseUrl: ''}
): BaseQueryFn<
  string | { method?: string, url?: string, body?: any, params?: Record<string, any> },
  unknown,
  FetchBaseQueryError
> => async (args, _api, _extraOptions) => {
  let { method = 'GET', url, body = undefined, params = undefined } = (typeof args === 'string')
    ? { url: args }
    : args
  ;
  const searchParams = new URLSearchParams(params).toString();
  url = baseUrl + url;
  if (searchParams) url += `?${searchParams}`;

  try {
    return { data: await makeRequest(method, url, body) };
  }
  catch (error) {
    return { error };
  }
};

export default makeRequestBaseQuery;
