const traverse = (routes, node, path) => {
  path += path.length > 0 ? '/' : '';
  path += node.endpoint;
  if (node.children && node.children.length > 0) {
    node.children.forEach((child) => traverse(routes, child, path));
  } else {
    routes.push(path);
  }
};

const fetchRoutes = (sidebarTree) => {
  const routes = [];
  const path = '';
  sidebarTree.forEach((node) => traverse(routes, node, path));
  return routes;
};

const getLastItem = (path) => path.substring(path.lastIndexOf('/') + 1)

const createPropertySets = (sidebarTree, propertyMap) => {
  const routes = fetchRoutes(sidebarTree);
  const propertySets = [];
  if (propertyMap.size > 0) {
    routes.forEach((route) => {
      const subset = [...propertyMap.values()]
        .filter((property) => property.id.includes(route))
        .map((property) => property);
      if (subset.length > 0) {
        propertySets.push({
          path: route,
          endpoint: getLastItem(route),
          properties: subset,
        });
      }
    });
  }
  return propertySets;
};

export { createPropertySets };
