import React from 'react';
import { Provider } from 'react-redux';
import createEquationStore from './redux/store';
import { Block } from './types';
import EquationController from './EquationController';
import { EngineeringChecklist } from '../../../types/engineeringChecklist';

interface EquationEditorContextState {
  variables: Map<string, EngineeringChecklist.Variable>;
}

export const EquationEditorContext = React.createContext<EquationEditorContextState>({
  variables: new Map(),
});

interface EquationEditorProps {
  initialValue: Block | undefined;
  onChange: (block: Block) => any;
  variables?: EngineeringChecklist.Variable[];
}

const variableTypesToDisplay = new Set<EngineeringChecklist.VariableType>([
  EngineeringChecklist.VariableType.Double,
  EngineeringChecklist.VariableType.Int16,
  EngineeringChecklist.VariableType.Int32,
]);

const EquationEditor: React.FC<EquationEditorProps> = ({
  initialValue,
  onChange,
  variables = [],
}) => {
  const store = React.useMemo(() => createEquationStore(initialValue), []);

  const variableMap = React.useMemo(() => (
    new Map<string, EngineeringChecklist.Variable>(
      variables
        // Only display variables with appropriate types
        .filter((variable) => variableTypesToDisplay.has(variable.type))
        // Sort by alias so they show up in alphabetical order in autocomplete
        .sort((a, b) => a.alias.localeCompare(b.alias))
        .map((variable) => [variable.alias, variable])
    )
  ), [variables]);

  const contextState: EquationEditorContextState = {
    variables: variableMap,
  };

  return (
    <Provider store={store}>
      <EquationEditorContext.Provider value={contextState}>
        <EquationController onChange={(blocks) => onChange(blocks)} />
      </EquationEditorContext.Provider>
    </Provider>
  );
};

export default EquationEditor;
