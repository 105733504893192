import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Dialog, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel, Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
  CalculatorIcon,
  Square3Stack3DIcon,
  ClipboardDocumentCheckIcon,
  CubeIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import logo from '../images/logo.svg'
import { signOut } from '../redux/authentication'
import { useDispatch } from '../redux/store'
import { userHasClaims } from '../utils/permissions'
import Typography from './common/Typography'
import useCurrentUser from '../hooks/useCurrentUser'
import { Claim, Role } from '../types/API'
import ProfileIcon from './Account/ProfileIcon'

export interface MenuEntryWithIcon {
  name: string;
  description: string;
  route: string;
  icon: React.ElementType<{ className?: string }>;
  necessaryClaims?: Claim[];
  allowedRoles?: Role[];
}

export const toolbox: MenuEntryWithIcon[] = [
  {
    name: 'Engineering Checklist',
    description: 'Generate an engineering checklist for Xchanger Suite',
    route: '/engineering-checklist',
    icon: ClipboardDocumentCheckIcon,
    necessaryClaims: ['checklists'],
  },
  {
    name: 'Materials Database',
    description: 'View and compare material properties',
    route: '/materials-database',
    icon: CubeIcon,
    necessaryClaims: ['materials'],
  },
  {
    name: 'Steam Tables',
    description: 'Generate water/steam property tables using IAPWS-IF97',
    route: '/steam-tables',
    icon: Square3Stack3DIcon,
    necessaryClaims: ['steam-tables'],
  },
  {
    name: 'Dew Point Temperature',
    description: 'Calculate the dew point temperature of a gas mixture',
    route: '/dew-point-calculator/inputs',
    icon: CalculatorIcon,
    necessaryClaims: ['dew-points'],
  },
  // {
  //   name: 'HTRI Databank Model Regression',
  //   description: 'Regress HTRI Databank model parameters from experimental data',
  //  route: '/',
  //  icon: Square3Stack3DIcon,
  // },
  // {
  //   name: 'Bayonet Heat Exchanger',
  //   description: 'Perform a bayonet heat exchanger design',
  //   route: '/',
  //   icon: CalculatorIcon,
  // },
  // {
  //   name: 'SPDA Calculator',
  //   description: 'Calculate a supplementary pressure drop application intercooler',
  //   route: '/',
  //   icon: CalculatorIcon,
  // },
  // {
  //   name: 'Batch Reactor',
  //   description: 'Calculate a batch reactor heating/cooling curve',
  //   route: '/',
  //   icon: CalculatorIcon,
  // },
  // {
  //   name: 'Spray Condenser',
  //   description: 'Perform a spray condenser rating calculation',
  //   route: '/',
  //   icon: CalculatorIcon,
  // },
  // {
  //   name: 'Particle Terminal Velocity',
  //   description: 'Calculate a particle terminal velocity in a fluid stream',
  //   route: '/',
  //   icon: CalculatorIcon,
  // },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)
  const user = useCurrentUser()!;

  const toolboxMenuItems = toolbox.map((item) => {
    const selectable = item.necessaryClaims === undefined ? true : userHasClaims(user, item.necessaryClaims);
    const containerClass = selectable ? ' hover:bg-gray-50 cursor-pointer' : '';
    const iconContainerClass = selectable ? 'bg-gray-50 group-hover:bg-white' : 'bg-transparent';
    const iconClass = selectable ? 'text-gray-600 group-hover:text-indigo-600' : 'text-gray-300';
    const nameColor = selectable ? 'black' : 'lightSlate';
    const descriptionColor = selectable ? 'slate' : 'extraLightSlate'
    const handleClick = selectable ? () => navigate(item.route) : undefined;

    return (
      <PopoverButton
        as="div"
        className={`group relative flex gap-x-6 rounded-lg p-4 leading-6 select-none${containerClass}`}
        onClick={handleClick}
        key={item.name}
      >
        <div className={`flex size-11 flex-none items-center justify-center rounded-lg ${iconContainerClass}`}>
          <item.icon className={`size-6 ${iconClass}`} aria-hidden="true" />
        </div>
        <div className="flex-auto">
          <Typography variant="body2" color={nameColor} bold>{item.name}</Typography>
          <Typography variant="caption" className="mt-1" color={descriptionColor}>{item.description}</Typography>
        </div>
      </PopoverButton>
    );
  });

  return (
    <header>
      <nav className="flex mx-auto h-12 max-w-8xl items-center justify-between px-4 lg:px-2 bg-gradient-to-r from-sky-300 to-red-400" aria-label="Global">
        <div className="flex lg:block">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">HTRI</span>
            <img className="h-12 w-auto" src={logo} alt="HTRI" />
          </Link>
        </div>
        <div className="block lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover className="hidden lg:block lg:gap-x-12 relative">
          <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
            <Typography variant="body2">Toolbox</Typography>
            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-900" aria-hidden="true" />
          </PopoverButton>
          <PopoverPanel transition className="absolute transition-opacity duration-200 data-[closed]:opacity-0 -left-8 top-full z-30 mt-3 w-screen max-w-lg overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              {toolboxMenuItems}
            </div>
          </PopoverPanel>
        </Popover>
        <div className="hidden lg:block">
          <ProfileIcon />
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-30" />
        <DialogPanel className="fixed inset-y-0 right-0 z-30 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">HTRI</span>
              <img className="h-12 w-auto" src={logo} alt="HTRI" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:cursor-pointer">
                        Toolbox
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </DisclosureButton>
                      <DisclosurePanel className="mt-2 space-y-2">
                        {[...toolbox].map((item) => (
                          <Link
                            to={item.route}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:cursor-pointer"
                            key={item.name}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              </div>
              <div className="py-6">
                <Link onClick={() => dispatch(signOut())} to="/"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log Out
                </Link>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default Header;
