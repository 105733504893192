import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import  { authenticationSlice, fetchAuthentication } from './authentication';
import { errorsSlice } from './errors';
import { engineeringChecklistSlice } from './tools/engineeringChecklist';
import { listenerMiddleware } from './listenerMiddleware';
import { materialsSlice } from './tools/materials';
import { notificationsSlice } from './notifications';
import { steamTablesSlice } from './tools/steamTables';
import HTRICalcAPI from './HTRICalcAPI';
import materialsAPI from './materialsAPI';
import unitsAPI from './unitsAPI';
import userQueriesAPI from './userQueriesAPI';
import errorMiddleware from './errorMiddleware';

const store = configureStore({
  reducer: {
    authentication: authenticationSlice.reducer,
    errors: errorsSlice.reducer,
    engineeringChecklist: engineeringChecklistSlice.reducer,
    materials: materialsSlice.reducer,
    notifications: notificationsSlice.reducer,
    steamTables: steamTablesSlice.reducer,
    [HTRICalcAPI.reducerPath]: HTRICalcAPI.reducer,
    [materialsAPI.reducerPath]: materialsAPI.reducer,
    [unitsAPI.reducerPath]: unitsAPI.reducer,
    [userQueriesAPI.reducerPath]: userQueriesAPI.reducer,
  },
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .prepend(listenerMiddleware.middleware)
      .prepend(errorMiddleware)
      .concat(
        HTRICalcAPI.middleware,
        materialsAPI.middleware,
        unitsAPI.middleware,
        userQueriesAPI.middleware,
      )
  ),
});

// Fetch authentication status as soon as the store is initialized
store.dispatch(fetchAuthentication());

export type RootState = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
const useAppDispatch = useDispatch.withTypes<Dispatch>();
const useAppSelector = useSelector.withTypes<RootState>();

export {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
};
export default store;
