import React from 'react';
import Range from '../../common/Range';
import { useDispatch, useSelector } from '../../../redux/store';
import { steamTablesSlice } from '../../../redux/tools/steamTables';
import Typography from '../../common/Typography';

const SteamTableInputs: React.FC = () => {
  const dispatch = useDispatch();
  const pressure = useSelector((state) => state.steamTables.pressure);
  const temperature = useSelector((state) => state.steamTables.temperature);

  return (
    <div className="grid grid-cols-[repeat(2,_min-content)] gap-2">
      <Typography>Pressure</Typography>
      <Range
        min={pressure.min}
        max={pressure.max}
        unit={pressure.unit}
        unitSet={pressure.unitSet}
        onMinChange={(min) => dispatch(steamTablesSlice.actions.updatePressure({ min }))}
        onMaxChange={(max) => dispatch(steamTablesSlice.actions.updatePressure({ max }))}
        onUnitChange={(unit) => dispatch(steamTablesSlice.actions.updatePressure({ unit}))}
      />
      <Typography>Temperature</Typography>
      <Range
        min={temperature.min}
        max={temperature.max}
        unit={temperature.unit}
        unitSet={temperature.unitSet}
        onMinChange={(min) => dispatch(steamTablesSlice.actions.updateTemperature({ min }))}
        onMaxChange={(max) => dispatch(steamTablesSlice.actions.updateTemperature({ max }))}
        onUnitChange={(unit) => dispatch(steamTablesSlice.actions.updateTemperature({ unit}))}
      />
    </div>
  );
};

export default SteamTableInputs;
