import React from 'react';
import { Routes, Route } from 'react-router-dom'

import Sidebar from './Sidebar'
import Actionbar from './Actionbar'
import PropertySet from './PropertySet'
import ClaimValidator from './common/ClaimValidator';

const Tool = ({ context }) => {
  const { status, sidebarTree, propertySets, propertyMutators, controlActions } = React.useContext(context);
  return (
    <ClaimValidator claims="dew-points">
      <div className="flex flex-row h-full max-h-full w-full">
        <Sidebar sidebarTree={sidebarTree} />
        <div className="h-full max-h-full w-full bg-white">
          <div className="flex flex-col items-center justify-start h-full max-h-full w-full">
            <Actionbar status={status} controlActions={controlActions} />
            <Routes>
              {propertySets.map((propertySet) => (
                <Route
                  key={propertySet.path}
                  path={propertySet.endpoint}
                  element={<PropertySet status={status} propertyMutators={propertyMutators} controlActions={controlActions} properties={propertySet.properties} />} />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </ClaimValidator>
  );
};

export default Tool;
