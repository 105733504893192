import React from 'react';
import Typography, { TypographyColor, TypographyVariant } from './Typography';
import { XMarkIcon } from '@heroicons/react/24/outline';

export type ChipColor = 'primary' | 'green' | 'white';
interface ChipColorClasses {
  bg: string;
  bgHover: string;
  text: TypographyColor;
  icon: string;
  delete: string;
}
const colorClasses: Record<ChipColor, ChipColorClasses> = {
  primary: {
    bg: 'bg-sky-600 text-sky-600',
    bgHover: 'hover:bg-sky-500 hover:text-sky-500',
    text: 'white',
    icon: 'text-white',
    delete: 'bg-sky-300 hover:bg-white',
  },
  green: {
    bg: 'bg-green-500 text-green-500',
    bgHover: 'hover:bg-green-600 hover:text-green-600',
    text: 'white',
    icon: 'text-white',
    delete: 'bg-green-300 hover:bg-white',
  },
  white: {
    bg: 'bg-white text-white border-2 border-slate-300',
    bgHover: 'hover:bg-slate-100 hover:text-slate-100',
    text: 'black',
    icon: 'text-slate-700',
    delete: 'bg-slate-300 hover:bg-slate-500',
  },
};

interface ChipProps {
  color?: ChipColor;
  size?: 'medium' | 'small';
  icon?: any;
  label: string;
  onDelete?: () => any;
  onClick?: () => any;
}

const Chip: React.FC<ChipProps> = ({
  color = 'primary',
  size = 'medium',
  icon,
  label,
  onClick,
  onDelete,
}) => {
  const deleteRef = React.useRef<HTMLButtonElement>(null);
  const classes = colorClasses[color];

  const iconComponent = icon ? (
    <div className={`${classes.icon} size-5`}>
      {icon}
    </div>
  ) : undefined;

  const deleteIcon = onDelete ? (
    <button
      className={`${classes.delete} size-4 rounded-full`}
      onClick={() => { onDelete(); }}
      ref={deleteRef}
    >
      <XMarkIcon className="stroke-[3px]" />
    </button>
  ) : undefined;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!onClick || !(e.target instanceof HTMLElement)) return;
    // Don't handle click if delete button was clicked
    if (!deleteRef.current?.contains(e.target)) onClick();
  };

  const containerSizeClass = size === 'medium' ? 'h-7 px-2' : 'h-5 px-[2px]';
  const typograhyVariant: TypographyVariant = size === 'medium' ? 'body1' : 'body2';
  const Container = onClick ? 'button' : 'div';

  return (
    <Container
      className={`${classes.bg} ${onClick ? classes.bgHover : ''} ${containerSizeClass} flex flex-row items-center justify-center w-min rounded-full overflow-hidden shrink-0`}
      onClick={handleClick}
    >
      {iconComponent}
      <Typography variant={typograhyVariant} color={classes.text} className="px-1 select-none truncate">{label}</Typography>
      {deleteIcon}
    </Container>
  );
};

export default Chip;
