import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Material from '../../types/materials';
import { setNotification } from '../notifications';
import { createAsyncThunk } from '../asyncThunk';
import HTRIUnits from '../../types/HTRIUnits';

interface MaterialsState {
  comparing: number[];
  favorites: number[];
  filters: Material.FilterValues;
  viewingDetailsFor: number[];
  selectedCorrelationProperty: string;
  correlationManualPointValues: HTRIUnits.Value[];
  correlationXRange: HTRIUnits.Range;
  correlationYUnits: Record<Material.Correlation.Property, string>;
}

const initialState: MaterialsState = {
  comparing: [],
  favorites: [],
  filters: {
    name: '',
    group: 'All Materials',
    composition: [],
    form: '',
    code: '',
    designationType: '',
    designationNumber: '',
    type: '',
    temper: '',
    grade: '',
  },
  selectedCorrelationProperty: '',
  viewingDetailsFor: [],
  correlationManualPointValues: [],
  correlationXRange: {
    min: '-200',
    max: '1400',
    unit: 'F',
    unitSet: 'Temperature',
  },
  correlationYUnits: {
    'Allowable Stress': 'ksi',
    'Coefficient of Thermal Expansion': 'micro inch/inch-F',
    'Metal Density': 'lb/in3',
    'Modulus of Elasticity': 'MM psi',
    'Thermal Conductivity': 'Btu/hr-ft-F',
    'Yield Stress': 'ksi',
  },
};

const MAX_CONCURRENT_DETAIL_VIEWS = 6;
export const startViewingMaterialDetailsFor = createAsyncThunk<void, number>(
  'materials/startViewingMaterialDetailsFor',
  (materialID, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    // Do nothing if the material is added to avoid an unnecessary notification
    if (state.materials.viewingDetailsFor.includes(materialID)) return;

    if (state.materials.viewingDetailsFor.length >= MAX_CONCURRENT_DETAIL_VIEWS) {
      dispatch(setNotification({
        message: `You may only view details for ${MAX_CONCURRENT_DETAIL_VIEWS} materials at once. Please close the details for a material before trying again.`,
      }));
      rejectWithValue(undefined);
    }
  },
);

export const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    // Filters
    updateFilters: (state, action: PayloadAction<Partial<Material.FilterValues>>) => {
      Object.assign(state.filters, action.payload);
    },
    addCompositionFilter: (state) => {
      state.filters.composition.push({
        id: crypto.randomUUID(),
        substance: '',
        operator: Material.CompositionOperator.GreaterOrEqual,
        percentage: '0',
      });
    },
    removeCompositionFilter: (state, action: PayloadAction<string>) => {
      state.filters.composition = state.filters.composition.filter((component) => (
        component.id !== action.payload
      ));
    },
    updateCompositionFilter: (
      state, action: PayloadAction<{ id: string, updates: Partial<Material.CompositionFilter> }>
    ) => {
      const toUpdate = state.filters.composition.find((component) => (
        component.id === action.payload.id
      ));
      Object.assign(toUpdate, action.payload.updates);
    },

    // Favorites
    addMaterialToFavorites: (state, action: PayloadAction<number>) => {
      if (state.favorites.includes(action.payload)) return;
      state.favorites.push(action.payload);
    },
    removeMaterialFromFavorites: (state, action: PayloadAction<number>) => {
      state.favorites = state.favorites.filter((id) => id !== action.payload);
    },

    // Details
    setViewingMaterialDetailsFor: (state, action: PayloadAction<number[]>) => {
      state.viewingDetailsFor = action.payload;
    },
    stopViewingMaterialDetailsFor: (state, action: PayloadAction<number>) => {
      state.viewingDetailsFor = state.viewingDetailsFor.filter((id) => (
        id !== action.payload
      ));
    },

    // Correlation property
    setSelectedCorrelationProperty: (state, action: PayloadAction<string>) => {
      state.selectedCorrelationProperty = action.payload;
    },
    updateCorrelationXRange: (state, action: PayloadAction<Partial<Omit<HTRIUnits.Range, 'unitSet'>>>) => {
      // TODO: could also update manual point values here to the new unit if changed
      Object.assign(state.correlationXRange, action.payload);
    },
    setCorrelationYUnit: (state, action: PayloadAction<{ property: string, unit: string }>) => {
      state.correlationYUnits[action.payload.property] = action.payload.unit;
    },
    addCorrelationManualPointValue: (state, action: PayloadAction<number>) => {
      state.correlationManualPointValues.push({
        value: action.payload.toString(),
        unit: state.correlationXRange.unit,
      });
    },
    clearCorrelationManualPointValues: (state) => {
      state.correlationManualPointValues = [];
    },

    // Comparisons
    addToCorrelationComparison: (state, action: PayloadAction<number>) => {
      if (state.comparing.includes(action.payload)) return;
      state.comparing.push(action.payload);
    },
    removeFromCorrelationComparison: (state, action: PayloadAction<number>) => {
      state.comparing = state.comparing.filter((id) => id !== action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(startViewingMaterialDetailsFor.fulfilled, (state, action) => {
      const materialID = action.meta.arg;
      if (state.viewingDetailsFor.includes(materialID)) return;
      if (state.viewingDetailsFor.length < MAX_CONCURRENT_DETAIL_VIEWS) {
        state.viewingDetailsFor.push(materialID);
      }
    })
  }
});

export const {
  updateFilters,
  addCompositionFilter,
  removeCompositionFilter,
  updateCompositionFilter,
  addMaterialToFavorites,
  removeMaterialFromFavorites,
  setViewingMaterialDetailsFor,
  stopViewingMaterialDetailsFor,
  setSelectedCorrelationProperty,
  addToCorrelationComparison,
  removeFromCorrelationComparison,
} = materialsSlice.actions;
