import React from 'react';
import Material from '../../../types/materials';
import Typography from '../../common/Typography';
import MaterialLabel from './MaterialLabel';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useDispatch } from '../../../redux/store';
import { addMaterialToFavorites } from '../../../redux/tools/materials';
import materialsAPI from '../../../redux/materialsAPI';
import MaterialsContext from './MaterialsContext';

// Keep track of resultscontainer id to allow programatically scrolling when they change
export const MATERIAL_SEARCH_RESULTS_CONTAINER_ID = '__materials-search-container';

const MaterialSearchResults: React.FC = () => {
  const dispatch = useDispatch();
  const { materialsMatchingFilters: materials } = React.useContext(MaterialsContext);
  const prefetchMaterialDetails = materialsAPI.usePrefetch('getMaterialDetails');

  const numResultsText = `${materials.length} Results`;

  const materialComponents = materials.map((material) => {
    const handleClick = () => {
      prefetchMaterialDetails(material.id);
      dispatch(addMaterialToFavorites(material.id));
    };

    return (
      <button
        className="group rounded-full w-full min-h-9 max-h-9 flex flex-row items-center gap-1 justify-between shadow-md bg-slate-100 hover:bg-slate-200 transition-colors px-1"
        onClick={handleClick}
        key={material.id}
      >
        <MaterialLabel material={material} />
        <PlusIcon className="hidden group-hover:block size-7 p-1 shrink-0 text-slate-500" />
      </button>
    );
  });

  return (
    <div
      id={MATERIAL_SEARCH_RESULTS_CONTAINER_ID}
      className="w-full h-full p-2 bg-white overflow-auto"
    >
      <Typography variant="h6">{numResultsText}</Typography>
      <div className="w-full h-full flex flex-col gap-2">
        {materialComponents}
      </div>
    </div>
  );
};

export default MaterialSearchResults;
