export default class DefaultMap<K, V> extends Map<K, V> {
  #factory: () => V;

  constructor(factory: () => V) {
    super();
    this.#factory = factory;
  }

  override get(key: K): V {
    let value = super.get(key);
    if (value !== undefined) return value;
    value = this.#factory();
    super.set(key, value);
    return value;
  }
}
