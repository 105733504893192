import { equationFunctionSet } from '../components/common/EquationEditor/types';
import { EngineeringChecklist } from '../types/engineeringChecklist';
import Counter from './Counter';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email: string): boolean {
  return emailRegex.test(email);
}

/**
 * Returns an array of issues with the provided password, or an empty array if there are no issues.
 * @param password Password to validate
 * @param confirmation Optional password confirmation to provide additional validation
 * @returns An array containing a string for each issue with the current password
 */
export function validatePassword(password: string, confirmation?: string): string[] {
  const problems = [];

  // Length validation
  if (password.length < 8) problems.push('Password must be at least 8 characters.');
  else if (password.length > 20) problems.push('Password must be no more than 20 characters.');

  // Confirmation match check
  if (password !== confirmation) problems.push('Password and validation must match.');

  return problems;
}

const variableValidationRegex = /^[a-zA-Z][a-zA-Z0-9_]*$/;
export function getVariableAliasValidationError(alias: string): string {
  if (!variableValidationRegex.test(alias)) return `Invalid alias "${alias}". Alias must start with a letter,\nand consist entirely of letters, numbers, and/or underscores.`;
  if (equationFunctionSet.has(alias)) return `"${alias}" cannot be used as an alias as it is the name of a function.`;
  return '';
}

export function validateVariables(variables: EngineeringChecklist.Variable[]): boolean {
  const aliasCounter = new Counter(variables.map((variable) => variable.alias));
  for (let [alias, count] of aliasCounter.entries()) {
    if (count > 1 || getVariableAliasValidationError(alias)) return false;
  }
  return true;
}

const positiveIntegerValidationRegex = /^\d+$/;
export function validateIsPositiveInteger(s: string): boolean {
  return positiveIntegerValidationRegex.test(s);
}
export function validateIsInteger(s: string): boolean {
  if (s[0] === '-') return validateIsPositiveInteger(s.slice(1));
  return validateIsPositiveInteger(s);
}

const numberValidationRegex = /^-?(\d+\.?\d*|\d*\.\d+)$/;
export function validateIsNumber(s: string): boolean {
  return numberValidationRegex.test(s);
}

export function validateIsNonNegativeNumber(s: string): boolean {
  if (!validateIsNumber(s)) return false;
  const float = Number.parseFloat(s);
  return !Number.isNaN(float) && float >= 0;
}
