import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Notification {
  message: string;
}

export interface NotificationsState {
  current: Notification | null;
}

const initialState: NotificationsState = {
  current: null,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetNotifications: (state) => {
      state.current = null;
    },
    setNotification: (state, action: PayloadAction<Notification>) => {
      state.current = action.payload;
    },
  },
});

export const { resetNotifications, setNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
