import React from 'react';

import Block from './Blocks/Block';
import EquationInputHandler from './EquationInputHandler';
import { useEquationSelector } from './redux/store';
import { Block as BlockType } from './types';

interface EquationControllerProps {
  onChange: (block: BlockType) => any;
}

const EquationController: React.FC<EquationControllerProps> = ({ onChange }) => {
  const block = useEquationSelector((state) => state.block);
  React.useEffect(() => onChange(block), [block]);

  return (
    <EquationInputHandler>
      <Block block={block} />
    </EquationInputHandler>
  );
};

export default EquationController;
