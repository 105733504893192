import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

import IndexNavSelect from './IndexNavSelect';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';
import Counter from '../../../../utils/Counter';
import { useDispatch } from '../../../../redux/store';
import { engineeringChecklistSlice } from '../../../../redux/tools/engineeringChecklist';
import { getVariableAliasValidationError } from '../../../../utils/inputValidation';
import Tooltip from '../../../common/Tooltip';
import Select from '../../../common/Select';
import InputField from '../../../common/InputField';
import HTRIUnitSelect from '../../../common/HTRIUnitSelect';
import Chip from '../../../common/Chip';
import Typography from '../../../common/Typography';
import { getVariableTooltipLabel } from '../../../../utils/strings';

interface EngineeringChecklistVariableProps {
  variable: EngineeringChecklist.Variable;
  aliasCounter: Counter<string>;
  idx: number;
}

const EngineeringChecklistVariable: React.FC<EngineeringChecklistVariableProps> = ({
  variable,
  aliasCounter,
  idx,
}) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(engineeringChecklistSlice.actions.removeVariable(variable.id))
  };

  function update(updates: Omit<Partial<EngineeringChecklist.Variable>, 'id' | 'type' | 'unitSet'>): void {
    dispatch(engineeringChecklistSlice.actions.updateVariable({
      id: variable.id,
      updates,
    }));
  }

  let error: string | undefined;
  if (aliasCounter.get(variable.alias)! > 1) error = `Alias "${variable.alias}" is defined multiple times.`;
  const validationError = getVariableAliasValidationError(variable.alias);
  if (validationError) error = validationError;
  const errorComponent = error ? (
    <Tooltip label={error} width="full">
      <ExclamationTriangleIcon className="text-red-500 size-6" />
    </Tooltip>
  ) : undefined;

  const ioSelectionOptions: string[] = [EngineeringChecklist.IOSelection.Input, EngineeringChecklist.IOSelection.Output];

  const ioSelectionComponent = variable.ioSelection ? (
    <Select
      value={variable.ioSelection}
      options={ioSelectionOptions}
      onChange={(s) => update({ ioSelection: s as EngineeringChecklist.IOSelection })}
    />
  ) : undefined;

  let navOptionComponents: React.ReactNode;
  if (variable.navigationStrings.length) {
    // Populate options, removing duplicates
    const navOptions: Set<string>[] = Array.from(
      { length: variable.navigationStrings[0].split(',').length},
      () => new Set(),
    );
    variable.navigationStrings.forEach((row) => {
      row.split(',').forEach((option, idx) => navOptions[idx].add(option));
    });

    // Create components
    navOptionComponents = navOptions.map((options, navIdx) => {
      const handleChange = (s: string | EngineeringChecklist.IndexNavSelection) => {
        dispatch(engineeringChecklistSlice.actions.updateVariableNavSelection({
          id: variable.id,
          navIdx,
          value: s,
        }));
      };

      let inputComponent: React.ReactNode;
      // If 0 is an option, this is an index selection
      if (options.has('0')) {
        inputComponent = (
          <IndexNavSelect
            navSelections={variable.navSelections}
            idx={navIdx}
            onChange={handleChange}
          />
        );
      }
      // If there is only one possible value, simply show that to the user
      else if (options.size === 1) {
        inputComponent = <InputField value={options.values().next().value} readOnly />;
      }
      // Default: let user select between the options
      else {
        inputComponent = (
          <Select
            value={variable.navSelections[navIdx] as string}
            options={Array.from(options)}
            onChange={handleChange}
          />
        );
      }

      return (
        <div key={navIdx}>
          {inputComponent}
        </div>
      );
    });
  }

  const unitSelectComponent = variable.unitSet ? (
    <HTRIUnitSelect
      unitSet={variable.unitSet}
      unit={variable.unit}
      onChange={(unit) => update({ unit })}
    />
  ) : undefined;

  return (
    <>
      <div className="h-11 flex items-center justify-center">
        {errorComponent}
      </div>
      <div className="h-11 flex items-center justify-center">
        <Chip color="white" label={(idx + 1).toString()} onDelete={handleDelete} />
      </div>
      <Tooltip className="h-11 flex flex-row items-center" width="full" label={getVariableTooltipLabel(variable)}>
        <Typography className="truncate" variant="body1">{variable.label}</Typography>
      </Tooltip>
      <div className="min-w-24">
        <InputField
          textAlign="right"
          value={variable.alias}
          onChange={(alias) => update({ alias })}
        />
      </div>
      <div>
        {ioSelectionComponent}
      </div>
      <div className="flex flex-col gap-[2px]">
        {navOptionComponents}
      </div>
      <div>
        {unitSelectComponent}
      </div>
    </>
  );
};

export default EngineeringChecklistVariable;
