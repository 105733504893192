import React from 'react';
import InputField from '../../common/InputField';
import Materials from '../../../types/materials';
import { useDispatch, useSelector } from '../../../redux/store';
import { updateFilters } from '../../../redux/tools/materials';
import Select from '../../common/Select';
import Typography from '../../common/Typography';
import NominalCompositionFilter from './NominalCompositionFilter';
import MaterialsContext from './MaterialsContext';

interface LabeledFilterProps {
  name: string;
  children: React.ReactNode;
}

const LabeledFilter: React.FC<LabeledFilterProps> = ({ name, children }) => {
  return (
    <>
      <Typography className="truncate">{name}</Typography>
      <div className="max-w-80">
        {children}
      </div>
    </>
  );
};

const Filters: React.FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.materials.filters);
  const { filterOptions } = React.useContext(MaterialsContext);

  const update = (updates: Partial<Materials.FilterValues>) => {
    dispatch(updateFilters(updates));
  };

  return (
    <div className="grid grid-cols-[minmax(64px,_min-content),_minmax(120px,_1fr)] p-2 gap-2 min-w-min w-full items-center">
      <LabeledFilter name="Name">
        <InputField value={filters.name} onChange={(name) => update({ name })} />
      </LabeledFilter>
      <LabeledFilter name="Group">
        <Select
          value={filters.group}
          options={filterOptions.groups}
          onChange={(group) => update({ group })}
        />
      </LabeledFilter>
      <LabeledFilter name="Composition">
        <NominalCompositionFilter filterOptions={filterOptions} />
      </LabeledFilter>
      <LabeledFilter name="Form">
        <Select
          value={filters.form}
          options={filterOptions.forms}
          onChange={(form) => update({ form })}
        />
      </LabeledFilter>
      <LabeledFilter name="Code">
        <InputField value={filters.code} onChange={(code) => update({ code })} />
      </LabeledFilter>
      <LabeledFilter name="Designation Type">
        <Select
          value={filters.designationType}
          options={filterOptions.designationTypes}
          onChange={(designationType) => update({ designationType })}
        />
      </LabeledFilter>
      <LabeledFilter name="Designation Number">
        <InputField
          value={filters.designationNumber}
          onChange={(designationNumber) => update({ designationNumber })}
        />
      </LabeledFilter>
      <LabeledFilter name="Type">
        <Select
          value={filters.type}
          options={filterOptions.types}
          onChange={(type) => update({ type })}
        />
      </LabeledFilter>
      <LabeledFilter name="Temper">
        <Select
          value={filters.temper}
          options={filterOptions.tempers}
          onChange={(temper) => update({ temper })}
        />
      </LabeledFilter>
      <LabeledFilter name="Grade">
        <InputField value={filters.grade} onChange={(grade) => update({ grade })} />
      </LabeledFilter>
    </div>
  );
};

export default Filters;
