import React from 'react';
import UsersQueryTable, { usersQueryTableColumns } from './UsersQueryTable';
import { generateInitialColumnWidths } from '../../common/ResizableGrid';
import { Role } from '../../../types/API';
import RoleValidator from '../../common/RoleValidator';
import MultiSidebar from '../../common/MultiSidebar';
import UserFilters from './UserFilters';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import DynamicFlexContainer from '../../common/DynamicFlexContainer';
import UserQueryPageSelector from './UserQueryPageSelector';

const initialColumnWidths = generateInitialColumnWidths(usersQueryTableColumns);

export const userManagementToolAccessRoles = new Set<Role>([Role.Admin, Role.Officer]);

const UserManagementTool: React.FC = () => {
  const [columnWidths, setColumnWidths] = React.useState(initialColumnWidths);

  return (
    <RoleValidator roles={userManagementToolAccessRoles}>
      <DynamicFlexContainer direction="row" className="w-full h-full" initialSizes={['20%', '80%']}>
        <MultiSidebar sidebars={[{ title: 'Filters', icon: AdjustmentsHorizontalIcon, content: <UserFilters /> }]} />
        <div className="flex flex-col w-full h-full bg-white">
          <UsersQueryTable columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
          <UserQueryPageSelector />
        </div>
      </DynamicFlexContainer>
    </RoleValidator>
  );
};

export default UserManagementTool;
