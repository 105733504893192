import React from 'react';
import { Block, BlockProps, SymbolGroupBlock } from '../types';
import Symbol from '../Symbol';
import { useEquationSelector } from '../redux/store';
import Autocomplete from './Autocomplete';

const displayAutocompleteMatcher = /^\d$/;

const SymbolGroup: React.FC<BlockProps> = ({ block }) => {
  const symbolGroup = block as Block<SymbolGroupBlock>;
  const showAutocomplete = useEquationSelector((state) => {
    if (displayAutocompleteMatcher.test(symbolGroup.symbols[0].character)) return;
    const possibleFocusTargets = new Set(symbolGroup.id);
    for (const { id } of symbolGroup.symbols) possibleFocusTargets.add(id);
    return possibleFocusTargets.has(state.currentFocus.target?.id);
  });

  const components = symbolGroup.symbols.map((symbol, idx) => <Symbol symbol={symbol} key={idx} />);
  const container = (
    <div className="relative flex flex-row">
      {components}
    </div>
  );

  if (showAutocomplete) return (
    <Autocomplete block={symbolGroup}>
      {container}
    </Autocomplete>
  );

  return container;
};

export default SymbolGroup;
