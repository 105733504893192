import React from 'react';
import Actionbar, { ActionbarActions } from '../Actionbar';
import { useDispatch, useSelector } from '../../../redux/store';
import { calculatePropertyTable, steamTablesSlice } from '../../../redux/tools/steamTables';
import SteamTableInputs from './SteamTableInputs';
import { TabProps } from '../../common/Tabs';
import SteamTableProperties from './SteamTableProperties';
import SteamTableGraphs from './SteamTableGraphs';
import RoutedTabs from '../../common/RoutedTabs';

const TabContent = ({ children }: { children: React.ReactNode }) => (
  <div className="w-full h-full p-2">
    {children}
  </div>
);

const tabs: TabProps[] = [
  {
    label: 'Operating Conditions',
    element: (
      <TabContent>
        <SteamTableInputs />
      </TabContent>
    ),
  },
  {
    label: 'Property Tables',
    element: (
      <TabContent>
        <SteamTableProperties />
      </TabContent>
    ),
  },
  {
    label: 'Property Graphs',
    element: (
      <TabContent>
        <SteamTableGraphs />
      </TabContent>
    ),
  },
];

const SteamTables: React.FC = () => {
  const dispatch = useDispatch();
  const solveStatus = useSelector((state) => state.steamTables.solveStatus);
  const [activeTabIdx, setActiveTabIdx] = React.useState(0);

  const actionbarActions: ActionbarActions = {
    save: () => dispatch(steamTablesSlice.actions.save()),
    load: {
      accept: 'application/json',
      onLoad: (content) => dispatch(steamTablesSlice.actions.load(content)),
    },
    export: () => dispatch(steamTablesSlice.actions.export()),
    run: () => dispatch(calculatePropertyTable()),
  };

  return (
    <div className="h-full w-full flex flex-col bg-white">
      <Actionbar actions={actionbarActions} solveStatus={solveStatus} />
      <RoutedTabs tabs={tabs} />
    </div>
  );
};

export default SteamTables;
