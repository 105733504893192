import React from 'react';
import { useDispatch, useSelector } from '../../../redux/store';
import { useGetAllMaterialsQuery } from '../../../redux/materialsAPI';
import Material from '../../../types/materials';
import MaterialLabel from './MaterialLabel';
import { TrashIcon } from '@heroicons/react/24/outline';
import { removeMaterialFromFavorites, startViewingMaterialDetailsFor } from '../../../redux/tools/materials';

interface FavoriteMaterialProps {
  material: Material.Overview;
}

const FavoriteMaterial: React.FC<FavoriteMaterialProps> = ({ material }) => {
  const [hovering, setHovering] = React.useState(false);
  const dispatch = useDispatch();

  const removeButton = hovering && (
    <TrashIcon
      className="size-7 shrink-0 ml-auto p-1 rounded-full text-slate-700 bg-transparent hover:bg-slate-400 transition-colors"
      onClick={(e) => {
        e.stopPropagation();
        dispatch(removeMaterialFromFavorites(material.id));
      }}
    />
  );

  return (
    <button
      className="flex flex-row items-center w-full max-w-full px-1 bg-transparent hover:bg-sky-100 transition-colors"
      onClick={() => dispatch(startViewingMaterialDetailsFor(material.id))}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <MaterialLabel material={material} />
      {removeButton}
    </button>
  );
};

function useMaterialIDMap() {
  const { currentData } = useGetAllMaterialsQuery();

  return React.useMemo(() => {
    const materialsMap = new Map<number, Material.Overview>();
    for (const material of currentData ?? []) materialsMap.set(material.id, material);
    return materialsMap;
  }, [currentData]);
}

const FavoriteMaterials: React.FC = () => {
  const favorites = useSelector((state) => state.materials.favorites);
  const materialIDMap = useMaterialIDMap();

  const components: React.ReactNode[] = favorites.map((id) => {
    const material = materialIDMap.get(id);
    if (!material) return undefined;
    return <FavoriteMaterial material={material} key={id} />;
  });

  return (
    <div className="flex flex-col">
      {components}
    </div>
  );
};

export default FavoriteMaterials;
