import parseEquation from './parseEquation';
import { BinaryOperatorBlock, Block, BlockType, BlockWithoutID, CharacterSymbol, DataModelVariableBlock, DigitOrCharacterSymbol, InvalidFunctionOrVariableBlock, NegativeBlock, ParenthesesBlock, PlaceholderBlock, Symbol, SymbolGroupBlock, SymbolType, SymbolWithoutID, UnparsableBlock } from './types';
import { BlockPlacement, Focus, FocusType, SymbolFocus } from './redux/reducer';
import { BlockInsertionKey, createController } from './redux/BlockController';
import { EngineeringChecklist } from '../../../types/engineeringChecklist';

export function createSymbol<ST extends SymbolWithoutID>(props: ST): Symbol<ST> {
  return {
    ...props,
    id: crypto.randomUUID(),
  }
}
const decimalRegex = /^\d$/;
export function createSymbolFromCharacter(c: string): DigitOrCharacterSymbol {
  if (decimalRegex.test(c)) return createSymbol({ type: SymbolType.Digit, character: c });
  return createSymbol({ type: SymbolType.Character, character: c });
}
export function createBlock<BT extends BlockWithoutID>(props: BT): Block<BT> {
  return {
    ...props,
    id: crypto.randomUUID(),
  }
}

export function createSymbolFocus(symbol: Symbol): SymbolFocus {
  return { type: FocusType.Symbol, target: symbol };
}
export function createBlockFocus(block: Block, placement: BlockPlacement = BlockPlacement.Before): Focus {
  switch (block.type) {
    case BlockType.Placeholder:
      return { type: FocusType.Symbol, target: block.placeholder };
    case BlockType.DataModelVariable:
    case BlockType.InvalidFunctionOrVariable:
    case BlockType.Unparsable:
      return { type: FocusType.Block, target: block.symbolGroup, placement };
    default:
      return { type: FocusType.Block, target: block, placement };
  }
}

function symbolizeText(text: string, nonItalic?: boolean): Symbol<CharacterSymbol>[] {
  const symbols: Symbol<CharacterSymbol>[] = [];
  for (const c of text) {
    symbols.push(createSymbol({
      type: SymbolType.Character,
      character: c,
      nonItalic,
    }));
  }
  return symbols;
}
export function createSymbolGroupBlock(text: string, nonItalic?: boolean): Block<SymbolGroupBlock> {
  return createBlock({
    type: BlockType.SymbolGroup,
    symbols: symbolizeText(text, nonItalic),
  });
}

export function createDataModelVariableBlock(variable: EngineeringChecklist.Variable): Block<DataModelVariableBlock> {
  return createBlock({
    type: BlockType.DataModelVariable,
    variable,
    symbolGroup: createSymbolGroupBlock(variable.alias),
  });
}

export function createInvalidFunctionOrVariableBlock(text: string): Block<InvalidFunctionOrVariableBlock> {
  return createBlock({
    type: BlockType.InvalidFunctionOrVariable,
    symbolGroup: createSymbolGroupBlock(text),
  });
}

export function createPlaceholderBlock(): Block<PlaceholderBlock> {
  return createBlock({
    type: BlockType.Placeholder,
    placeholder: createSymbol({ type: SymbolType.Placeholder }),
  });
}

export function createBinaryOperatorBlock(
  lhs: Block,
  operator: '+' | '-' | '*',
  rhs: Block,
): Block<BinaryOperatorBlock> {
  return createBlock({
    type: BlockType.BinaryOperator,
    lhs,
    operator: createSymbol({
      type: SymbolType.Operator,
      character: operator,
    }),
    rhs,
  });
}

export function createNegativeBlock(content: Block): Block<NegativeBlock> {
  return createBlock({
    type: BlockType.Negative,
    negativeSign: createSymbol({
      type: SymbolType.Digit,
      character: '-',
    }),
    content,
  })
}

export function createParenthesesBlock(content: Block): Block<ParenthesesBlock> {
  return createBlock({
    type: BlockType.Parentheses,
    leftParen: createSymbol({
      type: SymbolType.Parenthesis,
      character: '(',
    }),
    content,
    rightParen: createSymbol({
      type: SymbolType.Parenthesis,
      character: ')',
    }),
  });
}

export function getBlockDirectSymbols(block: Block): Symbol[] {
  switch (block.type) {
    case BlockType.BinaryOperator: return [block.operator];
    case BlockType.Parentheses: return [block.leftParen, block.rightParen];
    case BlockType.SymbolGroup: return block.symbols;
    case BlockType.Placeholder: return [block.placeholder];
    case BlockType.MismatchedParenthesis: return [block.symbol];
    case BlockType.Negative: return [block.negativeSign];
  }
  return [];
}

type BlockControllerProps = { block: Block, createsNewContext?: boolean };
export function getBlockChildren(block: Block): BlockControllerProps[] {
  switch (block.type) {
    case BlockType.BinaryOperator:
      return [{ block: block.lhs }, { block: block.rhs }];
    case BlockType.DataModelVariable:
      return [{ block: block.symbolGroup, createsNewContext: true }];
    case BlockType.Division:
      return [
        { block: block.numerator, createsNewContext: true },
        { block: block.denominator, createsNewContext: true },
      ];
    case BlockType.Exponentiation:
      return [
        { block: block.base },
        { block: block.exponent, createsNewContext: true },
      ];
    case BlockType.Function:
      return [{ block: block.function }, { block: block.operand }];
    case BlockType.Negative:
      return [{ block: block.content }];
    case BlockType.Parentheses:
      return [{ block: block.content }];
    case BlockType.InvalidFunctionOrVariable:
      return [{ block: block.symbolGroup, createsNewContext: true }];
    case BlockType.Unparsable:
      return [{ block: block.symbolGroup, createsNewContext: true }];
  }
  return [];
}

function createNthSymbolFocus(block: Block, n: number, variables: Map<string, EngineeringChecklist.Variable>) {
  const controller = createController(block, variables);
  let idx = 0;
  for (const symbol of controller.getAllSymbols()) {
    if (symbol.type === SymbolType.Operator || symbol.type === SymbolType.Parenthesis) continue;
    if (idx === n) return createSymbolFocus(symbol);
    idx++;
  }
}

export function createBlockFromSymbols(
  symbols: DigitOrCharacterSymbol[],
  variables: Map<string, EngineeringChecklist.Variable>,
  focusIdx?: number,
  prioritizeMovingIntoFunction = false,
): { block: Block, focus: Focus | undefined } {
  const text = symbols.map((symbol) => symbol.character).join('');
  const block = parseEquation(text, variables);

  // Determine focus to use (if any)
  let focus: Focus;
  if (prioritizeMovingIntoFunction) {
    // Symbol with idx 1 in a function is the placeholder inside
    if (block.type === BlockType.Function) focus = createNthSymbolFocus(block.operand, 0, variables);
  }
  if (focusIdx !== undefined && !focus) {
    focus = createNthSymbolFocus(block, focusIdx, variables);
  }

  return {
    block,
    focus,
  };
}

export function deleteFromBlock(
  originalSymbols: Symbol[],
  toDelete: Symbol,
  variables: Map<string, EngineeringChecklist.Variable>,
): { block: Block, focus: Focus } {
  const symbolToDeleteIdx = originalSymbols.findIndex((symbol) => symbol.id === toDelete.id);
  if (symbolToDeleteIdx === -1) return;
  const newSymbols = [...originalSymbols];
  newSymbols.splice(symbolToDeleteIdx, 1);

  // Turn empty groups into placeholders
  if (!newSymbols.length) {
    const block = createPlaceholderBlock();
    return {
      block,
      focus: createSymbolFocus(block.placeholder),
    };
  }

  const { block } = createBlockFromSymbols(newSymbols as DigitOrCharacterSymbol[], variables);

  // Determine focus to use
  const symbolToFocusIdx = symbolToDeleteIdx - 1;
  let focus: Focus;
  if (symbolToFocusIdx === -1) focus = createBlockFocus(block);
  if (!focus) {
    let i = 0;
    let lastSymbol: Symbol;
    const controller = createController(block, variables);

    for (const symbol of controller.getAllSymbols()) {
      if (i === symbolToFocusIdx) {
        focus = createSymbolFocus(symbol);
        break;
      }
      lastSymbol = symbol;
      i++;
    }

    if (!focus) focus = createSymbolFocus(lastSymbol);
  }

  return { block, focus };
}

export function mergeBlocks(lhs: Block, rhs: Block): Block {
  const lhsChildren = getBlockChildren(lhs);
  const rhsChildren = getBlockChildren(rhs);

  // If these blocks have no children, combine into symbol group
  if (
    !lhsChildren.length && lhs.type !== BlockType.Placeholder
    && !rhsChildren.length && rhs.type !== BlockType.Placeholder
  ) return createBlock({
    type: BlockType.SymbolGroup,
    symbols: [
      ...getBlockDirectSymbols(lhs),
      ...getBlockDirectSymbols(rhs),
    ] as DigitOrCharacterSymbol[],
  });

  // If both groups are placeholders, make into a single placeholder
  if (lhs.type === BlockType.Placeholder && rhs.type === BlockType.Placeholder) {
    return lhs;
  }

  // Blocks have children, need to convert into multiplication
  return createBinaryOperatorBlock(lhs, '*', rhs);
}

const exponentiationBasesWithParens = new Set<BlockType>([
  BlockType.BinaryOperator,
  BlockType.Division,
  BlockType.Function,
]);
export type SplitBlock = [
  // LHS
  Block | undefined,
  // RHS
  Block | undefined,
  // Block wrapper function
  (block: Block) => Block
];
export function splitBlock(
  key: BlockInsertionKey,
  split: SplitBlock,
): { block: Block, focus: Focus } {
  let [lhs, rhs, wrapBlock] = split;
  const originalLHS = lhs;
  if (lhs === undefined) lhs = createPlaceholderBlock();
  if (rhs === undefined) rhs = createPlaceholderBlock();

  let block: Block;
  let focus: Focus;

  switch (key) {
    case '+':
      block = createBinaryOperatorBlock(lhs, '+', rhs);
      if (rhs.type === BlockType.Placeholder) focus = createSymbolFocus(rhs.placeholder);
      else focus = createSymbolFocus(block.operator);
      break;
    case '-':
      // If there was no LHS, make RHS negative
      if (originalLHS === undefined) {
        block = createNegativeBlock(rhs);
        if (rhs.type === BlockType.Placeholder) focus = createSymbolFocus(rhs.placeholder);
        else focus = createSymbolFocus(block.negativeSign);
      }
      // If there's an LHS, treat as subtraction
      else {
        block = createBinaryOperatorBlock(lhs, '-', rhs);
        if (rhs.type === BlockType.Placeholder) focus = createSymbolFocus(rhs.placeholder);
        else focus = createSymbolFocus(block.operator);
      }
      break;
    case '*':
      block = createBinaryOperatorBlock(lhs, '*', rhs);
      if (rhs.type === BlockType.Placeholder) focus = createSymbolFocus(rhs.placeholder);
      else focus = createSymbolFocus(block.operator);
      break;
    case '^':
      block = createBlock({
        type: BlockType.Exponentiation,
        base: lhs,
        exponent: rhs,
      });
      if (exponentiationBasesWithParens.has(block.base.type)) {
        block.base = createParenthesesBlock(block.base);
      }
      focus = createBlockFocus(block.exponent);
      break;
    case '/':
      block = createBlock({
        type: BlockType.Division,
        numerator: lhs,
        denominator: rhs,
      });
      if (block.denominator.type === BlockType.Placeholder) {
        focus = createSymbolFocus(block.denominator.placeholder);
      }
      else focus = createBlockFocus(block.denominator);
      break;
    case '(':
      block = createParenthesesBlock(rhs);
      if (rhs.type === BlockType.Placeholder) focus = createSymbolFocus(rhs.placeholder);
      else focus = createSymbolFocus(block.leftParen);
      if (lhs && lhs.type !== BlockType.Placeholder) {
        block = createBinaryOperatorBlock(lhs, '*', block);
      }
      break;
    case ')':
      block = createParenthesesBlock(lhs);
      if (lhs.type === BlockType.Placeholder) focus = createSymbolFocus(lhs.placeholder);
      else focus = createSymbolFocus(block.rightParen);
      if (rhs && rhs.type !== BlockType.Placeholder) {
        block = createBinaryOperatorBlock(block, '*', rhs);
      }
      break;
  }

  // Ensure binary operators are correctly nested
  if (block.type === BlockType.BinaryOperator && block.lhs.type === BlockType.BinaryOperator) {
    block = createBlock({
      type: BlockType.BinaryOperator,
      lhs: block.lhs.lhs,
      operator: block.lhs.operator,
      rhs: createBlock({
        type: BlockType.BinaryOperator,
        lhs: block.lhs.rhs,
        operator: block.operator,
        rhs: block.rhs,
      }),
    });
  }

  if (wrapBlock) {
    return {
      block: wrapBlock(block),
      focus,
    };
  }

  return {
    block,
    focus,
  };
}

export function isEmpty(block: Block): boolean {
  if (block.type === BlockType.Placeholder) return true;
  if (block.type === BlockType.Parentheses) return isEmpty(block.content);
  return false;
}

export function getContent(block: Block): Block {
  if (block.type === BlockType.Parentheses) return getContent(block.content);
  return block;
}
