import React from 'react';
import { ComposedChart, ResponsiveContainer, Tooltip } from 'recharts';
import { DataSource } from '../../utils/recharts/dataSources';
import DataAggregator from '../../utils/recharts/DataAggregator';
import { toSignificantFigures } from '../../utils/math';
import Typography from './Typography';
import Data from '../../utils/recharts/types';
import { SynchronousUnitConversionFunction } from '../../hooks/useUnitConversions';

function getUnitSetAbbreviation(unit: Data.Unit): React.ReactNode {
  switch (unit.unitSet.toLowerCase()) {
    case 'temperature': return 'T';
    case 'pressure': return 'P';
    default: return 'x';
  }
}

// These props are implicitly provided by recharts
interface LineProps {
  dataKey: string;
  name: React.ReactNode;
  payload: { x: number };
  unit: string;
  stroke: string;
  value: number;
}

interface CustomTooltipProps {
  payload?: LineProps[];
  xUnits?: Data.Unit;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ payload, xUnits }) => {
  if (!payload?.length) return undefined;

  // Determine the correct x value
  const x = toSignificantFigures(payload[0].payload.x);

  // Deduplicate names, prioritizing interpolated values
  const nameValues = new Map<React.ReactNode, LineProps>();
  for (const point of payload) {
    if (nameValues.has(point.name) && point.dataKey.endsWith('extrapolated')) continue;
    nameValues.set(point.name, point);
  }

  // Sort in descending order so that high y values show at the top
  const sortedNameValues = Array.from(nameValues.entries()).sort(
    ([_nameA, pointA], [_nameB, pointB]) => pointB.value - pointA.value
  );

  const values: React.ReactNode[] = [];
  for (const [name, point] of sortedNameValues) {
    let valueText: React.ReactNode = toSignificantFigures(point.value).toString();

    if (point.unit) valueText += ` ${point.unit}`;

    // Show warning on extrapolated values
    const extrapolationWarning = point.dataKey.endsWith('extrapolated') && (
      <Typography variant="body2" color="red">
        (extrapolated)
      </Typography>
    );

    values.push(
      <div className="contents" style={{ color: point.stroke }} key={point.dataKey}>
        <Typography variant="body2" color="black" className="pl-3 pr-1">
          {valueText}
        </Typography>
        <div className="flex flex-row gap-1 items-center">
          {name}
          {extrapolationWarning}
        </div>
      </div>
    );
  }

  const xValueText = xUnits
    ? `${getUnitSetAbbreviation(xUnits)}=${x} ${xUnits.unit}`
    : `x=${x}`
  ;
  const valuesText = values.length === 1 ? 'Value' : 'Values';

  return (
    <div className="p-1 bg-white bg-opacity-90 border border-slate-300">
      <Typography>{`${valuesText} at ${xValueText}:`}</Typography>
      <Typography variant="body2" className="grid grid-cols-[max-content_max-content] items-center">
        {values}
      </Typography>
    </div>
  );
};

interface GraphProps {
  xDomain: Data.Domain;
  dataSources: DataSource[];
  units?: {
    x: Data.Unit;
    y: Data.Unit;
    convert: SynchronousUnitConversionFunction;
  };
  additionalPointValueCalculationXs?: number[];
  syncId?: string;
}

const Graph: React.FC<GraphProps> = ({ xDomain, dataSources, units, additionalPointValueCalculationXs, syncId }) => {
  const { components, data } = new DataAggregator({
    xDomain,
    dataSources,
    xUnits: units?.x,
    yUnits: units?.y,
    convert: units?.convert,
    additionalPointValueCalculationXs,
  }).generate();

  return (
    <ResponsiveContainer className="overflow-hidden bg-white" width="100%" height="100%">
      <ComposedChart data={data} throttleDelay={75} syncId={syncId}>
        {components}
        <Tooltip content={<CustomTooltip xUnits={units.x} />} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Graph;
