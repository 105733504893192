import React from 'react';

type FormItemBase<T> = (
  { initialValue: T | undefined, value?: never, onChange?: never }
  | { initialValue?: never, value: T | undefined, onChange: (t: T) => any }
  | { initialValue?: never, value?: never, onChange?: never }
) & {
  label?: string;
  optional?: boolean;
  validator?: T extends boolean ? never : (t: T) => boolean;
};

export type TextFormItem = FormItemBase<string> & {
  type: 'text' | 'email' | 'password',
};
type CheckboxFormItem = FormItemBase<boolean> & {
  type: 'checkbox',
};
type SelectFormItem<V> = FormItemBase<V> & {
  type: 'select',
  options: readonly V[] | Map<V, string>,
};

export type FormItem<V> = (
  (V extends string ? TextFormItem : never)
  | (V extends boolean ? CheckboxFormItem : never)
  | SelectFormItem<V>
);

export type FormInitializer = Record<string, FormItem<any>>;
type FormValue<T extends FormItem<any>> = (
  T extends TextFormItem ? string
  : T extends CheckboxFormItem ? boolean
  : T extends SelectFormItem<infer V> ? V
  : never
);
export type FormValues<T extends FormInitializer> = {
  [K in keyof T]: T[K] extends FormItem<any> ? FormValue<T[K]> : never;
};
export type InitializedFormItem<T> = T extends FormItem<any>
  ? (
    T & {
      field: string;
      getter: FormValue<T>;
      setter: (v: FormValue<T>) => void;
    }
  )
  : never
;
type InitializedFormItems<T extends FormInitializer> = {
  [K in keyof T]: InitializedFormItem<T[K]>;
};


function getInitialValue<T extends FormItem<any>>(formItem: T): FormValue<T> {
  if (formItem.initialValue !== undefined) return formItem.initialValue;
  if (formItem.value !== undefined) return formItem.value;
  switch (formItem.type) {
    case 'text':
    case 'email':
    case 'password':
      return '' as FormValue<T>;
    case 'checkbox':
      return false as FormValue<T>;
    case 'select':
      return undefined as FormValue<T>;
  }
}

export default function useFormData<T extends FormInitializer>(
  initializer: T
): {
  values: FormValues<T>;
  fields: InitializedFormItems<T>;
} {
  const [values, setValues] = React.useState<FormValues<T>>(() => (
    Object.entries(initializer).reduce((acc, [key, formItem]) => {
      acc[key as keyof T] = getInitialValue(formItem);
      return acc;
    }, {} as Partial<FormValues<T>>) as FormValues<T>
  ));

  const fields: Partial<InitializedFormItems<T>> = {};
  for (const key in initializer) {
    const formItem = initializer[key];
    // Update state value without rerendering here - generally bad practice
    // but we need to ensure that values are synced with the initializer
    if ('value' in formItem) values[key] = formItem.value;
    fields[key] = {
      ...formItem,
      getter: values[key],
      setter: (newValue: any) => {
        if (formItem.onChange !== undefined) formItem.onChange(newValue as never);
        else setValues({ ...values, [key]: newValue});
      },
    } as InitializedFormItem<T[typeof key]>;
  }

  return {
    values,
    fields: fields as InitializedFormItems<T>,
  };
}
