import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Error as SerializableError } from '../types/API';

// Valid interfaces to set errors state with:
type ErrorsStateSetter = Error | Error[] | SerializableError | SerializableError[];

export interface ErrorState {
  hasError: boolean;
  errors: SerializableError[];
}

const initialState: ErrorState = {
  hasError: false,
  errors: [],
};

export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    reset: (state) => {
      state.hasError = false;
      state.errors = [];
    },
    set: (state, action: PayloadAction<ErrorsStateSetter>) => {
      const errors = Array.isArray(action.payload) ? action.payload : [action.payload];
      const newErrors = errors.map((error) => ({ message: error.message }));
      state.hasError = true;
      state.errors = newErrors;
    },
  },
});

export const { reset, set } = errorsSlice.actions;
