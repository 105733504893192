import React from 'react';
import SetSelector from '../../../common/SetSelector';
import { useDispatch } from '../../../../redux/store';
import Select from '../../../common/Select';
import Typography from '../../../common/Typography';
import { TrashIcon } from '@heroicons/react/24/outline';
import { engineeringChecklistSlice } from '../../../../redux/tools/engineeringChecklist';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';

interface DependencyGroupProps {
  rules: EngineeringChecklist.Rule[];
  rulesToExclude: Set<string>;
  rule: EngineeringChecklist.Rule;
  group: EngineeringChecklist.DependencyGroup;
  idx: number;
}

const dependencyGroupTypes: string[] = [
  EngineeringChecklist.DependencyGroupType.Any,
  EngineeringChecklist.DependencyGroupType.All,
];

const DependencyGroup: React.FC<DependencyGroupProps> = ({ rules, rulesToExclude, rule, group, idx }) => {
  const dispatch = useDispatch();

  const update = (updates: Partial<EngineeringChecklist.DependencyGroup>) => {
    dispatch(engineeringChecklistSlice.actions.updateRuleDependencyGroup({
      ruleID: rule.id,
      dependencyGroupIdx: idx,
      updates,
    }));
  };

  const handleRemoveClick = () => {
    dispatch(engineeringChecklistSlice.actions.removeRuleDependencyGroup({
      ruleID: rule.id,
      dependencyGroupIdx: idx,
    }));
  };

  const options = React.useMemo(() => {
    const options = new Map<string, string>();
    rules.forEach((currentRule, idx) => {
      // Don't display the rule this group is for
      if (rule.id === currentRule.id) return;
      // Don't display options that would create a circular dependency chain
      if (rulesToExclude.has(currentRule.id)) return;

      // Show rules as their display indices (offset by 1)
      options.set(currentRule.id, (idx + 1).toString());
    });
    return options;
  }, [rules]);

  return (
    <div className="flex flex-row gap-1">
      <div className="flex items-center h-9 gap-1">
        <div
          className="rounded-full bg-transparent hover:bg-slate-300 transition-colors cursor-pointer"
          onClick={handleRemoveClick}
        >
          <TrashIcon className="size-8 p-1 text-slate-500" />
        </div>
        <Select
          value={group.type}
          options={dependencyGroupTypes}
          onChange={(type) => update({ type: type as EngineeringChecklist.DependencyGroupType })}
        />
        <Typography className="mr-1 text-nowrap">of these:</Typography>
      </div>
      <SetSelector
        selected={group.ruleIDs}
        options={options}
        onChange={(ruleIDs) => update({ ruleIDs })}
        placeholder="Create another rule to add a dependency to this one."
      />
    </div>
  );
};

export default DependencyGroup;
