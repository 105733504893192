import React from 'react';

interface UseDragOptions {
  onDrag: (event: MouseEvent) => any,
  onMouseDown?: () => any,
  onMouseUp?: () => any,
  cursor?: string; // Cursor to use while dragging
}

/**
 * Detects element onDraggableElementMouseDown is attached to being dragged
 * @param options Options to use
 * @returns { onDraggableElementMouseDown }, a callback to attach to the draggable element
 */
export default function useDrag(options: UseDragOptions) {
  const [dragging, setDragging] = React.useState(false);

  React.useEffect(() => {
    if (!dragging) return;

    const handleMouseMove = (e: MouseEvent) => {
      options.onDrag(e);
    };
    const handleMouseUp = (e: MouseEvent) => {
      if (e.button !== 0) return;
      document.body.style.setProperty('cursor', 'default');
      setDragging(false);
      if (options.onMouseUp) options.onMouseUp();
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, options.onDrag]);

  const onDraggableElementMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    if (e.button !== 0) return;
    e.preventDefault();
    document.body.style.setProperty('cursor', options.cursor);
    setDragging(true);
    if (options.onMouseDown) options.onMouseDown();
  };

  return { dragging, onDraggableElementMouseDown };
}
