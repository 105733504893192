import React from 'react';
import unitsAPI, { UnitSetValues } from '../redux/unitsAPI';
import HTRICalcAPI from '../redux/HTRICalcAPI';
import useQueries from './useQueries';
import { UnitSet } from '../types/units';

export type SynchronousUnitConversionFunction = (
  value: number | undefined,
  unitSet: string,
  unit: string,
  newUnit: string,
) => number;

export class NotReadyError extends Error {
  constructor(message?: string) { super(message); this.name = 'NotReadyError'; }
}

type UnitSetConversionFactors = Map<string, UnitSetValues>;

interface UnitConverter {
  isFetching: boolean;
  isError: boolean;
  currentData?: UnitSetConversionFactors;
  convert: SynchronousUnitConversionFunction;
  refetch: () => void;
}

function useConvert(
  conversionFactors: UnitSetConversionFactors
): SynchronousUnitConversionFunction {
  return React.useCallback((value: number | undefined, unitSet: string, unit: string, newUnit: string) => {
    if (!conversionFactors) throw new NotReadyError('Attempted to convert units without checking for ready and error.');
    if (value === undefined) return undefined;
    const from = conversionFactors.get(unitSet)[unit];
    const to = conversionFactors.get(unitSet)[newUnit];
    return (from.valueSI * value + from.offset - to.offset) / to.valueSI;
  }, [conversionFactors]);
}

const useUnitsServiceQueries = useQueries(
  unitsAPI.endpoints.getUnitSet
);

export function useUnitsServiceConversions(unitSets: UnitSet[]): UnitConverter {
  const { isFetching, isError, currentData, refetch } = useUnitsServiceQueries(unitSets);
  const convert = useConvert(currentData);

  return { isFetching, isError, currentData, convert, refetch };
}

const useHTRIUnitConversionQueries = useQueries(
  HTRICalcAPI.endpoints.getHTRIUnitConversions
);

export function useHTRIUnitConversions(unitSets: string[]): UnitConverter {
  const { isFetching, isError, currentData, refetch } = useHTRIUnitConversionQueries(unitSets);
  const convert = useConvert(currentData);

  return { isFetching, isError, currentData, convert, refetch };
}
