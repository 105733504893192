import React from 'react';
import Typography from '../../../common/Typography';
import InputField from '../../../common/InputField';
import { validateIsPositiveInteger } from '../../../../utils/inputValidation';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';

interface IndexNavSelectProps {
  navSelections: EngineeringChecklist.NavSelections;
  idx: number;
  onChange: (navSelection: EngineeringChecklist.IndexNavSelection) => any;
}

function convertToDisplayValue(value: EngineeringChecklist.IndexNavSelection): string {
  if (value === '*') return 'All';
  if (value === '*Max') return 'Max';
  if (value === '*Min') return 'Min';
  if (typeof value === 'string') return value;
  return value.toString();
}

function convertToIndexNavSelection(s: string): EngineeringChecklist.IndexNavSelection {
    if (s === 'All') return '*';
    if (s === 'Max') return '*Max';
    if (s === 'Min') return '*Min';
    // Cut off at 10 digits to avoid overflow
    return parseInt(s.slice(0, 10));
}

const singleDigitRegex = /\d/;
const specialDisplayValues = new Set(['All', 'Min', 'Max']);

const IndexNavSelect: React.FC<IndexNavSelectProps> = ({ navSelections, idx, onChange }) => {
  const value = navSelections[idx] as EngineeringChecklist.IndexNavSelection;
  const displayValue = convertToDisplayValue(value);

  // If any other selection is min or max, disable selecting those on this selection,
  // as this isn't supported by Xchanger Suite
  const displayMinAndMax = navSelections.every((selection, currentIdx) => (
    idx === currentIdx || (selection !== '*Min' && selection !== '*Max')
  ));

  const handleChange = (s: string) => {
    onChange(convertToIndexNavSelection(s));
  };

  const isValidDisplayValue = (s: string): boolean => {
    if (validateIsPositiveInteger(s)) return true;
    if (displayMinAndMax) {
      if (s === 'Min' || s === 'Max' || s === 'All') return true;
    }
    return false;
  }

  const handleValidationFail = (s: string) => {
    // If empty, fill with 0
    if (s.length === 0) return handleChange('0');

    // Handle when coming from a special value
    if (specialDisplayValues.has(displayValue)) {
      // If a number was inserted, use that as the new value.
      // Otherwise the user deleted a character, set to 0.
      const matches = s.match(singleDigitRegex);
      if (matches) handleChange(matches[0]);
      else handleChange('0');
    }
  };

  const createColorClasses = (selectorValue: string): string => {
    return (displayValue === selectorValue)
      ? "bg-green-500 hover:bg-green-400"
      : "bg-sky-600 hover:bg-sky-500"
    ;
  }

  const specialValueSelectors = [
    <button
      className={`${createColorClasses('All')} flex items-center justify-center row-span-2 h-full rounded px-1 transition-colors`}
      onClick={() => handleChange('All')}
      key="all"
    >
      All
    </button>
  ];

  if (displayMinAndMax) {
    specialValueSelectors.push(
      (
        <button
          className={`${createColorClasses('Max')} flex items-center justify-center rounded px-1 transition-colors`}
          onClick={() => handleChange('Max')}
          key="max"
        >
          Max
        </button>
      ),
      (
        <button
          className={`${createColorClasses('Min')} flex items-center justify-center rounded px-1 transition-colors`}
          onClick={() => handleChange('Min')}
          key="min"
        >
          Min
        </button>
      ),
    );
  }

  const specialValueSelectComponent = (
    <Typography
      className="h-full pl-0.5 grid grid-rows-2 grid-flow-col gap-px text-center"
      variant="caption"
      color="white"
    >
      {specialValueSelectors}
    </Typography>
  );

  return (
    <div className="h-9 flex flex-row items-center justify-start">
      <Typography className="pr-2">Index:</Typography>
      <div className="min-w-9 max-w-16">
        <InputField
          textAlign="right"
          value={displayValue}
          validator={isValidDisplayValue}
          onValidationFail={handleValidationFail}
          onChange={handleChange}
        />
      </div>
      {specialValueSelectComponent}
    </div>
  );
};

export default IndexNavSelect;
