import { Block, BlockType } from '../components/common/EquationEditor/types';
import { createDataModelVariableBlock, createInvalidFunctionOrVariableBlock, getBlockChildren } from '../components/common/EquationEditor/utils';
import { EngineeringChecklistState } from '../redux/tools/engineeringChecklist';
import { EngineeringChecklist } from '../types/engineeringChecklist';

function generateVariableAlias(label: string): string {
  return label.length > 3 ? label.replace(/[^A-Z0-9]+/g, "") : label;
}

function generateNavSelections(navigationStrings: string[]): EngineeringChecklist.NavSelections {
  if (navigationStrings.length === 0) return [];
  return navigationStrings[0].split(',').map((value) => value === '0' ? 0 : value);
}

/**
 * Creates a variable for a data model node
 * @param node Node to parse
 * @param state Current checklist state (used to prevent alias collisions)
 * @returns A variable with a unique alias for the given node
 */
export function parseVariable(
  node: EngineeringChecklist.DataModelNode,
  state: EngineeringChecklistState,
): EngineeringChecklist.Variable {
  // Ensure alias is unique
  const originalAlias = generateVariableAlias(node.label);
  const existingAliases = new Set(state.variables.map((variable) => variable.alias));
  let alias = originalAlias;
  for (let i = 2; existingAliases.has(alias); i++) alias = `${originalAlias}_${i}`;

  return {
    id: crypto.randomUUID(),
    label: node.label,
    attributeName: node.attributeName,
    type: node.type!,
    navigationStrings: node.navigationStrings,
    alias,
    navSelections: generateNavSelections(node.navigationStrings),
    ioSelection: node.io ? EngineeringChecklist.IOSelection.Input : null,
    unitSet: node.units,
    unit: node.units ? [] : undefined,
  };
}

function updateBlockVariableReferences(
  block: Block | undefined,
  variable: EngineeringChecklist.Variable,
  oldVariableID: string,
): void {
  if (!block) return;
  // If block is a data model variable, check if reference matches
  if (block.type === BlockType.DataModelVariable) {
    if (block.variable.id === oldVariableID) {
      // If new alias has characters, set text to that variable's alias
      if (variable.alias.length) Object.assign(block, createDataModelVariableBlock(variable));
      // Otherwise, detach this block from the variable
      else {
        const blockText = block.symbolGroup.symbols.map((symbol) => symbol.character).join('');
        Object.assign(block, createInvalidFunctionOrVariableBlock(blockText));
      }
    }
  }

  // Recursively update children
  else {
    getBlockChildren(block).forEach(({ block: childBlock }) => (
      updateBlockVariableReferences(childBlock, variable, oldVariableID)
    ));
  }

  // Note that InvalidFunctionOrVariable blocks *could* match the new variable text,
  // but automatically linking these to variables without user confirmation seems unintuitive
  // so we choose not to do anything with these blocks
}

export function updateNumericRuleVariableReferences(
  rule: EngineeringChecklist.NumericRule,
  variable: EngineeringChecklist.Variable,
  oldVariableID: string,
): void {
  updateBlockVariableReferences(rule.lhs, variable, oldVariableID);
  updateBlockVariableReferences(rule.rhs, variable, oldVariableID);
}

function updateBlockWithVariableDeletion(block: Block | undefined, variableID: string): void {
  if (!block) return;
  // Detach variables with matching id from the deleted variable
  if (block.type === BlockType.DataModelVariable) {
    if (block.variable.id === variableID) {
      const blockText = block.symbolGroup.symbols.map((symbol) => symbol.character).join('');
      Object.assign(block, createInvalidFunctionOrVariableBlock(blockText));
    }
  }

  // Recursively update children
  else {
    getBlockChildren(block).forEach(({ block: childBlock}) => (
      updateBlockWithVariableDeletion(childBlock, variableID)
    ));
  }
}

export function updateNumericRuleWithVariableDeletion(
  rule: EngineeringChecklist.NumericRule,
  variableID: string,
): void {
  updateBlockWithVariableDeletion(rule.lhs, variableID);
  updateBlockWithVariableDeletion(rule.rhs, variableID);
}

/**
 * Updates rules given the deletion of a variable
 * @param state Current checklist state
 * @param variableID Variable ID that has been deleted
 */
export function updateRulesWithVariableDeletion(
  state: EngineeringChecklistState,
  variableID: string
): void {
  const rulesToDelete = new Set<number>();
  state.rules.forEach((rule, idx) => {
    switch (rule.type) {
      case (EngineeringChecklist.RuleType.Enum): {
        if (rule.lhs?.id === variableID) rulesToDelete.add(idx);
        return;
      }
      case (EngineeringChecklist.RuleType.Numeric): {
        updateNumericRuleWithVariableDeletion(rule, variableID);
        return;
      }
      case (EngineeringChecklist.RuleType.String): {
        if (rule.lhs?.id === variableID) rule.lhs = undefined;
        rule.rhs = rule.rhs?.filter((value) => {
          if (typeof value === 'string') return true;
          return value?.id !== variableID;
        });
        return;
      }
    }
  });

  state.rules = state.rules.filter((_, idx) => !rulesToDelete.has(idx));
}

/**
 * Updates references in rules to the variable with a given id
 * @param state Engineering checklist state
 * @param variable Variable to change references to oldVariableID to
 * @param oldVariableID ID to replace variable references for
 */
export function updateRulesReferencingVariable(
  state: EngineeringChecklistState,
  variable: EngineeringChecklist.Variable,
  oldVariableID: string,
) {
  state.rules.forEach((rule) => {
    switch (rule.type) {
      case (EngineeringChecklist.RuleType.Enum): {
        if (rule.lhs?.id === oldVariableID) rule.lhs = variable;
        return;
      }
      case (EngineeringChecklist.RuleType.Numeric): {
        updateNumericRuleVariableReferences(rule, variable, oldVariableID);
        return;
      }
      case (EngineeringChecklist.RuleType.String): {
        if (rule.lhs?.id === oldVariableID) rule.lhs = variable;
        rule.rhs?.forEach((value, idx) => {
          if (typeof value === 'string') return;
          if (value?.id === oldVariableID && rule.rhs) rule.rhs[idx] = variable;
        });
        return;
      }
    }
  });
}

export function createEmptyRule(type: EngineeringChecklist.RuleType): EngineeringChecklist.Rule {
  const rule: EngineeringChecklist.Rule = {
    type,
    id: crypto.randomUUID(),
    lhs: undefined,
    rhs: undefined,
    op: EngineeringChecklist.Operator.Equals,
    note: '',
    dependencyGroups: [],
  };
  if (rule.type !== EngineeringChecklist.RuleType.Numeric) return rule;
  // Numeric rules should start with greater than or equals, as users
  // probably won't write numeric rules with strict equality very often
  rule.op = EngineeringChecklist.Operator.GreaterThanOrEquals;
  return rule;
}

/**
 * Handles the deletion of a rule
 * @param state Engineering checklist state
 * @param ruleToDeleteID ID of the rule to delete
 */
export function updateRulesWithRuleDeletion(state: EngineeringChecklistState, ruleToDeleteID: string) {
  // Delete rule with the given ID
  const ruleIdx = state.rules.findIndex((rule) => rule.id === ruleToDeleteID);
  state.rules.splice(ruleIdx, 1);

  // Update dependencies
  state.rules.forEach((rule) => {
    const groupsToDelete = new Set<number>();
    rule.dependencyGroups.forEach((group, idx) => {
      // Remove dependency groups that would be made empty because the rule was deleted -
      // ones that were already empty are okay to keep
      if (!group.ruleIDs.length) return;
      group.ruleIDs = group.ruleIDs.filter((ruleID) => ruleID !== ruleToDeleteID);
      if (!group.ruleIDs.length) groupsToDelete.add(idx);
    });
    rule.dependencyGroups = rule.dependencyGroups.filter((_, idx) => !groupsToDelete.has(idx));
  });
}

function getChildWithLabel(children: EngineeringChecklist.DataModelNode[], label: string): EngineeringChecklist.DataModelNode {
  const ret = children.find((child) => label === child.label);
  if (ret) return ret;
  throw new Error('Child does not exist');
}
/**
 * Sets the open prop of a data model node. If newStatus is unspecified, toggles whether the node is open.
 * @param tree Tree to search for the target node
 * @param path Path to the item to change
 * @param newStatus Whether the node should be opened
 * @returns The edited node
 */
export function setDataModelNodeOpenStatus(tree: EngineeringChecklist.DataModelNode[], path: string[], newStatus?: boolean): EngineeringChecklist.DataModelNode {
  try {
    let currentNode = getChildWithLabel(tree, path[0]);
    path.slice(1).forEach((label) => {
      currentNode = getChildWithLabel(currentNode.children, label);
    });
    if (newStatus === undefined) currentNode.open = !currentNode.open;
    else currentNode.open = newStatus;
    return currentNode;
  }
  catch {
    throw new Error(`Attempted to expand non-existent tree node ${path}.`);
  };
}

function nodeMatchesQuery(node: EngineeringChecklist.DataModelNode, query: string): boolean {
  const attributeName = node.attributeName.toLocaleLowerCase();
  const label = node.label.toLocaleLowerCase();
  const lowercaseQuery = query.toLocaleLowerCase();
  // We can make this more robust if needed, but this is a good starting point
  return (
    attributeName.startsWith(lowercaseQuery)
    || label.startsWith(lowercaseQuery)
  );
}
function queryVariablesTreeRecursive(tree: EngineeringChecklist.DataModelNode[], query: string): EngineeringChecklist.DataModelNode[] {
  const results: EngineeringChecklist.DataModelNode[] = [];
  tree.forEach((node) => {
    // If this node directly matches the query, all children should be shown
    if (nodeMatchesQuery(node, query)) results.push(node);
    // No match in this node, filter children to those that match
    else {
      const childrenMatchingQuery = queryVariablesTreeRecursive(node.children, query);
      if (childrenMatchingQuery.length) results.push({ ...node, children: childrenMatchingQuery });
    }
  });
  return results;
}
function expandVariablesTreeNode(node: EngineeringChecklist.DataModelNode): void {
  // Don't expand items with no children
  if (!node.children.length) return;
  node.open = true;
  node.children.forEach(expandVariablesTreeNode);
}
const AUTO_EXPAND_TREE_THRESHOLD = 50;
export function queryDataModel(tree: EngineeringChecklist.DataModelNode[], query: string): EngineeringChecklist.DataModelNode[] {
  const results = queryVariablesTreeRecursive(tree, query);

  // Fully expand tree if there are sufficiently few matches
  let fullyExpandedTreeSize = results.length;
  const nodesToProcess: EngineeringChecklist.DataModelNode[] = [...results];
  const processedNodes = new Set<EngineeringChecklist.DataModelNode>();
  while (nodesToProcess.length) {
    const currentNode = nodesToProcess.pop()!;
    fullyExpandedTreeSize += currentNode.children.length;
    if (fullyExpandedTreeSize >= AUTO_EXPAND_TREE_THRESHOLD) break;
    if (processedNodes.has(currentNode)) continue;
    processedNodes.add(currentNode);
    currentNode.children.forEach((child) => nodesToProcess.push(child));
  }
  if (fullyExpandedTreeSize <= AUTO_EXPAND_TREE_THRESHOLD) {
    results.forEach(expandVariablesTreeNode);
  }

  return results;
}
