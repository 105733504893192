import AutoField from './AutoField';
import FormContainer from './FormContainer';
import FormItem from './FormItem';

const Form = {
  Container: FormContainer,
  Item: FormItem,
  AutoField: AutoField,
};

export default Form;
