import React from 'react';
import LoadingIndicator from './LoadingIndicator';

type LoadingScreenSize = 'sm' | 'lg';

const iconSizes: Record<LoadingScreenSize, string> = {
  sm: 'size-6',
  lg: 'size-12',
};

interface LoadingScreenProps {
  size?: LoadingScreenSize;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ size = 'lg' }) => {
  const iconClass = `${iconSizes[size]} text-slate-700`;

  return (
    <div className="h-full w-full flex bg-white items-center justify-center">
      <LoadingIndicator className={iconClass} />
    </div>
  );
};

export default LoadingScreen;
