import { Middleware } from '@reduxjs/toolkit';
import { errorsSlice, set as setError } from './errors';

export class LoggedError extends Error {
  constructor(message: string) { super(message); this.name = 'LoggedError'; }
}

export function isDispatchableLoggedError(e: any): e is (LoggedError | LoggedError[]) {
  if (e instanceof LoggedError) return true;
  if (Array.isArray(e) && e[0] instanceof LoggedError) return true;
  return false;
}

const errorMiddleware: Middleware = store => next => action => {
  try {
    // Handling for rejected thunk and RTKQ actions
    if (
      !errorsSlice.actions.set.match(action)
      && isDispatchableLoggedError((action as any)?.payload)
    ) {
      throw (action as any).payload;
    }

    return next(action);
  }
  catch (e) {
    // Non-thunk actions will skip the above block and be handled directly here
    if (isDispatchableLoggedError(e)) store.dispatch(setError(e));
    else throw e;

    return next(action);
  }
}

export default errorMiddleware;
