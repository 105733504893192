function hexStringFromBuffer(buffer: ArrayBuffer): string { // buffer is an ArrayBuffer
  return Array.from(new Uint8Array(buffer))
    .map((x) => x.toString(16).padStart(2, '0'))
    .join('');
}

export async function SHA256(s: string): Promise<string> {
  const buffer = new TextEncoder().encode(s);
  const digest = await crypto.subtle.digest('SHA-256', buffer);
  return hexStringFromBuffer(digest);
}
