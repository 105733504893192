import React from 'react';
import { useNavigate } from 'react-router-dom';

import Form from '../common/Form/Form';
import { reset as resetError, set as setError } from '../../redux/errors';
import { useDispatch, useSelector } from '../../redux/store';
import { requestPasswordReset } from '../../utils/API';
import { validateEmail } from '../../utils/inputValidation';
import { setNotification } from '../../redux/notifications';
import { authenticationSlice } from '../../redux/authentication';
import useFormData from '../../hooks/useFormData';
import { isDispatchableLoggedError, LoggedError } from '../../redux/errorMiddleware';

const ResetPasswordRequestPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { values, fields } = useFormData({
    email: {
      label: 'Email',
      type: 'email',
      value: useSelector((state) => state.authentication.signedOutEmail),
      onChange: (newEmail) => dispatch(authenticationSlice.actions.setSignedOutEmail(newEmail)),
    },
  });
  const [requestProcessing, setRequestProcessing] = React.useState(false);

  const canSubmit = validateEmail(values.email);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(resetError());

    setRequestProcessing(true);
    requestPasswordReset(values.email)
      .then(() => {
        setRequestProcessing(false);
        dispatch(setNotification({ message: 'If an account exists with the email you provided, you should receive a link to reset your password shortly.'}));
        navigate('/');
      })
      .catch((e) => {
        if (isDispatchableLoggedError(e)) dispatch(setError(e));
        setRequestProcessing(false);
      });
  };

  return (
    <Form.Container
      title="Request Password Reset"
      submitButtonLabel="Reset"
      canSubmit={canSubmit}
      onSubmit={handleSubmit}
      loading={requestProcessing}
      onBack={() => navigate('/')}
    >
      <Form.AutoField field={fields.email} />
    </Form.Container>
  );
};

export default ResetPasswordRequestPage;
