import React from 'react';
import { range } from '../../utils/iterables';
import Button from './Button';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import Typography from './Typography';

function pagesToDisplay(currentPage: number, numPages: number): number[] {
  // Try to always display 5 pages
  if (currentPage <= 3) {
    return Array.from(range(1, Math.min(numPages + 1, 6)));
  }
  if (currentPage + 2 >= numPages) {
    return Array.from(range(Math.max(numPages - 4, 1), numPages + 1));
  }
  return Array.from(range(
    Math.max(currentPage - 2, 1),
    Math.min(currentPage + 3, numPages + 1),
  ));
}

interface PageSelectorProps {
  page: number;
  numPages: number;
  onPageChange: (newPage: number) => any;
}

const PageSelector: React.FC<PageSelectorProps> = ({ page: currentPage, numPages, onPageChange }) => {
  // Keep page in-bounds
  React.useEffect(() => {
    if (numPages < currentPage) onPageChange(numPages);
    else if (currentPage < 0) onPageChange(1);
  }, [currentPage, numPages]);

  if (numPages <= 1) return undefined;

  const previousPage = currentPage - 1;
  const nextPage = currentPage + 1;

  const pageButtons = pagesToDisplay(currentPage, numPages).map((page) => {
    if (page === currentPage) return <Typography className="px-2 py-1" key={page}>{page}</Typography>
    return (
      <Button
        color="white"
        label={page.toString()}
        onClick={() => onPageChange(page)}
        key={page}
      />
    );
  });

  // Add first and last buttons if there are enough pages to warrant it
  const firstButton = numPages > 5 && (
    <Button
      color="primary"
      label="First"
      iconLeft={<ChevronDoubleLeftIcon className="size-5" />}
      disabled={currentPage <= 1}
      onClick={() => onPageChange(1)}
    />
  );
  const lastButton = numPages > 5 && (
    <Button
      color="primary"
      label="Last"
      iconRight={<ChevronDoubleRightIcon className="size-5" />}
      disabled={currentPage >= numPages}
      onClick={() => onPageChange(numPages)}
    />
  );

  return (
    <div className="flex flex-row gap-2">
      {firstButton}
      <Button
        color="primary"
        label="Previous"
        iconLeft={<ChevronLeftIcon className="size-5" />}
        disabled={previousPage <= 0}
        onClick={() => onPageChange(previousPage)}
      />
        {pageButtons}
      <Button
        color="primary"
        label="Next"
        iconRight={<ChevronRightIcon className="size-5" />}
        disabled={nextPage > numPages}
        onClick={() => onPageChange(nextPage)}
      />
      {lastButton}
    </div>
  );
};

export default PageSelector;
