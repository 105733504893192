import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import App from '../App';
import ViewAccountPage from './Account/ViewAccountPage';
import ChangePasswordPage from './Account/ChangePasswordPage';
import CreateAccountPage from './Account/CreateAccountPage';
import DewPointCalculatorContext from '../context/DewPointCalculatorContext';
import Tool from './Tool';
import LandingPage from './LandingPage';
import UserManagementTool from './Account/UserManagementTool/UserManagementTool';
import SteamTables from './Tools/SteamTables/SteamTables';
import MaterialsDatabase from './Tools/MaterialsDatabase/MaterialsDatabase';
import ClaimValidator from './common/ClaimValidator';
import EngineeringChecklist from './Tools/EngineeringChecklist/EngineeringChecklist';
import ResetPasswordRequestPage from './Account/ResetPasswordRequestPage';
import ResetPasswordPage from './Account/ResetPasswordPage';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/*" element={<App />}>
      <Route index element={<LandingPage />} />
      <Route path="dew-point-calculator/*" element={<Tool context={DewPointCalculatorContext} />} />
      <Route path="steam-tables" element={<ClaimValidator claims={['steam-tables']} />}>
        <Route path=":tab?" element={<SteamTables />} />
      </Route>
      <Route path="engineering-checklist" element={<ClaimValidator claims={['checklists']} />}>
        <Route path=":tab?" element={<EngineeringChecklist />} />
      </Route>
      <Route path="materials-database" element={<ClaimValidator claims={['materials']} />}>
        <Route path=":tab?" element={<MaterialsDatabase />} />
      </Route>
      <Route path="account">
        <Route index element={<ViewAccountPage />} />
        <Route path="change-password" element={<ChangePasswordPage />} />
        <Route path="create" element={<CreateAccountPage />} />
        <Route path="manage" element={<UserManagementTool />} />
      </Route>
      <Route path="reset-password">
        <Route index element={<ResetPasswordRequestPage />} />
        <Route path=":code" element={<ResetPasswordPage />} />
      </Route>
    </Route>
  )
);

export default router;
