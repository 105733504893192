import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Role } from '../types/API';
import { UnitSet } from '../types/units';
import type { BadRequestResponse, Claim, Error, GetUnitSetValuesAPIResponse, SolvePTRangeRequestBody, SolvePTRangeResponse, UserApprovalStatus, UsersAPI } from '../types/API';
import { LoggedError } from '../redux/errorMiddleware';

class NeedsReauthenticationError extends Error {
  constructor(message: string) { super(message); this.name = 'NeedsReauthenticationError'; }
}

/**
 * Parses an HTTP 4xx/5xx response into an Error
 * @param res Unexpected response to process
 * @returns An Error with text explaining the reason for the bad response
 */
export const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred while processing your request. Please try again later or contact an administrator if the problem persists.';
export function parseBadResponse(error: FetchBaseQueryError): Error | Error[] {
  switch (error.status) {
    // 403: Need to reauthenticate
    case 403: return new NeedsReauthenticationError('Your login has expired.');
    // 409: User password expired
    case 409: {
      import('../components/router')
        .then((router) => router.default.navigate('/reset-password'));
      return new LoggedError('Your password has expired. Please reset your password before logging in.');
    }
    // 5xx: Server errors, give an appropriate message
    case 500: return new LoggedError('An internal server error occurred. Please try again later or contact an administrator if the problem persists.');
    case 502: return new LoggedError('We were unable to contact a server necessary to process your request. Please try again later.');
    case 503: return new LoggedError('We were unable to process your request due to a temporary outage. Please try again later or contact an administrator if the problem persists.');
    default:
      if (typeof error.status === 'string' || error.status > 500) return new LoggedError(DEFAULT_ERROR_MESSAGE);
      // Handle 4xx error codes: attempt to read as expected bad request response
      try {
        const responseData = error.data as BadRequestResponse;
        const errors = responseData.errors;
        if (Array.isArray(errors)) {
          return errors.map((error) => new LoggedError(error.message));
        }
        return new LoggedError(DEFAULT_ERROR_MESSAGE);
      }
      catch {
        return new LoggedError(DEFAULT_ERROR_MESSAGE);
      }
  }
}

/**
 * Wrapper function to standardize and remove boilerplate from API calls
 * @param method Request method to use
 * @param url URL to send request to
 * @param body Request body as a plain object
 * @returns A promise with the response body
 * @throws If the response status is not 200-299
 */
export async function makeRequest<
  RequestBodyType = void,
  ResponseType = void
>(
  method: string,
  url: string,
  body?: RequestBodyType,
  tryReauthenticate = true,
): Promise<ResponseType> {
  const res = await fetch(url, {
    method,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (res.ok) return res.json() as Promise<ResponseType>;

  let error: Error | Error[];
  try {
    error = parseBadResponse({
      status: res.status,
      data: await res.json(),
    });
    if (error instanceof NeedsReauthenticationError && tryReauthenticate) {
      await reauthenticate();
      return makeRequest(method, url, body, false);
    }
  }
  catch (e) {
    error = new LoggedError(DEFAULT_ERROR_MESSAGE);
  }

  throw error;
}

// iapws-97 endpoint
export function solvePTRange(
  MinPressure: number,
  MaxPressure: number,
  MinTemperature: number,
  MaxTemperature: number
) {
  const url = '/api/v1/iapws-if97/properties/pt-range';
  return makeRequest<SolvePTRangeRequestBody, SolvePTRangeResponse>(
    'POST',
    url,
    {
      MinPressure,
      MaxPressure,
      MinTemperature,
      MaxTemperature,
      PressurePoints: 11,
      TemperaturePoints: 11,
    }
  );
}

// units endpoint
export function getUnitSetValues(unitSet: UnitSet) {
  const url = `/api/v1/units/unit-sets/${unitSet}/details`;
  return makeRequest<void, GetUnitSetValuesAPIResponse>('GET', url);
}

// users endpoint
export function createAccount({ email, password, role, firstName, lastName, companyName, country,  requestingUserIsAdmin }: {
  email: string,
  password: string,
  role: Role,
  firstName: string,
  lastName: string,
  companyName: string,
  country: string,
  requestingUserIsAdmin?: boolean,
}) {
  const url = requestingUserIsAdmin ? '/api/v1/users' : '/api/v1/users/signup';
  return makeRequest<UsersAPI.CreateAccountAPIRequestBody>('POST', url, {
    email, password, role, firstName, lastName, companyName, country,
  });
}

export function getCurrentUser() {
  const url = '/api/v1/users/current-user';
  return makeRequest<void, UsersAPI.GetCurrentUserAPIResponse>('GET', url);
}

export function signIn(credentials: UsersAPI.SignInAPIRequestBody) {
  const url = '/api/v1/users/signin';
  return makeRequest<UsersAPI.SignInAPIRequestBody, UsersAPI.SignInAPIResponse>(
    'POST',
    url,
    credentials,
  );
}

export function reauthenticate() {
  const url = '/api/v1/users/refresh';
  return makeRequest<void, UsersAPI.SignInAPIResponse>('GET', url);
}

export function signOut() {
  const url = '/api/v1/users/signout';
  return makeRequest('POST', url);
}

export function updatePassword(email: string, password: string, newPassword: string) {
  const url = '/api/v1/users/update';
  return makeRequest<UsersAPI.PasswordUpdateAPIRequestBody>(
    'PUT', url, { email, password, newPassword }
  );
}

export function signHash(digest: string) {
  const url = '/api/v1/users/sign-hash';
  return makeRequest<UsersAPI.SignHashAPIRequestBody, UsersAPI.SignHashAPIResponse>(
    'POST', url, { digest }
  );
}

export function updateUserApprovalStatus(id: string, status: UserApprovalStatus) {
  const url = `/api/v1/users/${id}/status`;
  return makeRequest<UsersAPI.UpdateUserStatusRequestBody>('PUT', url, { status });
}

export function setUserClaims(id: string, claims: Claim[]) {
  const url = `/api/v1/users/${id}/claims`;
  return makeRequest<UsersAPI.SetUserClaimsRequestBody>('PUT', url, claims);
}

export function requestPasswordReset(email: string) {
  const url = '/api/v1/users/request-reset';
  return makeRequest<UsersAPI.RequestPasswordResetBody>('POST', url, { email });
}

export function resetPassword(body: UsersAPI.ResetPasswordBody) {
  const url = '/api/v1/users/reset';
  return makeRequest<UsersAPI.RequestPasswordResetBody>('PUT', url, body);
}
