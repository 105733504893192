import React from 'react';
import { MenuEntryWithIcon, toolbox } from './Header';
import { UserIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/outline';
import Paper from './common/Paper';
import Typography from './common/Typography';
import { useNavigate } from 'react-router-dom';
import useCurrentUser from '../hooks/useCurrentUser';
import { userHasClaims } from '../utils/permissions';
import { userManagementToolAccessRoles } from './Account/UserManagementTool/UserManagementTool';

const navigationCards: MenuEntryWithIcon[] = [
  {
    name: 'Manage Users',
    description: 'View and manage user data',
    icon: WrenchScrewdriverIcon,
    route: '/account/manage',
    allowedRoles: Array.from(userManagementToolAccessRoles),
  },
  ...toolbox,
];

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const user = useCurrentUser()!;

  const cardComponents = navigationCards.map((card) => {
    // Completely hide cards the user doesn't have the necessary role for
    if (card.allowedRoles !== undefined && !card.allowedRoles.includes(user.role)) return;

    // Gray out cards the user doesn't have the necessary claims for
    const selectable = card.necessaryClaims === undefined ? true : userHasClaims(user, card.necessaryClaims);
    const iconClass = selectable ? 'text-slate-500' : 'text-slate-400';
    const nameColor = selectable ? 'black' : 'lightSlate';
    const descriptionColor = selectable ? 'slate' : 'lightSlate';

    return (
      <Paper compact onClick={() => navigate(card.route)} disabled={!selectable} key={card.name}>
        <div className="flex flex-col size-40 items-center justify-center text-center select-none">
          <card.icon className={`size-12 ${iconClass}`} />
          <Typography color={nameColor}>{card.name}</Typography>
          <Typography color={descriptionColor} variant="body2">{card.description}</Typography>
        </div>
      </Paper>
    );
  });

  return (
    <div className="w-full h-full overflow-auto flex items-center justify-center p-4">
      <div className="max-w-[min(1280px,_80%)] h-min flex flex-wrap gap-4 m-auto justify-center">
        {cardComponents}
      </div>
    </div>
  );
};

export default LandingPage;
