import React from 'react';

import Form from '../common/Form/Form';
import logo from '../../images/logo.svg';
import { reset as resetError } from '../../redux/errors';
import { authenticationSlice, signIn } from '../../redux/authentication';
import { useDispatch, useSelector } from '../../redux/store';
import Link from '../common/Link';
import Button from '../common/Button';
import { useNavigate } from 'react-router-dom';

function useSubmit({ email, password}: { email: string, password: string }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const submit = React.useCallback(async (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(resetError());
    setLoading(true);
    dispatch(signIn({ email, password }))
      .unwrap()
      .catch(() => setLoading(false))
  }, [email, password]);

  return { submit, loading };
}

const SignInPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = useSelector((state) => state.authentication.signedOutEmail);
  const saveEmail = useSelector((state) => state.authentication.saveEmail);
  const [password, setPassword] = React.useState('');
  const { submit, loading } = useSubmit({ email, password });

  return (
    <Form.Container>
      <img src={logo} className="h-20 w-auto mx-auto" alt="HTRI" />
      <Form.Item
        label="Email"
        type="email"
        value={email}
        onChange={(newEmail) => dispatch(authenticationSlice.actions.setSignedOutEmail(newEmail))}
        autoFocus
      />
      <Form.Item label="Password" type="password" value={password} onChange={setPassword} />
      <Link typographyVariant="body2" onClick={() => navigate('/reset-password')}>
        Reset Password
      </Link>
      <Form.Item
        label="Remember me"
        type="checkbox"
        value={saveEmail}
        onChange={(newSaveEmail) => dispatch(authenticationSlice.actions.setSaveEmail(newSaveEmail))}
      />
      <div className="flex flex-row items-center gap-2">
        <Button type="submit" color="primary" label="Sign In" onClick={submit} loading={loading} />
        <Link
          typographyVariant="body2"
          onClick={() => navigate('/account/create', { state: { email } })}
        >
          Create an account
        </Link>
      </div>
    </Form.Container>
  );
};

export default SignInPage;
