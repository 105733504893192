import React from 'react';
import { BlockProps, ExponentiationBlock } from '../types';
import Block from './Block';
import { useEquationSelector } from '../redux/store';
import { BlockPlacement, FocusType } from '../redux/reducer';
import Cursor from '../Cursor';

const Exponentiation: React.FC<BlockProps> = ({ block }) => {
  const exponentiation = block as ExponentiationBlock;
  const focus = useEquationSelector((state) => state.currentFocus);

  const isFocused = focus.type === FocusType.Block && focus.target.id === block.id;
  const isFocusedBefore = isFocused && focus.placement === BlockPlacement.Before;
  const isFocusedAfter = isFocused && focus.placement === BlockPlacement.After;

  return (
    <div className="flex flex-row">
      {isFocusedBefore && <Cursor />}
      <div className="self-end">
        <Block block={exponentiation.base} />
      </div>
      <div className="text-[0.85em] mb-[0.5em]">
        <Block block={exponentiation.exponent} />
      </div>
      {isFocusedAfter && <Cursor />}
    </div>
  );
};

export default Exponentiation;
