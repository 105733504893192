import { EngineeringChecklist } from '../../../types/engineeringChecklist';
import {
  Block,
  BlockType,
  DigitSymbol,
  ExponentiationBlock,
  OperatorSymbolCharacter,
  Symbol,
  SymbolType,
  equationFunctions,
} from './types';
import { createBinaryOperatorBlock, createBlock, createDataModelVariableBlock, createInvalidFunctionOrVariableBlock, createNegativeBlock, createParenthesesBlock, createPlaceholderBlock, createSymbol, createSymbolGroupBlock } from './utils';

enum TokenType {
  Number,
  DataModelVariable,
  Exponentiation,
  Division,
  Addition,
  Subtraction,
  Multiplication,
  OpeningParenthesis,
  ClosingParenthesis,
  Function,
  MismatchedParenthesis,
  InvalidFunctionOrVariable,
  Unparsable,
}
interface Token {
  text: string;
  type: TokenType;
}

// TODO: need to match scientific notation (ex. [e/E]4)
const numberTokenMatcher = /^(\d+\.?\d*|\d*\.\d+)/;
const variableTokenMatcher = /^[a-zA-Z][a-zA-Z0-9_]*/;
const binaryOperators = ['+', '-', '*'] as const;
const binaryOperatorTokenTypes: Record<typeof binaryOperators[number], TokenType> = {
  '+': TokenType.Addition,
  '-': TokenType.Subtraction,
  '*': TokenType.Multiplication,
};
const binaryTokenTypeOperators: Partial<Record<TokenType, OperatorSymbolCharacter>> = {
  [TokenType.Addition]: '+',
  [TokenType.Subtraction]: '-',
  [TokenType.Multiplication]: '*',
}
// Sort functions in order of descending length
// so that functions that use other functions as prefixes (ex. cosh -> cos) are checked first
const sortedFunctions = [...equationFunctions].sort((a, b) => b.length - a.length);
function readFirstToken(s: string, variableMap: Map<string, EngineeringChecklist.Variable>): Token {
  // Parse as exponentiation
  if (s.startsWith('^')) {
    return {
      type: TokenType.Exponentiation,
      text: '^',
    };
  }

  // Parse as division
  if (s.startsWith('/')) {
    return {
      type: TokenType.Division,
      text: '/',
    };
  }

  // Parse as an opening parenthesis
  if (s.startsWith('(')) {
    return {
      type: TokenType.OpeningParenthesis,
      text: '(',
    };
  }

  // Parse as a closing parenthesis
  if (s.startsWith(')')) {
    return {
      type: TokenType.ClosingParenthesis,
      text: ')',
    };
  }

  // Parse as a binary operator
  for (const operator of binaryOperators) {
    if (s.startsWith(operator)) {
      return {
        type: binaryOperatorTokenTypes[operator],
        text: operator,
      };
    }
  }

  // Parse as number
  const numberMatch = numberTokenMatcher.exec(s);
  if (numberMatch !== null) {
    return {
      type: TokenType.Number,
      text: numberMatch[0],
    };
  }


  // Parse as function
  for (const f of sortedFunctions) {
    if (s.startsWith(f)) {
      return {
        type: TokenType.Function,
        text: f,
      };
    }
  }

  // Parse as variable
  const variableMatch = variableTokenMatcher.exec(s);
  if (variableMatch !== null) {
    if (variableMap.has(variableMatch[0])) {
      return {
        type: TokenType.DataModelVariable,
        text: variableMatch[0],
      };
    }
    else return {
      type: TokenType.InvalidFunctionOrVariable,
      text: variableMatch[0],
    }
  }

  // No valid tokens to parse, skip this character and continue parsing
  return {
    type: TokenType.Unparsable,
    text: s[0],
  };

}
function* readTokens(eq: string, variableMap: Map<string, EngineeringChecklist.Variable>): Generator<Token> {
  let remaining = eq;
  let currentUnparsableText = '';

  while (remaining) {
    const token = readFirstToken(remaining, variableMap);
    remaining = remaining.slice(token.text.length);
    if (token.type === TokenType.Unparsable) currentUnparsableText += token.text;
    else {
      if (currentUnparsableText) yield { type: TokenType.Unparsable, text: currentUnparsableText };
      yield token;
      currentUnparsableText = '';
    }
  }
  if (currentUnparsableText) yield { type: TokenType.Unparsable, text: currentUnparsableText };
}

type OperatorToken = (
  TokenType.Addition
  | TokenType.Subtraction
  | TokenType.Multiplication
  | TokenType.Division
  | TokenType.Exponentiation
  | TokenType.ClosingParenthesis
  | TokenType.Function
);
const operatorPriorities: Record<OperatorToken, number> = {
  [TokenType.Addition]: 1,
  [TokenType.Subtraction]: 1,
  [TokenType.Multiplication]: 2,
  [TokenType.Division]: 2,
  [TokenType.Exponentiation]: 3,
  [TokenType.ClosingParenthesis]: 4,
  [TokenType.Function]: 5,
};

function toPostfix(eq: string, variableMap = new Map<string, EngineeringChecklist.Variable>()): Token[] {
  // TODO: handle implicit multiplication
  // Use shunting yard algorithm to parse equation into postfix notation
  let unparsed = eq;
  let output: Token[] = [];
  const operatorStack: Token[] = [];
  for (const token of readTokens(eq, variableMap)) {
    switch (token.type) {
      case (TokenType.Number):
      case (TokenType.DataModelVariable):
      case (TokenType.InvalidFunctionOrVariable):
      case (TokenType.Unparsable):
        output.push(token);
        break;
      case (TokenType.Function):
      case (TokenType.OpeningParenthesis):
        operatorStack.push(token);
        break;
      case (TokenType.Addition):
      case (TokenType.Subtraction):
      case (TokenType.Multiplication):
      case (TokenType.Division):
      case (TokenType.Exponentiation):
        while (operatorStack.length) {
          const topOperator = operatorStack[operatorStack.length - 1];
          if (
            topOperator.type === TokenType.OpeningParenthesis
            || operatorPriorities[topOperator.type] < operatorPriorities[token.type]
          ) break;
          output.push(operatorStack.pop()!);
        }
        operatorStack.push(token);
        break;
      case (TokenType.ClosingParenthesis):
        while (operatorStack.length) {
          const topOperator = operatorStack[operatorStack.length - 1];
          if (topOperator.type === TokenType.OpeningParenthesis) break;
          output.push(operatorStack.pop()!);
        }
        if (!(operatorStack[operatorStack.length - 1]?.type === TokenType.OpeningParenthesis)) {
          // TODO: this is probably wrong and weird, could check while rendering blocks instead of here
          output.push({ type: TokenType.MismatchedParenthesis, text: token.text });
        }
        else {
          operatorStack.pop(); // Discard associated parenthesis
          if (operatorStack[operatorStack.length - 1]?.type === TokenType.Function) output.push(operatorStack.pop());
        }
    }
    unparsed = unparsed.slice(token.text.length);
  }

  // No more tokens to parse, put remaining operators into tokens
  while (operatorStack.length) {
    const topOperator = operatorStack.pop();
    // TODO: this is probably wrong also
    if (topOperator.type === TokenType.OpeningParenthesis) {
      output = [{ type: TokenType.MismatchedParenthesis, text: topOperator.text }];
    }
    else output.push(topOperator);
  }
  return output;
}

// Ensure that potentially nested exponentiation blocks are rendered correctly.
// The equation is till correct if this isn't corrected, but the exponents don't look correct.
function handleExponentiation(base: Block, exponent: Block): Block<ExponentiationBlock> {
  // TODO: if division block or something else that might look funky, wrap in parens
  return (base?.type === BlockType.Exponentiation) ? createBlock({
      type: BlockType.Exponentiation,
      base: base.base,
      exponent: handleExponentiation(base.exponent, exponent),
  }) : createBlock({
    type: BlockType.Exponentiation,
    base,
    exponent,
  });
}

export default function parseEquation(
  eq: string,
  variableMap: Map<string, EngineeringChecklist.Variable> = new Map(),
): Block {
  // If empty, the equation consists of a single empty block
  if (!eq.length) return createPlaceholderBlock();

  const tokens = toPostfix(eq, variableMap);

  let blocks: Block[] = [];
  let valid = true;

  const popOperand = (): Block => {
    if (blocks.length) return blocks.pop()!;
    return createPlaceholderBlock();
  };

  for (const token of tokens) {
    if (!valid) break;
    switch (token.type) {
      case TokenType.Number:
        const symbols: Symbol<DigitSymbol>[] = [];
        for (const x of token.text) {
          symbols.push(createSymbol({ type: SymbolType.Digit, character: x }));
        }
        blocks.push(createBlock({
          type: BlockType.SymbolGroup,
          symbols,
        }));
        break;
      case TokenType.DataModelVariable:
        blocks.push(createDataModelVariableBlock(variableMap.get(token.text)));
        break;
      case TokenType.Exponentiation:
        const exponent = popOperand();
        const base = popOperand();
        const exponentiationBlock = handleExponentiation(base, exponent);
        // If base is undefined, the only real argument should be the base
        if (exponentiationBlock.base.type === BlockType.Placeholder && exponentiationBlock.exponent) {
          [exponentiationBlock.base, exponentiationBlock.exponent] = [exponentiationBlock.exponent, exponentiationBlock.base];
        }
        blocks.push(exponentiationBlock);
        break;
      case TokenType.Division:
        const divisionBlock = createBlock({
          type: BlockType.Division,
          denominator: popOperand(),
          numerator: popOperand(),
        });
        if (divisionBlock.numerator.type === BlockType.Placeholder && divisionBlock.denominator) {
          [divisionBlock.numerator, divisionBlock.denominator] = [divisionBlock.denominator, divisionBlock.numerator];
        }
        blocks.push(divisionBlock);
        break;
      case TokenType.Addition:
      case TokenType.Subtraction:
      case TokenType.Multiplication:
        const rhs = popOperand();
        const lhs = popOperand();
        const operator = binaryTokenTypeOperators[token.type];
        let binaryOperatorBlock: Block = createBinaryOperatorBlock(lhs, operator, rhs);
        if (binaryOperatorBlock.lhs.type === BlockType.Placeholder && binaryOperatorBlock.rhs) {
          if (token.type === TokenType.Subtraction) {
            binaryOperatorBlock = createNegativeBlock(binaryOperatorBlock.rhs);
          }
          else [binaryOperatorBlock.lhs, binaryOperatorBlock.rhs] = [binaryOperatorBlock.rhs, binaryOperatorBlock.lhs];
        }
        blocks.push(binaryOperatorBlock);
        break;
      case TokenType.Function:
        const rawOperand = popOperand();
        const operand = rawOperand.type === BlockType.Parentheses
          ? rawOperand
          : createParenthesesBlock(rawOperand)
        ;
        const functionBlock = createBlock({
          type: BlockType.Function,
          function: createSymbolGroupBlock(token.text, true),
          operand,
        });
        blocks.push(functionBlock);
        break;
      case TokenType.MismatchedParenthesis:
        blocks.push(createBlock({
          type: BlockType.MismatchedParenthesis,
          symbol: createSymbol({ type: SymbolType.Character, nonItalic: true, character: token.text }),
        }));
        break;
      case TokenType.InvalidFunctionOrVariable:
        blocks.push(createInvalidFunctionOrVariableBlock(token.text));
        break;
      case TokenType.Unparsable:
        blocks.push(createBlock({
          type: BlockType.Unparsable,
          symbolGroup: createSymbolGroupBlock(token.text),
        }));
        break;
    }
  }

  if (blocks.length === 0) return createPlaceholderBlock();
  if (blocks.length === 1) return blocks[0];

  // If there are multiple blocks, join them with multiplication
  let block = blocks[0];
  for (let i = 1; i < blocks.length; i++) {
    block = createBinaryOperatorBlock(block, '*', blocks[i]);
  }
  return block;
}
