import React from 'react';
import RulesContext from './RulesContext';
import DependencyGroup from './DependencyGroup';
import Button from '../../../common/Button';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from '../../../../redux/store';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';
import { engineeringChecklistSlice } from '../../../../redux/tools/engineeringChecklist';

interface RuleDependenciesSelectorProps {
  rule: EngineeringChecklist.Rule;
}

const RuleDependenciesSelector = ({ rule }: RuleDependenciesSelectorProps) => {
  const dispatch = useDispatch();
  const rules = useSelector((state) => state.engineeringChecklist.rules);
  const { dependencyGraph } = React.useContext(RulesContext)!;
  const { dependencyGroups } = rule;

  const rulesToExclude = new Set<string>();
  rules.forEach((ruleToCheck) => {
    if (dependencyGraph.addingEdgeWouldCreateCycle(rule.id, ruleToCheck.id)) {
      rulesToExclude.add(ruleToCheck.id);
    }
  });

  const dependencyGroupComponents = dependencyGroups.map((group, idx) => (
    <DependencyGroup rules={rules} rulesToExclude={rulesToExclude} rule={rule} group={group} idx={idx} key={idx} />
  ));

  // Center while there are no dependency groups to better align with other rule columns
  let containerClass = 'flex flex-col gap-1';
  if (dependencyGroupComponents.length === 0) containerClass += ' h-11 justify-center';

  return (
    <div className={containerClass}>
      {dependencyGroupComponents}
      <Button
        label="Requirement Group"
        color="green"
        iconLeft={<PlusIcon className="size-6 text-white" />}
        onClick={() => dispatch(engineeringChecklistSlice.actions.addRuleDependencyGroup(rule.id))}
      />
    </div>
  );
};

export default RuleDependenciesSelector;
