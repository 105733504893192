import React from 'react';
import Tabs, { TabProps } from './Tabs';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

function toURLString(label: string): string {
  return label.toLowerCase().replace(' ', '-');
}

function useURLMap(tabs: TabProps[]) {
  return React.useMemo(() => {
    const urlToLabel = new Map<string, string>();
    const labelToURL = new Map<string, string>();
    for (const { label } of tabs) {
      const url = toURLString(label);
      labelToURL.set(label, url);
      urlToLabel.set(url, label);
    }
    if (process.env.NODE_ENV === 'development' && labelToURL.size !== urlToLabel.size) {
      console.error(labelToURL);
      console.error(urlToLabel);
      throw new Error(`Duplicate labels detected in tabs after processing! See above for details`);
    }
    return { urlToLabel, labelToURL };
  }, [tabs]);
}

interface RoutedTabsProps {
  tabs: TabProps[];
  defaultTab?: string;
}

/**
 * NOTE: Ensure the <Route /> this component is in has a ":tab?" parameter for it to work properly!
 * Example: <Route path=":tab?" element={<SomeComponent />} />
 */
const RoutedTabs: React.FC<RoutedTabsProps> = ({ tabs, defaultTab = tabs[0].label }) => {
  const { labelToURL, urlToLabel } = useURLMap(tabs);
  const navigate = useNavigate();
  const { tab = '' } = useParams();

  if (process.env.NODE_ENV === 'development' && urlToLabel.get(defaultTab)) {
    throw new Error(`Invalid default tab: ${defaultTab}\nUsers will experience incorrect behavior when calling invalid routes.`);
  }

  const activeTabIdx = tabs.findIndex((t) => t.label === urlToLabel.get(tab));

  // If no tab is found for the current tab param, redirect back to the default tab
  if (activeTabIdx === -1) {
    let navigationTarget = labelToURL.get(defaultTab);
    if (tab) navigationTarget = '../' + navigationTarget;
    return <Navigate to={navigationTarget} replace />;
  }

  const handleTabChange = (idx: number) => {
    navigate(`../${labelToURL.get(tabs[idx].label)!}`);
  }

  return <Tabs tabs={tabs} activeTabIdx={activeTabIdx} onTabChange={handleTabChange} />
};

export default RoutedTabs;
