import React from 'react';

import landing from '../../images/landing.jpg';
import useBackgroundImage from '../../hooks/useBackgroundImage';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SignInPage from './SignInPage';

// Valid locations without being logged in
const validLocations = new Set(['/', '/account/create', '/account/change-password', '/reset-password']);
const validLocationMatchers = [/^\/reset-password\/[^\/]+$/];
function isValidLocation(pathname: string) {
  if (pathname === '/') return true;
  if (pathname.slice(-1) === '/') pathname = pathname.slice(0, -1);
  if (validLocations.has(pathname)) return true;
  for (const matcher of validLocationMatchers) {
    if (matcher.test(pathname)) return true;
  }
  return false;
}

const Auth: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const bg = useBackgroundImage(landing);

  // Go home if the page isn't valid
  if (!isValidLocation(location.pathname)) {
    navigate('/');
    return undefined;
  }

  const content = location.pathname === '/' ? <SignInPage /> : <Outlet />;

  return (
    <div className="relative w-full h-full bg-sky-50 flex">
      <div
        className={`fixed ${bg.className} w-full h-full bg-cover filter contrast-50`}
        style={bg.style}
      />
      <div className="relative m-auto w-full">
        {content}
      </div>
    </div>
  );
}

export default Auth;
