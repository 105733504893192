namespace Material {
  export type Composition = Record<string, number>;

  export interface DesignationOverview {
    type: string | null;
    number: string | null;
  }

  export interface Overview {
    id: number;
    names: string[];
    composition: Material.Composition;
    code: string | null;
    form: string | null;
    grade: string | null;
    type: string | null;
    temper: string | null;
    groups: string[];
    designations: Material.DesignationOverview[];
  }

  export type ExternalPressureChart = `Table ${string}` | 'None';

  export interface GroupDetails {
    name: string;
  }

  export interface DesignationDetails {
    type: string | null;
    country: string | null;
    number: string | null;
    nominalComposition: string | null;
  }

  export namespace Correlation {
    export type FunctionalForm = (
      'polynomial'
      | 'hyperbolic'
      | 'tableinterpolation'
    );

    export type InterpolationMethod = (
      'linear'
    );

    export type Property = (
      'Allowable Stress'
      | 'Coefficient of Thermal Expansion'
      | 'Metal Density'
      | 'Modulus of Elasticity'
      | 'Thermal Conductivity'
      | 'Yield Stress'
    );

    export interface DependentVariable {
      name: Correlation.Property;
      unitOfMeasure: string;
      units: string | null;
      lowValue: number;
      highValue: number;
    }

    export interface IndependentVariable {
      name: 'Temperature';
      unitOfMeasure: 'Temperature';
      units: string;
      lowValue: number;
      highValue: number;
    }

    export interface TableRecord {
      dependentVariableValue: number;
      independentVariableValue: number;
    }

    export interface Constant {
      subscriptLabel: number;
      value: number;
      units: string | null;
    }

    export interface Details {
      id: number;
      functionalForm: Material.Correlation.FunctionalForm;
      dependentVariable: Material.Correlation.DependentVariable;
      independentVariable: Material.Correlation.IndependentVariable;
      tableRecords: Material.Correlation.TableRecord[];
      tableInterpolationMethod: Material.Correlation.InterpolationMethod;
      constants: Material.Correlation.Constant[];
      sources: string[];
      notes: string[];
    };
  }

  export interface Details {
    id: number;
    names: string[];
    composition: Material.Composition;
    code: string | null;
    form: string | null;
    class: string | null;
    grade: string | null;
    type: string | null;
    temper: string | null;
    externalPressureChart: Material.ExternalPressureChart;
    groups: GroupDetails[];
    designations: DesignationDetails[];
    correlations: Material.Correlation.Details[];
  }

  export interface FilterOptions {
    groups: string[];
    compositionElements: string[];
    forms: string[];
    designationTypes: string[];
    types: string[];
    tempers: string[];
  }

  export enum CompositionOperator {
    GreaterOrEqual = '≥',
    LessOrEqual = '≤',
  }

  export interface CompositionFilter {
    id: string;
    substance: string;
    operator: CompositionOperator;
    percentage: string;
  }

  export interface FilterValues {
    name: string;
    group: string;
    composition: CompositionFilter[];
    form: string;
    code: string;
    designationType: string;
    designationNumber: string;
    type: string;
    temper: string;
    grade: string;
  }
}

export default Material;
