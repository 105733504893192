import { useFilePicker } from 'use-file-picker/dist'
import { useDispatch } from 'react-redux';
import FileSaver from 'file-saver'
import { PlayIcon, ExclamationTriangleIcon, ArrowUpTrayIcon, ArrowDownTrayIcon, CheckIcon, ClockIcon, PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/solid'

import { set as setError } from '../redux/errors';

const Actionbar = ({ status, controlActions }) => {
  const dispatch = useDispatch();
  const { openFilePicker } = useFilePicker({
      readAs: 'Text',
      accept: '*.json',
      multiple: false,
      onFilesRejected: ({ errors }) => {
          errors.forEach(error => dispatch(setError(error)));
      },
      onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
          filesContent.map((file) => {
              controlActions.load(file);
          });
      },
  });

  const onFileSave = () => {
      const file = controlActions.save();
      const { filename, content } = file;
      const blob = new Blob([JSON.stringify(content)], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, filename + '.json');
  }

  return (
      <div className="flex items-center justify-start w-full bg-slate-100">
          <span className="isolate inline-flex rounded-md shadow-sm mx-2 gap-x-2">
              {controlActions.load && <button
                  onClick={() => openFilePicker()}
                  type="button"
                  className='relative -ml-px inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text- ring-1 ring-inset ring-slate-400 hover:bg-slate-200 focus:z-10'>
                  <ArrowUpTrayIcon className="-ml-0.5 h-5 w-5 text-slate-400" aria-hidden="true" />
                      Load
              </button>}
              {controlActions.save && <button
                  onClick={onFileSave}
                  type="button"
                  className='relative -ml-px inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text- ring-1 ring-inset ring-slate-400 hover:bg-slate-200 focus:z-10'>
                  <ArrowDownTrayIcon className="-ml-0.5 h-5 w-5 text-slate-400" aria-hidden="true" />
                      Save
              </button>}
              {controlActions.add && <button
                  onClick={controlActions.add}
                  type="button"
                  className='relative -ml-px inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text- ring-1 ring-inset ring-slate-400 hover:bg-slate-200 focus:z-10'>
                  <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-slate-400" aria-hidden="true" />
                      Add
              </button>}
              {controlActions.remove && <button
                  onClick={controlActions.remove}
                  type="button"
                  className='relative -ml-px inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text- ring-1 ring-inset ring-slate-400 hover:bg-slate-200 focus:z-10'>
                  <MinusCircleIcon className="-ml-0.5 h-5 w-5 text-slate-400" aria-hidden="true" />
                      Remove
              </button>}
              {controlActions.run && <button
                  onClick={controlActions.run}
                  type="button"
                  className="relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-black ring-1 ring-inset ring-slate-400 hover:bg-green-600 focus:z-10"
              >
                  <PlayIcon className="-ml-0.5 h-5 w-5 text-slate-400" aria-hidden="true" />
                      Run
              </button>}
              <button
                  type="button"
                  className='relative -ml-px inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text- ring-1 ring-inset ring-slate-400 focus:z-10'>
                  {status === 'Unsolved' && <ClockIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />}
                  {status === 'Failed' && <ExclamationTriangleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />}
                  {status === 'Solved' && <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>}
                  {status === 'Solving' && <div class="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status"></div>}
                  {status}
              </button>
          </span>
      </div>
  );
};

export default Actionbar;
