import { FloatingPortal, autoPlacement, autoUpdate, shift, size, useFloating } from '@floating-ui/react';
import React from 'react';
import Collapsible from './Collapsible';
import Typography from './Typography';
import LoadingIndicator from './LoadingIndicator';
import { useDetectClickOutside } from '../../hooks/useDetectClickOutside';

enum OpenStatus { Open, Closing, Closed };

interface DropdownProps<K> {
  options: readonly string[] | Map<K, string>;
  onChange: (k: K) => any;
  onDismiss?: () => any;
  referenceElement: React.MutableRefObject<Element | null>;
  selected?: K | undefined;
  widthClassName?: `w-${string} max-w-${string}`;
  placeholder?: string;
  loading?: boolean;
  skipCloseAnimation?: boolean;
  disableAnimations?: boolean;
  disableReferenceClickClose?: boolean;
  disableShift?: boolean;
}

function Dropdown<K> ({
  options,
  onChange,
  onDismiss,
  referenceElement,
  selected,
  widthClassName,
  placeholder,
  loading,
  skipCloseAnimation,
  disableAnimations,
  disableReferenceClickClose,
  disableShift,
}: DropdownProps<K>): React.ReactNode {
  const [openStatus, setOpenStatus] = React.useState(OpenStatus.Closed);
  const [optionsStyle, setOptionsStyle] = React.useState<React.CSSProperties>();
  const [targetHeight, setTargetHeight] = React.useState(0);
  const { refs, floatingStyles } = useFloating({
    middleware: [
      autoPlacement({
        allowedPlacements: ['bottom-start', 'top-start'],
      }),
      {
        ...size({
          apply({ availableHeight, rects, elements }) {
            // Match option dropdown width to parent
            const width = `${rects.reference.width}px`;
            Object.assign(elements.floating.style, {
              width: widthClassName ? undefined : width,
              minWidth: width,
            });

            // Determine appropriate height to use to prevent overflow
            let maxHeight: string;
            if (disableAnimations) {
              maxHeight = `${availableHeight}px`;
              Object.assign(elements.floating.style, { maxHeight });
            }
            else {
              maxHeight = `${Math.min(targetHeight + 4, document.body.clientHeight)}px`;
              Object.assign(elements.floating.style, { height: maxHeight });
            }

            // Keep track of maxHeight so options dropdown content can scroll appropriately
            setOptionsStyle({ maxHeight });
          },
        }),
        options: [targetHeight],
      },
      !disableShift && shift({
        crossAxis: true,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });
  // Convert options to map for ease of use and consistent handling
  const optionsMap = React.useMemo<Map<K, string>>(() => {
    if ('length' in options) return new Map(options.map((option) => [option as K, option]));
    return options;
  }, [options]);

  const closingState = (skipCloseAnimation || disableAnimations)
    ? OpenStatus.Closed
    : OpenStatus.Closing
  ;

  React.useEffect(() => {
    // Keep reference element updated
    if(refs.reference.current !== referenceElement.current) {
      refs.setReference(referenceElement.current);
    }

    if (!refs.reference.current) return;

    // Add ref click handler to open/close
    const handleReferenceClick = (e: Event) => {
      e.stopPropagation();
      if (openStatus === OpenStatus.Open && !disableReferenceClickClose) setOpenStatus(closingState);
      else setOpenStatus(OpenStatus.Open);
    };
    const listenerElement = referenceElement.current;
    if (listenerElement) listenerElement.addEventListener('click', handleReferenceClick);
    return () => listenerElement?.removeEventListener('click', handleReferenceClick);
  }, [referenceElement.current, refs, openStatus]);

  // Add appropriate eventlisteners while open
  React.useEffect(() => {
    if (openStatus !== OpenStatus.Open) return;
    const { floating, reference } = refs;
    const detectClickOutside = (e: MouseEvent) => {
      if (!(e.target instanceof Node)) return;
      if (
        e.target instanceof Node
        && !floating.current?.contains(e.target)
        && !(reference.current as Node).contains(e.target)
      ) {
        setOpenStatus(closingState);
        if (onDismiss) onDismiss();
      }
    };
    document.addEventListener('click', detectClickOutside);
    return () => document.removeEventListener('click', detectClickOutside);
  }, [refs, openStatus]);

  // Render nothing while closed
  if (openStatus === OpenStatus.Closed) return;

  let optionComponents = [];
  // While loading, show loading indicator
  if (loading) optionComponents = [
    <div className="flex items-center justify-center p-4" key="loading">
      <div>
        <LoadingIndicator className="size-6 text-slate-500" />
      </div>
    </div>
  ];
  // Done loading, show all options
  else {
    for (const [key, value] of optionsMap.entries()) {
      const optionSelected = selected === key;
      // Replace empty string options with &nbsp; to set height appropriately
      const text = value || '\u00A0';
      optionComponents.push(
        <div
          className={`p-1 cursor-pointer ${optionSelected ? 'bg-blue-100' : 'hover:bg-slate-100'}`}
          onClick={(e) => {
            console.log('clicked');
            e.stopPropagation();
            setOpenStatus(closingState);
            onChange(key);
          }}
          key={value}
        >
          <Typography variant="body1" className="truncate select-none">{text}</Typography>
        </div>
      );
    }
  }
  // No available options, show placeholder
  if (optionComponents.length === 0) optionComponents.push(
    <Typography
      color="lightSlate"
      variant="body1"
      className="px-2 select-none truncate"
      key="placeholder"
    >
      {placeholder}
    </Typography>
  );

  let containerClassName = "flex flex-col overflow-hidden shadow-md border-2 border-slate-300 rounded-md";
  if (widthClassName) containerClassName += ` ${widthClassName}`;

  const optionsWrapper = disableAnimations
    ? optionComponents
    : (
      <Collapsible
        open={openStatus === OpenStatus.Open}
        wrapperClassName="w-full"
        contentClassName="w-full py-1 bg-white"
        onCloseFinish={() => setOpenStatus(OpenStatus.Closed)}
        onTargetHeightChange={(h) => setTargetHeight(h)}
      >
        {optionComponents}
      </Collapsible>
    )
  ;

  return (
    <>
      <FloatingPortal>
        <div className="z-20" ref={refs.setFloating} style={floatingStyles}>
          <div className={containerClassName} style={optionsStyle}>
            <div className="w-full overflow-y-auto bg-white">
              {optionsWrapper}
            </div>
          </div>
        </div>
      </FloatingPortal>
    </>
  );
};

export default Dropdown;
