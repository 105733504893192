import React from 'react';

interface LoadingIndicatorProps {
  className: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ className }) => (
  <div
    className={`${className} animate-spin rounded-full border-2 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite]`}
    role="status"
  />
);

export default LoadingIndicator;
