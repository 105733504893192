import React from 'react';
import MaterialsContext from './MaterialsContext';
import { useDispatch, useSelector } from '../../../redux/store';
import Typography from '../../common/Typography';
import { setSelectedCorrelationProperty } from '../../../redux/tools/materials';

const CorrelationPropertySelector = () => {
  const dispatch = useDispatch();
  const { correlationPropertyOptions } = React.useContext(MaterialsContext);
  const selectedCorrelationProperty = useSelector((state) => state.materials.selectedCorrelationProperty);

  const correlationProperties = correlationPropertyOptions.map((property) => {
    const bgClass = selectedCorrelationProperty === property
      ? 'bg-sky-200'
      : 'bg-transparent hover:bg-sky-100'
    ;
    return (
      <button
        className={`${bgClass} w-full p-1 text-left transition-colors`}
        onClick={() => dispatch(setSelectedCorrelationProperty(property))}
        key={property}
      >
        {property}
      </button>
    );
  });

  return (
    <Typography className="w-full flex flex-col">
      {correlationProperties}
    </Typography>
  );
};

export default CorrelationPropertySelector;
