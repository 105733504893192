import React from 'react';
import { Claim, RawDetailedUser, UserApprovalStatus } from '../../../types/API';
import Typography from '../../common/Typography';
import Button, { ButtonColor } from '../../common/Button';
import { CheckIcon, ClockIcon, NoSymbolIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../common/Tooltip';
import { setUserClaims, updateUserApprovalStatus } from '../../../utils/API';
import { reset as resetError, set as setError } from '../../../redux/errors';
import { useDispatch } from '../../../redux/store';
import useCurrentUser from '../../../hooks/useCurrentUser';
import SetSelector from '../../common/SetSelector';
import { useGetClaimsQuery } from '../../../redux/userQueriesAPI';

const statusDisplayText: Record<UserApprovalStatus, string> = {
  pending: 'Pending',
  approved: 'Approved',
  denied: 'Denied',
  disabled: 'Disabled',
};

interface ActionButtonProps {
  label: string;
  icon: React.ElementType;
  color: ButtonColor;
}
const actionButtonProps: Record<UserApprovalStatus, ActionButtonProps> = {
  pending: {
    label: 'Set as Pending',
    icon: ClockIcon,
    color: 'white',
  },
  approved: {
    label: 'Approve',
    icon: CheckIcon,
    color: 'green',
  },
  denied: {
    label: 'Deny',
    icon: XMarkIcon,
    color: 'red',
  },
  disabled: {
    label: 'Disable',
    icon: NoSymbolIcon,
    color: 'red',
  },
};
const orderedStatuses: UserApprovalStatus[] = ['approved', 'denied', 'disabled', 'pending'];

function formatDate(date: Date): string {
  return date.toLocaleString(
    'en-US',
    {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
  );
}

function useEditClaims(
  user: RawDetailedUser,
  onSetClaims: (claims: Claim[]) => any,
) {
  const dispatch = useDispatch();
  const { isError, isLoading, currentData: availableClaims } = useGetClaimsQuery();
  const [updatingClaims, setUpdatingClaims] = React.useState(false);

  const setClaims = (claims: Claim[]) => {
    if (updatingClaims) return;
    dispatch(resetError());
    setUpdatingClaims(true);
    setUserClaims(user.id, claims)
      .then(() => onSetClaims(claims))
      .catch(() => {})
      .finally(() => setUpdatingClaims(false))
    ;
  };

  return {
    isError,
    isLoading: updatingClaims || isLoading,
    availableClaims: availableClaims ?? [],
    setClaims,
  }
}

function useApprovalActions(
  user: RawDetailedUser,
  onStatusChange: (newStatus: UserApprovalStatus) => any,
) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const setStatus = (status: UserApprovalStatus) => {
    dispatch(resetError());
    setLoading(true);
    updateUserApprovalStatus(user.id, status)
      .then(() => onStatusChange(status))
      .catch(() => {})
      .finally(() => setLoading(false))
  };

  return {
    loading,
    setStatus,
  };
}

interface UserProps {
  user: RawDetailedUser;
  onStatusChange: (newStatus: UserApprovalStatus) => any;
  onSetClaims: (claims: Claim[]) => any;
}

const User: React.FC<UserProps> = ({ user, onStatusChange, onSetClaims }) => {
  const claims = useEditClaims(user, onSetClaims);
  const { loading, setStatus } = useApprovalActions(user, onStatusChange);
  const currentUser = useCurrentUser();

  const createActionButton = (status: UserApprovalStatus) => {
    const props = actionButtonProps[status];
    return (
      <Tooltip label={props.label} width="md">
        <Button
          disabled={status === user.status}
          color={props.color}
          iconLeft={<props.icon className="size-5" />}
          loading={loading}
          onClick={() => setStatus(status)}
        />
      </Tooltip>
    );
  };

  const approvalActions = currentUser!.id !== user.id && orderedStatuses.map(createActionButton);

  return (
    <>
      <Typography className="truncate">{user.firstName}</Typography>
      <Typography className="truncate">{user.lastName}</Typography>
      <Typography className="truncate">{user.email}</Typography>
      <Typography className="truncate">{user.companyName}</Typography>
      <Typography className="truncate">{user.role}</Typography>
      <Typography className="truncate">{statusDisplayText[user.status]}</Typography>
      <div>
        <SetSelector
          selected={user.claims}
          options={claims.availableClaims}
          onChange={claims.setClaims}
          loading={claims.isLoading}
        />
      </div>
      <div className="flex gap-1 items-center">
        {approvalActions}
      </div>
    </>
  );
};

export default User;
