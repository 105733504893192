/**
 * Performs linear interpolation between a and b
 * @param a Low value
 * @param b High value
 * @param t t value representing a position between a and b, where a=0 and b=1
 * @returns Interpolated value
 */
export function lerp(a: number, b: number, t: number): number {
  return a + (b - a) * t;
}

/**
 * Calculates the t value for where x lies between a and b
 * @param a Low value
 * @param b High value
 * @param x Number to calculate t for
 * @returns The t value for x
 */
export function inverseLerp(a: number, b: number, x: number): number {
  return (x - a) / (b - a);
}

/**
 * Converts x to a number with n significant figures
 * @param x Number to convert
 * @param n Number of significant figures
 */
export function toSignificantFigures(x: number, n: number = 6): number {
  return Number.parseFloat(x.toPrecision(n));
}

/**
 * Generates <count> uniformly spaced points between <start> and <stop>
 * @param start Starting value
 * @param stop End value
 * @param count Number of points to generate
 */
export function* linspace(start: number, stop: number, count: number): Generator<number> {
  for (let i = 0; i < count; i++) {
    const t = i / (count - 1);
    yield lerp(start, stop, t);
  }
}
