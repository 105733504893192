import React from 'react';
import HTRIUnits from '../../types/HTRIUnits';
import Typography from './Typography';
import InputField from './InputField';
import { useGetHTRIUnitConversionsQuery } from '../../redux/HTRICalcAPI';
import Select from './Select';
import { convertHTRIUnits } from '../../utils/units';
import { validateIsNumber } from '../../utils/inputValidation';
import { toSignificantFigures } from '../../utils/math';

type HTRIUnitRangeProps = HTRIUnits.Range & {
  onMinChange: (min: string) => any;
  onMaxChange: (max: string) => any;
  onUnitChange: (unit: string) => any;
}

const HTRIUnitRange: React.FC<HTRIUnitRangeProps> = (props) => {
  const { isFetching, isError, currentData } = useGetHTRIUnitConversionsQuery(props.unitSet);

  let unitSelect: React.ReactNode;
  if (isFetching) unitSelect = <Select value="" options={[]} loading />;
  else if (isError) unitSelect = <div />;
  else {
    const unitOptions = Object.keys(currentData);
    const handleUnitChange = (newUnit: string) => {
      props.onUnitChange(newUnit);
      if (validateIsNumber(props.min)) {
        convertHTRIUnits(Number.parseFloat(props.min), props.unitSet, props.unit, newUnit)
          .then((newMin) => props.onMinChange(toSignificantFigures(newMin, 6).toString()))
          .catch(() => {});
      }
      if (validateIsNumber(props.max)) {
        convertHTRIUnits(Number.parseFloat(props.max), props.unitSet, props.unit, newUnit)
          .then((newMax) => props.onMaxChange(toSignificantFigures(newMax, 6).toString()))
          .catch(() => {});
      }
    };
    unitSelect = <Select value={props.unit} options={unitOptions} onChange={handleUnitChange} />;
  }

  return (
    <div className="flex flex-row gap-1 items-center">
      <InputField label="Min" type="decimal" allowNegative value={props.min} onChange={props.onMinChange} />
      -
      <InputField label="Max" type="decimal" allowNegative value={props.max} onChange={props.onMaxChange} />
      {unitSelect}
    </div>
  );
};

export default HTRIUnitRange;
