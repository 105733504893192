export class DirectedGraph<T> {
  #edges: Map<T, T[]>;

  constructor() {
    this.#edges = new Map();
  }

  addEdge(u: T, v: T) {
    const uEdges = this.#edges.get(u);
    if (uEdges) uEdges.push(v);
    else this.#edges.set(u, [v]);
  }

  DFS(start: T, end: T, visited = new Set<T>): boolean {
    visited.add(start);
    const adjacentEdges = this.#edges.get(start) ?? [];
    for (let next of adjacentEdges) {
      if (next === end) return true;
      if (!visited.has(next)) {
        const found = this.DFS(next, end, visited);
        if (found) return true;
      }
    }
    return false;
  }

  addingEdgeWouldCreateCycle(u: T, v: T): boolean {
    return this.DFS(v, u);
  }
}
