import React from 'react';
import { useDispatch, useSelector } from '../../../../redux/store';
import Counter from '../../../../utils/Counter';
import { useBlocker } from 'react-router-dom';
import { validateVariables } from '../../../../utils/inputValidation';
import { engineeringChecklistSlice } from '../../../../redux/tools/engineeringChecklist';
import ResizableGrid from '../../../common/ResizableGrid';
import { variablesColumns } from './variablesColumns';
import Typography from '../../../common/Typography';
import Dialog from '../../../common/Dialog';
import DynamicFlexContainer from '../../../common/DynamicFlexContainer';
import DataModelTree from './DataModelTree';
import EngineeringChecklistVariable from './EngineeringChecklistVariable';

const EngineeringChecklistVariables: React.FC = () => {
  const dispatch = useDispatch();
  const variables = useSelector((state) => state.engineeringChecklist.variables);
  const variablesColumnWidths = useSelector((state) => state.engineeringChecklist.variablesColumnWidths);
  const aliasCounter = new Counter(variables.map((variable) => variable.alias));

  // When attempting to navigate away, ensure there are no duplicate aliases and that each is valid
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (currentLocation.pathname === nextLocation.pathname) return false;
    return !validateVariables(variables);
  });

  const variableComponents = variables.map((variable, idx) => (
    <EngineeringChecklistVariable variable={variable} aliasCounter={aliasCounter} idx={idx} key={variable.id} />
  ));

  const handleColumnWidthsChange = (widths: string[]) => {
      dispatch(engineeringChecklistSlice.actions.setVariablesColumnWidths(widths))
  };

  const content = variables.length ? (
    <ResizableGrid
      columns={variablesColumns}
      columnWidths={variablesColumnWidths}
      onColumnWidthsChange={handleColumnWidthsChange}
      alignItems="start"
    >
      {variableComponents}
    </ResizableGrid>
  ) : (
    <Typography variant="body1" color="slate" className="text-nowrap p-2">
      To add new variables, add attributes from the selection tree on the left.
    </Typography>
  );

  const blockerDialog = blocker.state === 'blocked' ? (
    <Dialog
      title="Invalid variable aliases"
      onClose={() => blocker.reset()}
      buttons={[
        { label: 'Leave', color: 'white', onClick: () => blocker.proceed() },
        { label: 'Stay', color: 'primary', shouldDismiss: true },
      ]}
    >
      <Typography>
        There are issues with some of the variable aliases you've defined.
        Are you sure you want to leave variable configuration?
      </Typography>
    </Dialog>
  ) : undefined;

  return (
    <>
      <DynamicFlexContainer direction="row" initialSizes={['15%', '85%']}>
        <DataModelTree />
        <div className="flex flex-col items-start justify-start">
          {content}
        </div>
      </DynamicFlexContainer>
      {blockerDialog}
    </>
  );
};

export default EngineeringChecklistVariables;
