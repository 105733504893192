import React from 'react';
import EquationEditor from '../../../common/EquationEditor/EquationEditor';
import { useSelector } from '../../../../redux/store';
import Select from '../../../common/Select';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';

interface NumericRuleProps {
  rule: EngineeringChecklist.NumericRule;
  onChange: (rule: Partial<EngineeringChecklist.NumericRule>) => any;
}

const NumericRule: React.FC<NumericRuleProps> = ({ rule, onChange }) => {
  const variables = useSelector((state) => state.engineeringChecklist.variables);

  return (
    <>
      <div className="p-1">
        <EquationEditor
          initialValue={rule.lhs}
          variables={variables}
          onChange={(newLHS) => onChange({ lhs: newLHS })}
        />
      </div>
      <div>
        <Select
          value={rule.op}
          options={EngineeringChecklist.NumericRuleOperators as readonly string[]}
          onChange={(newOp) => onChange({ op: newOp as EngineeringChecklist.NumericRuleOperator })}
        />
      </div>
      <div className="p-1">
        <EquationEditor
          initialValue={rule.rhs}
          variables={variables}
          onChange={(newRHS) => onChange({ rhs: newRHS })}
        />
      </div>
    </>
  );
};

export default NumericRule;
