import React from 'react';
import Typography from './Typography';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Dropdown from './Dropdown';

interface SelectProps<K> {
  value: K | undefined;
  onChange: (k: K) => any;
  options: Map<K, string> | (K extends string ? readonly K[] : never);
  placeholder?: string;
  loading?: boolean;
  fullWidth?: boolean;
}

function isMapped<K>(props: SelectProps<K>): props is SelectProps<K> & { options: Map<K, string> } {
  return !Array.isArray(props.options);
}

function Select<K>(props: SelectProps<K>): React.JSX.Element {
  const dropdownReferenceElement = React.useRef<HTMLDivElement>(null);

  const label = isMapped(props) ? props.options.get(props.value as K) : props.value as string;
  let containerClass = 'relative max-w-full w-min ';
  containerClass += props.fullWidth ? ' min-w-full' : 'min-w-12';

  return (
    <div className={containerClass}>
      <div
        className="flex flex-row items-center justify-between h-9 p-1 w-full bg-white rounded-md border-2 border-sky-200 hover:border-sky-400 focus:border-sky transition-colors cursor-pointer"
        ref={dropdownReferenceElement}
      >
        <Typography variant="body1" className="truncate select-none">{label}</Typography>
        <ChevronDownIcon className="min-w-5 h-5 text-slate-500" />
      </div>
      <Dropdown
        widthClassName="w-min max-w-min min-w-full"
        referenceElement={dropdownReferenceElement}
        options={props.options}
        onChange={props.onChange}
        selected={props.value}
        loading={props.loading}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default Select;
