import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { Block } from '../types';
import { equationSlice, initializeEquationStateFrom } from './reducer';

export function createEquationStore(block: Block | undefined) {
  return configureStore({
    reducer: equationSlice.reducer,
    middleware: (getDefaultMiddleware) => (
      getDefaultMiddleware({ serializableCheck: false })
    ),
    preloadedState: initializeEquationStateFrom(block),
  });
}


type equationStoreType = ReturnType<typeof createEquationStore>;
export type EquationRootState = ReturnType<equationStoreType['getState']>;
export type EquationDispatch = equationStoreType['dispatch'];
export const useEquationDispatch = useDispatch.withTypes<EquationDispatch>();
export const useEquationSelector = useSelector.withTypes<EquationRootState>();

export default createEquationStore;
