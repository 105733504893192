import { useState, useContext } from 'react'

import RefPropContext from '../context/RefPropContext'

import SearchBox from '../components/SearchBox'

import { floatingPtIn, floatingPtOut, percent } from '../utils/ValueDisplay'

const Component = ({
  status,
  propertyMutators,
  controlActions,
  index,
  id,
  userSpecified,
  component,
  updateSum,
  fraction
}) => {
  const { species } = useContext(RefPropContext);

  const [displayValue, setDisplayValue] = useState(component.value);

  const onOptionChange = (newOption) => {
    propertyMutators.updateComponentName(id, index, newOption);
    controlActions.reset();
  }; 

  const onValueChangeHandler = (e) => {
    if (displayValue === e.target.value) return;
    propertyMutators.updateComponentValue(id, index, e.target.value);
    setDisplayValue(e.target.value);
    updateSum();
    controlActions.reset();
  };

  return (
    <div className="flex items-center justify-start h-10 w-full m-1">
      {userSpecified?
      <SearchBox options={species} currentOption={component.name} onOptionChange={onOptionChange} />
      : 
      <input
        className="m-1 p-1 w-60 text-left rounded-md border-2 border-slate-100"
        type="text"
        value={component.name}
        readOnly="readonly"
      />}
      <input
        {...(userSpecified
          ? { className: 'm-1 p-1 w-60 text-right text-blue-500 rounded-md border-2 border-blue-400 hover:border-4 hover:border-blue-700' }
          : {
              className: 'm-1 p-1 w-60 text-right rounded-md border-2 border-slate-100',
              readOnly: 'readonly',
            })}
        type="number"
        step="0.01"
        min="0"
        value={userSpecified? floatingPtIn(displayValue) : floatingPtOut(displayValue)} 
        onChange={onValueChangeHandler}
      />
      <input
        className="m-1 p-1 w-60 text-right rounded-md border-2 border-slate-100"
        type="text"
        value={percent(fraction)}
        readOnly="readonly"
      />
    </div>
  );
}

export default Component;
