import React from 'react';

import VariableSelect from './VariableSelect';
import Select from '../../../common/Select';
import { equalityOperators } from './EnumRule';
import StringRuleValueSelect from './StringRuleValueSelect';
import StringRuleValueMultiSelect from './StringRuleValueMultiSelect';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';
import { useSelector } from '../../../../redux/store';

interface StringRuleProps {
  rule: EngineeringChecklist.StringRule;
  onChange: (r: Partial<EngineeringChecklist.StringRule>) => any;
}

const StringRule: React.FC<StringRuleProps> = ({ rule, onChange }) => {
  const variables = useSelector((state) => state.engineeringChecklist.variables);

  const variable = rule.lhs;

  const variableSelect = (
    <VariableSelect
      variable={variable}
      allowedTypes={[EngineeringChecklist.VariableType.String]}
      onChange={(v) => onChange({ lhs: v })}
    />
  );

  if (!variable) return (
    <>
      <div>
        {variableSelect}
      </div>
      <div />
      <div />
    </>
  );

  function handleOperatorChange(newOp: string) {
    const op = newOp as EngineeringChecklist.EnumRuleOperator;
    // Ensure rhs is updated appropriately when changing operators
    if (equalityOperators.has(op)) {
      onChange({ op, rhs: [rule.rhs?.[0]] });
    }
    else onChange({ op });
  }

  const aliasMap = new Map(variables.map((variable) => [variable.alias, variable]));

  const rhsSelectComponent = equalityOperators.has(rule.op)
    ? (
      <StringRuleValueSelect
        rule={rule}
        idx={0}
        aliasMap={aliasMap}
        onChange={(value) => onChange({ rhs: [value] })}
      />
    )
    : (
      <StringRuleValueMultiSelect
        rule={rule}
        aliasMap={aliasMap}
        onChange={(rhs) => onChange({ rhs })}
      />
    )
  ;

  return (
    <>
      {variableSelect}
      <Select
        value={rule.op}
        options={EngineeringChecklist.EnumRuleOperators as readonly string[]}
        onChange={handleOperatorChange}
      />
      {rhsSelectComponent}
    </>
  );
};

export default StringRule;
