import React from 'react';

interface DetectClickOutsideOptions {
  active?: boolean;
  capture?: boolean;
  button?: number;
}

/**
 * Detects user clicking outside the provided elements
 * @param callback Callback to run when clicking outside
 * @param elements Refs to check for clicking inside
 * @param active Whether click detection should be active
 */
export function useDetectClickOutside(
  callback: (e: MouseEvent) => any,
  elements: React.RefObject<Element>[],
  {
    active = true,
    capture = true,
    button = 0,
  }: DetectClickOutsideOptions
) {
  const handleClick = React.useCallback((e: MouseEvent) => {
    if (e.button !== button || !(e.target instanceof Node)) return;
    // Check elements sequentially to determine whether the click was outside them
    let clickedOutside = false;
    for (const element of elements) {
      if (!element.current?.contains(e.target)) {
        clickedOutside = true;
        break;
      }
    }
    if (clickedOutside) callback(e);
  }, [...elements, callback, button]);

  React.useEffect(() => {
    if (!active) return;
    document.addEventListener('click', handleClick, { capture });
    return () => document.removeEventListener('click', handleClick, { capture });
  }, [active, capture]);
}
