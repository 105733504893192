import React from 'react';
import { BlockProps, DivisionBlock } from '../types';
import Block from './Block';
import { useEquationSelector } from '../redux/store';
import { BlockPlacement, FocusType } from '../redux/reducer';
import Cursor from '../Cursor';

const Division: React.FC<BlockProps> = ({ block }) => {
  const division = block as DivisionBlock;
  const focus = useEquationSelector((state) => state.currentFocus);

  const isFocused = focus.type === FocusType.Block && focus.target.id === block.id;
  const isFocusedBefore = isFocused && focus.placement === BlockPlacement.Before;
  const isFocusedAfter = isFocused && focus.placement === BlockPlacement.After;

  return (
    <div className="flex flex-row items-stretch w-min text-[0.85em]">
      {isFocusedBefore && <Cursor />}
      <div className="flex flex-col items-center">
        <Block block={division.numerator} />
        <div className="w-full h-px bg-black" />
        <Block block={division.denominator} />
      </div>
      {isFocusedAfter && <Cursor />}
    </div>
  );
};

export default Division;
