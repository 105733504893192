import { validateIsNumber } from '../utils/inputValidation';
import { convertUnits } from '../utils/units';

export enum UnitSet {
  DynamicViscosity = 'dynamic-viscosity',
  MassDensity = 'mass-density',
  MolarDensity = 'molar-density',
  MolarEnergy = 'molar-energy',
  MolarEntropy = 'molar-entropy',
  Pressure = 'pressure',
  Temperature = 'temperature',
  ThermalConductivity = 'thermal-conductivity',
}

export interface UnitConversionFactors {
  valueSI: number;
  offset: number;
}

export interface SerializableUnitValue {
  value: string;
  unit: string;
}

// Lightweight wrapper for unit/value pairs to abstract out unit conversions
// from consumers
export class UnitValue<US extends UnitSet> {
  private _unitSet: US;
  public value: string | undefined;
  private _unit: string | undefined;

  constructor(unitSet: US, value?: string, unit?: string | undefined) {
    this._unitSet = unitSet;
    this.value = value;
    this._unit = unit;
  }

  get unitSet() { return this._unitSet; }
  get unit() { return this._unit; }

  async setUnit(newUnit: string) {
    // If value is invalid, only update the unit
    if (!validateIsNumber(this.value)) {
      this._unit = newUnit;
      return;
    }

    // Value is valid, convert to new unit
    const value = Number.parseFloat(this.value);
    const newValue = await convertUnits(value, this._unitSet, this._unit, newUnit);

    this.value = newValue.toString();
    this._unit = newUnit;
  }

  serialize(): SerializableUnitValue {
    return {
      unit: this._unit,
      value: this.value,
    };
  }
}

export interface RangeValues<US extends UnitSet> {
  min: string;
  max: string;
  unit: string;
  unitSet: US;
}

export type ConfigurableRangeValues = Omit<RangeValues<any>, 'unitSet'>;
