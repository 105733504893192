import React from 'react';

const shadowSizes = {
  sm: 'shadow-sm',
  md: 'shadow-md',
  lg: 'shadow-lg',
};

interface PaperProps {
  className?: string;
  innerClassName?: string;
  compact?: boolean;
  disabled?: boolean;
  onClick?: () => any;
  children: React.ReactNode;
  shadowSize?: 'sm' | 'md' | 'lg';
}

const Paper: React.FC<PaperProps> = ({
  className,
  innerClassName,
  compact,
  disabled = false,
  shadowSize = 'md',
  onClick,
  children,
}) => {
  let contentClassName = innerClassName ? `${innerClassName} ` : '';
  contentClassName += shadowSizes[shadowSize];
  contentClassName += ' rounded-lg ';
  contentClassName += disabled ? 'bg-slate-200' : 'bg-white';
  contentClassName += compact ? ' p-2' : ' p-4';

  const allowClick = onClick && !disabled;
  if (allowClick) contentClassName += ' hover:bg-slate-100 transition-colors';
  const Component = allowClick ? 'button' : 'div';

  const content = (
    <Component
      className={contentClassName}
      onClick={() => { if (allowClick) onClick(); }}
    >
      {children}
    </Component>
  );
  return className ? (
    <div className={className}>{content}</div>
  ) : content;
};

export default Paper;
