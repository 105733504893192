import { EngineeringChecklist } from '../types/engineeringChecklist';

/**
 * Transforms a string to PascalCase (also known as UpperCamelCase)
 * @param s String to transform
 * @returns String in PascalCase
 */
export function toPascalCase(s: string): string {
  return s.toLowerCase().split(' ').map((s) => s[0].toUpperCase() + s.substring(1)).join(' ');
}

const maxLineLength = 75;
export function getVariableTooltipLabel(v: EngineeringChecklist.Variable | EngineeringChecklist.DataModelNode) {
  let label: string = v.attributeName;
  if (v.navigationStrings?.length) {
    let currentLine = '[';
    const lastNavStringIdx = v.navigationStrings.length - 1;
    // Split navstrings on multiple lines if too long - for example, the attributes in Material Stream
    v.navigationStrings.forEach((navString, idx) => {
      const navStringText = navString + (idx === lastNavStringIdx ? '' : ', ');
      if (currentLine.length + navStringText.length > maxLineLength) {
        label += `\n${currentLine}`;
        currentLine = navStringText;
      }
      else currentLine += navStringText;
    });
    label += `\n${currentLine}]`;
  }
  return label;
}
