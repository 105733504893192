import React from 'react';
import { useGetUnitSetQuery } from '../../redux/unitsAPI';
import Select from './Select';
import { UnitSet } from '../../types/units';

interface UnitSelectorProps {
  unitSet: UnitSet;
  value: string;
  onChange: (newUnit: string) => any;
}

const UnitSelect: React.FC<UnitSelectorProps> = ({ unitSet, value, onChange }) => {
  const { isFetching, isError, currentData: unitOptions } = useGetUnitSetQuery(unitSet);

  if (isFetching || isError) return <Select value="" options={[]} loading />;

  return <Select value={value} options={Object.keys(unitOptions)} onChange={onChange} />;
};

export default UnitSelect;
