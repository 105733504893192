import React from 'react';
import Typography from '../Typography';

interface TodoNoteProps {
  children: string;
}

const TodoNote: React.FC<TodoNoteProps> = ({ children }) => {
  return (
    <div className="bg-yellow-400 p-1">
      <Typography variant="body1" color="red">
        {children}
      </Typography>
    </div>
  );
};

export default TodoNote;
