import React from 'react';
import { HTRIUnitSet, UnitType, useGetHTRIUnitSetQuery } from '../../redux/HTRICalcAPI';
import Select from './Select';
import Typography from './Typography';

function generateDefaultUnit(unitSet: HTRIUnitSet): string[] {
  const unit = [unitSet.numerators[0]];
  if (unitSet.type === UnitType.MultiUnit) {
    unitSet.denominators.forEach((values) => unit.push(values[0]));
  }
  return unit;
}

interface HTRIUnitSelectProps {
  unitSet: string;
  unit?: string[];
  onChange: (newUnit: string[]) => any;
}

const HTRIUnitSelect: React.FC<HTRIUnitSelectProps> = ({ unitSet, unit: unitProp, onChange }) => {
  const { isFetching, isError, currentData } = useGetHTRIUnitSetQuery(unitSet);

  if (isFetching) return <Select value="" options={[]} loading />;
  if (isError) return <div />;

  const { type, numerators } = currentData!;
  // Unit can be an empty array as a placeholder while data is fetching,
  // so make sure it has the correct length: 1 for numerator + number of denominators
  let expectedLength = 1;
  if (currentData?.type === UnitType.MultiUnit) expectedLength += currentData.denominators.length;
  let unit = unitProp ?? [];
  if (unit.length !== expectedLength) {
    unit = generateDefaultUnit(currentData!);
    // Fire onChange event once done fetching
    onChange(unit);
  }

  const handleChange = (idx: number, value: string) => {
    const newUnit = [...unit];
    newUnit[idx] = value;
    onChange(newUnit);
  };

  const numeratorSelect = (
    <Select
      value={unit[0]}
      options={numerators}
      onChange={(s) => handleChange(0, s)}
    />
  );

  // Handle singleunits
  if (currentData!.type === UnitType.SingleUnit) return numeratorSelect;

  // Handle multiunits
  const { denominators } = currentData!;
  const denominatorSelect = denominators.map((denominatorOptions, idx) => (
    <Select
      value={unit[idx + 1]}
      options={denominatorOptions}
      onChange={(s) => handleChange(idx + 1, s)}
      key={denominatorOptions[0]}
    />
  ));

  return (
    <div
      className="grid gap-x-1 items-center"
      style={{gridTemplateColumns: `repeat(${expectedLength + 1}, min-content)`}}
    >
      {numeratorSelect}
      <Typography variant="body1" className="select-none">/</Typography>
      {denominatorSelect}
    </div>
  );
};

export default HTRIUnitSelect;
