import React from 'react'
import { FormItem, InitializedFormItem, TextFormItem } from '../../../hooks/useFormData'
import Form from './Form';

const AutoField = <T extends FormItem<any>>(
  { field, autoFocus }: { field: InitializedFormItem<T>, autoFocus?: T extends TextFormItem ? boolean : never }
): React.ReactNode => {
  let label = field.label ?? field.field;
  switch (field.type) {
    case 'text':
    case 'email':
    case 'password':
      return <Form.Item label={label} type={field.type} value={field.getter} onChange={field.setter} key={field.field} autoFocus={autoFocus} />;
    case 'checkbox':
      return <Form.Item label={label} type="checkbox" value={field.getter} onChange={field.setter} key={field.field}  />;
    case 'select':
      return <Form.Item label={label} type="select" options={field.options} value={field.getter} onChange={field.setter} key={field.field}  />;
  }
};

export default AutoField
