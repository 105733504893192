import React from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '../common/Paper';
import Typography from '../common/Typography';
import { Role } from '../../types/API';
import Link from '../common/Link';
import { userManagementToolAccessRoles } from './UserManagementTool/UserManagementTool';
import useCurrentUser from '../../hooks/useCurrentUser';

interface PrivilegedTool {
  label: string;
  route: string;
  roles: Set<Role>;
}
const privilegedTools: PrivilegedTool[] = [
  {
    label: 'Create New Account',
    route: 'create',
    roles: new Set([Role.Admin]),
  },
  {
    label: 'Manage Users',
    route: 'manage',
    roles: userManagementToolAccessRoles,
  }
];

const PrivilegedTools: React.FC = () => {
  const user = useCurrentUser()!;
  const navigate = useNavigate();

  const tools = privilegedTools
    .filter((tool) => tool.roles.has(user.role))
    .map((tool) => (
      <Link onClick={() => navigate(tool.route)}>{tool.label}</Link>
    ));

  return user.role === Role.Admin ? (
    <div className="mt-4">
      <Typography variant="h6">Admin Tools</Typography>
      {tools}
    </div>
  ) : undefined;
};

const ViewAccountPage: React.FC = () => {
  const user = useCurrentUser()!;
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center">
      <Paper className="m-4 w-128 xl:w-1/2 max-w-[800px]">
        <Typography variant="h4">My Account</Typography>
        <div className="mt-4">
          <Typography variant="h5">{`${user.firstName} ${user.lastName} - ${user.company}`}</Typography>
        </div>
        <Typography variant="body1" color="slate">{user.email}</Typography>
        <Link onClick={() => navigate('change-password')}>Change Password</Link>
        <PrivilegedTools />
      </Paper>
    </div>
  );
};

export default ViewAccountPage;
