import React from 'react';
import { BinaryOperatorBlock, BlockProps } from '../types';
import Block from './Block';
import Symbol from '../Symbol';

const BinaryOperator: React.FC<BlockProps> = ({ block }) => {
  const binaryOperator = block as BinaryOperatorBlock;

  return (
    <div className="flex flex-row items-center">
      <Block block={binaryOperator.lhs} />
      <Symbol symbol={binaryOperator.operator} />
      <Block block={binaryOperator.rhs} />
    </div>
  );
};

export default BinaryOperator;
