import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Form from '../common/Form/Form';
import { reset as resetError, set as setError } from '../../redux/errors';
import { useDispatch, useSelector } from '../../redux/store';
import { resetPassword } from '../../utils/API';
import { validateEmail, validatePassword } from '../../utils/inputValidation';
import { setNotification } from '../../redux/notifications';
import { authenticationSlice } from '../../redux/authentication';
import useFormData from '../../hooks/useFormData';
import { isDispatchableLoggedError, LoggedError } from '../../redux/errorMiddleware';

const ResetPasswordRequestPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { values, fields } = useFormData({
    email: {
      label: 'Email',
      type: 'email',
      value: useSelector((state) => state.authentication.signedOutEmail),
      onChange: (newEmail) => dispatch(authenticationSlice.actions.setSignedOutEmail(newEmail)),
    },
    newPassword: { label: 'New Password', type: 'password' },
    confirmNewPassword: { label: 'Confirm New Password', type: 'password' },
  });
  const { code } = useParams();
  if (!code) {
    navigate('/');
    return;
  }
  const [requestProcessing, setRequestProcessing] = React.useState(false);

  const passwordIssues = validatePassword(values.newPassword, values.confirmNewPassword);
  const canSubmit = validateEmail(values.email) && !passwordIssues.length;
  let errorMessage: string;
  if (passwordIssues.length) errorMessage = passwordIssues.join('\n');

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(resetError());

    setRequestProcessing(true);
    resetPassword({
      email: values.email,
      newPassword: values.newPassword,
      code,
    })
      .then(() => {
        setRequestProcessing(false);
        dispatch(setNotification({ message: 'Your password has been reset. Please login with your new credentials.'}));
        navigate('/');
      })
      .catch((e) => {
        if (isDispatchableLoggedError(e)) dispatch(setError(e));
        setRequestProcessing(false);
      });
  };

  return (
    <Form.Container
      title="Reset Password"
      canSubmit={canSubmit}
      onSubmit={handleSubmit}
      loading={requestProcessing}
      onBack={() => navigate('/')}
    >
      <Form.AutoField field={fields.email} />
      <Form.AutoField field={fields.newPassword} />
      <Form.AutoField field={fields.confirmNewPassword} />
    </Form.Container>
  );
};

export default ResetPasswordRequestPage;
