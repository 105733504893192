import React from 'react';
import StringRuleValueSelect from './StringRuleValueSelect';
import Button from '../../../common/Button';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';

interface StringRuleValueMultiSelectProps {
  rule: EngineeringChecklist.StringRule;
  aliasMap: Map<string, EngineeringChecklist.Variable>;
  onChange: (rhs: EngineeringChecklist.StringRuleValue[]) => any;
}

const StringRuleValueMultiSelect: React.FC<StringRuleValueMultiSelectProps> = ({
  rule,
  aliasMap,
  onChange,
}) => {
  const rhs = rule.rhs ?? [];

  const handleValueChange = (idx: number, value: EngineeringChecklist.StringRuleValue) => {
    const newRHS = [...rhs];
    newRHS[idx] = value;
    onChange(newRHS);
  };
  const handleRemoveClick = (idx: number) => {
    const newRHS = [...rhs];
    newRHS.splice(idx, 1);
    onChange(newRHS);
  };
  const valueSelectComponents = rhs.map((_value, idx) => (
    <div className="flex flex-row" key={idx}>
      <div
        className="rounded-full bg-transparent hover:bg-slate-300 transition-colors cursor-pointer"
        onClick={() => handleRemoveClick(idx)}
      >
        <TrashIcon className="size-8 p-1 text-slate-500" />
      </div>
      <StringRuleValueSelect
        rule={rule}
        idx={idx}
        aliasMap={aliasMap}
        onChange={(value) => handleValueChange(idx, value)}
      />
    </div>
  ));

  const handleAddValueClick = () => {
    const newRHS = [...rhs];
    newRHS.push(undefined);
    onChange(newRHS);
  };

  let containerClass = 'flex flex-col gap-1';
  if (rhs.length === 0) containerClass += ' h-11 justify-center';

  return (
    <div className={containerClass}>
      {valueSelectComponents}
      <Button
        label="Value"
        color="green"
        iconLeft={<PlusIcon className="size-6 text-white" />}
        onClick={handleAddValueClick}
      />
    </div>
  );
};

export default StringRuleValueMultiSelect;
