import React from 'react';
import { TabProps } from '../../common/Tabs';
import EngineeringChecklistInfo from './EngineeringChecklistInfo';
import EngineeringChecklistHelp from './EngineeringChecklistHelp';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import EngineeringChecklistVariables from './Variables/EngineeringChecklistVariables';
import Rules from './Rules/Rules';
import RoutedTabs from '../../common/RoutedTabs';

const tabs: TabProps[] = [
  {
    label: 'Information',
    element: <EngineeringChecklistInfo />,
  },
  {
    label: 'Variables',
    element: <EngineeringChecklistVariables />,
  },
  {
    label: 'Rules',
    element: <Rules />,
  },
  {
    label: 'Help',
    icon: QuestionMarkCircleIcon,
    element: <EngineeringChecklistHelp />,
  },
];

const EngineeringChecklistContent: React.FC = () => {
  return <RoutedTabs tabs={tabs} defaultTab="Variables" />;
};

export default EngineeringChecklistContent;
