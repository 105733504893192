import React from 'react';
import Material from '../../../types/materials';
import useFilters from './useFilters';
import useMaterials from './useMaterials';
import useCorrelationProperties from './useCorrelationProperties';
import { useGetAllMaterialsQuery } from '../../../redux/materialsAPI';

export interface MaterialsContextState {
  getAllMaterialsQuery: ReturnType<typeof useGetAllMaterialsQuery>;
  materialsMatchingFilters: Material.Overview[];
  filterOptions: Material.FilterOptions;
  correlationPropertyOptions: string[];
  materialIDMap: Map<number, Material.Overview>;
};

const MaterialsContext = React.createContext<MaterialsContextState>(undefined);



interface MaterialsContextProviderProps {
  children: React.ReactNode;
}

export const MaterialsContextProvider: React.FC<MaterialsContextProviderProps> = ({ children }) => {
  const { filterOptions, getAllMaterialsQuery, materials, materialIDMap } = useMaterials();
  const materialsMatchingFilters = useFilters(materials);
  const correlationPropertyOptions = useCorrelationProperties(materials);

  const contextState: MaterialsContextState = {
    getAllMaterialsQuery,
    materialsMatchingFilters,
    filterOptions,
    correlationPropertyOptions,
    materialIDMap,
  };

  return (
    <MaterialsContext.Provider value={contextState}>
      {children}
    </MaterialsContext.Provider>
  );
};

export default MaterialsContext;
