import React from 'react';
import useCurrentUser from '../../hooks/useCurrentUser';
import Typography from '../common/Typography';
import { Transition } from '@headlessui/react';
import { autoUpdate, FloatingPortal, offset, size, useFloating } from '@floating-ui/react';
import Paper from '../common/Paper';
import { useDetectClickOutside } from '../../hooks/useDetectClickOutside';
import { ArrowLeftStartOnRectangleIcon, ChevronRightIcon, UserIcon } from '@heroicons/react/24/outline';
import router from '../router';
import store from '../../redux/store';
import { signOut } from '../../redux/authentication';

interface ActionButton {
  icon: React.ElementType<{ className?: string }>;
  label: string;
  action: () => any;
}

const actionButtons: ActionButton[] = [
  {
    icon: UserIcon,
    label: 'My Account',
    action: () => router.navigate('/account'),
  },
  {
    icon: ArrowLeftStartOnRectangleIcon,
    label: 'Sign Out',
    action: () => store.dispatch(signOut()),
  },
];

const ProfileIcon: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const user = useCurrentUser();
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-end',
    middleware: [
      offset(2),
      {
        ...size({
          apply({ availableWidth, availableHeight, elements }) {
            if (open && window.innerWidth < 1024) setOpen(false);

            Object.assign(elements.floating.style, {
              maxWidth: availableWidth,
              maxHeight: availableHeight,
            });
          },
        }),
        options: [open],
      },
    ],
    whileElementsMounted: autoUpdate,
  });

  useDetectClickOutside(() => {
    setOpen(false);
  }, [refs.floating], { active: open });

  React.useEffect(() => {
    if (!open) return;
    // While open, allow closing with escape key
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') setOpen(false);
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [open]);

  if (!user) return <div />;

  const initials = (user.firstName[0] ?? '') + (user.lastName[0] ?? '');

  const buttons = actionButtons.map((button) => (
    <button
      className="w-full rounded-full text-black h-9 flex flex-row items-center gap-1 shadow-md bg-slate-100 hover:bg-slate-200 transition-colors px-2"
      onClick={() => { button.action(); setOpen(false); }}
      key={button.label}
    >
      <button.icon className="size-7 shrink-0" />
      <Typography color="inherit" className="truncate">{button.label}</Typography>
      <ChevronRightIcon className="ml-auto size-7 shrink-0" />
    </button>
  ));

  return (
    <button
      className="size-10 rounded-full border-2 border-white bg-rose-700"
      onClick={(e) => {
        // Only change state when clicked directly
        if (
          e.target instanceof Node
          && !refs.floating.current?.contains(e.target)
        ) setOpen(!open);
      }}
      ref={refs.setReference}
    >
      <Typography color="white">{initials}</Typography>
      <FloatingPortal>
        <Transition show={open}>
          <div
            className="z-30 transition-opacity duration-200 data-[closed]:opacity-0"
            style={floatingStyles}
            ref={refs.setFloating}
          >
            <Paper shadowSize="lg" innerClassName="w-96 max-w-full overflow-auto flex flex-col gap-3">
              <div className="px-2">
                <Typography variant="h6">
                  {user.firstName} {user.lastName}
                </Typography>
                <Typography variant="body2" color="lightSlate">
                  {user.company}
                </Typography>
              </div>
              <div className="flex flex-col gap-2">
                {buttons}
              </div>
            </Paper>
          </div>
        </Transition>
      </FloatingPortal>
    </button>
  );
};

export default ProfileIcon;
