import React from 'react';

import Typography from '../../../common/Typography';
import Tooltip from '../../../common/Tooltip';
import InputField from '../../../common/InputField';
import VariableSelect from './VariableSelect';
import { EngineeringChecklist } from '../../../../types/engineeringChecklist';

interface StringRuleValueSelectProps {
  rule: EngineeringChecklist.StringRule;
  idx: number;
  aliasMap: Map<string, EngineeringChecklist.Variable>;
  onChange: (value: EngineeringChecklist.StringRuleValue) => any;
}

function isString(value: string | EngineeringChecklist.Variable | undefined): value is string {
  return typeof value === 'string';
}

const StringRuleValueSelect: React.FC<StringRuleValueSelectProps> = ({
  rule,
  idx,
  aliasMap,
  onChange,
}) => {
  const value = rule.rhs?.[idx];
  const valueIsString = isString(value);

  const toggleMode = () => {
    // Convert from string to variable
    if (valueIsString) onChange(aliasMap.get(value));
    // Convert from variable to string
    else onChange(value?.alias ?? '');
  };

  const modeToggleButtonColor = valueIsString ? 'bg-sky-600' : 'bg-green-500';
  const modeToggleButtonText = valueIsString ? 'T' : 'V';
  const modeToggleButtonTooltipText = valueIsString ? 'Text' : 'Variable';
  const modeToggleButtonComponent = (
    <Tooltip label={modeToggleButtonTooltipText} width="md">
      <div
        className={`${modeToggleButtonColor} size-7 mr-1 rounded-full cursor-pointer transition-colors flex items-center justify-center select-none`}
        onClick={toggleMode}
      >
        <Typography color="white" variant="body2">
          {modeToggleButtonText}
        </Typography>
      </div>
    </Tooltip>
  );

  const inputComponent = valueIsString
    ? (
      <InputField value={value} onChange={onChange} />
    )
    : (
      <div className="min-w-12">
        <VariableSelect
          variable={value}
          allowedTypes={[EngineeringChecklist.VariableType.String]}
          onChange={onChange}
        />
      </div>
    )
  ;

  return (
    <div className="flex flex-row items-center min-w-0">
      {modeToggleButtonComponent}
      {inputComponent}
    </div>
  );
};

export default StringRuleValueSelect;
