import React from 'react';

export default function useBackgroundImage(src: string) {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    const image = document.createElement('img');
    image.src = src;
    image.onload = () => {
      setLoaded(true);
      image.remove();
    };
    image.onerror = () => {
      setLoaded(true);
    };

    return () => image.remove();
  }, []);

  const props = React.useMemo(() => {
    let className = 'duration-1000 transition-opacity ';
    className += loaded ? 'opacity-100' : 'opacity-0';
    const style: React.CSSProperties = {
      backgroundImage: loaded ? `url(${src})` : undefined,
    };

    return { className, style };
  }, [loaded]);

  return props;
}
