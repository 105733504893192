import React from 'react';
import { BlockProps, NegativeBlock } from '../types';
import Symbol from '../Symbol';
import Block from './Block';

const Negative: React.FC<BlockProps> = ({ block }) => {
  const negative = block as NegativeBlock;

  return (
    <div className="flex flex-row items-center">
      <div className="pr-0.5">
        <Symbol symbol={negative.negativeSign} />
      </div>
      <Block block={negative.content} />
    </div>
  );
};

export default Negative;
